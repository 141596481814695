import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/generic/inputs/TextInput';
import React from 'react';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import ProductSelect from 'src/components/generic/inputs/ProductMultiSelect';
import { getAllProductsWithoutPagination } from 'src/api';

export const SchemableTargets = (props) => {
  const { translationPrefix, disabled, required } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();

  const targetType = useWatch({
    control,
    name: 'schemableId.targetType',
  });

  const inputsMap = {
    PRODUCT: (
      <ProductSelect
        productFormName={'schemableId.product'}
        getProducts={getAllProductsWithoutPagination}
        translationPrefix={translationPrefix}
        required={required}
        disabled={disabled}
      />
    ),
    FAMILY: (
      <ParameterSelect
        domain="FAMILY"
        name="schemableId.family"
        label={t(`${translationPrefix}.family`)}
        disabled={disabled}
        required={required}
      />
    ),
    GROUP: (
      <ParameterSelect
        domain="GROUP"
        name="schemableId.group"
        label={t(`${translationPrefix}.group`)}
        disabled={disabled}
        required={required}
      />
    ),
    ALL: (
      <TextInput
        name="schemableId.filler"
        label={t('targets.targets')}
        placeholder={t(`${translationPrefix}.all`)}
        disabled
      />
    ),
  };

  return (
    inputsMap[targetType] || (
      <TextInput
        name="schemableId.filler"
        label={t('targets.targets')}
        placeholder={t(`${translationPrefix}.choose`)}
        disabled
      />
    )
  );
};
