import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import GraveConcessionForm from '../GraveConcessionForm';
import { GRAVE_CONCESSION_OPTION_DEFAULT } from 'src/utils/defaultsSupplier';

export default function GraveConcession(props) {
  const { namePrefix } = props;
  const name = namePrefix
    ? `${namePrefix}.graveConcessions`
    : 'graveConcessions';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="graveConcession"
      Form={GraveConcessionForm}
      defaultValues={GRAVE_CONCESSION_OPTION_DEFAULT}
      fieldsNames={[]}
    />
  );
}

GraveConcession.propTypes = {
  namePrefix: PropTypes.string,
};
