import { RULE_PATH } from 'src/api/apiPaths';
import HttpService from '../../services/HttpService';
import { mapFilterToParams } from '../../utils/mappers/filterMapper';

export function createApisForRule(type) {
  const instance = HttpService.getAxiosClient(type);

  const addRule = async (rule) => {
    const res = await instance.post(RULE_PATH, rule);
    return res.data;
  };
  const updateRule = async (rule, id) => {
    const res = await instance.put(`${RULE_PATH}/${id}`, rule);
    return res.data;
  };

  const getRuleById = async (ruleId) => {
    const res = await instance.get(`${RULE_PATH}/${ruleId}`);
    return res.data;
  };

  const getAllRules = async () => {
    const res = await instance.get(`${RULE_PATH}/all`);
    return res.data;
  };

  const getAllRulesByCriteria = async (page, size, filter) => {
    const params = mapFilterToParams(page, size, filter);

    const res = await instance.get(`${RULE_PATH}`, {
      params,
    });
    return res.data;
  };

  const findRuleConstraintUsages = async (ruleId) => {
    const res = await instance.get(`${RULE_PATH}/usages`, {
      params: {
        ruleId,
      },
    });
    return res.data;
  };
  return {
    addRule,
    updateRule,
    getRuleById,
    getAllRules,
    getAllRulesByCriteria,
    findRuleConstraintUsages,
  };
}
