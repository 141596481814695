import { getCorrectLabelForAdmin } from '../../internationalisationUtils';
import { getCardNestedOptions } from './cardNestedOptionsMapper';
import { getDamageNestedOptions } from './damageNestedOptionsMapper';
import { getPackNestedOptions } from './packNestedOptionsMapper';
import { getBancassNestedOptions } from 'src/utils/mappers/targetOptionMapper/bancassNestedOptionsMapper';

const optionAttributeMap = {
  card: 'themeList',
  bankOnline: 'offers',
  bancass: 'options',
  exemption: 'exemptionFormulas',
  offer: 'formulaList',
  damage: 'damageFormulas',
};

const nestedOptionMappersMap = {
  card: getCardNestedOptions,
  offer: getPackNestedOptions,
  bancass: getBancassNestedOptions,
  damage: getDamageNestedOptions,
};

export function getOptions(selectedProduct, selectedLang) {
  if (!selectedProduct || !optionAttributeMap[selectedProduct.type]) return [];
  const unmappedOptions =
    selectedProduct[optionAttributeMap[selectedProduct.type]];
  if (!unmappedOptions) return [];
  return unmappedOptions.map((elem) => ({
    functionalId: elem.functionalId,
    name:
      elem.functionalId +
      ' - ' +
      getCorrectLabelForAdmin(elem.label || elem.name, selectedLang),
  }));
}

export function getNestedOptions(selectedProduct, selectedLang) {
  if (!selectedProduct) return [];
  return (
    nestedOptionMappersMap[selectedProduct.type] || getDefaultNestedOptions
  )(selectedProduct, selectedLang);
}

// implementations

export function getDefaultNestedOptions() {
  return [];
}
