import ArrayAccordion from '../../../../generic/ArrayAccordion';
import { DOCUMENT_ATTACHMENT_DEFAULT } from '../../../../../utils/defaultsSupplier';
import DocumentAttachmentForm from '../DocumentAttachmentForm';

const DocumentAttachment = ({ namePrefix }) => {
  const name = namePrefix
    ? `${namePrefix}.documentAttachmentList`
    : 'documentAttachmentList';

  return (
    <ArrayAccordion
      name={name}
      translationPrefix="documentAttachment"
      Form={DocumentAttachmentForm}
      defaultValues={DOCUMENT_ATTACHMENT_DEFAULT}
      fieldsNames={['functionalId', 'descriptions']}
    />
  );
};

export default DocumentAttachment;
