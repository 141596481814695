import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { isAuthorized } from 'src/utils/authUtils';
import React from 'react';
import PropTypes from 'prop-types';

function AuthComponentDisabler({ componentRender, api, roles }) {
  const { props, type: Component } = componentRender();
  const { userContext, currentApi } = useUserContext();
  const isAuth = isAuthorized(userContext[api ?? currentApi], roles);

  return <Component {...props} disabled={!isAuth} />;
}

AuthComponentDisabler.propTypes = {
  componentRender: PropTypes.func,
  roles: PropTypes.array,
  api: PropTypes.string,
};

export { AuthComponentDisabler };
