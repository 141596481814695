import { DownOutlined } from '@ant-design/icons';
import { Empty, Tree } from 'antd';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { eligibilitySimulation } from 'src/api';
import CardDisplay from 'src/components/generic/CardDisplay';
import Loading from 'src/components/generic/Loading';
import { mapSimulationResult } from 'src/utils/mappers/simulationResultMapper';
import { FactsContext } from '../context/FactsContext';

export default function EligibilitySimulationResult() {
  const { t } = useTranslation();
  const { eventChoice, facts } = useContext(FactsContext);

  const {
    data: simulationResult,
    isError,
    error,
    isLoading,
  } = useQuery(
    ['eligibilityData', facts, eventChoice],
    () =>
      eligibilitySimulation({
        ...facts,
        productId: eventChoice.product.product,
        actId: eventChoice.act,
      }),
    {
      enabled: !!facts && !!eventChoice,
      useErrorBoundary: false,
    },
  );

  if (isLoading) {
    return (
      <CardDisplay title={t('simulation.resultCardTitle')} titleCentered={true}>
        <Loading />
      </CardDisplay>
    );
  }

  if (isError && error) {
    return (
      <CardDisplay title={t('simulation.resultCardTitle')} titleCentered={true}>
        <Empty description={error.response.data.message} />
      </CardDisplay>
    );
  }

  return (
    <CardDisplay title={t('simulation.resultCardTitle')} titleCentered={true}>
      {getContent(simulationResult, t)}
    </CardDisplay>
  );
}

function getContent(simulationResult, t) {
  if (isEmpty(simulationResult)) {
    return <Empty description={t('simulation.noEventSelected')} />;
  }

  if (isEmpty(simulationResult.constraintSimulationList)) {
    return <Empty description={t('simulation.noContraintSimulationResult')} />;
  }

  return (
    <Tree
      data-testid="tree"
      showLine
      switcherIcon={<DownOutlined />}
      treeData={mapSimulationResult(simulationResult, t)}
    />
  );
}
