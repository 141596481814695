import { DryRunResult } from 'src/components/constraint/form/ScriptConstraint/Dryrun/DryRunResult';
import { useTranslation } from 'react-i18next';

export const EvaluationDryRunResult = ({
  isError,
  error,
  isSuccess,
  dryRunResult,
}) => {
  const { t } = useTranslation();

  const hasErrorData = !!error && !!error.response && !!error.response.data;
  return (
    <div data-testid={'evaluation-dry-run'}>
      {isError && hasErrorData && (
        <DryRunResult variant="error" result={error.response.data.message} />
      )}

      {isSuccess && dryRunResult && (
        <DryRunResult
          variant="success"
          result={t('scriptConstraint.dryRunResult', {
            result: dryRunResult.result,
          })}
        />
      )}
    </div>
  );
};
