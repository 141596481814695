import { IMap } from 'src/types';
import { useQuery } from 'react-query';
import { getParametersByDomain } from 'src/api';
import { RESOURCES } from 'src/pages/formSchemas/fieldTypes/reference/constants';
import { createScoperChainedFilter } from 'src/utils/scoper';

export const useResourceStore = () => {
  const list = ({ resource, filter }: { resource?: string; filter?: IMap }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery({
      queryKey: [resource, createScoperChainedFilter(filter ?? {})],
      queryFn: () => {
        if (resource === RESOURCES.parameters && filter?.domain) {
          return getParametersByDomain(filter?.domain, filter?.query);
        }
        return Promise.resolve([]);
      },
      enabled: !!resource,
      staleTime: 10_000,
      keepPreviousData: true,
    });
  };

  return { list };
};
