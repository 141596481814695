// useMutateClearCache.js
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useShowMessage } from 'src/hooks/normalHooks/useShowMessage';

const useMutateClearCache = ({ update, queryKey }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showSuccess, showError } = useShowMessage();

  return useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      showSuccess(t('caches.cleared'));
    },
    onError: (err) => {
      showError(err);
    },
  });
};

export default useMutateClearCache;
