import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'antd';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/generic/inputs/TextInput';
import * as yup from 'yup';

const schema = yup.object().shape({
  variables: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        value: yup.string().required(),
      }),
    )
    .min(1),
});

export const ScriptDryRunForm = (props) => {
  const { onSubmit } = props;

  const { t } = useTranslation();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      variables: [],
    },
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'variables',
  });

  const handleSubmit = () => {
    const data = form
      .getValues('variables')
      .reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {});
    onSubmit(data);
  };

  return (
    <FormProvider {...form}>
      <div className="space-y-2">
        {fields.map((field, index) => (
          <div key={field.id} className="flex items-center gap-2">
            <TextInput
              name={`variables.${index}.name`}
              label={t('scriptConstraint.key_name')}
            />

            <TextInput
              name={`variables.${index}.value`}
              data-testid={`param-${index}`}
              label={t('scriptConstraint.key_value')}
            />
            <button
              type="button"
              onClick={() => remove(index)}
              data-testid={`delete-param-${index}`}
              className="pt-6"
            >
              <CloseCircleOutlined className="text-lg" />
            </button>
          </div>
        ))}
      </div>

      <div className="py-2 flex justify-center">
        <Button
          data-testid={'add-variable-btn'}
          onClick={() => append({ name: '', value: '' })}
        >
          <div className="flex items-center gap-2">
            <PlusCircleOutlined className="text-lg" />
            <p>{t('scriptConstraint.add_variable')}</p>
          </div>
        </Button>
      </div>

      <div className="flex justify-end">
        <Button
          data-testid={'execute-btn'}
          onClick={handleSubmit}
          disabled={!form.formState.isValid}
        >
          {t('scriptConstraint.execute')}
        </Button>
      </div>
    </FormProvider>
  );
};
