import AdditionalAttribute from 'src/components/product/form/generic/AdditionalAttribute';
import CardDisplay from 'src/components/generic/CardDisplay';
import { ServiceFormula } from 'src/components/product/form/service/serviceFormula';
import { useTranslation } from 'react-i18next';

function ServiceInfo() {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t('serviceFormulas.info')}>
      <ServiceFormula />
      <AdditionalAttribute />
    </CardDisplay>
  );
}

export default ServiceInfo;
