import {
  DesignerFormComponentType,
  FormBuilderField,
} from 'src/pages/formSchemas/types';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { useResourceStore } from 'src/pages/formSchemas/fieldTypes/reference/store';
import TextInput from 'src/components/generic/inputs/TextInput';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import { KeyValueInput } from 'src/components/generic/KeyValue';
import { CheckboxInput } from 'src/components/generic/inputs/CheckboxInput';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { RESOURCES } from 'src/pages/formSchemas/fieldTypes/reference/constants';

export const DesignerFormComponent: DesignerFormComponentType = ({
  fieldName,
  fieldValue,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const methods = useForm<FormBuilderField>({
    defaultValues: fieldValue,
  });

  // State
  const [selectedResource, setSelectedResource] = useState<string>(
    fieldValue?.config?.resource,
  );
  const [selectedFilters, setSelectedFilters] = useState<any>(
    fieldValue?.config?.query,
  );

  // Computed
  const filters = useMemo(() => {
    const result: { [key: string]: string } = {};
    selectedFilters?.forEach(
      ({ label, value }: { label: string; value: string }) => {
        result[label] = value;
      },
    );
    return result;
  }, [selectedFilters]);

  // Store
  const store = useResourceStore();
  const dataQuery = store.list({
    resource: selectedResource,
    filter: filters,
  });

  // Data
  const dataKeys = useMemo(() => {
    if (!dataQuery?.data?.length) {
      return [];
    }

    return Object.keys(dataQuery?.data?.[0]).map((k) => ({
      functionalId: k,
      name: k,
    }));
  }, [dataQuery.data]);

  const resources = useMemo(() => {
    return Object.values(RESOURCES).map((val) => ({
      name: t(`form_schema.resources.${val}`),
      functionalId: val,
    }));
  }, [t]);

  // Handlers
  const handleSubmit = (data: FormBuilderField) => {
    return onSubmit(fieldName, data);
  };

  const handleSelectedResourceChange = useMemo(
    () => debounce(setSelectedResource, 500),
    [],
  );
  const handleSelectedFiltersChange = useMemo(
    () => debounce(setSelectedFilters, 100),
    [],
  );

  return (
    <FormProvider {...methods}>
      <form className="space-y-4" onSubmit={methods.handleSubmit(handleSubmit)}>
        <TextInput type="text" name="name" label={t('form.name')} required />
        <TextInput type="text" name="label" label={t('form.label')} required />
        <SelectInput
          label={t('form.resource')}
          name="config.resource"
          data={resources}
          required
          onChange={handleSelectedResourceChange}
        />
        <KeyValueInput
          label={t('form.filters')}
          keyLabel={t('form.label')}
          valueLabel={t('form.value')}
          keyName="label"
          valueName="value"
          name="config.query"
          onChange={handleSelectedFiltersChange}
        />
        <TextInput
          type="text"
          name="config.defaultValue"
          label={t('form.default_value')}
        />
        <SelectInput
          label={t('form.label_attribute')}
          name="config.labelAttribute"
          data={dataKeys}
          required
          description={t('form.label_attribute_description')}
        />
        <SelectInput
          label={t('form.value_attribute')}
          name="config.valueAttribute"
          data={dataKeys}
          required
          description={t('form.value_attribute_description')}
          disabled
        />
        <CheckboxInput name="config.multiple" label={t('form.multiple')} />
        <CheckboxInput name="config.required" label={t('form.required')} />
        <Button
          type="primary"
          ghost
          htmlType="submit"
          className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
        >
          {t('form.save')}
        </Button>
      </form>
    </FormProvider>
  );
};
