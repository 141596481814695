import { COLUMN_TYPES } from 'src/components/generic/Table/CustomTable/CustomTable';

export function batchSchedulingColumns(t) {
  return [
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('factTechnicalSetting.batch.resetZero.plannedLaunchDate'),
      key: 'plannedLaunchDate',
    },
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('factTechnicalSetting.batch.resetZero.actualLaunchDate'),
      key: 'actualLaunchDate',
    },

    {
      type: COLUMN_TYPES.TAG,
      title: t('factTechnicalSetting.batch.resetZero.batchStatus'),
      key: 'batchStatus',
    },
    {
      type: COLUMN_TYPES.TAG,
      title: t('factTechnicalSetting.batch.resetZero.counts'),
      key: 'countFactNames',
    },
    {
      type: COLUMN_TYPES.ACTION,
      title: t('table.columns.actions'),
      key: 'action',
    },
  ];
}

export function batchSchedulingCronColumns(t) {
  return [
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('factTechnicalSetting.batch.resetZero.cron'),
      key: 'cronExpression',
    },
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('factTechnicalSetting.batch.resetZero.cronMeaning'),
      key: 'cronMeaning',
    },
    {
      type: COLUMN_TYPES.TAG,
      title: t('factTechnicalSetting.batch.resetZero.batchStatus'),
      key: 'status',
    },
    {
      type: COLUMN_TYPES.TAG,
      title: t('factTechnicalSetting.batch.resetZero.counts'),
      key: 'countFactNames',
    },
    {
      type: COLUMN_TYPES.ACTION,
      title: t('table.columns.actions'),
      key: 'action',
    },
  ];
}
