import {
  DesignerFormComponentType,
  FormBuilderUIFieldType,
  FormBuilderField,
  DesignerComponentType,
  FormComponentType,
  FormBuilderSchema,
} from 'src/pages/formSchemas/types';
import React from 'react';
import { FormFieldV2, FormSchemaAdminDto, IMap } from 'src/types';
import DesignerComponentWrapper from 'src/pages/formSchemas/components/DesignerComponentWrapper';
import { FieldEditButton } from 'src/pages/formSchemas/components/FieldEditButton';
import { FieldDeleteButton } from 'src/pages/formSchemas/components/FieldDeleteButton';
import FieldDuplicateButton from 'src/pages/formSchemas/components/FieldDuplicateButton';
import {
  BUILDER_FIELD_TYPES,
  EDITOR_DROPPABLE_SPACE_ID,
  FORM_BUILDER_UTILITY,
} from 'src/pages/formSchemas/utils/constants/form';
import { createScoperChainedFilter, mapChainedString } from 'src/utils/scoper';
import { SCHEMABLE_ID_KEY_DELIMITER } from 'src/pages/formSchemas/utils/constants/schemable';

export function getDesignerFieldDefaultValues(
  type: FormBuilderUIFieldType,
): FormBuilderField | null {
  const field = FORM_BUILDER_UTILITY[type.type];
  if (!field) {
    return null;
  }

  return field.designerDefaultFieldValues(type);
}

export function getDesignerFormComponent({
  fieldName,
  fieldValue,
  onSubmit,
}: React.ComponentProps<DesignerFormComponentType>) {
  const util = FORM_BUILDER_UTILITY[fieldValue.type];
  if (!util?.designerFormComponent) {
    return null;
  }

  const Component = util.designerFormComponent;
  return (
    <Component
      fieldName={fieldName}
      fieldValue={fieldValue}
      onSubmit={onSubmit}
    />
  );
}

export function getDesignerComponent({
  fieldValue,
  fieldName,
  onEdit,
  onDelete,
  onDuplicate,
  disabled,
}: React.ComponentProps<DesignerComponentType>) {
  const util = FORM_BUILDER_UTILITY[fieldValue.type];
  if (!util) {
    return null;
  }

  const canEdit = !!util.designerFormComponent && !disabled;
  const canDelete = !disabled;
  const canDuplicate = !disabled;

  const Component = util.designerComponent;
  return (
    <DesignerComponentWrapper
      fieldName={fieldName}
      fieldValue={fieldValue}
      disabled={disabled}
      extra={
        <div className="flex items-center space-x-2">
          {canEdit && (
            <FieldEditButton onEdit={() => onEdit(fieldName, fieldValue)} />
          )}
          {canDuplicate && (
            <FieldDuplicateButton onDuplicate={() => onDuplicate(fieldName)} />
          )}
          {canDelete && (
            <FieldDeleteButton onDelete={() => onDelete(fieldName)} />
          )}
        </div>
      }
    >
      <Component
        fieldName={fieldName}
        fieldValue={fieldValue}
        onEdit={onEdit}
        onDelete={onDelete}
        disabled={disabled}
        onDuplicate={onDuplicate}
      />
    </DesignerComponentWrapper>
  );
}

export function mapToFormSchemaAdminDTO(
  data: FormBuilderSchema,
): FormSchemaAdminDto {
  if (data.schemableId) {
    Object.values(data.schemableId).forEach((value: any[]) => {
      if (Array.isArray(value)) {
        value.sort();
      }
    });
  }
  const schemableId = createScoperChainedFilter(data.schemableId, {
    keysDelimiter: SCHEMABLE_ID_KEY_DELIMITER,
  });
  return {
    ...data,
    schemableId,
  };
}

export function mapToFormBuilderForm(
  schema: FormSchemaAdminDto,
): FormBuilderSchema {
  const schemableId = mapChainedString(schema.schemableId, {
    keysDelimiter: SCHEMABLE_ID_KEY_DELIMITER,
  });
  const fields = schema.fields.map((field) => mapToFormBuilderFieldType(field));
  return {
    ...schema,
    schemableId,
    fields,
  };
}

function mapToFormBuilderFieldType(field: FormFieldV2) {
  const result: FormBuilderField = { ...field };
  if (result?.config?.fields) {
    result.config.fields = result.config.fields.map((f: FormFieldV2) =>
      mapToFormBuilderFieldType(f),
    );
  }
  return result;
}

export function getNestedFieldName(
  parentFieldName: string,
  index: number,
): string {
  return `${parentFieldName}.config.fields.${index}`;
}

export function getNestedFieldNameWithoutIndex(
  parentFieldName: string,
): string {
  return `${parentFieldName}.config.fields`;
}

export function canBeDraggedOverType(
  field: FormBuilderUIFieldType,
  overType: string,
): boolean {
  const over = BUILDER_FIELD_TYPES[overType];
  if (overType !== EDITOR_DROPPABLE_SPACE_ID && !over?.droppable) {
    return false;
  }

  if (
    field.limitedDraggableTypes &&
    !field.limitedDraggableTypes.includes(overType)
  ) {
    return false;
  }

  const overDroppableTypes = over?.limitedDroppableTypes;
  return !overDroppableTypes || overDroppableTypes.includes(field.type);
}

export function updateFormBuilderFieldValues(
  values: FormBuilderField,
  options?: { labelSuffix?: () => string },
): FormBuilderField {
  if (!options) {
    return values;
  }

  const updatedValues = { ...values };
  if (updatedValues.label && options.labelSuffix) {
    updatedValues.label = `${updatedValues.label} ${options.labelSuffix()}`;
  }

  return updatedValues;
}

export function getFormComponent({
  field,
  namePrefix,
}: React.ComponentProps<FormComponentType>) {
  const util = FORM_BUILDER_UTILITY[field.type];
  if (!util?.formComponent) {
    return null;
  }

  const Component = util.formComponent;
  return <Component field={field} namePrefix={namePrefix} />;
}

export function getFormDefaultValue(
  schema: FormSchemaAdminDto,
): IMap<string, any> {
  const result: IMap<string, any> = {};
  schema.fields.forEach((field) => {
    FORM_BUILDER_UTILITY[field.type]?.formResolveDefaultValue(result, {
      field,
      namePrefix: '',
    });
  });
  return result;
}

export function isFormNamesNotReserved(
  reservedNames: string[],
  fields: FormFieldV2[],
): string | null {
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    if (field.name) {
      if (reservedNames.includes(field.name)) {
        return field.name;
      }
    } else if (field.config?.fields) {
      const result = isFormNamesNotReserved(
        reservedNames,
        field.config?.fields,
      );
      if (result !== null) {
        return result;
      }
    }
  }

  return null;
}
