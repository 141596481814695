import { API_NAMES, USER_ROLES } from 'src/utils/constants';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';

export function isAuthorized(userContext, roles) {
  if (!roles || !userContext || !userContext.roles) {
    return false;
  }

  const userRoles = userContext.roles;
  if (userRoles.includes(USER_ROLES.SUPER_ADMIN)) {
    return true;
  }

  return roles.some((role) => userRoles.includes(role));
}

export const isUserHasRoles = isAuthorized;

export default function getUserRolesByTypeAndResource(type, resource) {
  const typePrefix = type ? type.toUpperCase() : '';
  const resourceSuffix = resource ? resource.toUpperCase() : '';

  const roles = Object.values(USER_ROLES);

  return roles.filter((role) =>
    role.startsWith(`${typePrefix}_${resourceSuffix}`),
  );
}

export const useCheckSuperAdminRole = () => {
  const { userContext } = useUserContext();
  const allRoles = Object.values(API_NAMES).flatMap(
    (api) => userContext[api]?.roles,
  );
  const isSuperAdmin = allRoles?.includes(USER_ROLES.SUPER_ADMIN);
  return {
    isSuperAdmin,
  };
};
