import i18n from 'src/i18n';
import * as yup from 'yup';

const REQUIRED_STRING = yup.string().required(i18n.t('validator.blank'));

export const PRODUCT_VALIDATION_SCHEMA = yup
  .object({
    functionalId: REQUIRED_STRING,
    name: yup.object(),
    description: yup.object(),
    family: REQUIRED_STRING,
    provider: REQUIRED_STRING,
    imageLink: REQUIRED_STRING,
    canals: yup
      .array()
      .min(1, i18n.t('validator.blank'))
      .required(i18n.t('validator.blank')),
    sellingModes: yup
      .array()
      .min(1, i18n.t('validator.blank'))
      .required(i18n.t('validator.blank')),
  })
  .required();

export const PLAFOND_VALIDATION_SCHEMA = yup
  .object({
    functionalId: REQUIRED_STRING,
    labelOperation: REQUIRED_STRING,
    deviseOperation: REQUIRED_STRING,
    codeOrigin: REQUIRED_STRING,
    labelOrigin: REQUIRED_STRING,
    codePeriodicity: REQUIRED_STRING,
    labelPeriodicity: REQUIRED_STRING,
    startDate: yup.date().required(i18n.t('validator.blank')),
    endDate: yup.date().required(i18n.t('validator.blank')),
    amount: yup
      .number()
      .typeError(i18n.t('validator.amountMustBeNumber'))
      .positive(i18n.t('validator.amountShouldBePositiveNumber'))
      .required(i18n.t('validator.blank')),
    amountMin: yup
      .number()
      .typeError(i18n.t('validator.amountMustBeNumber'))
      .positive(i18n.t('validator.amountShouldBePositiveNumber'))
      .required(i18n.t('validator.blank')),
    amountMax: yup
      .number()
      .typeError(i18n.t('validator.amountMustBeNumber'))
      .positive(i18n.t('validator.amountShouldBePositiveNumber'))
      .required(i18n.t('validator.blank')),
    step: yup
      .number()
      .typeError(i18n.t('validator.stepMustBeNumber'))
      .positive(i18n.t('validator.stepShouldBePositiveNumber'))
      .required(i18n.t('validator.blank')),
    cap: yup
      .number()
      .typeError(i18n.t('validator.capMustBeNumber'))
      .positive(i18n.t('validator.capShouldBePositiveNumber'))
      .required(i18n.t('validator.blank')),
    capSharingCodeOper: REQUIRED_STRING,
    plafondType: REQUIRED_STRING,
    stateChangeable: yup.bool(),
    ceilingChangeable: yup.bool(),
    consultable: yup.bool(),
    foreignService: yup.bool(),
  })
  .required();

export const FORMULA_DETAIL_VALIDATION_SCHEMA = yup
  .object({
    functionalId: REQUIRED_STRING,
    productType: REQUIRED_STRING,
    label: REQUIRED_STRING,
    offer: REQUIRED_STRING,
    providerCode: REQUIRED_STRING,
    pricePeriodic: yup
      .number()
      .typeError(i18n.t('validator.priceMustBeNumber'))
      .positive(i18n.t('validator.priceShouldBePositiveNumber'))
      .required(i18n.t('validator.blank')),
    optional: yup.bool(),
    toLink: yup.bool(),
    linkInPostSell: yup.bool(),
    hasProductSheet: yup.bool(),
    isMoneyFacility: yup.bool(),
  })
  .required();
