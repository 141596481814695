import { useState } from 'react';
import { getSimulation } from '../../../../api/testCases';

const useSimulation = () => {
  const [loadingSimulate, setLoadingSimulate] = useState(false);

  const simulate = (data, index, setDataSource, setExpandedRowKeys) => {
    setLoadingSimulate(true);
    getSimulation(data)
      .then((res) => {
        const simulation = res.map((elem) => ({
          facts: elem.facts,
          expected: true,
          actual: elem.evaluation,
        }));
        setDataSource((prevData) => {
          const newData = [...prevData];
          newData[index].simulation = simulation;
          return newData;
        });
        setExpandedRowKeys([index]);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoadingSimulate(false));
  };

  const simulatePage = (dataSource, pagination, setDataSource) => {
    setLoadingSimulate(true);
    const startIndex = (pagination.current - 1) * pagination.pageSize;
    const endIndex = startIndex + pagination.pageSize;
    const currentPageData = dataSource.slice(startIndex, endIndex);

    Promise.all(
      currentPageData.map((record, index) => {
        const data = {
          productId: record.productId,
          actId: record.actId,
          testCaseAlternativeDto: { testCaseDtoList: record.conditions },
        };
        return getSimulation(data)
          .then((res) => {
            const simulation = res.map((elem) => ({
              facts: elem.facts,
              expected: true,
              actual: elem.evaluation,
            }));
            setDataSource((prevData) => {
              const newData = [...prevData];
              newData[startIndex + index].simulation = simulation;
              return newData;
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }),
    ).finally(() => setLoadingSimulate(false));
  };

  return {
    loadingSimulate,
    simulate,
    simulatePage,
  };
};

export default useSimulation;
