import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCorrectLabelForAdmin } from 'src/utils/internationalisationUtils';

const ProductCard = (props) => {
  const { product } = props;
  const selectedLang = useSelector(
    (state) => state.selectedLanguage.selectedLanguage,
  );
  const { t } = useTranslation();

  return (
    <div className="rounded-xl overflow-hidden p-2">
      <div className="relative">
        <div className="w-full h-full">
          <img className="w-full" src={product.imageLink} alt="imageProduct" />
        </div>

        <div className="bg-black bg-opacity-25 opacity-0 hover:opacity-100 duration-300 absolute top-0 left-0 w-full h-full">
          <div className="flex flex-col w-full h-full justify-center px-5">
            <Link to={`/products/update/${product.id}`}>
              <div className="bg-green-600 text-white text-center py-1 px-3 w-full rounded-lg mb-4 hover:bg-green-500">
                {t('product.edit')}
              </div>
            </Link>
            <Link
              to={`/products/new`}
              state={{ product: { ...product, id: undefined } }}
            >
              <div className="bg-orange-400 text-white text-center py-1 px-3 w-full rounded-lg hover:bg-orange-300">
                {t('product.duplicate')}
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex justify-center text-center p-2 text-gray-600">
        {getCorrectLabelForAdmin(product.label, selectedLang)}
      </div>
    </div>
  );
};
ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};
export default ProductCard;
