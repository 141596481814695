import { USERS_PATH } from '../apiPaths';
import { mapFilterToParams } from 'src/utils/mappers/filterMapper';
import { getApiInstance } from 'src/utils';

export const addUser = async (sourceApi, user) => {
  const res = await getApiInstance(sourceApi).post(USERS_PATH, user);
  return res.data;
};

export const updateUser = async (sourceApi, user, id) => {
  const res = await getApiInstance(sourceApi).put(`${USERS_PATH}/${id}`, user);
  return res.data;
};

export const deleteUser = async (sourceApi, id) => {
  const res = await getApiInstance(sourceApi).delete(`${USERS_PATH}/${id}`);
  return res.data;
};

export const getUsersByCriteria = async (sourceApi, page, size, filter) => {
  const params = mapFilterToParams(page, size, filter);
  const res = await getApiInstance(sourceApi).get(USERS_PATH, { params });
  return res.data;
};

export const getUserById = async (sourceApi, id) => {
  const res = await getApiInstance(sourceApi).get(`${USERS_PATH}/${id}`);
  return res.data;
};
