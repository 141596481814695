import React, { useState } from 'react';
import { Space, Modal, Divider, message } from 'antd';
import JSONPretty from 'react-json-pretty';
import { DownOutlined, CopyOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const JSONViewerModal = ({ payload, maxLength }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  const truncated =
    payload && payload.length > maxLength
      ? payload.substring(0, maxLength) + '...'
      : payload;

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(payload);
      message.success('Copied to clipboard successfully');
    } catch (error) {
      message.error('Failed to copy to clipboard');
    }
  };
  return (
    <Space>
      <div className="flex">
        {truncated}
        {payload && payload.length > maxLength && (
          <DownOutlined className="ml-0.5 cursor-pointer" onClick={openModal} />
        )}
      </div>
      {payload && payload.length > maxLength && (
        <Modal
          open={modalVisible}
          onCancel={closeModal}
          footer={null}
          width={'50vw'}
          style={{ top: 20 }}
        >
          <div>
            {t('administration.copieJson')}
            <CopyOutlined
              className="cursor-pointer"
              onClick={copyToClipboard}
            />
          </div>
          <Divider />
          <JSONPretty
            id="json-pretty"
            style={{ overflow: 'auto' }}
            data={payload}
          ></JSONPretty>
        </Modal>
      )}
    </Space>
  );
};
JSONViewerModal.propTypes = {
  payload: PropTypes.string,
};
