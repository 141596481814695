import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { mapRuleToData } from 'src/utils/mappers/ruleMapper';
import Form from 'src/components/generic/Form';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import { RULE_DEFAULT } from 'src/utils/defaultsSupplier';
import { RULE_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/ruleSchema';
import TextInput from 'src/components/generic/inputs/TextInput';
import { RULE_BASE_PATH } from 'src/routes/paths';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';
import { AuthFormDisabler } from 'src/components/generic/AuthFormDisabler';
import ExportImport from '../../generic/ExportImport';
import { USER_ROLES } from 'src/utils/constants';

const RuleForm = (props) => {
  const {
    onSubmit,
    rule,
    submitDisabled,
    additionalButtons,
    isUpdateForm,
    formDisabled,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex justify-center mx-5 ">
      <div className="w-full ">
        <AuthFormDisabler
          componentRender={({ isAuth }) => (
            <Form
              formDisabled={formDisabled || !isAuth}
              onSubmit={onSubmit}
              submitDisabled={submitDisabled}
              defaultValues={rule ? mapRuleToData(rule) : RULE_DEFAULT}
              onCancel={() => navigate(RULE_BASE_PATH)}
              validationSchema={RULE_VALIDATION_SCHEMA}
              additionalButtons={
                <>
                  {additionalButtons}
                  <ExportImport dataName="rule" />
                </>
              }
            >
              <Row gutter={[16, 12]}>
                <Col span={12}>
                  <TextInput
                    name="functionalId"
                    label={t('rule.functionalId')}
                    disabled={isUpdateForm}
                  />
                </Col>
                <Col span={12}>
                  <InternationalizationInput
                    name="name"
                    label={t('rule.name')}
                  />
                </Col>
                <Col span={24}>
                  <InternationalizationInput
                    name="description"
                    label={t('rule.description')}
                  />
                </Col>
                <Col span={24}>
                  <h2>{t('rule.constraints')}</h2>
                  <ConstraintWrapper hasErrorMessage={true} />
                </Col>
              </Row>
            </Form>
          )}
          roles={[
            USER_ROLES.EDIT_CATALOG_RULES,
            USER_ROLES.EDIT_EVENT_PRICING_RULES,
          ]}
        />
      </div>
    </div>
  );
};
export default RuleForm;
