import { TranslationOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import useModal from 'src/hooks/componentHooks/useModal';

import { fetchSupportedLanguages } from 'src/redux/api/supportedLanguages';
import TextInput from '../TextInput';
import { useTranslation } from 'react-i18next';
import ParameterSelect from '../ParameterSelect';
import { Button } from 'antd';

export default function InternationalizationInput(props) {
  const {
    name,
    label,
    startAdornment,
    description = undefined,
    required,
    placeholder,
  } = props;
  const { Modal, setOpen } = useModal();

  const dispatch = useDispatch();
  const languages = useSelector(
    (state) => state?.supportedLanguages?.supportedLanguages,
  );
  const selectedLang = useSelector(
    (state) => state.selectedLanguage.selectedLanguage,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (languages.length === 0) {
      dispatch(fetchSupportedLanguages());
    }
  }, [dispatch, languages.length]);
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: name + `[otherCanals]`,
  });

  return (
    <>
      <TextInput
        key={name + `[${selectedLang}]`}
        name={name + `[${selectedLang}]`}
        label={label}
        description={description}
        placeholder={placeholder}
        endAdornment={
          <TranslationOutlined
            style={{ fontSize: '25px' }}
            onClick={() => setOpen(true)}
          />
        }
        startAdornment={startAdornment}
        required={required}
      />
      <Modal title={`${t('internationalisation.of')}  ${label}`}>
        <>
          {languages.map((lan) => (
            <TextInput
              key={`${name}[${lan.functionalId}]`}
              name={`${name}[${lan.functionalId}]`}
              control={control}
              placeholder={placeholder}
              label={label + ' in ' + lan.name}
            />
          ))}
          {fields.map((field, index) => (
            <div className="shadow-md p-5 rounded-xl my-3" key={field.id}>
              <ParameterSelect
                domain="CANAL"
                name={name + `[otherCanals][${index}][canal]`}
                label="Canal"
              />
              {languages.map((lan) => (
                <TextInput
                  key={`${field.id}-${lan.functionalId}`}
                  name={`${name}[otherCanals][${index}][${lan.functionalId}]`}
                  control={control}
                  label={label + ' in ' + lan.name}
                />
              ))}
              <Button
                danger
                className="w-full mt-2"
                onClick={() => {
                  remove(index);
                }}
              >
                {t('constraints.deleteAlternative')}
              </Button>
            </div>
          ))}

          <Button
            type="primary"
            ghost
            className="w-full mt-2 border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
            onClick={() => {
              append({});
            }}
          >
            {t('constraints.addAlternative')}
          </Button>
        </>
      </Modal>
    </>
  );
}

InternationalizationInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  required: PropTypes.bool,
  description: PropTypes.string,
  placeholder: PropTypes.string,
};
