import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import TextInput from 'src/components/generic/inputs/TextInput';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import ExemptionEvent from '../ExemptionEvent';
import InternationalizationArrayInput from '../../../../generic/inputs/InternationalizationArrayInput';

const ExemptionFormulaForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('exemptionOption.functionalId')}
        />
      </Col>

      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.name`}
          label={t('exemptionOption.name')}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.description`}
          label={t('exemptionOption.description')}
        />
      </Col>
      <Col span={12}>
        <SwitchInput
          name={`${namePrefix}.salable`}
          label={t('exemptionOption.salable')}
        />
      </Col>
      <Col span={24}>
        <InternationalizationArrayInput
          name={`${namePrefix}.details`}
          label={t('detail')}
          translationPrefix="details"
        />
      </Col>
      <Col span={24}>
        <ExemptionEvent namePrefix={`${namePrefix}`} />
      </Col>
    </Row>
  );
};

export default ExemptionFormulaForm;
