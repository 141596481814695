import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { DAMAGE_DEFAULT } from 'src/utils/defaultsSupplier';
import DamageFormulaForm from '../DamageFormulaForm';

export default function DamageFormula(props) {
  const { namePrefix } = props;
  const name = namePrefix ? `${namePrefix}.damageFormulas` : 'damageFormulas';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="damageFormula"
      Form={DamageFormulaForm}
      defaultValues={DAMAGE_DEFAULT}
      fieldsNames={['functionalId', 'name']}
    />
  );
}

DamageFormula.propTypes = {
  namePrefix: PropTypes.string,
};
