import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { EXEMPTION_EVENT_DEFAULT } from 'src/utils/defaultsSupplier';
import ExemptionEventForm from '../ExemptionEventForm';

export default function ExemptionEvent(props) {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={`${namePrefix}.exemptionEvents`}
      translationPrefix={'ExemptionEvent'}
      Form={ExemptionEventForm}
      defaultValues={EXEMPTION_EVENT_DEFAULT}
      fieldsNames={[]}
    />
  );
}

ExemptionEvent.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
