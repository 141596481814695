import PropTypes from 'prop-types';
import { Table } from 'antd';
import { JSONViewerModal } from 'src/components/generic/JSONViewerModal/JSONViewerModal';

const ExpandedRowRender = ({ simulation }) => {
  if (!simulation || simulation.length === 0) return null;

  const columns = [
    {
      title: 'Facts',
      dataIndex: 'facts',
      key: 'facts',
      width: '70%',
      render: (text, record) => (
        <JSONViewerModal
          payload={JSON.stringify(record.facts)}
          maxLength={150}
        />
      ),
    },
    {
      title: 'Expected',
      dataIndex: 'expected',
      key: 'expected',
      width: '15%',
      render: (text, record) => <span>{record.expected ? '✔' : '❌'}</span>,
    },
    {
      title: 'Actual',
      dataIndex: 'actual',
      key: 'actual',
      width: '15%',
      render: (text, record) => <span>{record.actual ? '✔' : '❌'}</span>,
    },
  ];

  return (
    <Table
      bordered
      pagination={false}
      columns={columns}
      dataSource={simulation}
      rowClassName="editable-row"
    />
  );
};

ExpandedRowRender.propTypes = {
  simulation: PropTypes.arrayOf(
    PropTypes.shape({
      facts: PropTypes.object.isRequired,
      expected: PropTypes.bool.isRequired,
      actual: PropTypes.bool.isRequired,
    }),
  ),
};

ExpandedRowRender.defaultProps = {
  simulation: [],
};

export default ExpandedRowRender;
