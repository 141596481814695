export function getFieldValueFromPath(obj, path) {
  if (path.indexOf('.') < 0 && path.indexOf('[') < 0) return obj?.[path];
  path = path
    .split('[')
    .join('.')
    .split(']')
    .join('.')
    .split('.')
    .filter(Boolean);
  let res = obj;
  path.forEach((p) => (res = res?.[p]));
  return res;
}

export function getNameWithNamePrefix(name, namePrefix) {
  if (!namePrefix) return name;
  else return namePrefix + '.' + name;
}
