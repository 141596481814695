import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addEventPricing } from 'src/api/eventPricing';
import CardDisplay from 'src/components/generic/CardDisplay';
import { EVENT_PRICING_BASE_PATH } from 'src/routes/paths';
import EventPricingForm from '../../../components/eventPricing/EventPricingForm';
import { mapDataToEventPricing } from '../../../utils/mappers/eventPricingMapper';
import { useAddMutation } from 'src/hooks/normalHooks/mutation/useAddMutation';

function EventPricingAddPage() {
  const { t } = useTranslation();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const eventPricingAddMutation = useAddMutation({
    add: addEventPricing,
    basePath: EVENT_PRICING_BASE_PATH,
    translationPrefix: 'eventPricing',
    onSettled: () => setSubmitDisabled(false),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    const eventPricing = mapDataToEventPricing(data);
    eventPricingAddMutation.mutate(eventPricing);
  };

  return (
    <div className="p-3">
      <CardDisplay title={t('eventPricing.add')} titleCentered>
        <EventPricingForm submitDisabled={submitDisabled} onSubmit={onSubmit} />
      </CardDisplay>
    </div>
  );
}

export default EventPricingAddPage;
