import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from 'src/utils/mappers/mapperHelpers';
const mapDataToDamageGuaranteeOptionList = (data) => {
  return {
    functionalId: data.functionalId,
    label: mapDataToInternationalisation(data.label),
    description: mapDataToInternationalisation(data.description),
    plafond: data.plafond,
    details: (data.details || []).map((elem) =>
      mapDataToInternationalisation(elem),
    ),
  };
};

const mapDataToDamageGuarantees = (data) => {
  return {
    functionalId: data.functionalId,
    name: mapDataToInternationalisation(data.name),
    description: mapDataToInternationalisation(data.description),
    damageGuaranteeOptionList: (data.damageGuaranteeOptionList || []).map(
      (elem) => mapDataToDamageGuaranteeOptionList(elem),
    ),
    optional: data.optional,
    details: (data.details || []).map((elem) =>
      mapDataToInternationalisation(elem),
    ),
  };
};
const mapDataToDamageFormula = (data) => {
  return {
    functionalId: data.functionalId,
    name: mapDataToInternationalisation(data.name),
    damageGuarantees: data.damageGuarantees.map(mapDataToDamageGuarantees),
  };
};

const mapDamageGuaranteeOptionListToData = (damageGuaranteeOptionList) => {
  return {
    functionalId: damageGuaranteeOptionList.functionalId,
    label: mapInternationalisationToData(damageGuaranteeOptionList.label),
    description: mapInternationalisationToData(
      damageGuaranteeOptionList.description,
    ),
    plafond: damageGuaranteeOptionList.plafond,
    details: (damageGuaranteeOptionList.details || []).map((elem) =>
      mapInternationalisationToData(elem),
    ),
  };
};
const mapDamageGuaranteesToData = (damageGuarantees) => {
  return {
    functionalId: damageGuarantees.functionalId,
    name: mapInternationalisationToData(damageGuarantees.name),
    description: mapInternationalisationToData(damageGuarantees.description),
    damageGuaranteeOptionList: (
      damageGuarantees.damageGuaranteeOptionList || []
    ).map((elem) => mapDamageGuaranteeOptionListToData(elem)),
    optional: damageGuarantees.optional,
    details: (damageGuarantees.details || []).map((elem) =>
      mapInternationalisationToData(elem),
    ),
  };
};
const mapDamageFormulaToData = (damageFormula) => {
  return {
    functionalId: damageFormula.functionalId,
    name: mapInternationalisationToData(damageFormula.name),
    damageGuarantees: (damageFormula.damageGuarantees || []).map(
      mapDamageGuaranteesToData,
    ),
  };
};
export const mapDataToDamage = (data) => {
  const damageFormulas = (data.damageFormulas || []).map((elem) =>
    mapDataToDamageFormula(elem),
  );
  return {
    damageFormulas,
    type: 'damage',
  };
};
export const mapDamageToData = (damage) => {
  return {
    damageFormulas: (damage.damageFormulas || []).map((formula) =>
      mapDamageFormulaToData(formula),
    ),
  };
};
