import 'cronstrue/locales/fr';
import 'cronstrue/locales/en';
import 'cronstrue/locales/ar';

import cronstrue from 'cronstrue';

export function mapScheduledBatchDtoToTableData(data, t) {
  if (!data) return [];
  return data?.content.map((item) => ({
    key: item.id,
    id: item.id,
    actualLaunchDate: item.actualLaunchDate,
    plannedLaunchDate: item.plannedLaunchDate,
    batchStatus: [
      t(`factTechnicalSetting.batch.resetZero.status.${item.batchStatus}`),
    ],
    countFactNames: item.countFactNames,
  }));
}

export function mapScheduledBatchDtoToCronTableData(data, t, selectedLang) {
  if (!data) return [];
  return data?.content.map((item) => ({
    key: item.id,
    id: item.id,
    cronExpression: item.cronExpression,
    cronMeaning: cronstrue.toString(item.cronExpression, {
      locale:
        selectedLang === 'en-US' ? 'en' : selectedLang === 'ar' ? 'ar' : 'fr',
      throwExceptionOnParseError: false,
    }),
    status: [t(`factTechnicalSetting.batch.resetZero.status.${item.status}`)],
    countFactNames: item.countFactNames,
  }));
}
