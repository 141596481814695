import { DesignerComponentType } from 'src/pages/formSchemas/types';
import { FieldCardLabel } from 'src/pages/formSchemas/components/FieldCardLabel';
import { Form, Input } from 'antd';
import { TranslationOutlined } from '@ant-design/icons';

export const DesignerComponent: DesignerComponentType = ({ fieldValue }) => {
  return (
    <Form.Item className="w-full" help={fieldValue.description}>
      <FieldCardLabel fieldValue={fieldValue} />
      <Input
        value={fieldValue?.config?.defaultValue}
        placeholder={fieldValue?.config?.placeholder}
        addonAfter={<TranslationOutlined style={{ fontSize: '25px' }} />}
        disabled={true}
      />
    </Form.Item>
  );
};
