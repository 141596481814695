import { SchemableTypeUtil } from 'src/pages/formSchemas/types';
import { SchemaCreationFields } from './SchemaCreationFields';

export const productsSchemableType: SchemableTypeUtil = {
  schemaCreationFields: SchemaCreationFields,
  reservedNames: [
    'id',
    'functionalId',
    'displayed',
    'status',
    'publicationConstraints',
    'provider',
    'label',
    'descriptions',
    'salable',
    'family',
    'canals',
    'sellingModes',
    'active',
    'activationStartDate',
    'activationEndDate',
    'groupList',
    'imageLink',
    'tagList',
    'periodAmount',
    'periodValidity',
    'renewable',
    'additionalAttributes',
    'documentAttachmentList',
    'details',
    'formSchema',
    'formSchemaValues',
  ],
};
