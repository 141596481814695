import {
  DesignerComponentType,
  FormBuilderField,
} from 'src/pages/formSchemas/types';
import {
  getDesignerComponent,
  getNestedFieldName,
} from 'src/pages/formSchemas/utils/form';
import React from 'react';

export const DesignerComponent: DesignerComponentType = ({
  fieldValue,
  fieldName,
  onEdit,
  onDelete,
  onDuplicate,
  disabled,
}) => {
  return (
    <div className="space-y-4">
      {((fieldValue.config?.fields as FormBuilderField[]) ?? []).map(
        (val, i) => {
          const childFieldName = getNestedFieldName(fieldName, i);
          const component = getDesignerComponent({
            fieldName: childFieldName,
            fieldValue: val,
            onEdit,
            onDelete,
            onDuplicate,
            disabled,
          });
          return (
            <React.Fragment key={childFieldName}>{component}</React.Fragment>
          );
        },
      )}
    </div>
  );
};
