import React from 'react';
import { MenuItem } from './MenuItem';
import { useSideBarContext } from './context';
import { MenuItemWrapper } from 'src/components/generic/Sidebar/MenuItemWrapper';

function SideMenu({ items }) {
  const { defaultSelected } = useSideBarContext();

  return (
    <nav className="flex flex-col w-full flex-grow mt-4">
      <ul className="text-dark-900">
        {items.map((item) => {
          const selected = defaultSelected === item.path;
          return (
            <MenuItemWrapper
              key={item.path}
              componentRender={() => (
                <MenuItem
                  subItems={item.children}
                  icon={item.icon}
                  path={item.path}
                  title={item.name}
                  selected={selected}
                  parent={Boolean(item?.parent)}
                />
              )}
              requiredRoles={item.requiredRoles}
              apis={item.apis}
            />
          );
        })}
      </ul>
    </nav>
  );
}

export { SideMenu };
