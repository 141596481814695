import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { EXEMPTION_OPTION_DEFAULT } from 'src/utils/defaultsSupplier';
import ExemptionFormulaForm from '../ExemptionFormulaForm';

export default function ExemptionFormula(props) {
  const { namePrefix } = props;
  const name = namePrefix
    ? `${namePrefix}.exemptionFormulas`
    : 'exemptionFormulas';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="exemptionOption"
      Form={ExemptionFormulaForm}
      defaultValues={EXEMPTION_OPTION_DEFAULT}
      fieldsNames={['functionalId', 'name']}
    />
  );
}

ExemptionFormula.propTypes = {
  namePrefix: PropTypes.string,
};
