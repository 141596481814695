import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import CardDisplay from 'src/components/generic/CardDisplay';
import Loading from 'src/components/generic/Loading';
import RuleForm from 'src/components/rule/form/RuleForm';
import { Error500 } from 'src/pages/error';
import { RULE_BASE_PATH } from 'src/routes/paths';
import { mapDataToRule } from 'src/utils/mappers/ruleMapper';
import { useUpdateMutation } from 'src/hooks/normalHooks/mutation/useUpdateMutation';
import { createApisForRule } from '../../../api/rule';
import modalConfirm from '../../../utils/modalConfirm';
import RuleUpdateConfirmation from './RuleUpdateConfirmation';

export default function RuleUpdatePage() {
  const { type } = useParams();
  const { updateRule, getRuleById, findRuleConstraintUsages } =
    createApisForRule(type);

  const { ruleId } = useParams();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { t } = useTranslation();
  const queryKey = ['get-rule', ruleId];
  const {
    data: rule,
    isLoading,
    isError,
  } = useQuery(queryKey, () => getRuleById(ruleId));

  const ruleUpdateMutation = useUpdateMutation({
    update: (rule) => updateRule(rule, ruleId),
    basePath: RULE_BASE_PATH + '/' + type,
    queryKey: queryKey,
    translationPrefix: 'rule',
    onSettled: () => setSubmitDisabled(false),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    findRuleConstraintUsages(rule.functionalId).then((usages) => {
      modalConfirm(
        <RuleUpdateConfirmation usages={usages} />,
        async () => {
          const mappedRule = mapDataToRule(data);
          ruleUpdateMutation.mutate(mappedRule);
        },
        () => setSubmitDisabled(false),
        700,
      );
    });
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;

  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t('rule.update')}>
        <RuleForm
          key={rule?.id}
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
          rule={rule}
          isUpdateForm={true}
        />
      </CardDisplay>
    </div>
  );
}
