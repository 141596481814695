import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/generic/inputs/TextInput';
import ParameterMultiSelect from '../../generic/inputs/ParameterMultiSelect';
import ProductMultiSelect from '../../generic/ProductMultiSelect';
import ProductSelect from 'src/components/generic/inputs/ProductMultiSelect/ProductSelect';
import { getAllProductsWithoutPagination } from 'src/api';
import { getNestedOptions } from 'src/utils/mappers/targetOptionMapper/targetOptionMapper';
import { useEffect } from 'react';

const Targets = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  const { control, resetField } = useFormContext();

  const targetType = useWatch({
    control,
    name: 'targetType',
  });

  const targetsFormNames = {
    PRODUCT: 'products',
    FAMILY: 'families',
    GROUP: 'groups',
    PRODUCT_WITH_OPTIONS: `${namePrefix}.product`,
    PRODUCT_OPTIONS: `${namePrefix}.options`,
    ALL: 'filler',
  };

  useEffect(() => {
    Object.values(targetsFormNames).forEach((field) => resetField(field));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetType]);

  const inputsMap = {
    PRODUCT: (
      <ProductMultiSelect
        name={targetsFormNames['PRODUCT']}
        label={t(`${namePrefix}.products`)}
      />
    ),
    FAMILY: (
      <ParameterMultiSelect
        domain="FAMILY"
        name={targetsFormNames['FAMILY']}
        label={t(`${namePrefix}.families`)}
      />
    ),
    GROUP: (
      <ParameterMultiSelect
        domain="GROUP"
        name={targetsFormNames['GROUP']}
        label={t(`${namePrefix}.groups`)}
      />
    ),
    OPTION: (
      <ProductSelect
        getProducts={getAllProductsWithoutPagination}
        translationPrefix={namePrefix}
        namePrefix={namePrefix}
        getOptions={getNestedOptions}
      />
    ),
    ALL: (
      <TextInput
        name={targetsFormNames['ALL']}
        label={t('targets.targets')}
        placeholder={t(`${namePrefix}.all`)}
        disabled
      />
    ),
  };

  return (
    inputsMap[targetType] || (
      <TextInput
        name={targetsFormNames['ALL']}
        label={t('targets.targets')}
        placeholder={t(`${namePrefix}.choose`)}
        disabled
      />
    )
  );
};

export default Targets;
