import { DesignerComponentType } from 'src/pages/formSchemas/types';
import { Form, Select } from 'antd';
import { FieldCardLabel } from 'src/pages/formSchemas/components/FieldCardLabel';
import React from 'react';

export const DesignerComponent: DesignerComponentType = ({ fieldValue }) => {
  return (
    <Form.Item className="w-full" help={fieldValue.description}>
      <FieldCardLabel fieldValue={fieldValue} />
      <Select
        mode={fieldValue?.config?.multiple ? 'multiple' : undefined}
        style={{ width: '100%', borderRadius: '0%' }}
        options={[
          {
            label: fieldValue?.config?.defaultValue,
            value: fieldValue?.config?.defaultValue,
          },
        ]}
        disabled
        value={fieldValue?.config?.defaultValue}
        fieldNames={{ label: 'label', value: 'value' }}
        showArrow
      />
    </Form.Item>
  );
};
