import ReactDiffViewer from 'react-diff-viewer';
import { useTranslation } from 'react-i18next';

function DiffViewer({
  oldValue,
  newValue,
  showDiffOnly = true,
  splitView = true,
}) {
  const { t } = useTranslation();

  if (oldValue === newValue)
    return (
      <div>
        <p>{t('diffing.no_changes')}</p>
      </div>
    );

  return (
    <ReactDiffViewer
      oldValue={JSON.stringify(oldValue, null, 1)}
      newValue={JSON.stringify(newValue, null, 1)}
      splitView={splitView}
      showDiffOnly={showDiffOnly}
    />
  );
}

export { DiffViewer };
