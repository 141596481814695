import { useQuery } from 'react-query';
import SelectInput from '../inputs/SelectInput';
import { useSelectedLanguage } from '../../../hooks/normalHooks/useSelectedLanguage';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { createApisForRule } from '../../../api/rule';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function RuleSelect(props) {
  const { t } = useTranslation();
  const location = useLocation();

  const currentApi = location.pathname?.includes('event-pricing')
    ? 'eventPricing'
    : 'catalogue';

  const { getAllRules } = createApisForRule(currentApi);
  const { name, label, className } = props;
  const { data, isLoading } = useQuery(['get-all-rules', currentApi], () =>
    getAllRules(),
  );

  const { renderLocalizedContent } = useSelectedLanguage();

  const { control, setValue } = useFormContext();

  const currentRule = useWatch({
    name,
    control,
  });

  useEffect(() => {
    if (currentRule) setValue('rulesVisual', currentRule.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    !isLoading && (
      <>
        <SelectInput
          name="rulesVisual"
          label={label}
          className={className}
          data={data.map((elem) => ({
            functionalId: elem.id,
            name: renderLocalizedContent(elem.name),
          }))}
          onChange={(e) => {
            setValue(
              name,
              data.find((elem) => elem.id == e),
            );
          }}
        />
        {currentRule && (
          <Link
            to={`/rules/${currentApi}/update/${currentRule.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              type="primary"
              ghost
              className="flex justify-center items-center mt-3"
            >
              {t('rule.consult')}
              <InfoCircleOutlined />
            </Button>
          </Link>
        )}
      </>
    )
  );
}
