import { Form, Input, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { InputLabel } from 'src/components/generic/inputs/InputLabel';

export default function SelectInput(props) {
  const {
    name,
    label,
    data: options,
    required,
    startAdornment,
    mode,
    onChange,
    loading,
    disabled,
    placeholder,
    size = 'large',
    className,
    allowClear = false,
    onClear,
    description = undefined,
    defaultSelected,
    maxTagCount,
    fieldNames = { label: 'name', value: 'functionalId' },
  } = props;

  const { control, formDisabled } = useFormContext();

  return (
    <Form.Item help={description} style={{ marginBottom: '0px' }}>
      <InputLabel label={label} required={required} />
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        defaultValue={defaultSelected}
        render={({ field }) => (
          <Input.Group size={size}>
            <div className="flex justify-start bg-white border rounded-lg overflow-hidden">
              {startAdornment ? (
                <div
                  className="p-1 px-3 flex justify-center items-center border-gray-300 border-r rounded-l-md"
                  style={{ backgroundColor: '#FAFAFA' }}
                >
                  {startAdornment}
                </div>
              ) : null}
              <Select
                {...field}
                loading={loading ?? false}
                options={options}
                style={{ width: '100%', borderRadius: '0%' }}
                size={size}
                mode={mode}
                showSearch
                optionFilterProp="name"
                onChange={(e) => {
                  field.onChange(e);
                  if (onChange) onChange(e);
                }}
                maxTagCount={maxTagCount}
                disabled={formDisabled || disabled}
                fieldNames={fieldNames}
                showArrow
                bordered={false}
                placeholder={placeholder}
                className={className}
                allowClear={allowClear}
                onClear={onClear}
                defaultValue={defaultSelected}
              />
            </div>
          </Input.Group>
        )}
      />
    </Form.Item>
  );
}
