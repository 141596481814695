import { Card, Select } from 'antd';

const { Option } = Select;

export const CardInfo = (props) => {
  const { title, number, statusOptions, handleStatusChange, value, isLoading } =
    props;
  return (
    <Card
      loading={isLoading}
      size="small"
      className="text-center shadow-md rounded-2xl"
      extra={
        <>
          {typeof statusOptions === 'function' && (
            <Select
              onChange={handleStatusChange}
              value={value}
              size="small"
              dropdownMatchSelectWidth={false}
              placement="topRight"
            >
              {statusOptions()?.map((value) => (
                <Option key={value.functionalId} value={value.functionalId}>
                  {value.name}
                </Option>
              ))}
            </Select>
          )}
        </>
      }
    >
      <div className="flex flex-col gap-2 justify-center  items-center">
        <p className="text-5xl font-medium "> {number} </p>
        <span className="text-md text-gray-700 max-w-[80%] overflow-hidden whitespace-no-wrap truncate">
          {title}
        </span>
      </div>
    </Card>
  );
};
export default CardInfo;
