import { FormComponentType } from 'src/pages/formSchemas/types';
import { CheckboxInput } from 'src/components/generic/inputs/CheckboxInput';
import React from 'react';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;
  return (
    <CheckboxInput
      label={field.label as string}
      name={name}
      description={field.description}
    />
  );
};
