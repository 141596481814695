import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSideBarContext } from './context';
import { MenuItem } from './MenuItem';
import { MenuItemWrapper } from 'src/components/generic/Sidebar/MenuItemWrapper';

function SubItem({ title, path, subItems = undefined, isVisible = true }) {
  const { pathname, search } = useLocation();
  const fullPath = pathname + search;

  if (!isVisible) return null;

  if (subItems?.length) {
    return (
      <MenuItem
        path={path}
        title={title}
        subItems={subItems}
        selected={fullPath.startsWith(path)}
      />
    );
  }

  const active = fullPath === path;
  return (
    <li className="ml-7">
      <Link
        to={path}
        className={clsx(
          'flex flex-row text-sm p-1.5 hover:text-primary items-center',
          {
            ' text-primary font-semibold': active,
            'border-transparent font-normal': !active,
          },
        )}
      >
        <span>{title}</span>
      </Link>
    </li>
  );
}

function MenuItemChildren({ items, open }) {
  const { t } = useTranslation();
  const { collapsed } = useSideBarContext();

  if (!items?.length || collapsed || !open) {
    return <></>;
  }

  return (
    <ul className="ml-7 mt-2 border-primary border-dashed border-l-[1.5px]">
      {items.map((item) => {
        return (
          <MenuItemWrapper
            key={item.name}
            componentRender={() => (
              <SubItem
                title={t(item.name)}
                path={item.path}
                subItems={item.children}
              />
            )}
            requiredRoles={item.requiredRoles}
            apis={item.apis}
          />
        );
      })}
    </ul>
  );
}

export { MenuItemChildren };
