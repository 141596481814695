export const FILE_TYPE = {
  image: 'image',
  document: 'document',
  video: 'video',
  audio: 'audio',
  any: 'any',
} as const;

export const FILE_MIME_TYPES: any = {
  [FILE_TYPE.image]: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
  [FILE_TYPE.document]: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'text/plain',
  ],
  [FILE_TYPE.video]: [
    'video/mp4',
    'video/mpeg',
    'video/webm',
    'video/x-msvideo',
  ],
  [FILE_TYPE.audio]: ['audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm'],
  [FILE_TYPE.any]: undefined,
} as const;
