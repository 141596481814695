import { CopyOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Tag, message } from 'antd';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ConstraintAddForm from 'src/components/constraint/form/ConstraintAddForm';
import ComponentDisabler from 'src/components/generic/ComponentDisabler';
import { useDrawer } from 'src/stores/drawer';
import { getItem, setItem } from 'src/utils/localStorageUtils';
import UUIDClass from 'uuidjs';

export default function ConstraintsAdd({ className, name, hasErrorMessage }) {
  const { t } = useTranslation();
  const { openDrawer, closeDrawer } = useDrawer();

  // Form
  const { control, formDisabled } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name,
  });

  // Handlers
  const handleConstraintClick = (index) => {
    openDrawer({
      options: {
        title:
          index !== -1 ? (
            <div className="flex justify-between">
              {t('constraints.editTitleModal')}
              <CopyOutlined
                onClick={() => {
                  try {
                    setItem('copied-constraint', fields[index]);
                    message.success(t('copy.success.constraint'));
                  } catch (error) {
                    message.error(t('copy.fail.constraint'));
                  }
                }}
              />
            </div>
          ) : (
            t('constraints.titleModal')
          ),
      },
      body: (
        <ConstraintAddForm
          constraint={fields[index]}
          onCancel={closeDrawer}
          addConstraint={(constraint) => {
            append(constraint);
            closeDrawer();
          }}
          updateConstraint={(constraint) => {
            update(index, constraint);
            closeDrawer();
          }}
          formDisabled={formDisabled}
          hasErrorMessage={hasErrorMessage}
        />
      ),
    });
  };
  const handleConstraintRemove = (event, index) => {
    event.preventDefault();
    remove(index);
  };

  return (
    <div className={className}>
      {fields.map((con, index) => {
        return (
          <Tag
            className="cursor-pointer"
            data-testid="constraint-info"
            color="processing"
            onClick={() => handleConstraintClick(index)}
            closable={!formDisabled}
            onClose={(e) => handleConstraintRemove(e, index)}
            key={con.id}
          >
            {con.label}
          </Tag>
        );
      })}
      <div className="flex gap-2">
        <ComponentDisabler
          componentRender={() => (
            <Button
              data-testid="add-constraint-button"
              shape="circle"
              className="flex justify-center items-center my-2"
              onClick={() => handleConstraintClick(-1)}
              icon={<PlusOutlined />}
            />
          )}
        />
        <ComponentDisabler
          componentRender={() => (
            <Button
              data-testid="paste-constraint-button"
              shape="circle"
              className="flex justify-center items-center my-2"
              onClick={() => {
                try {
                  const item = getItem('copied-constraint');
                  item.id = UUIDClass.generate();
                  append(item);
                  message.success(t('paste.success.constraint'));
                } catch (error) {
                  message.error(t('paste.fail.constraint'));
                }
              }}
              icon={<FormOutlined />}
            />
          )}
        />
      </div>
    </div>
  );
}

ConstraintsAdd.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};
