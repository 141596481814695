import { yupResolver } from '@hookform/resolvers/yup';
import { Button, notification } from 'antd';
import { useContext } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CardDisplay from 'src/components/generic/CardDisplay';
import modalConfirm from 'src/utils/modalConfirm';
import * as yup from 'yup';
import { FactsContext } from '../context/FactsContext';
import SimulationFactsForm from './SimulationFactsForm';

const schema = yup.object().shape({
  facts: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        value: yup.string(),
      }),
    )
    .test('at-least-one-pair', 'At least one pair is required', (facts) => {
      return facts.some((fact) => fact.name.trim() && fact.value.trim());
    })
    .min(1),
});

export default function SimulationFacts({ FactsDisplayer }) {
  const { t } = useTranslation();
  const { setFacts } = useContext(FactsContext);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      facts: [],
    },
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'facts',
  });

  const { handleSubmit } = form;

  const onSubmit = (data) => {
    const res = {};
    data.facts.forEach(({ name, value }) => (res[name] = value));
    setFacts(res);
  };

  const handleResetFacts = () => {
    modalConfirm(t('simulation.resetFactsConfirm'), () =>
      form.reset({ facts: [] }),
    );
  };

  const handleJsonFacts = async () => {
    try {
      const object = JSON.parse(await getClipboardContent());
      const value = Object.keys(object).map((key) => ({
        name: key,
        value: object[key],
      }));
      form.reset({ facts: value });
    } catch (err) {
      notification.error({ message: t('simulation.failedToPaste') });
    }
  };

  async function getClipboardContent() {
    return await navigator.clipboard.readText();
  }

  return (
    <FormProvider
      {...{ ...form, ...{ fields, append, remove } }}
      className="p-5"
    >
      <CardDisplay title={t('simulation.factsCardTitle')} titleCentered={true}>
        <FactsDisplayer />
        <SimulationFactsForm />
        <div className="flex justify-end gap-3">
          <Button
            data-testid="reset-facts-button"
            onClick={handleResetFacts}
            className="border-red-600 text-red-600 hover:border-red-500 hover:text-red-500 disabled:opacity-30"
          >
            {t('simulation.resetFacts')}
          </Button>
          <Button
            data-testid="extract-json-facts-button"
            onClick={handleJsonFacts}
            className="border-orange-600 text-orange-600 hover:border-orange-500 hover:text-orange-500 disabled:opacity-30"
          >
            {t('simulation.pasteJson')}
          </Button>
          <Button
            data-testid="submit-button"
            onClick={handleSubmit(onSubmit)}
            disabled={!form.formState.isValid}
            className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
          >
            {t('simulation.simulate')}
          </Button>
        </div>
      </CardDisplay>
    </FormProvider>
  );
}
