import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../generic/inputs/TextInput';
import ParameterSelect from '../../../../generic/inputs/ParameterSelect';

const FormulaPricingForm = ({ namePrefix }) => {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        label={t('pricing.formulapricing')}
        type="text"
        name={`${namePrefix}.formula`}
      />
      <ParameterSelect
        name={`${namePrefix}.currency`}
        label={t('pricing.currency')}
        domain="CURRENCY"
      />
    </>
  );
};

export default FormulaPricingForm;
