import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import ComponentDisabler from '../ComponentDisabler';
import { Button } from 'antd';
import modalConfirm from 'src/utils/modalConfirm';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function Form(props) {
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage,
  );
  const {
    children,
    onSubmit,
    submitDisabled,
    formDisabled,
    isUpdateForm,
    defaultValues,
    validationSchema,
    onCancel,
    buttonsOnTop,
    additionalButtons,
    isCancelHidden,
    watchForDefaultValuesChanges = false,
    onDefaultValuesChanges,
    isSubmitting = false,
  } = props;

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit } = formMethods;

  const submit = (e) => {
    handleSubmit(onSubmit)(e);
    e.stopPropagation();
  };

  useEffect(() => {
    if (watchForDefaultValuesChanges) {
      onDefaultValuesChanges?.({
        valueSetter: formMethods.setValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchForDefaultValuesChanges, defaultValues]);
  return (
    <form onSubmit={submit} className="p-5">
      <FormProvider {...{ ...formMethods, formDisabled, isUpdateForm }}>
        {buttonsOnTop ? null : children}
        <div
          data-testid="buttons-wrapper"
          className={`flex justify-end ${
            selectedLang === 'ar' ? 'gap-4 ' : ''
          }px-3`}
        >
          {additionalButtons}
          {!isCancelHidden && (
            <Button
              danger
              className="mr-5"
              onClick={() => {
                modalConfirm(t('confirmationDialogue.cancelMessage'), onCancel);
              }}
            >
              {t('submit.cancel')}
            </Button>
          )}
          <ComponentDisabler
            componentRender={() => (
              <Button
                type="primary"
                ghost
                disabled={submitDisabled || isSubmitting}
                htmlType="submit"
                className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
              >
                {t('submit.submit')}
              </Button>
            )}
          />
        </div>
        {buttonsOnTop ? children : null}
      </FormProvider>
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.any,
  formDisabled: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object,
  validationSchema: PropTypes.object,
  watchForDefaultValuesChanges: PropTypes.bool,
  onDefaultValuesChanges: PropTypes.func,
  isUpdateForm: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};
