import React from 'react';
import TextInput from '../../generic/inputs/TextInput';
import { useTranslation } from 'react-i18next';

const FactCollectionIds = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  return (
    <div className={className}>
      <TextInput
        name="clientId"
        label={t('FactCollection.clientId')}
        placeholder={t('FactCollection.clientId')}
      />
      <TextInput
        name="contractId"
        label={t('FactCollection.contractId')}
        placeholder={t('FactCollection.contractId')}
      />
      <TextInput
        name="accountId"
        label={t('FactCollection.accountId')}
        placeholder={t('FactCollection.accountId')}
      />
      <TextInput
        name="personId"
        label={t('FactCollection.personId')}
        placeholder={t('FactCollection.personId')}
      />
    </div>
  );
};

export default FactCollectionIds;
