import { CONSENT_DEFAULT } from '../../../../utils/defaultsSupplier';
import ArrayAccordion from '../../../generic/ArrayAccordion';
import ConsentForm from '../ConsentForm';

export default function Consent() {
  return (
    <ArrayAccordion
      name="consents"
      translationPrefix="consent"
      Form={ConsentForm}
      defaultValues={CONSENT_DEFAULT}
      fieldsNames={['functionalId', 'descriptions']}
    />
  );
}
