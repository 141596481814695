import PropTypes from 'prop-types';
import { StatisticCardSkeleton } from 'src/components/administration/audit/StatisticCardSkeleton';

function StatisticCard({ title, icon, value, isLoading }) {
  if (isLoading) return <StatisticCardSkeleton />;
  return (
    <div className="rounded-md  flex flex-col justify-center items-center gap-3 p-4 border border-dashed border-primary bg-gray-50">
      <div className="flex items-center justify-center gap-5">
        <div className="rounded-md  bg-white text-primary p-3">{icon}</div>
        <span className="font-medium text-md">{title}</span>
      </div>
      <div className="font-bold text-3xl text-center text-primary">{value}</div>
    </div>
  );
}

StatisticCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  value: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export { StatisticCard };
