export const PRODUCT_PATH = '/products';
export const CONSTRAINT_PATH = '/constraints';
export const SCRIPT_VALIDATION_PATH = '/script-validation';
export const PARAMETER_PATH = '/parameters';
export const MEDIA_PATH = '/media';
export const ELIGIBLE_ACT_PATH = '/eligible-acts';
export const EVENT_PRICINGS_PATH = '/event-pricings';
export const RECOMMENDATION_PATH = '/recommendations';
export const DASHBOARD_PATH = '/dashboard';
export const FACTS_PATH = '/facts-collector';
export const ELIGIBILITY_SIMULATION_PATH = '/products/constraints/simulation';
export const PRICING_SIMULATION_PATH = '/pricings';
export const PRICING_FACTS_PATH = '/pricings/constraints/facts';
export const PARAMETER_SCHEMA_PATH = '/parameter-schemas';
export const AUDIT_STATISTICS_PATH = '/audit-statistics';
export const AUDIT_PATH = '/audit';

export const AUDITABLE_ACTIONS_PATH = '/auditable-actions';
export const PROFILES_PATH = '/profiles';
export const USERS_PATH = '/users';
export const USER_CONTEXT_PATH = '/user-context';
export const DOMAIN_PATH = '/domains';
export const DOMAIN_CATEGORY_PATH = '/domain-categories';
export const FORM_SCHEMAS_PATH = '/form-schemas';
export const REUSABLE_FORM_SCHEMAS_PATH = '/reusable-form-schemas';

export const CALCULATOR_PATH = '/calculator';
export const RULE_PATH = '/rules';
export const EVENT_PRICING_RULE_PATH = '/event-pricings/rules';
export const BATCH_SCHEDULING_PATH = 'batch-config';
export const DATE_SCHEDULING = '/date-scheduling';
export const CRON_SCHEDULING = '/cron-scheduling';

export const CACHE_PATH = '/caches';
export const TEST_CASES_PATH = '/test-cases';
