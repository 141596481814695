export const APIS_TOP5 = {
  EVENT_PRICING: 'eventPricing',
  CATALOG: 'catalog',
};
export const TOP5_TYPES = {
  [APIS_TOP5.EVENT_PRICING]: {
    CONSTRAINT: 'Constraint',
    ACT: 'Act',
  },
  [APIS_TOP5.CATALOG]: {
    CONSTRAINT: 'Constraint',
    ACT: 'Act',
  },
};
