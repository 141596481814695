import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import InternationalizationArrayInput from 'src/components/generic/inputs/InternationalizationArrayInput';
import GraveConcessionOption from '../GraveConcessionOption';

const GraveConcessionForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={24}>
        <GraveConcessionOption namePrefix={`${namePrefix}`} />
      </Col>
      <Col span={24}>
        <InternationalizationArrayInput
          name={`${namePrefix}.details`}
          label={t('graveConcession.details')}
          translationPrefix="details"
        />
      </Col>
    </Row>
  );
};

export default GraveConcessionForm;
