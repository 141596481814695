import { FormResolveDefaultValueType } from 'src/pages/formSchemas/types';

export const formResolveDefaultValue: FormResolveDefaultValueType = (
  acc,
  { field, namePrefix },
) => {
  if (field.name && field.config?.defaultValue) {
    acc[`${namePrefix}${field.name}`] = {
      resource: field.config.resource,
      field: field.config.valueAttribute,
      value: field.config.defaultValue,
    };
  }
};
