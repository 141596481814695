export const USER_ROLES = {
  CONSULT_DASHBOARD: 'CONSULT_DASHBOARD',
  CONSULT_PRODUCTS: 'CONSULT_PRODUCTS',
  CONSULT_PARAMETERS: 'CONSULT_PARAMETERS',
  CONSULT_EVENTS: 'CONSULT_EVENTS',
  CONSULT_PRICINGS: 'CONSULT_PRICINGS',
  CONSULT_RECOMMENDATIONS: 'CONSULT_RECOMMENDATIONS',
  CONSULT_FACTS: 'CONSULT_FACTS',
  EDIT_PRODUCTS: 'EDIT_PRODUCTS',
  EDIT_PARAMETERS: 'EDIT_PARAMETERS',
  EDIT_EVENTS: 'EDIT_EVENTS',
  EDIT_PRICINGS: 'EDIT_PRICINGS',
  EDIT_RECOMMENDATIONS: 'EDIT_RECOMMENDATIONS',
  EDIT_FACTS: 'EDIT_FACTS',
  PUBLISH_PRODUCTS: 'PUBLISH_PRODUCTS',
  PUBLISH_EVENTS: 'PUBLISH_EVENTS',
  PUBLISH_PRICINGS: 'PUBLISH_PRICINGS',
  PUBLISH_RECOMMENDATIONS: 'PUBLISH_RECOMMENDATIONS',
  SUPER_ADMIN: 'SUPER_ADMIN',
  SUPER_ADMIN_CATALOGUE: 'SUPER_ADMIN_CATALOGUE',
  SUPER_ADMIN_PRICING: 'SUPER_ADMIN_PRICING',
  SUPER_ADMIN_FACTS: 'SUPER_ADMIN_FACTS',
  DATA_IMPORT_EXPORT: 'DATA_IMPORT_EXPORT',
  TECHNICAL_ADMIN: 'TECHNICAL_ADMIN',
  CONSULT_CATALOG_CACHE: 'CONSULT_CATALOG_CACHE',
  CONSULT_EVENT_PRICING_CACHE: 'CONSULT_EVENT_PRICING_CACHE',
  CREATE_DOMAINS: 'CREATE_DOMAINS',
  EDIT_DOMAINS: 'EDIT_DOMAINS',
  EDIT_CATALOG_RULES: 'EDIT_CATALOG_RULES',
  EDIT_EVENT_PRICING_RULES: 'EDIT_EVENT_PRICING_RULES',
  CONSULT_CATALOG_RULES: 'CONSULT_CATALOG_RULES',
  CONSULT_EVENT_PRICING_RULES: 'CONSULT_EVENT_PRICING_RULES',
  PARTIALLY_PUBLISH_PRODUCTS: 'PARTIALLY_PUBLISH_PRODUCTS',
  PARTIALLY_PUBLISH_EVENTS: 'PARTIALLY_PUBLISH_EVENTS',
  PARTIALLY_PUBLISH_RECOMMENDATIONS: 'PARTIALLY_PUBLISH_RECOMMENDATIONS',
  PARTIALLY_PUBLISH_PRICINGS: 'PARTIALLY_PUBLISH_PRICINGS',
  CONSULT_FORM_SCHEMAS: 'CONSULT_FORM_SCHEMAS',
  CREATE_FORM_SCHEMAS: 'CREATE_FORM_SCHEMAS',
  EDIT_FORM_SCHEMAS: 'EDIT_FORM_SCHEMAS',
  PUBLISH_FORM_SCHEMAS: 'PUBLISH_FORM_SCHEMAS',
  SCHEDULE_PUBLICATION_PRICINGS: 'SCHEDULE_PUBLICATION_PRICINGS',
  SCHEDULE_PUBLICATION_PRODUCTS: 'SCHEDULE_PUBLICATION_PRODUCTS',
  SCHEDULE_PUBLICATION_EVENTS: 'SCHEDULE_PUBLICATION_EVENTS',
  SCHEDULE_PUBLICATION_RECOMMENDATIONS: 'SCHEDULE_PUBLICATION_RECOMMENDATIONS',
  MANAGE_TEST_CASES: 'MANAGE_TEST_CASES',
};
