import {
  DesignerComponentType,
  FormBuilderField,
} from 'src/pages/formSchemas/types';
import { Collapse } from 'antd';
import {
  getDesignerComponent,
  getNestedFieldName,
} from 'src/pages/formSchemas/utils/form';
import React from 'react';

export const DesignerComponent: DesignerComponentType = ({
  fieldName,
  fieldValue,
  onEdit,
  onDelete,
  onDuplicate,
  disabled,
}) => {
  return (
    <Collapse>
      <Collapse.Panel header={fieldValue?.label} key={fieldName}>
        {((fieldValue.config?.fields as FormBuilderField[]) ?? []).map(
          (val, i) => {
            const fName = getNestedFieldName(fieldName, i);
            const component = getDesignerComponent({
              fieldName: fName,
              fieldValue: val,
              onEdit,
              onDelete,
              onDuplicate,
              disabled,
            });
            return <React.Fragment key={fName}>{component}</React.Fragment>;
          },
        )}
      </Collapse.Panel>
    </Collapse>
  );
};
