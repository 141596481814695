import { useTranslation } from 'react-i18next';
import CardDisplay from '../../generic/CardDisplay';
import { useSelectedLanguage } from '../../../hooks/normalHooks/useSelectedLanguage';

const RuleCard = ({ rule }) => {
  const { t } = useTranslation();
  const { renderLocalizedContent } = useSelectedLanguage();

  return (
    <CardDisplay
      className="h-full shadow-md rounded-lg"
      title={rule.functionalId}
    >
      <div className="flex flex-col">
        <div className="flex items-start">
          <span className="text-gray-500 font-light">
            {t('rule.functionalId')}:{' '}
          </span>
          <span className="ml-2">{rule.functionalId}</span>
        </div>
        <div className="flex items-start mt-2">
          <span className="text-gray-500 font-light">{t('rule.name')}: </span>
          <span className="ml-2">{renderLocalizedContent(rule.name)}</span>
        </div>
        <div className="flex items-start mt-2">
          <span className="text-gray-500 font-light">
            {t('rule.description')}:{' '}
          </span>
          <span className="ml-2">
            {renderLocalizedContent(rule.description)}
          </span>
        </div>
      </div>
    </CardDisplay>
  );
};

export default RuleCard;
