import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { THEME_DEFAULT } from 'src/utils/defaultsSupplier';
import ThemeForm from '../ThemeForm';

export default function Theme() {
  return (
    <ArrayAccordion
      name="themes"
      translationPrefix="theme"
      Form={ThemeForm}
      defaultValues={THEME_DEFAULT}
      fieldsNames={['functionalId', 'label', 'descriptions']}
    />
  );
}

Theme.propTypes = {};
