import React, { useMemo, useState } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import { SideBarContext } from './context';
import { SidebarLogo } from './SidebarLogo';
import { SideMenu } from './SideMenu';
import { ChevronLeft, ChevronRight } from 'src/components/icons';
import clsx from 'clsx';
import { findSelectedItem } from './utils';
import { SideBarFooter } from 'src/components/generic/Sidebar/SideBarFooter';

function SideBar({ menu }) {
  const [collapsed, setCollapsed] = useState(false);

  const { pathname } = useLocation();
  const defaultSelected = useMemo(
    () => findSelectedItem(menu, pathname),
    [menu, pathname],
  );

  const handleCollapse = () => setCollapsed((old) => !old);

  return (
    <SideBarContext.Provider value={{ collapsed, defaultSelected }}>
      <Layout.Sider
        theme="light"
        breakpoint="lg"
        collapsed={collapsed}
        onCollapse={(collapsed) => setCollapsed(collapsed)}
        className="h-screen sticky top-0 left-0 z-10"
        width={300}
      >
        <div className="overflow-y-auto min-h-screen flex flex-col justify-between h-full w-full">
          <div>
            <SidebarLogo />
            <SideMenu items={menu} />
          </div>
          <div className="bg-white sticky bottom-0 w-full flex justify-center items-center py-2">
            <SideBarFooter />
          </div>
          <div className="flex flex-row items-center justify-end px-4 py-2 absolute -right-8 top-2 z-1">
            <button
              data-testid="sider-collapse-button"
              className={clsx(
                'flex items-center justify-center h-8 w-8 bg-white border rounded-full shadow',
              )}
              onClick={handleCollapse}
              type="button"
            >
              {collapsed ? (
                <ChevronRight
                  className="w-4 h-4"
                  data-testid="sider-collapsed"
                />
              ) : (
                <ChevronLeft
                  data-testid="sider-not-collapsed"
                  className="w-4 h-4"
                />
              )}
            </button>
          </div>
        </div>
      </Layout.Sider>
    </SideBarContext.Provider>
  );
}

export { SideBar };
