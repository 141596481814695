const mapDataToFacts = (arr) => {
  const obj = {};
  arr.forEach((element) => {
    obj[element.factName] = element.factValue;
  });
  return obj;
};

export const mapDataToFactCollection = (data) => {
  return {
    clientId: data.clientId,
    accountId: data.accountId,
    contractId: data.contractId,
    personId: data.personId,
    facts: mapDataToFacts(data.facts),
  };
};

const mapFactsToData = (obj) => {
  const res = [];
  const attributes = Object.keys(obj);
  for (let i = 0; i < attributes.length; i++) {
    res.push({ factName: attributes[i], factValue: obj[attributes[i]] });
  }
  return res;
};

export const mapFactCollectionToData = (FactCollection) => {
  return {
    clientId: FactCollection.clientId,
    accountId: FactCollection.accountId,
    contractId: FactCollection.contractId,
    facts: mapFactsToData(FactCollection.facts),
  };
};
