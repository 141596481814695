import { FileTextOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import InternationalizationInput from '../../../generic/inputs/InternationalizationInput';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';
import { useTranslation } from 'react-i18next';
import React from 'react';
import TextInput from 'src/components/generic/inputs/TextInput';

export const PrepaidPeriodForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 12]}>
      <Col span={8}>
        <InternationalizationInput
          name={`${namePrefix}.description`}
          label={t('prepaidPeriod.description')}
          startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={8}>
        <TextInput
          label={t('prepaidPeriod.periodsToSkipFormula')}
          type="text"
          name={`${namePrefix}.periodsToSkipFormula`}
        />
      </Col>
      <Col span={24}>
        <p className="font-medium">{t('prepaidPeriod.constraints')}</p>
        <ConstraintWrapper namePrefix={namePrefix} />
      </Col>
    </Row>
  );
};
