import { FormComponentType } from 'src/pages/formSchemas/types';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import React from 'react';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;

  const mode = field.config?.multiple === true ? 'multiple' : undefined;

  const data = (field.config?.options ?? []).map(
    ({ label, value }: { label: string; value: string }) => ({
      name: label,
      functionalId: value,
    }),
  );

  return (
    <SelectInput
      label={field.label as string}
      name={name}
      description={field.description}
      mode={mode}
      data={data}
      required={field.config?.required}
    />
  );
};
