import { Descriptions, Empty } from 'antd';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { pricingSimulation } from 'src/api';
import CardDisplay from 'src/components/generic/CardDisplay';
import Loading from 'src/components/generic/Loading';
import { parseSimulationPricing } from 'src/utils/mappers/simulationFactsNamesPricingMapper';
import { FactsContext } from '../../context';

export default function PricingSimulationResult() {
  const { eventChoice, facts } = useContext(FactsContext);
  const { t } = useTranslation();

  const {
    data: commissionData,
    isError,
    error,
    isLoading,
  } = useQuery(
    ['commissionData', facts, eventChoice],
    () =>
      pricingSimulation({
        ...facts,
        productId: eventChoice.product.product,
        actId: eventChoice.act,
      }).then((result) => parseSimulationPricing(result)),
    {
      enabled: !!facts && !!eventChoice,
      useErrorBoundary: false,
    },
  );

  const commissionInfo = (t, commissionInfo, field) => {
    return (
      <div>
        <div>
          <strong>{t('simulationPricingResult.functionalId')} : </strong>
          {get(commissionInfo, `${field}.functionalId`, '-')}
        </div>
        <div>
          <strong>{t('simulationPricingResult.name')} : </strong>
          {get(commissionInfo, `${field}.name`, '-')}
        </div>
        <div>
          <strong>{t('simulationPricingResult.domain')} : </strong>
          {get(commissionInfo, `${field}.domain`, '-')}
        </div>
        <div>
          <strong>{t('simulationPricingResult.description')} : </strong>
          {get(commissionInfo, `${field}.description`, '-')}
        </div>
      </div>
    );
  };
  const commissionPrice = (t, commissionInfo, field) => {
    return (
      <div>
        <div>
          <strong>{t('simulationPricingResult.amountHT')} : </strong>
          {get(commissionInfo, `${field}.amountHT`, '-')}
        </div>
        <div>
          <strong>{t('simulationPricingResult.amountTTC')} : </strong>
          {get(commissionInfo, `${field}.amountTTC`, '-')}
        </div>
        <div>
          <strong>{t('simulationPricingResult.amountVAT')} : </strong>
          {get(commissionInfo, `${field}.vatAmount`, '-')}
        </div>
        <div>
          <strong>{t('simulationPricingResult.currency')} : </strong>
          {get(commissionInfo, `${field}.currency`, '-')}
        </div>
        <div>
          <strong>{t('simulationPricingResult.percentageVAT')} : </strong>
          {get(commissionInfo, `${field}.vatPercentage`, '-')}
        </div>
      </div>
    );
  };
  const renderCommissionData = (commissionData) => {
    return commissionData?.commissionList
      .sort((a, b) => (a.label || '').localeCompare(b.label || ''))
      .map((commission, index) => (
        <div key={index} className="mb-12">
          <Descriptions
            title={`Commission : ${get(commission, 'commissionId', '-')}`}
            bordered
            column={{ xs: 1, sm: 4, md: 4 }}
            layout="vertical"
          >
            <Descriptions.Item label={t('simulationPricingResult.id')}>
              {isEmpty(commission.commissionId) ? '-' : commission.commissionId}
            </Descriptions.Item>
            <Descriptions.Item label={t('simulationPricingResult.label')}>
              {isEmpty(commission.label) ? '-' : commission.label}
            </Descriptions.Item>
            <Descriptions.Item label={t('simulationPricingResult.periodicity')}>
              {isEmpty(commission.periodicity) ? '-' : commission.periodicity}
            </Descriptions.Item>
            <Descriptions.Item label={t('simulationPricingResult.group')}>
              {isEmpty(commission.commissionGroup)
                ? '-'
                : commission.commissionGroup}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('simulationPricingResult.discountPeriodsAmount')}
            >
              {isEmpty(commission.discountPeriodsAmount)
                ? '-'
                : commission.discountPeriodsAmount}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('simulationPricingResult.perpetualDiscount')}
            >
              {isEmpty(commission.perpetualDiscount)
                ? '-'
                : commission.perpetualDiscount}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('simulationPricingResult.commissionType')}
            >
              {commissionInfo(t, commission, 'commissionType')}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('simulationPricingResult.commissionSens')}
            >
              {commissionInfo(t, commission, 'sens')}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('simulationPricingResult.commissionStandardPrice')}
            >
              {commissionPrice(t, commission, 'standardPrice')}
            </Descriptions.Item>
            <Descriptions.Item
              label={t(
                'simulationPricingResult.commissionAppliedPriceWithoutDiscount',
              )}
            >
              {commissionPrice(t, commission, 'appliedPriceWithoutDiscount')}
            </Descriptions.Item>
            <Descriptions.Item
              label={t('simulationPricingResult.commissionAppliedPrice')}
            >
              {commissionPrice(t, commission, 'appliedPrice')}
            </Descriptions.Item>
          </Descriptions>
        </div>
      ));
  };

  if (isError)
    return (
      <CardDisplay title={t('simulation.resultCardTitle')} titleCentered={true}>
        <Empty description={error.response.data.message} />
      </CardDisplay>
    );

  return (
    <CardDisplay title={t('simulation.resultCardTitle')} titleCentered={true}>
      {isLoading ? (
        <div className="h-32">
          <Loading />
        </div>
      ) : (
        (isEmpty(commissionData?.commissionList) && (
          <Empty description={t('simulationPricingResult.noCommissions')} />
        )) ||
        renderCommissionData(commissionData)
      )}
    </CardDisplay>
  );
}
PricingSimulationResult.propTypes = {
  commissionData: PropTypes.shape({
    commissionList: PropTypes.arrayOf(PropTypes.object),
  }),
  isLoading: PropTypes.bool,
};
