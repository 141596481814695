import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

export default function useModal() {
  const [open, setOpen] = useState(false);
  const MyModal = useMemo(
    () =>
      function (props) {
        const { children, title, widthFull, onCancel } = props;
        return (
          <Modal
            centered
            width={widthFull}
            title={<div className="flex justify-center">{title}</div>}
            open={open}
            closable
            footer={[]}
            onCancel={() => {
              if (onCancel) {
                onCancel();
              }
              setOpen(false);
            }}
          >
            {children}
          </Modal>
        );
      },
    [open],
  );
  MyModal.propTypes = {
    children: PropTypes.element.isRequired,
    className: PropTypes.object,
  };

  return { Modal: MyModal, setOpen };
}
