import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { getTargetTypes } from 'src/utils/getTargetTypes';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import { SchemableTargets } from 'src/pages/formSchemas/schemableTypes/eligibleActs/SchemableTargets';

export const SchemaCreationFields: FunctionComponent<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <>
      <ParameterSelect
        name={'schemableId.act'}
        label={t('domains.act')}
        domain="ACT"
        disabled={disabled}
      />
      <SelectInput
        name={'schemableId.targetType'}
        label={t('eligibleAct.targetType')}
        data={getTargetTypes(false)}
        disabled={disabled}
      />
      <SchemableTargets translationPrefix="targets" disabled={disabled} />
    </>
  );
};
