import { LEVEL_RANGE_DEFAULT } from '../../../../../utils/defaultsSupplier';
import ArrayAccordion from '../../../../generic/ArrayAccordion';
import LevelRangeForm from '../LevelRangeForm';

export default function LevelRange(props) {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={`${namePrefix}`}
      translationPrefix="levelRangeList"
      Form={LevelRangeForm}
      defaultValues={LEVEL_RANGE_DEFAULT}
      fieldsNames={['type', 'min', 'max']}
    />
  );
}
