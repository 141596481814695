import { getCorrectLabelForAdmin } from 'src/utils/internationalisationUtils';

export function getPackNestedOptions(selectedProduct, selectedLang) {
  const res = [];
  selectedProduct.formulaList?.forEach((formula) => {
    res.push({
      functionalId: formula.functionalId,
      name:
        formula.functionalId +
        ' - ' +
        getCorrectLabelForAdmin(formula.label, selectedLang),
    });
  });
  return res;
}
