// Widgets
export const widgetInput = 'input';
export const widgetTextarea = 'textarea';
export const widgetSelect = 'select';
export const widgetScript = 'script';
export const widgetCheckbox = 'checkbox';
export const widgetSwitch = 'switch';
export const widgetDate = 'date';
export const widgetGroupLabelsInput = 'groupLabels';
export const widgetUpload = 'upload';
export const widgetConstraints = 'constraints';

// Types
export const inputTypeText = 'text';
export const inputTypeEmail = 'email';
export const inputTypeNumber = 'number';

// Source resources
export const sourceDomains = 'domains';
export const sourceParameters = 'parameters';
export const sourceOffline = 'offline';
