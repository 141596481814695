import {
  CheckOutlined,
  CloseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const useTableColumns = (
  loadingSimulate,
  simulate,
  setDataSource,
  setExpandedRowKeys,
) => {
  const { t } = useTranslation();

  const getSimulationStatus = (record) => {
    if (!record.simulation || record.simulation.length === 0) {
      return null;
    }
    return record.simulation.every((item) => item.actual === item.expected)
      ? 'success'
      : 'failure';
  };

  return [
    {
      title: t('Conditions'),
      dataIndex: 'conditions',
      key: 'conditions',
      width: '70%',
      render: (text, record) => (
        <div>
          {record?.conditions?.map((elem, key) => (
            <Tag key={key} color="blue" className="my-1">
              {elem.label}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => {
        const data = {
          productId: record.productId,
          actId: record.actId,
          testCaseAlternativeDto: { testCaseDtoList: record.conditions },
        };
        const simulationStatus = getSimulationStatus(record);
        const buttonColor =
          simulationStatus === 'success'
            ? 'green'
            : simulationStatus === 'failure'
            ? 'red'
            : 'default';

        return (
          <div>
            <Button
              className="flex justify-center items-center"
              type="primary"
              icon={
                simulationStatus === 'success' ? (
                  <CheckOutlined />
                ) : simulationStatus === 'failure' ? (
                  <CloseOutlined />
                ) : (
                  <PlayCircleOutlined />
                )
              }
              loading={loadingSimulate}
              aria-busy={loadingSimulate}
              ghost
              style={{ borderColor: buttonColor, color: buttonColor }}
              onClick={() =>
                simulate(data, record.key, setDataSource, setExpandedRowKeys)
              }
            >
              {t('testCases.simulateAlternative')}
            </Button>
          </div>
        );
      },
    },
  ];
};

export default useTableColumns;
