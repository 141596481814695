import { Form } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { CodeEditor } from '../../CodeEditor';
import PropTypes from 'prop-types';

export const ScriptInput = (props) => {
  const {
    name,
    label,
    required,
    onChange,
    actionsComponent,
    disabled,
    language,
    onBlur,
  } = props;

  const { control, formDisabled } = useFormContext();

  return (
    <Form.Item className={'mb-0'}>
      <div className="flex justify-between">
        <label>{label}</label>

        <div>{actionsComponent}</div>
      </div>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <CodeEditor
            {...field}
            language={language}
            onChange={(value) => {
              field.onChange(value);
              onChange && onChange(value);
            }}
            disabled={formDisabled || disabled}
            onBlur={() => {
              field.onBlur();
              onBlur?.();
            }}
          />
        )}
      />
    </Form.Item>
  );
};

ScriptInput.propTypes = {
  onBlur: PropTypes.func,
};

ScriptInput.defaultProps = {
  onBlur: () => {},
};
