import { FormBuilderField } from 'src/pages/formSchemas/types';
import clsx from 'clsx';
import React from 'react';
import { FieldEditButton } from 'src/pages/formSchemas/components/FieldEditButton';
import { FieldDeleteButton } from 'src/pages/formSchemas/components/FieldDeleteButton';
import { InputLabel } from 'src/components/generic/inputs/InputLabel';

interface FieldCardLabelProps {
  className?: string;
  fieldValue: FormBuilderField;
  onEdit?: () => void;
  onDelete?: () => void;
}

export function FieldCardLabel({
  className,
  fieldValue,
  onEdit,
  onDelete,
}: Readonly<FieldCardLabelProps>) {
  return (
    <div
      className={clsx(
        'group flex items-end justify-between space-x-2 font-medium',
        className,
        {
          'mb-2': !!fieldValue.label,
        },
      )}
    >
      {fieldValue.label && (
        <InputLabel
          label={fieldValue.label}
          required={fieldValue?.config?.required}
        />
      )}
      <div className="group-hover:visible invisible flex transition-all space-x-2">
        {onEdit && <FieldEditButton onEdit={onEdit} />}
        {onDelete && <FieldDeleteButton onDelete={onDelete} />}
      </div>
    </div>
  );
}
