import { useQuery } from 'react-query';
import {
  getAllRoles,
  getProfilesByCriteria,
  getUsersByCriteria,
} from 'src/api';
import { getProfileNames } from 'src/api/profile/profile';

function useGetUsersByCriteria(sourceApi, page, size, filter) {
  return useQuery(
    ['get-users', filter, page, sourceApi],
    () => getUsersByCriteria(sourceApi, page, size, filter),
    {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
    },
  );
}

function useGetProfilesByCriteria(sourceApi, page, size, filter) {
  const queryKey = ['get-profiles'];
  if (sourceApi) queryKey.push(sourceApi);
  if (filter) queryKey.push(filter);
  if (page) queryKey.push(page);
  return useQuery(
    queryKey,
    () => getProfilesByCriteria(sourceApi, page, size, filter),
    {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
    },
  );
}

function useGetAllRoles(sourceApi) {
  return useQuery(['get-roles', sourceApi], () => getAllRoles(sourceApi), {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
  });
}

function useGetProfileNames(sourceApi) {
  return useQuery(
    ['get-profile-names', sourceApi],
    () => getProfileNames(sourceApi),
    {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
    },
  );
}

export {
  useGetUsersByCriteria,
  useGetAllRoles,
  useGetProfilesByCriteria,
  useGetProfileNames,
};
