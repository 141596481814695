export const mapDataToUser = (data) => {
  return {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    username: data.username,
    status: data.active ? 'ACTIVE' : 'DISABLED',
    profileName: data.profileName,
  };
};

export const mapUserToData = (user) => {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    username: user.username,
    active: user.status === 'ACTIVE',
    profileName: user.profileName,
  };
};
