import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomPagination from 'src/components/generic/CustomPagination';
import { Error500 } from 'src/pages/error';
import { getAllEventpricingsByCriteria } from '../../../api/eventPricing';
import {
  EVENTPRICING_FILTER_FIELDS,
  filterParamMapedKeys,
  filterListTypeKeys,
} from 'src/utils/filterFields';
import EventCard from '../../../components/generic/EventCard';
import GenericFilter from 'src/components/generic/GenericFilter';
import { EVENT_PRICING_ADD_PATH } from '../../../routes/paths';
import Loading from 'src/components/generic/Loading';
import {
  mapParamsToFilter,
  mapFilterToUrl,
} from 'src/utils/mappers/filterMapper';
import { USER_ROLES } from 'src/utils/constants';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';

const ELEMENT_PER_PAGE = 20;
export const EventPricingListingPage = () => {
  const { t } = useTranslation();
  const { state, pathname, search } = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState({});

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const {
    data: eventPricings,
    isLoading,
    isError,
  } = useQuery(['get-eventPricings', page, currentFilter], () =>
    getAllEventpricingsByCriteria(page - 1, ELEMENT_PER_PAGE, currentFilter),
  );

  useEffect(() => {
    if (state?.toastContent) toast.success(state?.toastContent);
    const filter = mapParamsToFilter(
      queryParams,
      filterParamMapedKeys,
      filterListTypeKeys,
    );

    setCurrentFilter(filter);
    setPage(1);
  }, [state, queryParams]);

  const onFilter = (param) => {
    const paramsString = mapFilterToUrl(param || {}, filterParamMapedKeys);
    const newUrl = `${pathname}?${paramsString}`;
    navigate(newUrl, { replace: true });
  };

  if (isError) return <Error500 />;

  return (
    <div className="h-auto">
      <GenericFilter
        onFilter={onFilter}
        initialFilterValues={currentFilter}
        emptyFilterValues={{}}
        filterFields={EVENTPRICING_FILTER_FIELDS}
        disableSubmit={isLoading}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <div className="w-full h-full flex flex-col p-5">
          <div className="flex items-end mb-4 mr-2 justify-between">
            <p className="mx-2">
              {eventPricings?.totalElements[0]?.totalElements
                ? eventPricings?.totalElements[0]?.totalElements +
                  ' ' +
                  t('search.numberEventsPricingsFound')
                : t('search.noResultFound')}
            </p>
            <Link to={EVENT_PRICING_ADD_PATH}>
              <AuthComponentDisabler
                componentRender={() => (
                  <Button
                    type="primary"
                    className="bg-orange-500 text-white flex items-center disabled:opacity-30"
                  >
                    <PlusOutlined sx={{ fontSize: '20px' }} /> {t('crud.add')}{' '}
                    {t('subnav.eventPricing')}
                  </Button>
                )}
                roles={[USER_ROLES.EDIT_PRICINGS]}
              />
            </Link>
          </div>
          {eventPricings?.content.length > 0 ? (
            <div className="grid grid-cols-5 gap-4 w-full mb-2">
              {eventPricings?.content.map((eventPricing) => (
                <Link
                  key={eventPricing.id}
                  to={`/event-pricings/update/${eventPricing.id}`}
                >
                  <EventCard event={eventPricing} />
                </Link>
              ))}
            </div>
          ) : (
            <div className="h-96">
              <Empty />
            </div>
          )}
          <CustomPagination
            current={page}
            total={eventPricings?.totalElements[0]?.totalElements}
            pageSize={ELEMENT_PER_PAGE}
            onChange={(value) => setPage(value)}
          />
        </div>
      )}
    </div>
  );
};

export default EventPricingListingPage;
