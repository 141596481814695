import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Button, Col, Input, Row } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const KEY = 'key';
const VALUE = 'value';

function KeyValue({
  rows: defaultRows = [],
  onChange,
  keyLabel = 'Key',
  valueLabel = 'Value',
  keyName = KEY,
  valueName = VALUE,
}) {
  const [rows, setRows] = useState(defaultRows);

  const { t } = useTranslation();

  const debounceOnChange = useMemo(() => {
    if (!onChange) {
      return undefined;
    }
    return debounce(onChange, 300);
  }, [onChange]);

  useEffect(() => {
    if (debounceOnChange) {
      debounceOnChange(rows);
    }
  }, [debounceOnChange, rows]);

  const handleAddNew = (e) => {
    e.preventDefault();
    setRows((old) => [...old, { [keyName]: '', [valueName]: '' }]);
  };

  const handleRemove = (index) => {
    setRows((old) => old.filter((row, i) => i !== index));
  };

  const handleItemChange = (index, value, field = keyName) => {
    setRows((old) =>
      old.map((row, i) => {
        if (index !== i) {
          return row;
        }
        return {
          ...row,
          [field]: value,
        };
      }),
    );
  };

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        {rows.map((row, i) => (
          <Row
            key={`key-value-row-${i}`}
            gutter={16}
            justify="space-between"
            align="middle"
          >
            <Col span={10}>
              <Input
                type="text"
                placeholder={keyLabel}
                value={row[keyName]}
                onChange={(e) =>
                  handleItemChange(i, e.currentTarget.value, keyName)
                }
              />
            </Col>
            <Col span={10}>
              <Input
                type="text"
                placeholder={valueLabel}
                value={row[valueName]}
                onChange={(e) =>
                  handleItemChange(i, e.currentTarget.value, valueName)
                }
              />
            </Col>
            <Col span={4} className="flex justify-end">
              <Button
                data-testid="remove-key-value-button"
                className="inline-flex justify-center items-center"
                icon={<DeleteOutlined />}
                onClick={() => handleRemove(i)}
              />
            </Col>
          </Row>
        ))}
      </div>
      <div className="">
        <Button
          data-testid="add-key-value-button"
          className="inline-flex items-center"
          icon={<PlusOutlined />}
          onClick={handleAddNew}
        >
          {t('crud.add_new')}
        </Button>
      </div>
    </div>
  );
}

export { KeyValue };
