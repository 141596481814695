import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { mapRecommendationToData } from 'src/utils/mappers/recommendationMapper';
import Form from 'src/components/generic/Form';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import { RECOMMENDATION_DEFAULT } from 'src/utils/defaultsSupplier';
import { RECOMMENDATION_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/recommendationSchema';
import TextInput from 'src/components/generic/inputs/TextInput';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import {
  AimOutlined,
  LinkOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import { RECOMMENDATION_BASE_PATH } from 'src/routes/paths';
import CardDisplay from 'src/components/generic/CardDisplay';
import VersionsTimeLine from 'src/components/product/form/generic/VersionsTimeLine';
import {
  getRecommendationById,
  getRecommendationVersions,
} from 'src/api/recommendation';
import Loading from 'src/components/generic/Loading';
import { Error500 } from 'src/pages/error';
import useVersions from 'src/hooks/normalHooks/useVersions';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';
import ExportImport from '../../../generic/ExportImport';
import { USER_ROLES } from 'src/utils/constants';
import { AuthFormDisabler } from 'src/components/generic/AuthFormDisabler';
import { useCompareVersions } from 'src/hooks/componentHooks/useCompareVersions';
import AdditionalAttribute from '../../../product/form/generic/AdditionalAttribute';
import RecommendationSpecificInfo from '../RecommendationSpecificInfo';

const RecommendationForm = (props) => {
  const {
    onSubmit,
    recommendation,
    submitDisabled,
    additionalButtons,
    isUpdateForm,
    formDisabled,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { versions, isLoading, isError } = useVersions(
    recommendation?.id,
    getRecommendationVersions,
  );

  const { onCompare } = useCompareVersions({
    versionFetcherQuery: (id) => getRecommendationById(id),
    versions,
  });

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;
  return (
    <div className="grid grid-cols-5 gap-2">
      <CardDisplay
        title={t('versions.versions')}
        titleCentered={true}
        className="h-fit"
      >
        <VersionsTimeLine
          versions={versions}
          path="/recommendations/update/"
          onCompare={onCompare}
        />
      </CardDisplay>
      <div className="col-span-4">
        <AuthFormDisabler
          componentRender={({ isAuth }) => (
            <Form
              formDisabled={formDisabled || !isAuth}
              onSubmit={onSubmit}
              submitDisabled={submitDisabled}
              defaultValues={
                recommendation
                  ? mapRecommendationToData(recommendation)
                  : RECOMMENDATION_DEFAULT
              }
              onCancel={() => navigate(RECOMMENDATION_BASE_PATH)}
              validationSchema={RECOMMENDATION_VALIDATION_SCHEMA}
              additionalButtons={
                <>
                  {additionalButtons}
                  <ExportImport dataName="recommendation" />
                </>
              }
            >
              <Row gutter={[16, 12]}>
                <Col span={12}>
                  <TextInput
                    name="functionalId"
                    label={t('recommendation.functionalId')}
                    disabled={isUpdateForm}
                  />
                </Col>
                <Col span={12}>
                  <InternationalizationInput
                    name="description"
                    label={t('recommendation.description')}
                  />
                </Col>

                <Col span={12}>
                  <TextInput
                    type="number"
                    name="order"
                    label={t('recommendation.order')}
                    startAdornment={
                      <OrderedListOutlined style={{ fontSize: '25px' }} />
                    }
                  />
                </Col>
                <Col span={12}>
                  <SelectInput
                    name="recommendationType"
                    label={t('recommendation.type')}
                    data={[
                      {
                        functionalId: 'product',
                        name: t('recommendationType.product'),
                      },
                      {
                        functionalId: 'action',
                        name: t('recommendationType.action'),
                      },
                    ]}
                    startAdornment={
                      <AimOutlined style={{ fontSize: '25px' }} />
                    }
                  />
                </Col>
                <Col span={24}>
                  <RecommendationSpecificInfo t={t} />
                </Col>
                <Col span={24}>
                  <TextInput
                    name="url"
                    label={t('recommendation.url')}
                    startAdornment={
                      <LinkOutlined style={{ fontSize: '25px' }} />
                    }
                  />
                </Col>
                <Col span={24}>
                  <h2>{t('recommendation.constraints')}</h2>
                  <ConstraintWrapper />
                </Col>
                <Col span={24}>
                  <AdditionalAttribute />
                </Col>
              </Row>
            </Form>
          )}
          roles={[USER_ROLES.EDIT_RECOMMENDATIONS]}
        />
      </div>
    </div>
  );
};
export default RecommendationForm;
