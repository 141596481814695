import SelectInput from 'src/components/generic/inputs/SelectInput';
import { Button } from 'antd';

import { FormProvider, useForm } from 'react-hook-form';
import { useQueries } from 'react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DiffViewer } from 'src/components/generic/DiffViewer';

export const VersionsComparatorModal = ({ versions, versionFetcherQuery }) => {
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: { sourceVersion: '', targetVersion: '' },
  });
  const [oldVersion, setOldVersion] = useState(null);
  const [newVersion, setNewVersion] = useState(null);
  const results = useQueries(
    versions.map((v) => ({
      queryKey: ['version', v.id],
      queryFn: () => versionFetcherQuery(v.id),
    })),
  );

  const onSubmit = (data) => {
    const mappedData = results.map((r) => r.data);
    setOldVersion(mappedData.find((v) => v.id === data.sourceVersion));
    setNewVersion(mappedData.find((v) => v.id === data.targetVersion));
  };

  return (
    <div>
      <div className={'flex justify-between pt-4 items-center'}>
        <div className="flex flex-col">
          <h1 className="text-xl font-bold mb-2">{t('diffing.title')}</h1>
        </div>
        <div>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormProvider {...form}>
              <div className={'flex gap-2'}>
                <SelectInput
                  name={'sourceVersion'}
                  label={t('diffing.source')}
                  data={versions}
                  className="w-48"
                />
                <SelectInput
                  name={'targetVersion'}
                  label={t('diffing.target')}
                  data={versions}
                  className="w-48"
                />
                <div className={'pt-7'}>
                  <Button
                    htmlType={'submit'}
                    className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
                  >
                    {t('diffing.compare')}
                  </Button>
                </div>
              </div>
            </FormProvider>
          </form>
        </div>
      </div>
      <div>
        {!!oldVersion && !!newVersion && (
          <div className={'w-full mt-4'}>
            <DiffViewer oldValue={oldVersion} newValue={newVersion} />
          </div>
        )}
      </div>
    </div>
  );
};
