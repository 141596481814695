import { DollarCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import MultiChipsInput from 'src/components/generic/inputs/MultiChipInput';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import TextInput from 'src/components/generic/inputs/TextInput';

export default function ModuleOptionForm(props) {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.description`}
          label={t('moduleOptions.description')}
          startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.additionalPrice`}
          label={t('moduleOptions.additionalPrice')}
          startAdornment={<DollarCircleOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <ParameterSelect
          name={`${namePrefix}.periodicity`}
          label={t('moduleOptions.periodicity')}
          domain="PERIODICITY"
        />
      </Col>
      <Col span={12}>
        <MultiChipsInput
          name={`${namePrefix}.remarks`}
          label={t('moduleOptions.remarks')}
        />
      </Col>
    </Row>
  );
}
