import { lazy, Suspense } from 'react';

const DomainListingContainer = lazy(() =>
  import('src/containers/domain/DomainsListingContainer'),
);

export function DomainsListing() {
  return (
    <Suspense fallback={null}>
      <DomainListingContainer />
    </Suspense>
  );
}
