import { StatisticCard } from 'src/components/administration/audit/StatisticCard';

function StatisticsCards({ statData }) {
  return (
    <div className="grid md:grid-cols-4 grid-cols-2 gap-3 mt-3">
      {statData?.map((element) => (
        <StatisticCard
          icon={element.icon}
          title={element.title}
          value={element.value}
          isLoading={element.isLoading}
          key={element.id}
        />
      ))}
    </div>
  );
}

export { StatisticsCards };
