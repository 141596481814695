import CardDisplay from 'src/components/generic/CardDisplay';
import * as React from 'react';
import ProductMultiSelect from '../../../generic/ProductMultiSelect';
import { useTranslation } from 'react-i18next';
import AdditionalAttribute from '../generic/AdditionalAttribute';

export default function Dotation() {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t('dotation.title')}>
      <ProductMultiSelect
        name="eligibleCards"
        label={t('dotation.eligibleCards')}
        family="MONETIQUE"
      />
      <AdditionalAttribute />
    </CardDisplay>
  );
}
