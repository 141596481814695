import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from 'src/utils/mappers/mapperHelpers';

export const mapServiceToData = (service) => {
  return {
    serviceFormulas: (service.serviceFormulas || []).map((formula) =>
      mapServiceFormulaToData(formula),
    ),
  };
};

export const mapDataToService = (data) => {
  const serviceFormulas = (data.serviceFormulas || []).map((elem) =>
    mapDataToServiceFormula(elem),
  );
  return {
    serviceFormulas,
    type: 'service',
  };
};

function mapDataToServiceFormula(data) {
  return {
    functionalId: data.functionalId,
    label: mapDataToInternationalisation(data.label),
    description: mapDataToInternationalisation(data.description),
    details: data.details.map((elem) => mapDataToInternationalisation(elem)),
  };
}

function mapServiceFormulaToData(formula) {
  return {
    functionalId: formula.functionalId,
    label: mapInternationalisationToData(formula.label),
    description: mapInternationalisationToData(formula.description),
    details: formula.details.map((elem) => mapInternationalisationToData(elem)),
  };
}
