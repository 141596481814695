import { useDispatch } from 'react-redux';
import { showMessage } from 'src/redux/slices/messageSlice/messageSlice';
import ErrorDisplayer from 'src/components/generic/userFeedback/ErrorDisplayer';

export const useShowMessage = () => {
  const dispatch = useDispatch();
  const showSuccess = (message) => {
    dispatch(showMessage({ type: 'success', message }));
  };

  const showError = (err) => {
    dispatch(
      showMessage({
        type: 'error',
        message: (
          <ErrorDisplayer
            message={err.response.data.message}
            fieldErrors={err.response.data.fieldErrors}
          />
        ),
      }),
    );
  };

  return { showSuccess, showError };
};
