import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  cancelRecommendationPublication,
  createRecommendationDraft,
  getRecommendationById,
  getRecommendationVersions,
  partiallyPublishRecommendationDraft,
  publishRecommendationDraft,
  scheduleRecommendationPublication,
  unpublishRecommendationDraft,
  updateRecommendation,
  updateRecommendationPublicationConstraints,
} from 'src/api/recommendation';
import CardDisplay from 'src/components/generic/CardDisplay';
import Loading from 'src/components/generic/Loading';
import VersioningButtons from 'src/components/generic/versioning/VersioningButtons';
import RecommendationForm from 'src/components/recommendation/form/RecommendationForm';
import { Error500 } from 'src/pages/error';
import { RECOMMENDATION_BASE_PATH } from 'src/routes/paths';
import { mapDataToRecommendation } from 'src/utils/mappers/recommendationMapper';
import { useUpdateMutation } from 'src/hooks/normalHooks/mutation/useUpdateMutation';
import { usePublishMutation } from 'src/hooks/normalHooks/mutation/usePublishMutation';
import { RESOURCES } from 'src/utils/constants';
import { usePartiallyPublishMutation } from 'src/hooks/normalHooks/mutation/usePartiallyPublishMutation';
import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { useSchedulePublicationMutation } from 'src/hooks/normalHooks/mutation/useSchedulePublicationMutation';
import { useCancelSchedulingMutation } from 'src/hooks/normalHooks/mutation/useCancelSchedulingMutation';

export default function RecommendationUpdatePage() {
  const { recommendationId } = useParams();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { t } = useTranslation();
  const { closeModal } = useModalContext();
  const queryKey = ['get-recommendation', recommendationId];
  const {
    data: recommendation,
    isLoading,
    isError,
  } = useQuery(queryKey, () => getRecommendationById(recommendationId));

  const recommendationUpdateMutation = useUpdateMutation({
    update: (recommendation) =>
      updateRecommendation(recommendation, recommendationId),
    basePath: RECOMMENDATION_BASE_PATH,
    queryKey: queryKey,
    translationPrefix: 'recommendation',
    onSettled: () => setSubmitDisabled(false),
  });

  const recommendationPublishMutation = usePublishMutation({
    publish: () => publishRecommendationDraft(recommendationId),
    basePath: RECOMMENDATION_BASE_PATH,
    queryKeysToInvalidate: [queryKey, getRecommendationVersions.name],
    translationPrefix: 'recommendation',
  });
  const recommendationPartiallyPublishMutation = usePartiallyPublishMutation({
    partiallyPublish: ({ functionalId, publicationConstraints }) =>
      partiallyPublishRecommendationDraft(functionalId, publicationConstraints),
    basePath: RECOMMENDATION_BASE_PATH,
    queryKeysToInvalidate: [queryKey, getRecommendationVersions.name],
    translationPrefix: 'recommendation',
  });

  const publicationConstraintsUpdateMutation = useUpdateMutation({
    update: ({ functionalId, publicationConstraints }) =>
      updateRecommendationPublicationConstraints(
        functionalId,
        publicationConstraints,
      ),
    basePath: RECOMMENDATION_BASE_PATH,
    queryKey: queryKey,
    translationPrefix: 'recommendation.publicationConstraints',
    onSettled: () => closeModal(),
  });

  const recommendationSchedulePublicationMutation =
    useSchedulePublicationMutation({
      schedulePublication: ({ functionalId, publicationDate }) =>
        scheduleRecommendationPublication(functionalId, publicationDate),
      basePath: RECOMMENDATION_BASE_PATH,
      queryKeysToInvalidate: [queryKey, getRecommendationVersions.name],
      translationPrefix: 'recommendation',
    });
  const recommendationCancelPublicationMutation = useCancelSchedulingMutation({
    cancelScheduling: (functionalId) =>
      cancelRecommendationPublication(functionalId),
    basePath: RECOMMENDATION_BASE_PATH,
    queryKeysToInvalidate: [queryKey, getRecommendationVersions.name],
    translationPrefix: 'recommendation',
  });
  const onSubmit = (data) => {
    setSubmitDisabled(true);
    const mappedRecommendation = mapDataToRecommendation(data);
    recommendationUpdateMutation.mutate(mappedRecommendation);
  };
  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;
  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t('recommendation.update')}>
        <RecommendationForm
          key={recommendation?.id}
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
          recommendation={recommendation}
          formDisabled={recommendation.version !== 'draft'}
          isUpdateForm={true}
          additionalButtons={
            <VersioningButtons
              element={recommendation}
              createDraft={createRecommendationDraft}
              publishDraft={recommendationPublishMutation.mutate}
              unpublishDraft={unpublishRecommendationDraft}
              partiallyPublishDraft={
                recommendationPartiallyPublishMutation.mutate
              }
              updatePublicationConstraints={
                publicationConstraintsUpdateMutation.mutate
              }
              updateUrl="/recommendations/update/"
              resource={RESOURCES.RECOMMENDATIONS}
              schedulePublication={
                recommendationSchedulePublicationMutation.mutate
              }
              cancelSchedulingQuery={
                recommendationCancelPublicationMutation.mutate
              }
            />
          }
        />
      </CardDisplay>
    </div>
  );
}
