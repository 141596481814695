import React, { lazy, Suspense } from 'react';

const ParameterSchemaCreationContainer = lazy(() =>
  import('src/containers/parameterSchema/ParameterSchemaCreationContainer'),
);

function ParameterSchemaCreation() {
  return (
    <Suspense fallback={null}>
      <ParameterSchemaCreationContainer />
    </Suspense>
  );
}

export { ParameterSchemaCreation };
