import { PUBLICATION_STATUS } from 'src/utils/constants/publicationStatus';
import { t } from 'i18next';

export function mapPublicationStatusesToOptions() {
  const options = Object.values(PUBLICATION_STATUS).map((status) => ({
    functionalId: status,
    name: t(`dashboard.${status.toLowerCase()}`),
  }));

  options.push({ functionalId: null, name: t('dashboard.all') });

  return options;
}
