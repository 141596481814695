import { Button, Tooltip } from 'antd';

export default function ActionButton(props) {
  const { tooltipTitle, icon, onClick, isHidden = false } = props;
  if (isHidden) return null;
  return (
    <Tooltip title={tooltipTitle} placement="bottom">
      <Button
        className="flex justify-center items-center"
        shape="circle"
        icon={icon}
        onClick={onClick}
      />
    </Tooltip>
  );
}
