import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getAllFactsByClientIdAndAccountIdAndContractIdAndPersonId } from '../../../api/facts';
import { mapSearchQueryToObject } from '../../../utils/mapSearchQueryToObject/mapSearchQueryToObject';
import Form from '../../../components/generic/Form';
import { Error500 } from '../../error';
import FactCollectionIds from '../../../components/fact/FactCollectionIds';

import FactTable from './FactTable';
import Header from './Header';

export default function FactListingPage() {
  const { t } = useTranslation();
  const { search, state } = useLocation();
  const navigate = useNavigate();

  const { data, isError, isLoading, refetch } = useQuery(
    ['get-factElements', search],
    () => {
      const { clientId, accountId, contractId, personId } =
        mapSearchQueryToObject(search);
      if (clientId)
        return getAllFactsByClientIdAndAccountIdAndContractIdAndPersonId(
          clientId,
          accountId,
          contractId,
          personId,
        );
    },
  );

  const onSubmit = ({ clientId, accountId, contractId, personId }) => {
    navigate(
      '/facts?clientId=' +
        clientId +
        '&accountId=' +
        (accountId || '') +
        '&contractId=' +
        (contractId || '') +
        '&personId=' +
        (personId || ''),
    );
  };

  useEffect(() => {
    if (state?.toastContent) toast.success(state?.toastContent);
  }, [state]);

  return (
    <>
      <Header t={t} />
      <Form
        isCancelHidden={true}
        onSubmit={onSubmit}
        validationSchema={yup.object()}
        defaultValues={mapSearchQueryToObject(search)}
      >
        <FactCollectionIds className="grid grid-cols-4 gap-6 mb-3" />
      </Form>
      {isError ? (
        <Error500 />
      ) : (
        <FactTable
          t={t}
          isLoading={isLoading}
          data={data}
          refetch={refetch}
          navigate={navigate}
        />
      )}
    </>
  );
}
