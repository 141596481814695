import modalConfirm from 'src/utils/modalConfirm';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { formatDateToISO } from 'src/utils/mappers/dateMapper';
import { PublicationSchedulingForm } from 'src/components/generic/versioning/PublicationSchedulingForm';
import PropTypes from 'prop-types';

function PublicationSchedulingModal({ element, publicationSchedulingQuery }) {
  const { t } = useTranslation();
  const { closeModal } = useModalContext();
  const onSubmit = (data) => {
    modalConfirm(t('versioning.schedulePublicationMessage'), () =>
      publicationSchedulingQuery({
        functionalId: element.id,
        publicationDate: formatDateToISO(data.publicationDate),
      }),
    );
  };

  return (
    <div>
      <h5 className="text-center font-medium">
        {t('versioning.schedulePublication')}
      </h5>
      <PublicationSchedulingForm onSubmit={onSubmit} closeModal={closeModal} />
    </div>
  );
}

PublicationSchedulingModal.propTypes = {
  element: PropTypes.any,
};
export { PublicationSchedulingModal };
