import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/generic/inputs/TextInput';
import { QrcodeOutlined } from '@ant-design/icons';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import { MESSAGE_TYPE } from 'src/utils/constants';
import { mapMessageTypeToOptions } from 'src/utils/mappers/selectOptionsMapper/messageType';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';

function MessageForm({ namePrefix }) {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 8]} className="mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('messageList.functionalId')}
          startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>

      <Col span={12}>
        <SelectInput
          name={`${namePrefix}.type`}
          label={t('messageList.type')}
          data={mapMessageTypeToOptions()}
          defaultSelected={MESSAGE_TYPE.INFO}
        />
      </Col>
      <Col span={24}>
        <InternationalizationInput
          name={`${namePrefix}.label`}
          label={t('messageList.label')}
        />
      </Col>
      <Col span={24}>
        <InternationalizationInput
          name={`${namePrefix}.content`}
          label={t('messageList.content')}
          placeholder={t('messageList.contentPlaceHolder')}
        />
      </Col>

      <Col span={24}>
        <h2>{t('messageList.constraints')}</h2>
        <ConstraintWrapper namePrefix={namePrefix} />
      </Col>
    </Row>
  );
}

export { MessageForm };
