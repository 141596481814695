import { useState } from 'react';
import { useQuery } from 'react-query';
import { getStasticsOfProductsByFilter } from 'src/api';
import Chart from 'src/components/dashboard/chart';
import ProductListModal from '../../ProductListModal/ProductListModal';
import useModal from 'src/hooks/componentHooks/useModal';
import { useTranslation } from 'react-i18next';
import { getChartFilters } from 'src/utils/getChartFilters';
import { PUBLICATION_STATUS } from 'src/utils/constants/publicationStatus';
import { mapPublicationStatusesToOptions } from 'src/utils/mappers/selectOptionsMapper';

export default function ProductChart() {
  const [status, setStatus] = useState(PUBLICATION_STATUS.PUBLISHED);
  const [filter, setFilter] = useState('family');
  const [functionnalIdOfPart, setFunctionnalIdOfPart] = useState();
  const [nameOfPart, setNameOfPart] = useState();
  const [showAll, setShowAll] = useState(false);
  const { Modal, setOpen } = useModal();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const { data: statisticsData, isLoading: isStatisticsLoading } = useQuery(
    ['get-statistic-products', filter, status],
    () => {
      return getStasticsOfProductsByFilter(filter, status);
    },
  );

  const handleStatusChange = (value) => {
    setStatus(value);
  };
  const handleFilterChange = (value) => {
    setFilter(value);
  };
  const onPartSelect = (functionnalId, name) => {
    setPage(1);
    setShowAll(false);
    setOpen(true);
    setFunctionnalIdOfPart(functionnalId);
    setNameOfPart(name);
  };
  const handleShowAll = () => {
    setPage(1);
    setFunctionnalIdOfPart(null);
    setShowAll(!showAll);
    setOpen(true);
  };
  const handlePageChange = (value) => {
    setPage(value);
  };

  return (
    <>
      <Chart
        data={statisticsData}
        handleStatusChange={handleStatusChange}
        valueStatus={status}
        statusOptions={() => mapPublicationStatusesToOptions()}
        isLoading={isStatisticsLoading}
        title={t('dashboard.product.chart')}
        filterOptions={() => getChartFilters(t)}
        handleFilterChange={handleFilterChange}
        value={filter}
        onPartSelect={onPartSelect}
        handleShowAll={handleShowAll}
      />
      <ProductListModal
        functionnalIdOfPart={functionnalIdOfPart}
        status={status}
        filter={filter}
        showAll={showAll}
        nameOfPart={nameOfPart}
        Modal={Modal}
        page={page}
        handlePageChange={handlePageChange}
      />
    </>
  );
}
