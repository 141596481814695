import { CodeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getConstraintScriptLanguages } from 'src/api';
import { ScriptInput } from 'src/components/generic/inputs/ScriptInput';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import { useFormContext } from 'react-hook-form';
import {
  buildEngineOptions,
  DRY_RUN_CONTEXT,
  getLanguageTemplateScript,
  resolveDryRunComponent,
} from './helpers';
import { DryRunSection } from './Dryrun';
import { Button } from 'antd';
import { useDryRunMutation } from 'src/hooks/normalHooks/mutation/useDryRunMutation';
import { useState } from 'react';
import { ScriptFacts } from './ScriptFacts';

export default function ScriptConstraint() {
  const { t } = useTranslation();
  const [dryRunContext, setDryRunContext] = useState(null);

  const { data: languages, isLoading } = useQuery(
    'language-list',
    getConstraintScriptLanguages,
  );

  const {
    mutate: dryRun,
    isError,
    error,
    isSuccess,
    data: dryRunResult,
  } = useDryRunMutation();

  const { getValues, setValue, watch } = useFormContext();

  const language = watch('language');

  const handleDryRunExecution = (data, context) => {
    const language = getValues('language');
    const script = getValues('script');
    setDryRunContext(context);

    dryRun({
      language,
      script,
      functionName: 'isValidFor',
      variables: data,
    });
  };

  const handleDryRun = (data) => {
    handleDryRunExecution(data, DRY_RUN_CONTEXT.EVALUATING);
  };

  const handleResetTemplate = () => {
    const script = getLanguageTemplateScript(languages, getValues('language'));
    setValue('script', script);
  };

  const handleLanguageChange = (language) => {
    const script = getLanguageTemplateScript(languages, language);
    setValue('script', script);
  };

  const handleBlur = () => {
    handleDryRunExecution({}, DRY_RUN_CONTEXT.CHECKING_SYNTAX);
  };

  const DryRunResultComponent = resolveDryRunComponent(dryRunContext);

  return (
    !isLoading && (
      <div className="grid grid-cols-1 gap-3">
        <SelectInput
          name="language"
          label={t('scriptConstraint.language')}
          data={buildEngineOptions(languages, t)}
          startAdornment={<CodeOutlined style={{ fontSize: '25px' }} />}
          onChange={handleLanguageChange}
        />

        <div>
          <ScriptInput
            name="script"
            label={t('scriptConstraint.script')}
            language={language}
            actionsComponent={
              <Button
                data-testid="reset-btn"
                type={'link'}
                onClick={handleResetTemplate}
                className="text-orange-500 underline-offset-1 underline text-right cursor-pointer"
              >
                {t('generic.reset')}
              </Button>
            }
            onBlur={handleBlur}
          />

          <ScriptFacts name="factNames" label={t('scriptConstraint.facts')} />

          {DryRunResultComponent != null ? (
            <DryRunResultComponent
              isSuccess={isSuccess}
              isError={isError}
              error={error}
              dryRunResult={dryRunResult}
            />
          ) : null}
        </div>

        <div>
          <DryRunSection onExecute={handleDryRun} />
        </div>
      </div>
    )
  );
}
