import { useFormContext, useWatch } from 'react-hook-form';
import TextInput from 'src/components/generic/inputs/TextInput';

export function Price({ name, label, currencyName, disabled }) {
  const { control, startAdornment } = useFormContext();
  const currency = useWatch({
    control,
    name: currencyName,
  });
  return (
    <TextInput
      name={name}
      label={label}
      type="number"
      disabled={disabled}
      startAdornment={startAdornment}
      endAdornment={<div className="text-lg">{currency}</div>}
    />
  );
}
