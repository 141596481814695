import { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';

const DomainUpdateContainer = lazy(() =>
  import('src/containers/domain/DomainUpdateContainer'),
);

export function DomainUpdate() {
  const { id } = useParams();
  return (
    <Suspense fallback={null}>
      <DomainUpdateContainer id={id} />
    </Suspense>
  );
}
