import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteProfile } from 'src/api/profile';
import CustomTable from 'src/components/generic/Table/CustomTable';
import GenericFilter from 'src/components/generic/GenericFilter';
import ProfileForm from 'src/components/profile/form/ProfileForm';
import useModal from 'src/hooks/componentHooks/useModal';
import { PROFILES_FILTER_FIELDS } from 'src/utils/filterFields';
import { useDeleteMutation } from 'src/hooks/normalHooks/mutation/useDeleteMutation';
import modalConfirm from 'src/utils/modalConfirm';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { resolveErrorMessage } from 'src/utils/errorUtils';
import ApiSwitcher from 'src/components/apiSwitcher/ApiSwitcher';
import {
  COLUMN_TYPES,
  CRUD_ACTIONS,
} from 'src/components/generic/Table/CustomTable/CustomTable';
import {
  useGetAllRoles,
  useGetProfilesByCriteria,
} from 'src/pages/userManagement/store';
import EmptyResult from 'src/components/generic/EmptyResult';
import { mapRolesToOptions } from 'src/utils/mappers/selectOptionsMapper/userManagement';
import { USER_ROLES } from 'src/utils/constants';
import { mapProfilesToData } from 'src/utils/mappers/profileMapper/profileMapper';

function columns(t) {
  return [
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('profile.name'),
      key: 'name',
    },
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('profile.description'),
      key: 'description',
    },
    {
      type: COLUMN_TYPES.TAG,
      title: t('profile.roles'),
      key: 'roles',
      className: 'max-w-md',
    },
    {
      type: COLUMN_TYPES.ACTION,
      title: t('table.columns.actions'),
      key: 'action',
    },
  ];
}

const ELEMENTS_PER_PAGE = 20;

function ProfilesPage() {
  const { t } = useTranslation();
  const { Modal, setOpen } = useModal();
  const [profile, setProfile] = useState({});
  const [currentFilter, setCurrentFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const { currentApi } = useUserContext();

  const onFilter = (formData) => {
    setCurrentPage(1);
    setCurrentFilter(formData);
  };

  const {
    data: profiles,
    isLoading: isProfilesLoading,
    isError: isProfilesError,
    error: profilesError,
  } = useGetProfilesByCriteria(
    currentApi,
    currentPage - 1,
    ELEMENTS_PER_PAGE,
    currentFilter,
  );

  const { data: roles } = useGetAllRoles(currentApi);

  const profileDeleteMutation = useDeleteMutation({
    translationPrefix: 'profile',
    deleteFunc: (id) => deleteProfile(currentApi, id),
  });

  const isSuperAdmin = (profile) => {
    return profile.roles?.includes(USER_ROLES.SUPER_ADMIN);
  };
  const actions = [
    {
      key: CRUD_ACTIONS.UPDATE,
      onAction: (id) => {
        setProfile(profiles.content.find((profile) => profile.id === id));
        setOpen(true);
      },
      isHidden: (id) =>
        isSuperAdmin(profiles.content.find((profile) => profile.id === id)),
    },
    {
      key: CRUD_ACTIONS.DELETE,
      onAction: (id) => {
        modalConfirm(t('confirmationDialogue.deleteMessage'), () =>
          profileDeleteMutation.mutate(id),
        );
      },
      isHidden: (id) =>
        isSuperAdmin(profiles.content.find((profile) => profile.id === id)),
    },
  ];

  return (
    <>
      <GenericFilter
        onFilter={onFilter}
        initialFilterValues={currentFilter}
        emptyFilterValues={{ label: undefined, roles: undefined }}
        filterFields={PROFILES_FILTER_FIELDS(mapRolesToOptions(roles))}
        disableSubmit={isProfilesLoading}
        customField={
          <ApiSwitcher
            catalogueRoles={[USER_ROLES.SUPER_ADMIN_CATALOGUE]}
            pricingRoles={[USER_ROLES.SUPER_ADMIN_PRICING]}
            factRoles={[USER_ROLES.SUPER_ADMIN_FACTS]}
          />
        }
      />

      <div className="flex flex-col px-5">
        <div className="flex items-end mb-4 mr-2 justify-between">
          <p className="mx-2">
            {profiles?.totalElements > 0 &&
              profiles?.totalElements + ' ' + t('search.numberProfilesFound')}
          </p>
          <Button
            type="primary"
            className="bg-orange-500 text-white flex items-center"
            onClick={() => {
              setProfile(null);
              setOpen(true);
            }}
          >
            <PlusOutlined />
            {t('crud.add') + ' ' + t('subnav.profile')}
          </Button>
        </div>
        {isProfilesError ? (
          <EmptyResult
            mainMessage={resolveErrorMessage(profilesError?.response?.status)}
          />
        ) : (
          <div>
            <CustomTable
              columns={columns(t)}
              data={mapProfilesToData(profiles?.content)}
              actions={actions}
              pagination={{
                current: currentPage,
                pageSize: ELEMENTS_PER_PAGE,
                total: profiles?.totalElements,
                onChange: (page) => setCurrentPage(page),
              }}
              isLoading={isProfilesLoading}
            />
          </div>
        )}
      </div>

      <Modal title={t('crud.add') + ' ' + t('subnav.profile')}>
        <ProfileForm
          roles={roles}
          close={() => setOpen(false)}
          profile={profile}
          sourceApi={currentApi}
        />
      </Modal>
    </>
  );
}

export { ProfilesPage };
