import { FormComponentType } from 'src/pages/formSchemas/types';
import React, { useMemo } from 'react';
import { FormFieldV2 } from 'src/types';
import { getFormComponent } from 'src/pages/formSchemas/utils/form';
import { Tabs } from 'antd';
import { Tab } from 'rc-tabs/lib/interface';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;

  const items: Tab[] | undefined = useMemo(
    () =>
      field?.config?.fields?.map((val: FormFieldV2, i: number) => {
        const component = getFormComponent({ field: val, namePrefix: name });
        const key = val.name || val.label;
        return {
          label: val.label,
          key,
          children: <React.Fragment key={key ?? i}>{component}</React.Fragment>,
        };
      }),
    [field?.config?.fields, name],
  );

  return <Tabs items={items} />;
};
