import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { Modal } from 'antd';

export const ModalContainer = () => {
  const { isOpen, body, closeModal, options } = useModalContext();

  return (
    <Modal
      open={isOpen}
      destroyOnClose
      {...options}
      onCancel={closeModal}
      footer={null}
    >
      {body}
    </Modal>
  );
};
