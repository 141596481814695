import HttpService from 'src/services/HttpService';
import { PARAMETER_SCHEMA_PATH } from 'src/api/apiPaths';

const instance = HttpService.getAxiosClient('catalogue');

const getParameterSchemaById = async (id) => {
  const res = await instance.get(`${PARAMETER_SCHEMA_PATH}/${id}`);
  return res.data;
};

const getParameterSchemas = async (filter) => {
  const res = await instance.get(`${PARAMETER_SCHEMA_PATH}`, {
    params: {
      filter,
    },
  });
  return res.data;
};

const createParameterSchema = async (data) => {
  const res = await instance.post(`${PARAMETER_SCHEMA_PATH}`, data);
  return res.data;
};

const updateParameterSchema = async (id, data) => {
  const res = await instance.put(`${PARAMETER_SCHEMA_PATH}/${id}`, data);
  return res.data;
};

export {
  getParameterSchemaById,
  getParameterSchemas,
  createParameterSchema,
  updateParameterSchema,
};
