export const extractConstraintsInfo = (inputObject) => {
  const processListValueType = (conditionValue) => {
    return `${conditionValue.join('-')}`;
  };
  const processRangeValueType = (conditionValue) => {
    const { min, max } = conditionValue;
    return min !== undefined || max !== undefined
      ? `[${min !== undefined ? min : ' '}, ${max !== undefined ? max : ' '}]`
      : '';
  };
  const processRangesValueType = (conditionValue) => {
    if (Array.isArray(conditionValue)) {
      const values = conditionValue
        .filter(({ min, max }) => min !== undefined && max !== undefined)
        .map(({ min, max }) => `[${min}, ${max}]`);
      return values.join(', ');
    } else {
      return '';
    }
  };
  const processValueType = (type, conditionValue) => {
    switch (type) {
      case 'LIST':
      case 'NOT_IN_LIST':
        return processListValueType(conditionValue);
      case 'RANGE':
        return processRangeValueType(conditionValue);
      case 'RANGES':
        return processRangesValueType(conditionValue);
      case 'STRING':
        return conditionValue;
      default:
        return conditionValue;
    }
  };
  const processConstraint = (constraint, acc = []) => {
    if (constraint.type.includes('composite')) {
      const leftConstraints = processConstraint(constraint.left, acc);
      const rightConstraints = processConstraint(constraint.right, acc);
      return [...leftConstraints, ...rightConstraints];
    }

    return (constraint.factNames || []).reduce(
      (innerAcc, { name, type, conditionValue }) => {
        const values = processValueType(type, conditionValue);

        const item = {
          key: name,
          name,
          values,
          description: constraint.description,
        };

        if (values) {
          innerAcc.push(item);
        }

        return innerAcc;
      },
      acc,
    );
  };
  return inputObject
    .flatMap((constraints) =>
      constraints.flatMap((constraint) => processConstraint(constraint)),
    )
    .reduce((acc, current) => {
      if (current.values) {
        acc.push(current);
      }
      return acc;
    }, []);
};
export const extractPricingConstraintsInfo = (inputObject) => {
  return (inputObject || []).map((commission) => ({
    commission: Object.keys(commission)[0],
    key: Object.keys(commission)[0],
    pricings: (commission[Object.keys(commission)[0]] || []).map(
      (item, index) => ({
        key: index,
        pricingType: item.type,
        factNames: extractConstraintsInfo(item.constraintFactsDtosList || []),
      }),
    ),
  }));
};

export const parseSimulationPricing = (commissionData) => {
  if (commissionData)
    commissionData.commissionList = Object.keys(commissionData).reduce(
      (result, key) => {
        if (Array.isArray(commissionData[key]) && key !== 'commissionList') {
          result = result.concat(commissionData[key]);
        }
        return result;
      },
      commissionData.commissionList,
    );
  return commissionData;
};
