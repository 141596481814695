import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { isAuthorized } from 'src/utils/authUtils';
import React from 'react';
import PropTypes from 'prop-types';

function AuthFormDisabler({ componentRender, api, roles }) {
  const { userContext, currentApi } = useUserContext();
  const isAuth = isAuthorized(userContext[api ?? currentApi], roles);
  return <>{componentRender({ isAuth })}</>;
}

AuthFormDisabler.propTypes = {
  componentRender: PropTypes.func,
  roles: PropTypes.array,
  api: PropTypes.string,
};
export { AuthFormDisabler };
