import {
  FileTextOutlined,
  QrcodeOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ImageUpload from 'src/components/generic/inputs/ImageUpload';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import Formula from '../Formula';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';

const ThemeForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <div>
      <h1>{t('theme.info')}</h1>

      <Row gutter={[16, 12]}>
        <Col span={12}>
          <ImageUpload
            name={`${namePrefix}.thumbnail`}
            label={t('theme.thumbnail')}
          />
        </Col>
        <Col span={12}>
          <ImageUpload
            name={`${namePrefix}.largeImage`}
            label={t('theme.largeImage')}
          />
        </Col>
        <Col span={12}>
          <TextInput
            label={t('theme.functionalId')}
            name={`${namePrefix}.functionalId`}
            startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
        <Col span={12}>
          <InternationalizationInput
            name={`${namePrefix}.label`}
            label={t('theme.label')}
            startAdornment={<TagOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
        <Col span={12}>
          <InternationalizationInput
            name={`${namePrefix}.descriptions`}
            label={t('theme.descriptions')}
            startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
        <Col span={12}>
          <InternationalizationInput
            name={`${namePrefix}.details`}
            label={t('theme.details')}
            startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
        <Col span={12}>
          <SwitchInput
            name={`${namePrefix}.virtual`}
            label={t('theme.virtual')}
          />
        </Col>
        <Col span={24}>
          <Formula namePrefix={namePrefix} />
        </Col>
      </Row>
    </div>
  );
};

ThemeForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default ThemeForm;
