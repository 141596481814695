import TestCasesDisplayer from '../TestCasesDisplayer';
import TestCasesProductAndActSelect from '../TestCasesProductAndActSelect';

const TestCasesPage = () => {
  return (
    <div>
      <TestCasesProductAndActSelect />
      <TestCasesDisplayer />
    </div>
  );
};
export default TestCasesPage;
