import { Card } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loading from 'src/components/generic/Loading';
import { useFormSchemasStore } from 'src/stores/formSchemas';
import { FormSchemaAdminDto } from 'src/types';

const FormSchemaParserContainer = React.lazy(
  () => import('src/pages/formSchemas/containers/FormSchemaParserContainer'),
);

export default function FormSchemaPreview() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { getById } = useFormSchemasStore();
  const schema = getById(id ?? '');

  if (schema.isLoading) {
    return <Loading />;
  }

  return (
    <React.Suspense fallback={null}>
      <Card title={t('form_schema.preview_card_title')}>
        <FormSchemaParserContainer
          schema={schema.data as FormSchemaAdminDto}
          withForm
        />
      </Card>
    </React.Suspense>
  );
}
