import { getAllPackagedProducts } from 'src/api';
import ProductSelect from 'src/components/generic/inputs/ProductMultiSelect/ProductSelect';
import { getOptions } from '../../../../../utils/mappers/targetOptionMapper/targetOptionMapper';
import { useTranslation } from 'react-i18next';
import InternationalizationArrayInput from '../../../../generic/inputs/InternationalizationArrayInput';
import InternationalizationInput from '../../../../generic/inputs/InternationalizationInput';

const ProductOptionPairForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <div className="mb-3">
      <ProductSelect
        getProducts={getAllPackagedProducts}
        namePrefix={namePrefix}
        translationPrefix="productOption"
        getOptions={getOptions}
      />
      <InternationalizationInput
        name={`${namePrefix}.label`}
        label={t('productOption.label')}
      />
      <InternationalizationArrayInput
        name={`${namePrefix}.details`}
        label={t('productOption.details')}
        translationPrefix="details"
      />
    </div>
  );
};

export default ProductOptionPairForm;
