import { FormComponentType } from 'src/pages/formSchemas/types';
import CustomDatePicker from 'src/components/generic/inputs/DatePicker';
import React from 'react';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;

  return (
    <CustomDatePicker
      label={field.label as string}
      name={name}
      placeholder={field.config?.placeholder}
      showTime
    />
  );
};
