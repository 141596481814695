import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from '../../mapperHelpers';

export const mapOfferToData = (offer) => {
  return {
    formulaList: offer.formulaList.map((formula) =>
      mapOfferFormulasToData(formula),
    ),
  };
};

const mapOfferFormulasToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    productOfferList: elem.productOfferList.map((productOffer) =>
      mapProductOfferToData(productOffer),
    ),
  };
};

const mapProductOfferToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    optional: elem.optional,
    details: elem.details.map((elem) => mapInternationalisationToData(elem)),
    productOption: elem.productOption.map((prodOpt) =>
      mapProductOptionPairToData(prodOpt),
    ),
  };
};
const mapProductOptionPairToData = (elem) => {
  return {
    product: elem.product,
    options: elem.options,
    details: elem.details.map((elem) => mapInternationalisationToData(elem)),
    label: mapInternationalisationToData(elem.label),
  };
};

export const mapDataToOffer = (data) => {
  const formulaList = data.formulaList.map((elem) =>
    mapDataToOfferFormulas(elem),
  );
  return {
    formulaList,
    type: 'offer',
  };
};

const mapDataToOfferFormulas = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapDataToInternationalisation(elem.label),
    productOfferList: elem.productOfferList.map((prodOffList) =>
      mapDataToProductOffer(prodOffList),
    ),
  };
};

const mapDataToProductOffer = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapDataToInternationalisation(elem.label),
    optional: elem.optional,
    details: elem.details.map((elem) => mapDataToInternationalisation(elem)),
    productOption: elem.productOption?.map((prodOpt) =>
      mapDataToProductOptionPair(prodOpt),
    ),
  };
};
const mapDataToProductOptionPair = (data) => {
  return {
    product: data.product,
    options: data.options,
    details: data.details.map((elem) => mapDataToInternationalisation(elem)),
    label: mapDataToInternationalisation(data.label),
  };
};
