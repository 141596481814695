import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from '../mapperHelpers';

export const mapPrepaidPeriodListToData = (prepaidPeriodsList = []) =>
  prepaidPeriodsList.map(
    ({ description, constraints, periodsToSkipFormula }) => ({
      description: mapInternationalisationToData(description),
      constraints: constraints.map((constraint) => ({ content: constraint })),
      periodsToSkipFormula,
    }),
  );

export const mapDataToPrepaidPeriod = (prepaidPeriodsList = []) =>
  prepaidPeriodsList.map(
    ({ periodsToSkipFormula, description, constraints }) => ({
      periodsToSkipFormula,
      description: mapDataToInternationalisation(description),
      constraints: constraints.map(({ content }) => content),
    }),
  );
