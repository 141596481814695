import { useQuery } from 'react-query';

export default function useVersions(id, getVersions) {
  const checkId = () => {
    if (!id) return Promise.resolve([]);
    return getVersions(id);
  };

  const {
    data: versions,
    isLoading,
    isError,
  } = useQuery([getVersions.name, id], checkId);

  return {
    versions,
    isLoading,
    isError,
  };
}
