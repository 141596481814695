import { useQuery } from 'react-query';
import { getParametersByDomain } from 'src/api/parameter';
import {
  sourceOffline,
  sourceParameters,
} from 'src/containers/DynamicFormContainer/constants';
import { IMap } from 'src/types';

function useAPI(resource: string, metadata?: IMap<string, any>) {
  return useQuery({
    queryKey: ['dynamic-forms', resource, metadata],
    queryFn: () => {
      if (resource === sourceParameters) {
        return getParametersByDomain(metadata?.domain);
      }

      if (resource === sourceOffline) {
        return Promise.resolve(metadata?.data ?? []);
      }
      return Promise.resolve([]);
    },
    staleTime: 10_000,
    refetchOnWindowFocus: false,
  });
}

export { useAPI };
