import { Collapse } from 'antd';
import { useMemo } from 'react';
import SimulationAlternativesDisplay from '../SimulationAlternativesDisplay';

export default function EligibleActFactsDisplayer({ eligibleActs }) {
  const items = useMemo(
    () =>
      eligibleActs.map((eligibleAct, i) => {
        return (
          <Collapse.Panel
            header={`${eligibleAct.targetType}: ${eligibleAct.targets.join(
              ', ',
            )}`}
            key={`${i + 1}`}
          >
            <SimulationAlternativesDisplay
              alternatives={eligibleAct.constraintFactList}
            />
          </Collapse.Panel>
        );
      }),
    [eligibleActs],
  );

  return (
    <Collapse accordion={true} className="mb-5">
      {items}
    </Collapse>
  );
}
