import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { familyMappers } from 'src/utils/familyMappers';
import InfoDisplayer from '../../../../generic/InfoDisplayer';
import { useFormSchemasStore } from 'src/stores/formSchemas';
import FormSchemaParserContainer from 'src/pages/formSchemas/containers/FormSchemaParserContainer';
import { Badge } from 'src/components/generic/Badge';
import { Fragment } from 'react/jsx-runtime';
import { Card } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

export default function SpecificInfo() {
  const { control, setValue } = useFormContext();
  const family = useWatch({
    control,
    name: 'family',
  });
  const { t } = useTranslation();

  const formSchemasStore = useFormSchemasStore();
  const { data: familySchema } = formSchemasStore.getByProductFamily(family);

  if (!family) {
    return <InfoDisplayer message={t('family.validFamily')} />;
  }

  if (familySchema?.id) {
    setValue('formSchema', {
      id: familySchema.id,
      version: familySchema.version,
      __schema: familySchema,
    });

    return (
      <Card
        title={familySchema.label}
        extra={
          <Badge color="secondary" className="flex items-center space-x-1">
            <span>{t('product.schema_loaded')}</span> <CheckOutlined />
          </Badge>
        }
      >
        <FormSchemaParserContainer
          Wrapper={Fragment}
          schema={familySchema}
          withForm={false}
          namePrefix="formSchemaValues."
        />
      </Card>
    );
  } else {
    setValue('formSchema', null);
  }

  return (
    familyMappers[family]?.component ||
    familyMappers['GENERIC_PRODUCT']?.component
  );
}
