import { Col, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { addProfile, updateProfile } from 'src/api/profile';
import Form from 'src/components/generic/Form/Form';
import DataMultiSelect from 'src/components/generic/inputs/DataMultiSelect/DataMultiSelect';
import TextInput from 'src/components/generic/inputs/TextInput/TextInput';
import { useAddMutation } from 'src/hooks/normalHooks/mutation/useAddMutation';
import { useUpdateMutation } from 'src/hooks/normalHooks/mutation/useUpdateMutation';
import { useShowMessage } from 'src/hooks/normalHooks/useShowMessage';
import { PROFILES_BASE_PATH } from 'src/routes/paths';
import { PROFILE_DEFAULT } from 'src/utils/defaultsSupplier';
import { mapDataToProfile, mapProfileToData } from 'src/utils';
import { PROFILE_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/profileSchema';

export default function ProfileForm(props) {
  const { t } = useTranslation();
  const { roles, close, profile, sourceApi } = props;
  const mappedRoles = roles.map((role) => ({ functionalId: role, name: role }));
  const { showSuccess } = useShowMessage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const profileAddMutation = useAddMutation({
    add: (profile) => addProfile(sourceApi, profile),
    onSuccess: () => {
      showSuccess(t('profile.added'));
      navigate(PROFILES_BASE_PATH);
      queryClient.invalidateQueries(
        { queryKey: ['get-profiles'] },
        { queryKey: ['get-roles'] },
      );
      close();
    },
    onSettled: () => setSubmitDisabled(false),
  });

  const profileUpdateMutation = useUpdateMutation({
    update: (profile) => updateProfile(sourceApi, profile, profile.id),
    onSuccess: () => {
      showSuccess(t('profile.updated'));
      navigate(PROFILES_BASE_PATH);
      queryClient.invalidateQueries(
        { queryKey: ['get-profiles'] },
        { queryKey: ['get-roles'] },
      );
      close();
    },
    onSettled: () => setSubmitDisabled(false),
  });

  const onSubmit = (data) => {
    if (profile) {
      setSubmitDisabled(true);
      const mappedProfile = mapDataToProfile(data);
      profileUpdateMutation.mutate(mappedProfile);
      close();
    } else {
      setSubmitDisabled(true);
      const mappedProfile = mapDataToProfile(data);
      profileAddMutation.mutate(mappedProfile);
      close();
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      onCancel={close}
      validationSchema={PROFILE_VALIDATION_SCHEMA}
      defaultValues={profile ? mapProfileToData(profile) : PROFILE_DEFAULT}
      submitDisabled={submitDisabled}
    >
      <Row gutter={[16, 16]} className="mb-6">
        <Col span={24}>
          <TextInput label={t('profile.name')} name="name" />
        </Col>
        <Col span={24}>
          <TextInput label={t('profile.description')} name="description" />
        </Col>
        <Col span={24}>
          <DataMultiSelect
            name="roles"
            label={t('profile.roles')}
            data={mappedRoles}
          />
        </Col>
      </Row>
    </Form>
  );
}
