import { RECOMMENDATION_PATH } from 'src/api/apiPaths';
import HttpService from '../../services/HttpService';
import { mapFilterToParams } from 'src/utils/mappers/filterMapper';
import {
  add,
  cancelPublication,
  createDraft,
  getVersions,
  partiallyPublishDraft,
  publishDraft,
  schedulePublicationDraft,
  unPublishDraft,
  updatePublicationConstraints,
} from 'src/api/versioning/versioningApiUtil';

const instance = HttpService.getAxiosClient('catalogue');

export const addRecommendation = (recommendation) =>
  add(instance, RECOMMENDATION_PATH, recommendation);

export const getAllRecommendations = async (page, size) => {
  const res = await instance.get(
    `${RECOMMENDATION_PATH}?page=${page}&size=${size}`,
  );
  return res.data;
};

export const getRecommendationById = async (recommendationId) => {
  const res = await instance.get(`${RECOMMENDATION_PATH}/${recommendationId}`);
  return res.data;
};

export const updateRecommendation = async (recommendation, id) => {
  const res = await instance.put(
    `${RECOMMENDATION_PATH}/${id}`,
    recommendation,
  );
  return res.data;
};

export const getRecommendationVersions = (functionalId) =>
  getVersions(instance, RECOMMENDATION_PATH, functionalId);

export const publishRecommendationDraft = (functionalId) =>
  publishDraft(instance, RECOMMENDATION_PATH, functionalId);

export const partiallyPublishRecommendationDraft = (
  functionalId,
  publicationConstraints,
) =>
  partiallyPublishDraft(
    instance,
    RECOMMENDATION_PATH,
    functionalId,
    publicationConstraints,
  );

export const unpublishRecommendationDraft = (functionalId) =>
  unPublishDraft(instance, RECOMMENDATION_PATH, functionalId);

export const createRecommendationDraft = (functionalId) =>
  createDraft(instance, RECOMMENDATION_PATH, functionalId);

export const getAllRecommendationsByCriteria = async (page, size, filter) => {
  const params = mapFilterToParams(page, size, filter);

  const res = await instance.get(`${RECOMMENDATION_PATH}`, {
    params,
  });
  return res.data;
};

export const updateRecommendationPublicationConstraints = (
  functionalId,
  publicationConstraints,
) =>
  updatePublicationConstraints(
    instance,
    RECOMMENDATION_PATH,
    functionalId,
    publicationConstraints,
  );

export const scheduleRecommendationPublication = async (
  functionalId,
  publicationDate,
) =>
  await schedulePublicationDraft(
    instance,
    RECOMMENDATION_PATH,
    functionalId,
    publicationDate,
  );

export const cancelRecommendationPublication = async (functionalId) =>
  await cancelPublication(instance, RECOMMENDATION_PATH, functionalId);
