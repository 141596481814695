import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FACT_BASE_PATH } from '../../../routes/paths';
import CardDisplay from '../../../components/generic/CardDisplay';
import { mapDataToFactCollection } from '../../../utils/mappers/factMapper';
import { addFactCollection } from '../../../api/facts';
import FactCollectionForm from '../../../components/fact/FactCollectionForm';
import { useAddMutation } from 'src/hooks/normalHooks/mutation/useAddMutation';

const FactAddPage = () => {
  const { t } = useTranslation();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const FactCollectionAddMutation = useAddMutation({
    add: addFactCollection,
    basePath: FACT_BASE_PATH,
    translationPrefix: 'FactCollection',
    onSettled: () => setSubmitDisabled(false),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    const mappedFactCollection = mapDataToFactCollection(data);
    FactCollectionAddMutation.mutate(mappedFactCollection);
  };

  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t('FactCollection.add')}>
        <FactCollectionForm
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
        />
      </CardDisplay>
    </div>
  );
};

export default FactAddPage;
