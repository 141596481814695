import modalConfirm from 'src/utils/modalConfirm';
import { useTranslation } from 'react-i18next';
import { mapDataToPublicationConstraints } from 'src/utils/mappers/publicationConstraintsMapper';
import { PUBLICATION_CONSTRAINTS_DEFAULT } from 'src/utils/defaultsSupplier';
import { PublicationConstraintsForm } from 'src/components/generic/versioning/PublicationConstraintsForm';

function PublicationConstraintsAddModal({ element, partiallyPublishQuery }) {
  const { t } = useTranslation();
  const onSubmit = (data) => {
    modalConfirm(t('versioning.partiallyPublishMessage'), () =>
      partiallyPublishQuery({
        functionalId: element.id,
        publicationConstraints: mapDataToPublicationConstraints(data),
      }),
    );
  };

  return (
    <PublicationConstraintsForm
      onSubmit={onSubmit}
      defaultValues={PUBLICATION_CONSTRAINTS_DEFAULT}
    />
  );
}

export { PublicationConstraintsAddModal };
