import { DesignerComponentType } from 'src/pages/formSchemas/types';
import { DatePicker, Form } from 'antd';
import { FieldCardLabel } from 'src/pages/formSchemas/components/FieldCardLabel';
import { mapDateToData } from 'src/utils/mappers/dateMapper';
import React from 'react';

export const DesignerComponent: DesignerComponentType = ({ fieldValue }) => {
  return (
    <Form.Item className="w-full" help={fieldValue.description}>
      <FieldCardLabel fieldValue={fieldValue} />
      <DatePicker
        className="w-full"
        showTime
        value={mapDateToData(fieldValue?.config?.defaultValue)}
        placeholder={fieldValue?.config?.placeholder}
      />
    </Form.Item>
  );
};
