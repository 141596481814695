import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { StatusBadge } from 'src/components/generic/Badge';
import Separator from 'src/components/generic/Separator';

const EventCard = ({ event }) => {
  const { t } = useTranslation();

  return (
    <Card className="h-full shadow-none" bodyStyle={{ height: '100%' }}>
      <div className="flex flex-col justify-between h-full space-y-4">
        <div>
          <h2 className="font-medium text-base mb-4 whitespace-nowrap text-ellipsis overflow-hidden">
            {event.functionalId}
          </h2>
          <ul className="space-y-1">
            <li>
              <span className="text-gray-500 font-light">
                {t('eventCard.act')} :
              </span>
              {event.act}
            </li>
            <li>
              <span className="text-gray-500 font-light">
                {t('eventCard.targetType')} :
              </span>
              {event.targetType}
            </li>
            <li>
              <span className="text-gray-500 font-light">
                {t('eventCard.targets')} :
              </span>
              {event?.targetType !== 'ALL'
                ? event?.targets?.join(', ')
                : t('targets.all')}
            </li>
          </ul>
        </div>
        {event.status && (
          <div>
            <Separator />
            <StatusBadge
              status={event.status}
              className="w-full justify-center"
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default EventCard;
