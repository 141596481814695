import Form from 'src/components/generic/Form';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from 'src/components/generic/inputs/DatePicker';
import { DATE_SCHEDULING_SCHEMA } from 'src/utils/validationSchemas/dateSchedulingSchema';
import PropTypes from 'prop-types';

function PublicationSchedulingForm({ onSubmit, closeModal }) {
  const { t } = useTranslation();
  const defaultDate = new Date();
  defaultDate.setMinutes(defaultDate.getMinutes() + 1);
  defaultDate.setSeconds(0);
  return (
    <Form
      onSubmit={onSubmit}
      onCancel={closeModal}
      buttonsOnTop={false}
      validationSchema={DATE_SCHEDULING_SCHEMA}
      defaultValues={{ publicationDate: defaultDate }}
    >
      <div className={'mb-4'}>
        <CustomDatePicker
          label={t('versioning.publicationDate')}
          name="publicationDate"
          required
          disabledDate={(current) => {
            return current && current.valueOf() < Date.now();
          }}
          showTime={true}
          showSecond={false}
          showNow={false}
          showInlineErrors={true}
        />
      </div>
    </Form>
  );
}

PublicationSchedulingForm.propTypes = {
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func,
};
export { PublicationSchedulingForm };
