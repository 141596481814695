import HttpService from '../../services/HttpService';
import { PRODUCT_PATH } from '../apiPaths';
import { mapFilterToParams } from 'src/utils/mappers/filterMapper';
import {
  add,
  cancelPublication,
  createDraft,
  getVersions,
  partiallyPublishDraft,
  publishDraft,
  schedulePublicationDraft,
  unPublishDraft,
  updatePublicationConstraints,
} from 'src/api/versioning/versioningApiUtil';

const instance = HttpService.getAxiosClient('catalogue');

export const addProduct = (product) => add(instance, PRODUCT_PATH, product);

export const updateProduct = async (product, id) => {
  const res = await instance.put(`${PRODUCT_PATH}/${id}`, product);
  return res.data;
};

export const getAllProducts = async (page, size) => {
  const res = await instance.get(`${PRODUCT_PATH}?page=${page}&size=${size}`);
  return res.data;
};

export const getAllProductsWithoutPagination = async () => {
  const res = await instance.get(PRODUCT_PATH + '/all');
  return res.data;
};

export const getPublishedProductsWithoutPagination = async () => {
  const res = await instance.get(PRODUCT_PATH + '/all-published');
  return res.data;
};

export const getAllProductsByCriteria = async (page, size, filter) => {
  const params = mapFilterToParams(page, size, filter);

  const res = await instance.get(`${PRODUCT_PATH}`, {
    params,
  });

  return res.data;
};

export const getProductById = async (functionalId) => {
  const res = await instance.get(`${PRODUCT_PATH}/${functionalId}`);
  return res.data;
};

export const getProductVersions = (functionalId) =>
  getVersions(instance, PRODUCT_PATH, functionalId);

export const publishProductDraft = (functionalId) =>
  publishDraft(instance, PRODUCT_PATH, functionalId);

export const unpublishProductDraft = (functionalId) =>
  unPublishDraft(instance, PRODUCT_PATH, functionalId);

export const createProductDraft = (functionalId) =>
  createDraft(instance, PRODUCT_PATH, functionalId);

export const getAllModules = async () => {
  const res = await instance.get(`${PRODUCT_PATH}/modules`);
  return res.data;
};

export const getAllProductsCodes = async (family = '') => {
  const res = await instance.get(
    `${PRODUCT_PATH}/product-codes?family=${family}`,
  );
  return res.data;
};
export const getAllPackagedProducts = async () => {
  const res = await instance.get(`${PRODUCT_PATH}/packaged?sellingMode=PCK`);
  return res.data;
};

export const partiallyPublishProductDraft = (
  functionalId,
  publicationConstraints,
) =>
  partiallyPublishDraft(
    instance,
    PRODUCT_PATH,
    functionalId,
    publicationConstraints,
  );

export const updateProductPublicationConstraints = (
  functionalId,
  publicationConstraints,
) =>
  updatePublicationConstraints(
    instance,
    PRODUCT_PATH,
    functionalId,
    publicationConstraints,
  );
export const getAllProductsByFamily = async (family) => {
  const res = await instance.get(
    `${PRODUCT_PATH}/allByFamily?family=${family}`,
  );
  return res.data;
};

export const scheduleProductPublication = async (
  functionalId,
  publicationDate,
) =>
  schedulePublicationDraft(
    instance,
    PRODUCT_PATH,
    functionalId,
    publicationDate,
  );

export const cancelProductPublication = async (functionalId) =>
  await cancelPublication(instance, PRODUCT_PATH, functionalId);
