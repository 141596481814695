import { FileOutlined, QrcodeOutlined, TagOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';
import ParameterMultiSelect from 'src/components/generic/inputs/ParameterMultiSelect';

const SupportingDocumentForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 8]} className="mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('supportingDocuments.functionalId')}
          startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.label`}
          label={t('supportingDocuments.label')}
          startAdornment={<TagOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>

      <Col span={12}>
        <TextInput
          name={`${namePrefix}.sides`}
          label={t('supportingDocuments.sides')}
          type="number"
          startAdornment={<FileOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <SwitchInput
          name={`${namePrefix}.mandatory`}
          label={t('supportingDocuments.mandatory')}
        />
      </Col>
      <Col span={12}>
        <ParameterMultiSelect
          name={`${namePrefix}.documentList`}
          label={t('supportingDocuments.documentList')}
          domain="SUPPORTING_DOCUMENT"
        />
      </Col>
      <Col span={24}>
        <h2>{t('eligibleAct.constraints')}</h2>
        <ConstraintWrapper namePrefix={namePrefix} />
      </Col>
    </Row>
  );
};

export default SupportingDocumentForm;
