import React from 'react';
import { ScriptInput } from 'src/components/generic/inputs/ScriptInput';
import { useTranslation } from 'react-i18next';
import {
  DryRunResult,
  DryRunSection,
} from 'src/components/constraint/form/ScriptConstraint/Dryrun';
import { useFormContext } from 'react-hook-form';
import { useEvaluateScript } from 'src/containers/DynamicFormContainer/hooks/useEvaluateScript';
import { ScrollArea } from 'src/components/generic/ScrollArea';

function FormScriptField({ schema, ...props }) {
  const { t } = useTranslation();

  const {
    mutate: evaluate,
    isError,
    error,
    isLoading,
    isSuccess,
    data: result,
  } = useEvaluateScript();

  const { getValues } = useFormContext();

  const language = schema?.ui?.metadata?.language;

  const handleEvaluate = async (data) => {
    evaluate({
      language,
      script: getValues(schema.id),
      variables: data,
      functionName: schema?.ui?.metadata?.functionName,
    });
  };

  return (
    <div className="space-y-2">
      <ScriptInput
        name={schema.id}
        label={schema.title}
        disabled={Boolean(schema?.ui?.disabled ?? false)}
        language={language}
        {...props}
      />

      {isError && (
        <DryRunResult
          variant="error"
          result={error?.response?.data?.message ?? t('an_error_occurred')}
        />
      )}

      {!isLoading && isSuccess && result && (
        <DryRunResult
          variant="success"
          result={
            <ScrollArea className="flex flex-col p-4 bg-gray-100 border rounded">
              <>
                <span className="block w-full">
                  {t('scriptConstraint.dryRunResult', { result: '' })}
                </span>
                <pre>
                  <code>{JSON.stringify(result.result, null, '\t')}</code>
                </pre>
              </>
            </ScrollArea>
          }
        />
      )}

      <div>
        <DryRunSection onExecute={handleEvaluate} />
      </div>
    </div>
  );
}

export { FormScriptField };
