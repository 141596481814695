import ArrayAccordion from '../../../generic/ArrayAccordion';
import { PrepaidPeriodForm } from 'src/components/eventPricing/billing/prepaidPeriodForm';

export function PrepaidPeriod(props) {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={`${namePrefix}`}
      translationPrefix="prepaidPeriod"
      Form={PrepaidPeriodForm}
      fieldsNames={['description']}
    />
  );
}
