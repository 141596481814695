import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useShowMessage } from '../../useShowMessage';
import { useTranslation } from 'react-i18next';

export const usePublishMutation = ({
  publish,
  basePath,
  queryKeysToInvalidate,
  translationPrefix,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSuccess, showError } = useShowMessage();
  const queryClient = useQueryClient();

  return useMutation(publish, {
    onSuccess: (res) => {
      queryKeysToInvalidate.forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey });
      });
      showSuccess(t(`${translationPrefix}.published`));
      navigate(basePath + '/update/' + res.id);
    },
    onError: (err) => {
      showError(err);
    },
  });
};
