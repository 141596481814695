import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'src/components/generic/Loading';
import ProductForm from 'src/components/product/form/ProductForm';
import { mapDataToProduct } from 'src/utils';
import { PRODUCT_BASE_PATH } from 'src/routes/paths';
import { useQuery } from 'react-query';
import { Error500 } from 'src/pages/error';
import VersioningButtons from 'src/components/generic/versioning/VersioningButtons/VersioningButtons';
import {
  cancelProductPublication,
  createProductDraft,
  getProductById,
  getProductVersions,
  partiallyPublishProductDraft,
  publishProductDraft,
  scheduleProductPublication,
  unpublishProductDraft,
  updateProduct,
  updateProductPublicationConstraints,
} from 'src/api';
import { useUpdateMutation } from '../../../hooks/normalHooks/mutation/useUpdateMutation';
import { usePublishMutation } from 'src/hooks/normalHooks/mutation/usePublishMutation';
import { RESOURCES } from 'src/utils/constants';
import { usePartiallyPublishMutation } from 'src/hooks/normalHooks/mutation/usePartiallyPublishMutation';
import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { useSchedulePublicationMutation } from 'src/hooks/normalHooks/mutation/useSchedulePublicationMutation';
import { useCancelSchedulingMutation } from 'src/hooks/normalHooks/mutation/useCancelSchedulingMutation';

export const ProductUpdatePage = () => {
  const { functionalId: id } = useParams();
  const { closeModal } = useModalContext();
  const queryKey = ['getProductById', id];
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const {
    data: product,
    isLoading,
    isError,
  } = useQuery(queryKey, () => getProductById(id));

  const productPublishMutation = usePublishMutation({
    publish: () => publishProductDraft(id),
    basePath: PRODUCT_BASE_PATH,
    queryKeysToInvalidate: [queryKey, getProductVersions.name],
    translationPrefix: 'product',
  });
  const productPartiallyPublishMutation = usePartiallyPublishMutation({
    partiallyPublish: ({ functionalId, publicationConstraints }) =>
      partiallyPublishProductDraft(functionalId, publicationConstraints),
    basePath: PRODUCT_BASE_PATH,
    queryKeysToInvalidate: [queryKey, getProductVersions.name],
    translationPrefix: 'product',
  });
  const productUpdateMutation = useUpdateMutation({
    update: (product) => updateProduct(product, id),
    basePath: PRODUCT_BASE_PATH,
    queryKey: queryKey,
    translationPrefix: 'product',
    onSettled: () => setSubmitDisabled(false),
  });
  const publicationConstraintsUpdateMutation = useUpdateMutation({
    update: ({ functionalId, publicationConstraints }) =>
      updateProductPublicationConstraints(functionalId, publicationConstraints),
    basePath: PRODUCT_BASE_PATH,
    queryKey: queryKey,
    translationPrefix: 'product.publicationConstraints',
    onSettled: () => closeModal(),
  });
  const productSchedulePublicationMutation = useSchedulePublicationMutation({
    schedulePublication: ({ functionalId, publicationDate }) =>
      scheduleProductPublication(functionalId, publicationDate),
    basePath: PRODUCT_BASE_PATH,
    queryKeysToInvalidate: [queryKey, getProductVersions.name],
    translationPrefix: 'product',
  });

  const productCancelPublicationMutation = useCancelSchedulingMutation({
    cancelScheduling: (functionalId) => cancelProductPublication(functionalId),
    basePath: PRODUCT_BASE_PATH,
    queryKeysToInvalidate: [queryKey, getProductVersions.name],
    translationPrefix: 'product',
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    const mappedProduct = mapDataToProduct(data);
    productUpdateMutation.mutate(mappedProduct);
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;

  return (
    <ProductForm
      key={product?.id}
      product={product}
      {...{
        onSubmit,
        isUpdateForm: true,
        formDisabled: product.version !== 'draft',
        submitDisabled,
        additionalButtons: (
          <VersioningButtons
            element={product}
            createDraft={createProductDraft}
            publishDraft={productPublishMutation.mutate}
            unpublishDraft={unpublishProductDraft}
            partiallyPublishDraft={productPartiallyPublishMutation.mutate}
            updatePublicationConstraints={
              publicationConstraintsUpdateMutation.mutate
            }
            schedulePublication={productSchedulePublicationMutation.mutate}
            updateUrl="/products/update/"
            resource={RESOURCES.PRODUCTS}
            cancelSchedulingQuery={productCancelPublicationMutation.mutate}
          />
        ),
      }}
    />
  );
};
