import dayjs from 'dayjs';
import { format as dateFnsFormat } from 'date-fns';

export const mapDateToData = (date) => date && dayjs(date);

export const formatDate = (date) => {
  if (typeof date === 'string') return new Date(date).toLocaleDateString();
  return date['$d'].toLocaleDateString();
};

export const formattedDate = (date) => {
  return new Intl.DateTimeFormat('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(new Date(date));
};
export function formatUnixTimestamp(date, format = 'dd/MM/yyyy') {
  return dateFnsFormat(new Date(date * 1000), format);
}

export const formatDateWithPattern = (date, pattern) =>
  date && dayjs(date).format(pattern);

export const formatDateToISO = (date) => {
  return dayjs(date).toISOString();
};
