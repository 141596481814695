import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import Separator from 'src/components/generic/Separator';
import { StatusBadge } from '../../../../components/generic/Badge';
import { useSelectedLanguage } from '../../../../hooks/normalHooks/useSelectedLanguage';

const RecommendationCard = ({ recommendation }) => {
  const { t } = useTranslation();
  const { renderLocalizedContent } = useSelectedLanguage();

  return (
    <Card className="h-full shadow-none" bodyStyle={{ height: '100%' }}>
      <div className="flex flex-col justify-between h-full space-y-4">
        <div>
          <h2 className="font-medium text-base mb-4 whitespace-nowrap text-ellipsis overflow-hidden">
            {recommendation.functionalId}
          </h2>
          <ul className="space-y-1">
            <li>
              <span className="text-gray-500 font-light">
                {t('recommendation.description')} :
              </span>
              {renderLocalizedContent(recommendation.description)}
            </li>
            <li>
              <span className="text-gray-500 font-light">
                {t('recommendation.order')} :
              </span>
              {recommendation.order}
            </li>
          </ul>
        </div>
        {recommendation.status && (
          <div>
            <Separator />
            <StatusBadge
              status={recommendation.status}
              className="w-full justify-center"
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default RecommendationCard;
