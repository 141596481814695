import {
  AimOutlined,
  HighlightOutlined,
  QrcodeOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  getEligibleActById,
  getEligibleActVersions,
} from 'src/api/eligibleAct';
import CardDisplay from 'src/components/generic/CardDisplay';
import Form from 'src/components/generic/Form';
import ParameterMultiSelect from 'src/components/generic/inputs/ParameterMultiSelect';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import Loading from 'src/components/generic/Loading';
import VersionsTimeLine from 'src/components/product/form/generic/VersionsTimeLine';
import useVersions from 'src/hooks/normalHooks/useVersions';
import { Error500 } from 'src/pages/error';
import { ELIGIBLE_ACT_BASE_PATH } from 'src/routes/paths';
import { ELIGIBLE_ACT_DEFAULT } from 'src/utils/defaultsSupplier';
import { getTargetTypes } from 'src/utils/getTargetTypes';
import { mapEligibleActToData } from 'src/utils/mappers/eligibleActMapper';
import { ELIGIBLE_ACT_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/eligibleActSchema';
import Targets from 'src/components/eventPricing/Targets';
import Consent from 'src/components/eligibleAct/form/Consent';
import SupportingDocument from 'src/components/eligibleAct/form/SupportingDocument';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import ExportImport from 'src/components/generic/ExportImport';
import AdditionalAttributesWithConstraints from 'src/components/product/form/generic/AdditionalAttributesWithConstraints';
import MotifsWithConstraints from 'src/components/eligibleAct/form/MotifsWithConstraints';
import { AuthFormDisabler } from 'src/components/generic/AuthFormDisabler';
import { USER_ROLES } from 'src/utils/constants';
import { useCompareVersions } from 'src/hooks/componentHooks/useCompareVersions';
import { MessageContainer } from 'src/components/eligibleAct/form/Message';
import { EligibleActFormSchemaLoader } from 'src/components/eligibleAct/form/FormSchemaLoader';

export default function EligibleActForm({
  onSubmit,
  eligibleAct,
  submitDisabled,
  additionalButtons,
  isUpdateForm,
  formDisabled,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { versions, isLoading, isError } = useVersions(
    eligibleAct?.id,
    getEligibleActVersions,
  );

  const { onCompare } = useCompareVersions({
    versionFetcherQuery: (id) => getEligibleActById(id),
    versions,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error500 />;
  }

  return (
    <div className="grid grid-cols-5 gap-2">
      <CardDisplay
        title={t('versions.versions')}
        titleCentered={true}
        className="h-fit"
      >
        <VersionsTimeLine
          versions={versions}
          path="/eligible-acts/update/"
          onCompare={onCompare}
        />
      </CardDisplay>
      <div className="col-span-4">
        <AuthFormDisabler
          componentRender={({ isAuth }) => (
            <Form
              onSubmit={onSubmit}
              submitDisabled={submitDisabled}
              formDisabled={formDisabled || !isAuth}
              defaultValues={
                eligibleAct
                  ? mapEligibleActToData(eligibleAct)
                  : ELIGIBLE_ACT_DEFAULT
              }
              onCancel={() => navigate(ELIGIBLE_ACT_BASE_PATH)}
              validationSchema={ELIGIBLE_ACT_VALIDATION_SCHEMA}
              additionalButtons={
                <>
                  {additionalButtons}
                  <ExportImport dataName="eligbleAct" />
                </>
              }
            >
              <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                <TextInput
                  name="functionalId"
                  label={t('eligibleAct.functionalId')}
                  startAdornment={
                    <QrcodeOutlined style={{ fontSize: '25px' }} />
                  }
                  disabled={isUpdateForm}
                />
                <InternationalizationInput
                  name="descriptions"
                  label={t('eventPricing.description')}
                  placeholder="Description"
                />

                <SelectInput
                  name="targetType"
                  label={t('eligibleAct.targetType')}
                  data={getTargetTypes(true)}
                  startAdornment={<AimOutlined style={{ fontSize: '25px' }} />}
                />

                <Targets namePrefix="targets" />

                <ParameterSelect
                  name="act"
                  label={t('eligibleAct.act')}
                  domain="ACT"
                  startAdornment={
                    <HighlightOutlined style={{ fontSize: '25px' }} />
                  }
                />

                <ParameterMultiSelect
                  name="tags"
                  label={t('eligibleAct.tags')}
                  domain="TAG"
                  startAdornment={<TagsOutlined style={{ fontSize: '25px' }} />}
                />

                <div className="col-span-2">
                  <Consent />
                </div>

                <div className="col-span-2">
                  <SupportingDocument />
                </div>

                <div className="col-span-2">
                  <MotifsWithConstraints />
                </div>
                <div className="col-span-2">
                  <MessageContainer />
                </div>
                <div className="col-span-2">
                  <AdditionalAttributesWithConstraints />
                </div>

                <div className="col-span-2">
                  <EligibleActFormSchemaLoader />
                </div>

                <div className="col-span-2">
                  <h2>{t('eligibleAct.constraints')}</h2>
                  <ConstraintWrapper hasErrorMessage={true} />
                </div>
              </div>
            </Form>
          )}
          roles={[USER_ROLES.EDIT_EVENTS]}
        />
      </div>
    </div>
  );
}
