import CardDisplay from 'src/components/generic/CardDisplay';
import DamageFormula from '../DamageFormula';
import AdditionalAttribute from '../../generic/AdditionalAttribute';
import { useTranslation } from 'react-i18next';

export default function Damage() {
  const { t } = useTranslation();

  return (
    <CardDisplay title={t('damage.info')}>
      <DamageFormula />
      <AdditionalAttribute />
    </CardDisplay>
  );
}
