import { getCorrectLabelForAdmin } from 'src/utils/internationalisationUtils';

export function getDamageNestedOptions(selectedProduct, selectedLang) {
  const res = [];
  selectedProduct.damageFormulas?.forEach((formula) => {
    res.push({
      functionalId: formula.functionalId,
      name:
        formula.functionalId +
        ' - ' +
        getCorrectLabelForAdmin(formula.name, selectedLang),
    });
  });
  return res;
}
