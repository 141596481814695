import SelectInput from '../SelectInput';

export default function DataMultiSelect(props) {
  const {
    name,
    data,
    label,
    startAdornment,
    placeholder,
    loading,
    onChange,
    maxTagCount,
    disabled,
  } = props;

  return (
    <SelectInput
      name={name}
      label={label}
      mode="multiple"
      data={data}
      startAdornment={startAdornment}
      placeholder={placeholder}
      loading={loading ?? false}
      onChange={onChange}
      maxTagCount={maxTagCount}
      disabled={disabled}
    />
  );
}
