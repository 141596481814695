import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getTestCases } from '../../../api/testCases/testCases';
import ProductAndActSelection from '../ProductAndActSelection';
import { handleFormSubmit } from '../utils/formUtils';

const TestCasesProductAndActSelect = () => {
  const { t } = useTranslation();
  const formMethods = useForm();

  const onSubmit = (data) => {
    handleFormSubmit(data, getTestCases, t);
  };

  return (
    <form
      onSubmit={formMethods.handleSubmit(onSubmit)}
      className="p-5"
      data-testid="test-cases-form"
    >
      <ProductAndActSelection formMethods={formMethods} />
    </form>
  );
};

export default TestCasesProductAndActSelect;
