import { configureStore } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import {
  selectedLanguageReducer,
  supportedLanguagesReducer,
} from '../slices/parameterSlice';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { messageReducer } from 'src/redux/slices/messageSlice';
import { errorReducer } from 'src/redux/slices/errorSlice';
import { alertReducer } from 'src/redux/slices/alertSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, selectedLanguageReducer);
export const store = configureStore({
  reducer: {
    selectedLanguage: persistedReducer,
    supportedLanguages: supportedLanguagesReducer,
    message: messageReducer,
    error: errorReducer,
    alert: alertReducer,
  },
  middleware: [thunk],
});
