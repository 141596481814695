import { Col, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { addUser, updateUser } from 'src/api';
import Form from 'src/components/generic/Form/Form';
import SelectInput from 'src/components/generic/inputs/SelectInput/SelectInput';
import SwitchInput from 'src/components/generic/inputs/SwitchInput/SwitchInput';
import TextInput from 'src/components/generic/inputs/TextInput/TextInput';
import { useAddMutation } from 'src/hooks/normalHooks/mutation/useAddMutation';
import { useUpdateMutation } from 'src/hooks/normalHooks/mutation/useUpdateMutation';
import { useShowMessage } from 'src/hooks/normalHooks/useShowMessage';
import { USERS_BASE_PATH } from 'src/routes/paths';
import { USER_DEFAULT } from 'src/utils/defaultsSupplier';
import { mapDataToUser, mapUserToData } from 'src/utils';
import { USER_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/userSchema';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import PropTypes from 'prop-types';

export default function UserForm(props) {
  const { t } = useTranslation();
  const { close, profiles, user } = props;
  const { showSuccess } = useShowMessage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { userContext, currentApi } = useUserContext();

  const userAddMutation = useAddMutation({
    add: (user) => addUser(currentApi, user),
    onSuccess: () => {
      showSuccess(t('user.added'));
      navigate(USERS_BASE_PATH);
      queryClient.invalidateQueries({ queryKey: ['get-users'] });
      close();
    },
    onSettled: () => setSubmitDisabled(false),
  });
  const invalidateQueriesOnUpdate = (user) => {
    queryClient.invalidateQueries({ queryKey: ['get-users'] });
    const currentUserUsername = userContext[currentApi].username;
    if (currentUserUsername === user.username) {
      queryClient.invalidateQueries(
        { queryKey: ['get-user-context'] },
        currentApi,
      );
    }
  };

  const userUpdateMutation = useUpdateMutation({
    update: (user) => updateUser(currentApi, user, user.id),
    onSuccess: () => {
      showSuccess(t('user.updated'));
      navigate(USERS_BASE_PATH);
      invalidateQueriesOnUpdate(user);
      close();
    },
    onSettled: () => setSubmitDisabled(false),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    let mappedUser = mapDataToUser(data);
    if (user) {
      userUpdateMutation.mutate(mappedUser);
      close();
    } else {
      userAddMutation.mutate(mappedUser);
      close();
    }
  };

  let filteredProfiles = profiles;

  return (
    <Form
      onSubmit={onSubmit}
      onCancel={close}
      validationSchema={USER_VALIDATION_SCHEMA}
      defaultValues={user ? mapUserToData(user) : USER_DEFAULT}
      submitDisabled={submitDisabled}
    >
      <Row gutter={[16, 16]} className="mb-6">
        <Col span={12}>
          <TextInput label={t('user.firstName')} name="firstName" />
        </Col>
        <Col span={12}>
          <TextInput label={t('user.lastName')} name="lastName" />
        </Col>
        <Col span={24}>
          <TextInput label={t('user.username')} name="username" />
        </Col>
        <Col span={24}>
          <SelectInput
            name="profileName"
            label={t('user.profile')}
            data={filteredProfiles}
          />
        </Col>
        <Col span={24}>
          <SwitchInput name="active" label={t('user.active')} />
        </Col>
      </Row>
    </Form>
  );
}

UserForm.propTypes = {
  close: PropTypes.func,
  profiles: PropTypes.array,
  user: PropTypes.object,
};
