import { Empty, Tabs } from 'antd';
import { useMemo } from 'react';
import SimulationConstraintsFactsTable from '../SimulationConstraintsFactsTable';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function SimulationAlternativesDisplay({ alternatives }) {
  const { t } = useTranslation();
  const tabs = useMemo(
    () =>
      alternatives.map((alternative, i) => ({
        key: i + '',
        label: `Alternative ${i + 1}`,
        children: <SimulationConstraintsFactsTable constraints={alternative} />,
      })),
    [alternatives],
  );
  if (isEmpty(alternatives))
    return <Empty description={t('simulation.noConstraintsFound')} />;
  return <Tabs defaultActiveKey="0" items={tabs} />;
}
