import React from 'react';
import AuditTabHandler from '../../../components/generic/AuditTabHandler';

export const AuditFactsPage = () => {
  const tabs = [
    {
      tabName: 'navbar.adminFacts',
      key: 'adminFacts',
    },
    {
      tabName: 'navbar.usageFacts',
      key: 'usageFacts',
    },
  ];

  return <AuditTabHandler tabs={tabs} defaultTab={'adminFacts'} />;
};

export default AuditFactsPage;
