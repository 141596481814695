import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSideBarContext } from './context';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { MenuItemChevron } from './MenuItemChevron';
import { MenuItemChildren } from './MenuItemChildren';
import { Tooltip } from 'antd';

function MenuItem({
  path,
  title,
  icon,
  subItems,
  selected,
  parent = false,
  isVisible = true,
}) {
  const [open, setOpen] = useState(selected);

  const { t } = useTranslation();
  const { collapsed } = useSideBarContext();

  const hasSubItems = subItems?.length > 0;

  useEffect(() => {
    setOpen(selected);
  }, [selected]);

  const handleLinkClick = (e) => {
    if (collapsed || !hasSubItems) {
      return;
    }

    e.preventDefault();
    setOpen(!open);
  };

  const label = t(title);
  if (!isVisible) return null;
  return (
    <li>
      <ItemTooltip title={label}>
        <Link
          to={path}
          onClick={handleLinkClick}
          className={clsx('flex flex-row p-3 hover:text-primary items-center', {
            'border-r-4': parent,
            'border-primary bg-seashell': selected && parent,
            'text-primary font-semibold': selected,
            'border-transparent font-normal': !selected,
            'justify-center': collapsed,
            'pl-4 justify-between': !collapsed,
          })}
        >
          <div className="space-x-4 flex items-center">
            <span className="text-xl">{icon}</span>
            <span className={clsx('capitalize text-sm', { hidden: collapsed })}>
              {label}
            </span>
          </div>
          <MenuItemChevron open={open} visible={hasSubItems && !collapsed} />
        </Link>
      </ItemTooltip>
      <MenuItemChildren items={subItems} open={open} />
    </li>
  );
}

function ItemTooltip({ title, children }) {
  const { collapsed } = useSideBarContext();
  if (collapsed) {
    return (
      <Tooltip placement="right" title={title}>
        {children}
      </Tooltip>
    );
  }

  return children;
}

export { MenuItem };
