import { useQuery } from 'react-query';
import { getDomains } from 'src/api/parameter';
import { getDomainCategories } from 'src/api/domain';

export function useDomains() {
  return useQuery({
    queryKey: ['domains'],
    queryFn: () => getDomains(),
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
}

export function useDomainCategories() {
  return useQuery({
    queryKey: ['domain-categories'],
    queryFn: () => getDomainCategories(),
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
}
