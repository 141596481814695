import { useFormContext, useWatch } from 'react-hook-form';
import { pricingTypes } from 'src/utils/pricingTypes';
import { useTranslation } from 'react-i18next';
import InfoDisplayer from '../../../generic/InfoDisplayer';

export default function SpecificPricingInfo({ namePrefix }) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const type = useWatch({
    control,
    name: `${namePrefix}.type`,
  });

  const Component = pricingTypes[type]?.component;

  if (!Component) return <InfoDisplayer message={t('type.validPricingType')} />;
  return <Component namePrefix={namePrefix} />;
}
