import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSimulation from './hooks/useSimulation';
import useTableColumns from './hooks/useTableColumns';
import SimulationButton from './SimulationButton';
import UploadSection from './UploadSection';
import TestCasesTable from './TestCasesTable';
import { beforeUpload, handleImport } from './handler';

const TestCasesDisplayer = () => {
  const [dataSource, setDataSource] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [loadingImport, setLoadingImport] = useState(false);

  const { t } = useTranslation();
  const { loadingSimulate, simulate, simulatePage } = useSimulation();
  const columns = useTableColumns(
    loadingSimulate,
    simulate,
    setDataSource,
    setExpandedRowKeys,
  );

  return (
    <div className="m-5">
      <div className="flex justify-between m-2">
        <SimulationButton
          simulatePage={() =>
            simulatePage(dataSource, pagination, setDataSource)
          }
          loadingSimulate={loadingSimulate}
        />
        <UploadSection
          beforeUpload={(file) => beforeUpload(file, t)}
          handleImport={({ file }) =>
            handleImport(file, t, setDataSource, setLoadingImport)
          }
          loadingImport={loadingImport}
        />
      </div>
      <TestCasesTable
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        expandedRowKeys={expandedRowKeys}
        setExpandedRowKeys={setExpandedRowKeys}
      />
    </div>
  );
};

export default TestCasesDisplayer;
