import { useQuery } from 'react-query';
import { getCountOfParameters } from 'src/api';
import CardInfo from './../../cardInfo/CardInfo';

export default function ParameterCardInfo(props) {
  const { title } = props;
  const { data, isLoading } = useQuery(['get-count-parameters'], () => {
    return getCountOfParameters();
  });

  return <CardInfo isLoading={isLoading} number={data} title={title} />;
}
