import AdditionalAttributeForm from '../AdditionalAttributeForm';
import ConstraintWrapper from '../../../../constraint/form/ConstraintWrapper/ConstraintWrapper';
import { useTranslation } from 'react-i18next';

const AdditionalAttributesWithConstraintsForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <div>
      <AdditionalAttributeForm namePrefix={namePrefix} />
      <div className="col-span-2">
        <h2>{t('eligibleAct.constraints')}</h2>
        <ConstraintWrapper namePrefix={namePrefix} />
      </div>
    </div>
  );
};

export default AdditionalAttributesWithConstraintsForm;
