import { message } from 'antd';

export const beforeUpload = (file, t) => {
  if (file.type !== 'application/json') {
    message.error(t('Invalid file type. Please upload a JSON file.'), 5);
    return false;
  }
  return true;
};

export const handleImport = (file, t, setDataSource, setLoadingImport) => {
  setLoadingImport(true);
  setDataSource([]);
  const fileReader = new FileReader();
  fileReader.onload = async function () {
    try {
      const content = JSON.parse(fileReader.result);
      setDataSource(
        content.testCaseDtoList.map((elem, i) => ({
          key: i,
          actual: true,
          expected: true,
          conditions: elem,
          productId: content.productId,
          actId: content.actId,
        })),
      );
    } catch (error) {
      message.error(t('Failed to parse JSON file.'), 5);
    } finally {
      setLoadingImport(false);
    }
  };

  fileReader.readAsText(file.originFileObj);
};
