import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { COMMISSION_DEFAULT } from 'src/utils/defaultsSupplier';
import CommissionForm from '../CommissionForm/CommissionForm';

const Commission = () => {
  return (
    <ArrayAccordion
      name="commissions"
      translationPrefix="commission"
      Form={CommissionForm}
      defaultValues={COMMISSION_DEFAULT}
      fieldsNames={['functionalId', 'label']}
    />
  );
};
export default Commission;
