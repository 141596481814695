import { FormResolveDefaultValueType } from 'src/pages/formSchemas/types';
import { Fn, FormFieldV2, IMap, ReusableFormSchemaDto } from 'src/types';

import {
  FORM_BUILDER_UTILITY,
  REUSABLE_FORM_SCHEMA_DRAGGABLE,
} from 'src/pages/formSchemas/utils/constants/form';
import { SCHEMABLE_TYPES } from 'src/pages/formSchemas/utils/constants/schemable';
import { Transform } from '@dnd-kit/utilities';

export const resolveFormDefaultValueForPlainTypes: FormResolveDefaultValueType =
  (acc, { field, namePrefix }) => {
    if (field.name && field.config?.defaultValue) {
      acc[`${namePrefix}${field.name}`] = field.config.defaultValue;
    }
  };

export const resolveFormDefaultValueForContainerFields: FormResolveDefaultValueType =
  (acc, { field, namePrefix }) => {
    const newNamePrefix = `${namePrefix ?? ''}${
      field.name ? field.name + '.' : ''
    }`;
    field.config?.fields?.forEach((subField: FormFieldV2) => {
      const type = FORM_BUILDER_UTILITY[subField.type];
      if (type?.formResolveDefaultValue) {
        type.formResolveDefaultValue(acc, {
          field: subField,
          namePrefix: newNamePrefix,
        });
      }
    });
  };

export const schemableTypeUIOptions = (t: Fn<string, string>) => {
  return Object.values(SCHEMABLE_TYPES).map((type) => ({
    functionalId: type,
    name: t(`form_schema.resources.${type}`),
  }));
};

export const getReusableSchemaDraggableCardId = (
  schema: ReusableFormSchemaDto,
): string => {
  return `${REUSABLE_FORM_SCHEMA_DRAGGABLE}|${schema.id}`;
};

type GetDraggableElementStyleArgs = {
  transform: Transform | null;
};

export const getDraggableElementStyle: Fn<
  GetDraggableElementStyleArgs,
  IMap<string, string>
> = ({ transform }) => {
  if (!transform) {
    return {} as IMap<string, string>;
  }

  return {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    zIndex: '10',
  };
};
