import ArrayAccordion from '../../../../generic/ArrayAccordion';
import DevelopedDetailsForm from '../DevelopedDetailsForm';

const DevelopedDetails = ({ namePrefix }) => {
  const name = namePrefix
    ? `${namePrefix}.developedDetailList`
    : 'developedDetailList';

  return (
    <ArrayAccordion
      name={name}
      translationPrefix="developedDetails"
      Form={DevelopedDetailsForm}
      defaultValues={{}}
      fieldsNames={['functionalId', 'descriptions']}
    />
  );
};

export default DevelopedDetails;
