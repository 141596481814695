import {
  mapDataToDocumentAttachmentList,
  mapDataToInternationalisation,
  mapDataToTargets,
  mapDocumentAttachmentListToData,
  mapInternationalisationToData,
  mapTargetsToData,
} from 'src/utils/mappers/mapperHelpers';
import {
  mapAdditionalAttributesToData,
  mapDataToAdditionAttributes,
} from '../additionalAttributesMapper';

const mapDevelopedDetailListToData = (developedDetailList) => {
  return (developedDetailList || []).map((elem) => ({
    functionalId: elem.functionalId,
    descriptions: mapInternationalisationToData(elem.descriptions),
    titles: mapInternationalisationToData(elem.titles),
    documentAttachmentList: mapDocumentAttachmentListToData(
      elem.documentAttachmentList,
    ),
    priority: elem.priority,
    details: elem.details?.map((detail) =>
      mapInternationalisationToData(detail),
    ),
  }));
};

const mapDataToDevelopedDetailList = (data) => {
  return (data || []).map((elem) => ({
    functionalId: elem.functionalId,
    descriptions: mapDataToInternationalisation(elem.descriptions),
    titles: mapDataToInternationalisation(elem.titles),
    documentAttachmentList: mapDataToDocumentAttachmentList(
      elem.documentAttachmentList,
    ),
    priority: elem.priority,
    details: elem.details?.map((detail) =>
      mapDataToInternationalisation(detail),
    ),
  }));
};

export const mapRecommendationToData = (recommendation) => {
  const additionalAttributes = mapAdditionalAttributesToData(
    recommendation.additionalAttributes,
  );
  return {
    functionalId: recommendation.functionalId,
    recommendationType: recommendation.recommendationType,
    order: recommendation.order,
    description: mapInternationalisationToData(recommendation.description),
    targetType: recommendation.targetType,
    products: [],
    families: [],
    groups: [],
    ...mapTargetsToData(recommendation),
    constraints: recommendation.constraints.map((elem) => ({
      content: elem,
    })),
    url: recommendation.url,
    additionalAttributes,
    codeAction: recommendation.codeAction,
    developedDetailList: mapDevelopedDetailListToData(
      recommendation.developedDetailList,
    ),
    documentAttachmentList: mapDocumentAttachmentListToData(
      recommendation.documentAttachmentList,
    ),
  };
};

export const mapDataToRecommendation = (data) => {
  const additionalAttributes = mapDataToAdditionAttributes(
    data.additionalAttributes,
  );
  return {
    functionalId: data.functionalId,
    recommendationType: data.recommendationType,
    order: data.order,
    description: mapDataToInternationalisation(data.description),
    targetType: data.targetType,
    targets: mapDataToTargets(data),
    constraints: data.constraints.map((elem) => elem.content),
    url: data.url,
    additionalAttributes,
    codeAction: data.codeAction,
    developedDetailList: mapDataToDevelopedDetailList(data.developedDetailList),
    documentAttachmentList: mapDataToDocumentAttachmentList(
      data.documentAttachmentList,
    ),
    type: data.recommendationType,
  };
};
