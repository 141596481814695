import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DateSchedulingForm } from 'src/components/administration/technicalSetting/factTechnicalSettings/batchSchedulingForm/dateSchedulingForm';
import { CronSchedulingForm } from 'src/components/administration/technicalSetting/factTechnicalSettings/batchSchedulingForm/cronSchedulingForm/CronSchedulingForm';
import { Select } from 'antd';
import { ImmediateSchedulingForm } from 'src/components/administration/technicalSetting/factTechnicalSettings/batchSchedulingForm/immediateSchedulingForm';
import { TYPES_SCHEDULING } from 'src/utils/constants/SchedulingTypes';

const { Option } = Select;

export function BatchSchedulingFormSwitcher(props) {
  const { t } = useTranslation();
  const { close } = props;
  const [currentType, setCurrentType] = useState(TYPES_SCHEDULING.CRON);

  const handleChangeType = (formData) => {
    setCurrentType(formData);
  };
  const selectOptions = (
    <>
      {Object.keys(TYPES_SCHEDULING)?.map((value) => (
        <Option key={value} value={value}>
          {t(`factTechnicalSetting.batch.resetZero.typeScheduling.${value}`)}
        </Option>
      ))}
    </>
  );
  return (
    <div>
      <label className="px-5">
        {t('factTechnicalSetting.batch.resetZero.input.schedulingTypes')}
      </label>
      <div className="flex justify-start bg-white  rounded-lg overflow-hidden px-5">
        <Select
          onChange={handleChangeType}
          defaultValue={currentType}
          style={{ width: '100%', borderRadius: '0%' }}
          size="large"
        >
          {selectOptions}
        </Select>
      </div>
      {currentType === TYPES_SCHEDULING.DATE && (
        <DateSchedulingForm close={close} />
      )}
      {currentType === TYPES_SCHEDULING.CRON && (
        <CronSchedulingForm close={close} />
      )}
      {currentType === TYPES_SCHEDULING.IMMEDIATE && (
        <ImmediateSchedulingForm close={close} />
      )}
    </div>
  );
}

BatchSchedulingFormSwitcher.propTypes = {
  close: PropTypes.func,
};
