import PropTypes from 'prop-types';
import ArrayAccordion from '../../../../generic/ArrayAccordion';
import AssistanceOptionForm from '../AssistanceOptionForm';
import { OPTION_ASSISTANCE_DEFAULT } from '../../../../../utils/defaultsSupplier';

const AssistanceOption = (props) => {
  const { namePrefix } = props;
  const name = namePrefix
    ? `${namePrefix}.assistanceOptionList`
    : 'assistanceOptionList';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="assistanceOption"
      Form={AssistanceOptionForm}
      defaultValues={OPTION_ASSISTANCE_DEFAULT}
      fieldsNames={['functionalId', 'label']}
    />
  );
};

AssistanceOption.propTypes = {
  namePrefix: PropTypes.string,
};

export default AssistanceOption;
