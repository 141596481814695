const {
  getCorrectLabelForAdmin,
} = require('src/utils/internationalisationUtils');

export function getCardNestedOptions(selectedProduct, selectedLang) {
  const res = [];
  selectedProduct.themeList?.forEach((theme) => {
    theme.formulaList?.forEach((formula) => {
      res.push({
        functionalId: theme.functionalId + ':' + formula.functionalId,
        name:
          theme.functionalId +
          ' - ' +
          getCorrectLabelForAdmin(theme.label, selectedLang) +
          ' : ' +
          formula.functionalId +
          ' - ' +
          getCorrectLabelForAdmin(formula.label, selectedLang),
      });
    });
  });
  return res;
}
