import { Col, Row, notification } from 'antd';
import PropTypes from 'prop-types';
import { addParameter, updateParameter } from 'src/api';
import Form from 'src/components/generic/Form';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import { mapDataToParameter, mapParameterToData } from 'src/utils';
import { PARAMETER_DEFAULT } from 'src/utils/defaultsSupplier';
import { PARAMETER_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/parameterSchema';
import { useTranslation } from 'react-i18next';
import { useGetParameterSchemaList } from 'src/containers/parameterSchema/store';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { parseFormFields } from 'src/containers/DynamicFormContainer/util';

export default function ParameterForm({
  domain,
  close,
  onParameterAdd,
  onParameterUpdate,
  parameter,
  formDisabled,
}) {
  const { t } = useTranslation();
  const { data } = useGetParameterSchemaList({
    domain: [domain.id],
  });
  const schema = useMemo(() => {
    if (isEmpty(data)) {
      return null;
    }
    return data[0];
  }, [data]);

  const onSubmit = async (data) => {
    const mappedParameter = mapDataToParameter(data, domain);

    try {
      if (parameter) {
        await updateParameter(parameter.id, mappedParameter);
        onParameterUpdate(parameter);
      } else {
        await addParameter(mappedParameter);
        onParameterAdd(mappedParameter);
      }

      close();
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = (err) => {
    notification.error({ message: err.response.data.message });
  };

  return (
    <Form
      onSubmit={onSubmit}
      onCancel={close}
      validationSchema={PARAMETER_VALIDATION_SCHEMA}
      defaultValues={
        parameter ? mapParameterToData(parameter) : PARAMETER_DEFAULT
      }
      formDisabled={formDisabled}
    >
      <Row gutter={[16, 16]} className="mb-6">
        <Col span={24}>
          <TextInput
            label={t('parameters.code')}
            name="functionalId"
            disabled={!!parameter}
          />
        </Col>
        <Col span={24}>
          <TextInput label={t('parameters.name')} name="name" />
        </Col>
        <Col span={24}>
          <InternationalizationInput
            name="description"
            label={t('parameters.description')}
          />
        </Col>
        <Col span={24}>
          <SwitchInput name="active" label={t('parameters.active')} />
        </Col>
        <Col span={24} className="space-y-4 flex flex-col">
          {parseFormFields({
            namePrefix: 'additionalFields.',
            properties: schema?.properties,
          })}
        </Col>
      </Row>
    </Form>
  );
}

ParameterForm.propTypes = {
  domain: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
};
