import PropTypes from 'prop-types';
import GenericFilter from 'src/components/generic/GenericFilter';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import CustomPagination from 'src/components/generic/CustomPagination';
import {
  mapParamsToFilter,
  mapFilterToUrl,
} from 'src/utils/mappers/filterMapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { Error500 } from 'src/pages/error';

export const AuditDataFilter = ({
  columns,
  paramMap,
  allowedKeys,
  filterFields,
  queryKey,
  fetchDataFunction,
  tab,
}) => {
  const [currentFilter, setCurrentFilter] = useState({
    tab,
  });
  const [page, setPage] = useState(1);
  const { pathname, search } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ELEMENT_PER_PAGE = 10;
  const {
    data: catalogueAdminAudits,
    isLoading,
    isError,
  } = useQuery([queryKey, page, currentFilter], () =>
    fetchDataFunction(page - 1, ELEMENT_PER_PAGE, currentFilter),
  );
  useEffect(() => {
    var queryParams = new URLSearchParams(search);
    const filter = mapParamsToFilter(queryParams, paramMap, allowedKeys);

    // GenericDatePicker need empty string to reset in case startDate/endDate null
    filter.startDate = filter.startDate || '';
    filter.endDate = filter.endDate || '';

    setCurrentFilter(filter);
    setPage(1);
  }, [allowedKeys, paramMap, tab, search]);

  const onFilter = (param) => {
    const paramsString = mapFilterToUrl(param || {}, paramMap);
    const newUrl = `${pathname}?${paramsString}`;
    navigate(newUrl, { replace: true });
  };

  if (isError) return <Error500 />;

  return (
    <>
      <div className="h-auto">
        <GenericFilter
          onFilter={onFilter}
          initialFilterValues={currentFilter}
          emptyFilterValues={{ tab, startDate: '', endDate: '' }}
          filterFields={filterFields}
          disableSubmit={false}
        />
      </div>
      <div className="w-full overflow-x-auto overflow-y-hidden">
        <Table
          dataSource={catalogueAdminAudits?.content?.map((item, index) => ({
            ...item,
            key: index,
          }))}
          columns={columns.map((c) => ({ ...c, title: t(c.title) }))}
          pagination={false}
          loading={isLoading}
          rowClassName={() => 'bg-white'}
        />
      </div>
      <div>
        <CustomPagination
          current={page}
          total={catalogueAdminAudits?.totalElements}
          pageSize={ELEMENT_PER_PAGE}
          onChange={(page) => setPage(page)}
        />
      </div>
    </>
  );
};

AuditDataFilter.propTypes = {
  columns: PropTypes.array.isRequired,
  paramMap: PropTypes.object.isRequired,
  allowedKeys: PropTypes.array.isRequired,
  filterFields: PropTypes.array.isRequired,
  queryKey: PropTypes.string.isRequired,
  fetchDataFunction: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
};

export default AuditDataFilter;
