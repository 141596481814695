import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FileTextOutlined,
  PercentageOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useWatch, useFormContext } from 'react-hook-form';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import InternationalizationInput from '../../generic/inputs/InternationalizationInput';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';
import { useTranslation } from 'react-i18next';
import DiscountPeriod from '../DiscountPeriod';

const DiscountForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();

  const discountType = useWatch({
    control,
    name: `${namePrefix}.type`,
  });

  return (
    <Row gutter={[16, 12]}>
      <Col span={8}>
        <InternationalizationInput
          name={`${namePrefix}.description`}
          label={t('discount.description')}
          startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={8}>
        <SelectInput
          className="mr-2"
          label={t('discount.discountType')}
          name={`${namePrefix}.type`}
          data={[
            { functionalId: 'formula', name: t('discount.formulaType') },
            { functionalId: 'rate', name: t('discount.rateType') },
          ]}
        />
      </Col>

      {discountType === 'formula' ? (
        <>
          <Col span={8}>
            <TextInput
              className="mr-2"
              label={t('discount.formula')}
              type="text"
              name={`${namePrefix}.formula`}
              startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
            />
          </Col>
          <Col span={8}>
            <TextInput
              className="mr-2"
              label={t('discount.minPercentage')}
              type="number"
              name={`${namePrefix}.minPercentage`}
              startAdornment={
                <ArrowDownOutlined style={{ fontSize: '25px' }} />
              }
            />
          </Col>
          <Col span={8}>
            <TextInput
              className="mr-2"
              label={t('discount.maxPercentage')}
              type="number"
              name={`${namePrefix}.maxPercentage`}
              startAdornment={<ArrowUpOutlined style={{ fontSize: '25px' }} />}
            />
          </Col>
        </>
      ) : (
        <Col span={8}>
          <TextInput
            className="mr-2"
            label={t('discount.percentage')}
            type="number"
            name={`${namePrefix}.rate`}
            startAdornment={<PercentageOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
      )}

      <Col span={8}>
        <SwitchInput name={`${namePrefix}.active`} label="Active" />
      </Col>
      <DiscountPeriod namePrefix={namePrefix} />
      <Col span={24}>
        <p className="font-medium">{t('discount.constraints')}</p>
        <ConstraintWrapper namePrefix={namePrefix} />
      </Col>
    </Row>
  );
};

export default DiscountForm;
