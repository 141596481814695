import {
  useInfiniteQuery,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { Fn, FormSchemaAdminDto, IMap } from 'src/types';
import {
  createFormSchema,
  createFormSchemaDraft,
  getFormSchemaById,
  getFormSchemaVersionsById,
  listFormSchemas,
  publishFormSchema,
  unpublishFormSchema,
  updateFormSchema,
} from 'src/api';
import { PageRequest, PageResult } from 'src/types/models/common';
import { UseInfiniteQueryResult } from 'react-query/types/react/types';
import { getPageRequestCacheKey } from 'src/utils/StringUtils';
import { createScoperChainedFilter } from 'src/utils/scoper';
import { ObjectVersionDto } from 'src/types/models/versioning';
import {
  SCHEMABLE_ID_KEY_DELIMITER,
  SCHEMABLE_TYPES,
} from 'src/pages/formSchemas/utils/constants/schemable';
import { PUBLICATION_STATUS } from 'src/utils/constants/publicationStatus';
import { useShowMessage } from 'src/hooks/normalHooks/useShowMessage';

export interface FormSchemasStore {
  getById: Fn<string, UseQueryResult<FormSchemaAdminDto>>;
  save: Fn<
    void,
    UseMutationResult<FormSchemaAdminDto, unknown, FormSchemaAdminDto>
  >;
  load: (_: {
    page: PageRequest;
    filter?: IMap<string, string | string[]>;
  }) => UseInfiniteQueryResult<PageResult<FormSchemaAdminDto>>;
  getByProductFamily: Fn<string, UseQueryResult<FormSchemaAdminDto | null>>;
  getByActAndTargetTypeAndTarget: (_: {
    act: string;
    targetType: string;
    target: IMap<string, string | string[]>;
  }) => UseQueryResult<FormSchemaAdminDto | null>;
  getAllVersionsById: Fn<string, UseQueryResult<ObjectVersionDto[]>>;
  createDraft: Fn<void, UseMutationResult<FormSchemaAdminDto, unknown, string>>;
  publish: Fn<void, UseMutationResult<FormSchemaAdminDto, unknown, string>>;
  unpublish: Fn<void, UseMutationResult<FormSchemaAdminDto, unknown, string>>;
}

export function useFormSchemasStore(): FormSchemasStore {
  const baseKey = 'form-schemas';
  const client = useQueryClient();

  const { showError } = useShowMessage();

  const save = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation<FormSchemaAdminDto, unknown, FormSchemaAdminDto>({
      mutationFn: (data) => {
        if (data.id) {
          return updateFormSchema(data.id, data);
        }
        return createFormSchema(data);
      },
      onSuccess: () => {
        client.invalidateQueries([baseKey]);
      },
    });

  const getById = (id: string) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery<FormSchemaAdminDto>({
      queryKey: [baseKey, id],
      queryFn: () => getFormSchemaById(id),
    });

  const load = ({
    page,
    filter,
  }: {
    page: PageRequest;
    filter?: IMap<string, string | string[]>;
  }) => {
    const chainedFilter = filter ? createScoperChainedFilter(filter) : '';
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useInfiniteQuery({
      queryKey: [baseKey, chainedFilter, getPageRequestCacheKey(page)],
      queryFn: ({ pageParam }) =>
        listFormSchemas(
          { ...page, pageNumber: pageParam ?? page.pageNumber },
          chainedFilter,
        ) as Promise<PageResult<FormSchemaAdminDto>>,
      staleTime: Infinity,
      getNextPageParam: (a) => (!a.last ? (a.pageNumber ?? -1) + 1 : undefined),
    });
  };

  const getByProductFamily = (family: string) => {
    const filter = createScoperChainedFilter(
      {
        schemableType: SCHEMABLE_TYPES.products,
        schemableId: createScoperChainedFilter({ family }),
        status: PUBLICATION_STATUS.PUBLISHED,
      },
      { appendOperator: true },
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<FormSchemaAdminDto | null>({
      queryKey: [baseKey, filter],
      queryFn: async () => {
        if (!family) {
          return Promise.resolve(null);
        }
        const result = await listFormSchemas({}, filter);
        return Promise.resolve(
          result.content?.length ? result.content[0] : null,
        );
      },
    });
  };

  const getByActAndTargetTypeAndTarget = ({
    act,
    targetType,
    target,
  }: {
    act: string;
    targetType: string;
    target: IMap<string, string | string[]>;
  }) => {
    const filter = createScoperChainedFilter(
      {
        schemableType: SCHEMABLE_TYPES.eligibleActs,
        schemableId: createScoperChainedFilter(
          { act, targetType, ...target },
          { keysDelimiter: SCHEMABLE_ID_KEY_DELIMITER },
        ),
        status: PUBLICATION_STATUS.PUBLISHED,
      },
      { appendOperator: true },
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<FormSchemaAdminDto | null>({
      queryKey: [baseKey, filter],
      queryFn: async () => {
        if (!act || !targetType) {
          return Promise.resolve(null);
        }
        const result = await listFormSchemas({}, filter);
        return Promise.resolve(
          result.content?.length ? result.content[0] : null,
        );
      },
    });
  };

  const getAllVersionsById = (id: string) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery<ObjectVersionDto[]>({
      queryKey: [baseKey, id, 'versions'],
      queryFn: () => getFormSchemaVersionsById(id),
    });

  const createDraft = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation<FormSchemaAdminDto, unknown, string>({
      mutationFn: (id) => {
        return createFormSchemaDraft(id);
      },
      onSuccess: () => {
        client.invalidateQueries([baseKey]);
      },
    });

  const publish = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation<FormSchemaAdminDto, unknown, string>({
      mutationFn: (id) => {
        return publishFormSchema(id);
      },
      onSuccess: () => {
        client.invalidateQueries([baseKey]);
      },
      onError: (err) => {
        showError(err);
      },
    });

  const unpublish = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation<FormSchemaAdminDto, unknown, string>({
      mutationFn: (id) => {
        return unpublishFormSchema(id);
      },
      onSuccess: () => {
        client.invalidateQueries([baseKey]);
      },
    });

  return {
    save,
    getById,
    load,
    getByProductFamily,
    getByActAndTargetTypeAndTarget,
    getAllVersionsById,
    createDraft,
    publish,
    unpublish,
  };
}
