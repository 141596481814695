import { useFormContext, useWatch } from 'react-hook-form';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import InputConditionalDisplayer from 'src/components/generic/inputs/InputConditionalDisplayer';
import { filtersMap } from 'src/components/administration/audit/constants';

function StatisticFilterSelect({
  placeholder,
  name,
  options,
  onClear,
  isLoading,
  isError,
}) {
  const { control } = useFormContext();
  const auditableAction = useWatch({ name: 'auditableAction', control });
  return (
    <>
      {filtersMap[auditableAction]?.includes(name) && (
        <InputConditionalDisplayer
          isLoading={isLoading}
          isError={isError}
          size="middle"
        >
          <SelectInput
            size="middle"
            placeholder={placeholder}
            data={options}
            name={name}
            className="w-40"
            allowClear
            onClear={onClear(name)}
          />
        </InputConditionalDisplayer>
      )}
    </>
  );
}

export { StatisticFilterSelect };
