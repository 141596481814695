import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import AuditCatalogue from 'src/components/administration/consultAudit/AuditCatalogue';
import AuditPricing from 'src/components/administration/consultAudit/AuditPricing';
import { useTranslation } from 'react-i18next';
import {
  adminColumns,
  usagePricingColumns,
  usageEligibilityColumns,
  usageProductColumns,
  usageFactsColumns,
} from 'src/utils/auditColumns';
import AuditFacts from 'src/components/administration/consultAudit/AuditFacts';

const AuditTabHandler = ({ tabs, defaultTab }) => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const componentsMap = {
    adminCatalogue: { component: AuditCatalogue, columns: adminColumns },
    usageProduct: { component: AuditCatalogue, columns: usageProductColumns },
    usageEligibility: {
      component: AuditCatalogue,
      columns: usageEligibilityColumns,
    },
    adminPricing: { component: AuditPricing, columns: adminColumns },
    usagePricing: { component: AuditPricing, columns: usagePricingColumns },
    adminFacts: { component: AuditFacts, columns: adminColumns },
    usageFacts: { component: AuditFacts, columns: usageFactsColumns },
  };

  const handleTabChange = (key) => {
    setSelectedTab(key);
    const newSearchParams = new URLSearchParams();
    newSearchParams.set('tab', key);

    const newUrl = `${location.pathname}?${newSearchParams.toString()}`;
    navigate(newUrl, { replace: true });
  };

  const renderComponent = (tabKey) => {
    const { component: Component, columns } = componentsMap[tabKey] || {};
    if (Component) {
      return <Component typeDomain={tabKey} columns={columns} />;
    }
    return null;
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabFromParams = searchParams.get('tab');
    if (tabFromParams && tabs.find((tab) => tab.key === tabFromParams)) {
      setSelectedTab(tabFromParams);
    }
  }, [location.search, tabs]);

  return (
    <>
      <Tabs
        centered={true}
        activeKey={selectedTab}
        onChange={handleTabChange}
        items={tabs.map((tab) => ({ label: t(tab.tabName), key: tab.key }))}
      />
      {renderComponent(selectedTab)}
    </>
  );
};

AuditTabHandler.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaultTab: PropTypes.string.isRequired,
};

export default AuditTabHandler;
