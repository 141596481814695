import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import InputLabel from 'src/components/generic/inputs/InputLabel/InputLabel';

export default function TextInput(props) {
  const {
    name,
    label,
    type,
    className,
    startAdornment,
    endAdornment,
    placeholder,
    disabled,
    description = undefined,
    required = false,
    minLength,
    maxLength,
    min,
    max,
  } = props;

  const { control, formDisabled } = useFormContext();
  const inputDisabled = disabled || formDisabled;

  return (
    <Form.Item help={description} style={{ marginBottom: '0px' }}>
      <InputLabel label={label} required={required} />
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Input
              {...field}
              size="large"
              className={className}
              placeholder={!inputDisabled && placeholder}
              type={type}
              addonBefore={
                startAdornment ? (
                  <div className="flex justify-center items-center">
                    {startAdornment}
                  </div>
                ) : null
              }
              addonAfter={endAdornment}
              disabled={inputDisabled}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              min={min}
              max={max}
            />
          );
        }}
      />
    </Form.Item>
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
};

TextInput.defaultValues = {
  type: 'text',
};
