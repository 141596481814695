import { useQuery } from 'react-query';
import { getParametersByDomain } from '../../../api';

const getParameters = async ({ queryKey }) => {
  const domain = queryKey[1];
  const query = queryKey[2];
  return getParametersByDomain(domain, query);
};

export default function useParameters(domain, query = '') {
  return useQuery(['parametersByDomain', domain, query], getParameters);
}
