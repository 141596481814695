import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Error500 } from 'src/pages/error';
import {
  ruleParamMappedKeys,
  RULE_FILTER_FIELDS,
} from 'src/utils/filterFields';
import CustomPagination from 'src/components/generic/CustomPagination';
import GenericFilter from 'src/components/generic/GenericFilter';
import Loading from 'src/components/generic/Loading';
import {
  mapParamsToFilter,
  mapFilterToUrl,
} from 'src/utils/mappers/filterMapper';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';
import RuleCard from '../../../components/rule/card';
import { createApisForRule } from '../../../api/rule';
import ApiSwitcher from '../../../components/apiSwitcher/ApiSwitcher';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { API_NAMES, USER_ROLES } from 'src/utils/constants';

const ELEMENT_PER_PAGE = 20;

export const RuleListingPage = () => {
  const { t } = useTranslation();
  const { state, pathname, search } = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState({});

  const { currentApi } = useUserContext();

  const { getAllRulesByCriteria } = createApisForRule(currentApi);

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const {
    data: rules,
    isLoading,
    isError,
  } = useQuery(['get-rules', page, currentFilter, currentApi], () =>
    getAllRulesByCriteria(page - 1, ELEMENT_PER_PAGE, currentFilter),
  );

  useEffect(() => {
    if (state?.toastContent) toast.success(state?.toastContent);
    const filter = mapParamsToFilter(queryParams, ruleParamMappedKeys, []);

    setCurrentFilter(filter);
    setPage(1);
  }, [state, queryParams]);

  const onFilter = (param) => {
    const paramsString = mapFilterToUrl(param || {}, ruleParamMappedKeys);
    const newUrl = `${pathname}?${paramsString}`;
    navigate(newUrl, { replace: true });
  };

  if (isError) return <Error500 />;

  return (
    <div className="h-auto">
      <GenericFilter
        onFilter={onFilter}
        initialFilterValues={currentFilter}
        emptyFilterValues={{}}
        filterFields={RULE_FILTER_FIELDS}
        disableSubmit={isLoading}
        customField={
          <ApiSwitcher
            catalogueRoles={[USER_ROLES.CONSULT_CATALOG_RULES]}
            pricingRoles={[USER_ROLES.CONSULT_EVENT_PRICING_RULES]}
            concernedApis={[API_NAMES.CATALOG, API_NAMES.EVENT_PRICING]}
          />
        }
      />

      {isLoading ? (
        <Loading />
      ) : (
        <div className="w-full h-full flex flex-col p-5">
          <div className="flex items-end mb-4 mr-2 justify-between">
            <p className="mx-2">
              {rules?.totalElements[0]?.totalElements
                ? rules?.totalElements[0]?.totalElements +
                  ' ' +
                  t('search.numberRulesFound')
                : t('search.noResultFound')}
            </p>
            <Link to={`/rules/${currentApi}/new`}>
              <AuthComponentDisabler
                componentRender={() => (
                  <Button
                    type="primary"
                    className="bg-orange-500 text-white flex items-center disabled:opacity-30"
                  >
                    <PlusOutlined sx={{ fontSize: '20px' }} /> {t('crud.add')}{' '}
                    {t('subnav.rule')}
                  </Button>
                )}
                api={currentApi}
                roles={[
                  USER_ROLES.EDIT_EVENT_PRICING_RULES,
                  USER_ROLES.EDIT_CATALOG_RULES,
                ]}
              />
            </Link>
          </div>
          {rules?.content.length > 0 ? (
            <div className="grid grid-cols-5 gap-4 w-full mb-2">
              {rules?.content.map((elem) => (
                <Link
                  key={elem.id}
                  to={`/rules/${currentApi}/update/${elem.id}`}
                >
                  <RuleCard rule={elem} />
                </Link>
              ))}
            </div>
          ) : (
            <div className="h-96">
              <Empty />
            </div>
          )}
          <CustomPagination
            current={page}
            total={rules?.totalElements[0]?.totalElements}
            pageSize={ELEMENT_PER_PAGE}
            onChange={(value) => setPage(value)}
          />
        </div>
      )}
    </div>
  );
};
export default RuleListingPage;
