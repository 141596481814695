import React from 'react';
import { FormSelectField } from 'src/containers/DynamicFormContainer/components/FormSelectField';
import { FormScriptField } from 'src/containers/DynamicFormContainer/components/FormScriptField';
import TextInput from 'src/components/generic/inputs/TextInput';
import {
  inputTypeText,
  widgetCheckbox,
  widgetConstraints,
  widgetDate,
  widgetGroupLabelsInput,
  widgetInput,
  widgetScript,
  widgetSelect,
  widgetSwitch,
  widgetTextarea,
  widgetUpload,
} from 'src/containers/DynamicFormContainer/constants';
import { CheckboxInput } from 'src/components/generic/inputs/CheckboxInput';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import { TextAreaInput } from 'src/components/generic/inputs/TextAreaInput';
import CustomDatePicker from 'src/components/generic/inputs/DatePicker';
import { GroupsLabelsInput } from 'src/components/generic/inputs/GroupsLabelsInput/GroupsLabelsInput';
import FileUpload from 'src/components/generic/inputs/FileUpload';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';

const SUPPORTED_FIELDS = {
  [widgetInput]: {
    component: TextInput,
    resolveProps: (schema) => ({
      label: schema.title,
      description: schema.description ?? '',
      type: String(schema?.ui?.type ?? inputTypeText),
      placeholder: String(schema?.ui?.placeholder ?? ''),
      disabled: Boolean(schema?.ui?.disabled ?? false),
    }),
  },
  [widgetTextarea]: {
    component: TextAreaInput,
    resolveProps: (schema) => ({
      label: schema.title,
      description: schema.description ?? '',
      type: String(schema?.ui?.type ?? inputTypeText),
      placeholder: String(schema?.ui?.placeholder ?? ''),
      disabled: Boolean(schema?.ui?.disabled ?? false),
      rows: Number(schema?.ui?.rows ?? 3),
    }),
  },
  [widgetSelect]: {
    component: FormSelectField,
    resolveProps: (schema) => ({ schema }),
  },
  [widgetScript]: {
    component: FormScriptField,
    resolveProps: (schema) => ({ schema }),
  },
  [widgetCheckbox]: {
    component: CheckboxInput,
    resolveProps: (schema) => ({
      label: schema.title,
      description: schema.description ?? '',
      disabled: Boolean(schema?.ui?.disabled ?? false),
    }),
  },
  [widgetSwitch]: {
    component: SwitchInput,
    resolveProps: (schema) => ({
      label: schema.title,
      description: schema.description ?? '',
      disabled: Boolean(schema?.ui?.disabled ?? false),
    }),
  },
  [widgetDate]: {
    component: CustomDatePicker,
    resolveProps: (schema) => ({
      label: schema.title,
      description: schema.description ?? '',
      disabled: Boolean(schema?.ui?.disabled ?? false),
      placeholder: String(schema?.ui?.placeholder ?? ''),
    }),
  },
  [widgetGroupLabelsInput]: {
    component: GroupsLabelsInput,
    resolveProps: (schema) => ({
      name: schema.id,
    }),
  },
  [widgetUpload]: {
    component: FileUpload,
    resolveProps: (schema) => ({
      name: schema.id,
      valueAsUrlOnly: true,
    }),
  },
  [widgetConstraints]: {
    component: ConstraintWrapper,
    resolveProps: () => ({
      // ConstraintWrapper forces the name to constraints
      // name: schema.id,
      hasErrorMessage: true,
    }),
  },
};

function FieldNotSupported({ widget }) {
  return (
    <div className="text-sm font-medium text-rose-500 border border-rose-300 rounded p-2 bg-rose-200">
      {`Le widget '${widget}' n'est pas encore supporté.`}
    </div>
  );
}

function FormField({ schema, namePrefix = '' }) {
  const widget = schema?.ui?.widget ?? widgetInput;

  const field = SUPPORTED_FIELDS[widget];
  if (!field) {
    return <FieldNotSupported widget={widget} />;
  }

  const Component = field.component;
  return (
    <Component
      {...field.resolveProps(schema, namePrefix)}
      name={`${namePrefix}${schema.id}`}
    />
  );
}

export { FormField };
