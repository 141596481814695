import { FormComponentType } from 'src/pages/formSchemas/types';
import { FormFieldV2 } from 'src/types';
import { getFormComponent } from 'src/pages/formSchemas/utils/form';
import React from 'react';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}.`;
  return (
    <div className="space-y-4">
      {field.config?.fields?.map((field: FormFieldV2, i: number) => {
        const key = `${field.label}-${i}`;
        const component = getFormComponent({ field, namePrefix: name });
        return <React.Fragment key={key}>{component}</React.Fragment>;
      })}
    </div>
  );
};
