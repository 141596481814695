import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundaryInner } from 'src/components/generic/ErrorBoundary/ErrorBoundaryInner';
import { useErrorHandler } from 'src/hooks/componentHooks/useErrorHandler';

export function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);
  const { onError } = useErrorHandler();
  const location = useLocation();

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <ErrorBoundaryInner
      hasError={hasError}
      setHasError={setHasError}
      onError={onError}
    >
      {children}
    </ErrorBoundaryInner>
  );
}
