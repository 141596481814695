import {
  BATCH_SCHEDULING_PATH,
  CRON_SCHEDULING,
  DATE_SCHEDULING,
  FACTS_PATH,
} from 'src/api/apiPaths';
import HttpService from '../../services/HttpService';

const instance = HttpService.getAxiosClient('facts');

export const addFactCollection = async (factCollection) => {
  const res = await instance.post(FACTS_PATH, factCollection);
  return res.data;
};

export const getFactCollectionById = async (FactCollectionId) => {
  const res = await instance.get(`${FACTS_PATH}/${FactCollectionId}`);
  return res.data;
};

export const updateFactCollection = async (factCollection, id) => {
  const res = await instance.put(`${FACTS_PATH}/${id}`, factCollection);
  return res.data;
};

export const deleteFactCollection = async (id) => {
  const res = await instance.delete(`${FACTS_PATH}/${id}`);
  return res.data;
};

export const getAllFactsByClientIdAndAccountIdAndContractIdAndPersonId = async (
  clientId,
  accountId,
  contractId,
  personId,
) => {
  const res = await instance.get(`${FACTS_PATH}`, {
    params: {
      clientId,
      accountId,
      contractId,
      personId,
    },
  });
  return res.data;
};

export const getBatchResetToZeroLaunchDates = async (
  page,
  size,
  currentFilter,
) => {
  const res = await instance.get(`${BATCH_SCHEDULING_PATH}${DATE_SCHEDULING}`, {
    params: { page: page, size: size, status: currentFilter },
  });
  return res.data;
};
export const getBatchResetToZeroLaunchCrons = async (
  page,
  size,
  currentFilter,
) => {
  const res = await instance.get(`${BATCH_SCHEDULING_PATH}${CRON_SCHEDULING}`, {
    params: { page: page, size: size, status: currentFilter },
  });
  return res.data;
};

export const scheduleResetToZeroDateBatch = async (data) => {
  const response = await instance.post(
    `${BATCH_SCHEDULING_PATH}${DATE_SCHEDULING}`,
    data,
  );
  return response.data;
};

export const scheduleResetToZeroCronBatch = async (data) => {
  const response = await instance.post(
    `${BATCH_SCHEDULING_PATH}${CRON_SCHEDULING}`,
    data,
  );
  return response.data;
};

export const cancelFactResetToZeroScheduledBatch = async (id) => {
  const res = await instance.delete(
    `${BATCH_SCHEDULING_PATH}${DATE_SCHEDULING}/${id}`,
  );
  return res.data;
};
export const cancelCronFactResetToZeroScheduledBatch = async (id) => {
  const res = await instance.delete(
    `${BATCH_SCHEDULING_PATH}${CRON_SCHEDULING}/${id}`,
  );
  return res.data;
};
export const getCountsByPeriodicity = async (periodicity) => {
  const res = await instance.get(`${BATCH_SCHEDULING_PATH}/count-names`, {
    params: {
      periodicity: periodicity,
    },
  });
  return res.data;
};
