import React from 'react';
import { DesignerComponentType } from 'src/pages/formSchemas/types';
import { Form } from 'antd';
import clsx from 'clsx';

export const DesignerComponent: DesignerComponentType = ({ fieldValue }) => {
  return (
    <div className="flex items-end h-full justify-between pb-2">
      <span>{fieldValue.label}</span>
      <Form.Item className="mb-0">
        <div
          className={clsx(
            'cursor-pointer w-12 h-6 rounded-full p-1 flex justify-start transition-all duration-200',
            {
              'bg-orange-400': !!fieldValue?.config?.defaultValue,
              'bg-gray-400': !fieldValue?.config?.defaultValue,
            },
          )}
        >
          <div
            className={
              'w-4 h-4 bg-white rounded-full z-10 transition-all duration-300 '
            }
          />
        </div>
      </Form.Item>
    </div>
  );
};
