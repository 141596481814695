import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { update } from 'src/redux/slices/parameterSlice';
import useModal from 'src/hooks/componentHooks/useModal';
import useParameters from 'src/hooks/normalHooks/useParameters';
import ParameterForm from '../../form/ParameterForm';
import Loading from 'src/components/generic/Loading';
import { Error500 } from 'src/pages/error';
import { useDispatch } from 'react-redux';
import { Button, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { USER_ROLES } from 'src/utils/constants';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';
import { ExportImport } from 'src/components/parameter/exportImport';
import { ParameterCard } from 'src/components/parameter/listing/ParameterCard';
import { useExportSelectionContext } from 'src/hooks/normalHooks/context/useExportSelectionContext';

export default function ParameterLister(props) {
  const { domain, searchQuery, formDisabled } = props;
  const { Modal, setOpen } = useModal();
  const { data, isLoading, isError, refetch } = useParameters(
    domain.id,
    searchQuery,
  );
  const [parameter, setParameter] = useState(null);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { selectable, onExport, resetSelection } = useExportSelectionContext();
  useEffect(() => {
    refetch();
  }, [searchQuery, refetch]);

  const onCardClick = (param) => {
    setOpen(true);
    setParameter(param);
  };

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error500 />;
  }

  return (
    <>
      {data.length === 0 && searchQuery ? (
        <></>
      ) : (
        <div className="w-full p-4 shadow-md mb-2 flex flex-col gap-2 ">
          <div className="flex justify-between align-baseline">
            <h1 className="font-semibold">{domain.name}</h1>
            <div className={'flex gap-2'}>
              {!selectable && (
                <>
                  <AuthComponentDisabler
                    componentRender={() => (
                      <Button
                        type="primary"
                        className=" bg-orange-500 text-white flex items-center disabled:opacity-30"
                        onClick={() => {
                          setOpen(true);
                          setParameter(null);
                        }}
                      >
                        <PlusOutlined /> {t('crud.add')} {domain.name}
                      </Button>
                    )}
                    roles={[USER_ROLES.EDIT_PARAMETERS]}
                  />
                  <ExportImport allParameters={data} domain={domain} />
                </>
              )}
              {selectable && (
                <>
                  <Button
                    type="primary"
                    ghost
                    htmlType="submit"
                    className=" border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
                    onClick={() => {
                      onExport(`parameters-${domain.name.toUpperCase()}`);
                    }}
                  >
                    {t('data.export')}
                  </Button>
                  <Button danger onClick={() => resetSelection()}>
                    {t('confirmationDialogue.cancel')}
                  </Button>
                </>
              )}
            </div>
          </div>
          {data.length === 0 ? (
            <Empty description={t('data.noDataFound')} />
          ) : (
            <div className="row-span-full grid grid-cols-1 gap-4 sm:grid md:grid-cols-2 md:gap-4 lg:grid lg:grid-cols-4 lg:gap-4">
              {data.map((param, index) => {
                return (
                  <ParameterCard
                    key={index}
                    parameter={param}
                    onClick={() => onCardClick(param)}
                  />
                );
              })}
            </div>
          )}

          <Modal
            title={
              (parameter ? t('crud.update') : t('crud.add')) + ' ' + domain.name
            }
          >
            <ParameterForm
              domain={domain}
              parameter={parameter}
              close={() => {
                setOpen(false);
                setParameter(null);
              }}
              onParameterAdd={() => {
                if (domain.id === 'SUPPORTED_LANGUAGE') {
                  refetch().then((res) => dispatch(update(res.data)));
                }
                refetch();
              }}
              onParameterUpdate={() => {
                if (domain.id === 'SUPPORTED_LANGUAGE') {
                  refetch().then((res) => dispatch(update(res.data)));
                }
                refetch();
              }}
              formDisabled={formDisabled}
            />
          </Modal>
        </div>
      )}
    </>
  );
}

ParameterLister.propTypes = {
  domain: PropTypes.object.isRequired,
};
