import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from '../../mapperHelpers';

const mapDataToOptions = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapDataToInternationalisation(elem.label),
    optionDetails: elem.optionDetails,
  };
};

const mapOptionsToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    optionDetails: elem.optionDetails,
  };
};

export const mapBancassToData = (bancass) => {
  return {
    options: bancass.options.map((option) => mapOptionsToData(option)),
  };
};

export const mapDataToBancass = (data) => {
  const options = data.options.map((elem) => mapDataToOptions(elem));
  return {
    options,
    type: 'bancass',
  };
};
