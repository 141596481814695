import { performDryRun } from 'src/api';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { evaluateScript } from 'src/api/eventPricing';
import {
  ELIGIBLE_ACT_BASE_PATH,
  EVENT_PRICING_BASE_PATH,
  PRODUCT_BASE_PATH,
  RECOMMENDATION_BASE_PATH,
} from 'src/routes/paths';

const DRY_RUN_API = [
  {
    handler: performDryRun,
    applyIf: ({ pathname }) =>
      [
        PRODUCT_BASE_PATH,
        RECOMMENDATION_BASE_PATH,
        ELIGIBLE_ACT_BASE_PATH,
      ].find((route) => pathname.includes(route)),
  },
  {
    handler: evaluateScript,
    applyIf: ({ pathname }) =>
      [EVENT_PRICING_BASE_PATH].find((route) => pathname.includes(route)),
  },
];
export const useDryRunMutation = () => {
  const location = useLocation();
  const apiHandler = DRY_RUN_API.find(({ applyIf }) => applyIf(location));

  return useMutation(!!apiHandler ? apiHandler.handler : performDryRun);
};
