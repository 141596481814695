import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { CONVENTION_CARD_FORMULA_DEFAULT } from 'src/utils/defaultsSupplier';
import ConventionCardFormulaForm from '../ConventionCardFormulaForm';

export default function ConventionCardFormula(props) {
  const { namePrefix } = props;
  const name = namePrefix
    ? `${namePrefix}.conventionCardFormulaList`
    : 'conventionCardFormulaList';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="conventionCardFormula"
      Form={ConventionCardFormulaForm}
      defaultValues={CONVENTION_CARD_FORMULA_DEFAULT}
      fieldsNames={['functionalId', 'label']}
    />
  );
}
