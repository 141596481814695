import {
  DesignerComponentType,
  FormBuilderField,
} from 'src/pages/formSchemas/types';
import { Card } from 'antd';
import {
  getDesignerComponent,
  getNestedFieldName,
} from 'src/pages/formSchemas/utils/form';
import React from 'react';

export const DesignerComponent: DesignerComponentType = ({
  fieldValue,
  fieldName,
  onEdit,
  onDelete,
  onDuplicate,
  disabled,
}) => {
  return (
    <Card className="border-none bg-transparent" title={fieldValue.label}>
      <div className="space-y-4">
        {((fieldValue.config?.fields as FormBuilderField[]) ?? []).map(
          (val, i) => {
            const fName = getNestedFieldName(fieldName, i);
            const component = getDesignerComponent({
              fieldName: fName,
              fieldValue: val,
              onEdit,
              onDelete,
              disabled,
              onDuplicate,
            });
            return <React.Fragment key={fName}>{component}</React.Fragment>;
          },
        )}
      </div>
    </Card>
  );
};
