import React from 'react';
import AuditTabHandler from '../../../components/generic/AuditTabHandler';

export const AuditCataloguePage = () => {
  const tabs = [
    {
      tabName: 'navbar.adminCatalogue',
      key: 'adminCatalogue',
    },
    {
      tabName: 'navbar.usageProduct',
      key: 'usageProduct',
    },
    {
      tabName: 'navbar.usageEligibility',
      key: 'usageEligibility',
    },
  ];

  return <AuditTabHandler tabs={tabs} defaultTab={'adminCatalogue'} />;
};

export default AuditCataloguePage;
