import CardDisplay from '../CardDisplay';

const InfoDisplayer = ({ message }) => {
  return (
    <CardDisplay>
      <div className="flex justify-center text-red-600">{message}</div>
    </CardDisplay>
  );
};

export default InfoDisplayer;
