import PropTypes from 'prop-types';
import TextInput from '../../../generic/inputs/TextInput';
import PossessionForm from '../PossessionForm';

const NonPossesionForm = (props) => {
  const { namePrefix } = props;

  return (
    <div className="w-full mb-2">
      <PossessionForm namePrefix={namePrefix} />
      <TextInput name={`${namePrefix}.maxAllowed`} label="Max Allowed" />
    </div>
  );
};

NonPossesionForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default NonPossesionForm;
