import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { SideBar } from 'src/components/generic/Sidebar';
import MessageDisplayer from 'src/components/generic/MessageDisplayer';
import { AlertDisplayer } from 'src/components/generic/AlertDisplayer';
import { ErrorBoundary } from 'src/components/generic/ErrorBoundary';
import { DrawerContainer } from 'src/containers/generic/DrawerContainer';
import { ModalContainer } from 'src/containers/generic/ModalContainer';
import { APP_ROUTES } from 'src/routes/routes';
import { ApiLocator } from 'src/components/apiLocator/ApiLocator';

function AppLayout({ menu }) {
  return (
    <Layout className="min-h-screen bg-slate-100" hasSider>
      <SideBar menu={menu} />
      <Layout className="bg-slate-100">
        <ErrorBoundary>
          <>
            <Layout.Content className="p-4 relative">
              <MessageDisplayer />
              <AlertDisplayer />
              <Outlet />
              <DrawerContainer />
              <ModalContainer />
              <ApiLocator routes={APP_ROUTES} />
            </Layout.Content>
          </>
        </ErrorBoundary>
        <Layout.Footer className="text-center bg-slate-100">
          Catanem &copy;2023
        </Layout.Footer>
      </Layout>
    </Layout>
  );
}

export { AppLayout };
