import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useShowMessage } from '../../useShowMessage';
import { useTranslation } from 'react-i18next';
import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';

export const usePartiallyPublishMutation = ({
  partiallyPublish,
  basePath,
  queryKeysToInvalidate,
  translationPrefix,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSuccess, showError } = useShowMessage();
  const queryClient = useQueryClient();
  const { closeModal } = useModalContext();

  return useMutation(partiallyPublish, {
    onSuccess: (res) => {
      queryKeysToInvalidate.forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey });
      });
      closeModal();
      showSuccess(t(`${translationPrefix}.partiallyPublished`));
      navigate(basePath + '/update/' + res.id);
    },
    onError: (err) => {
      closeModal();
      showError(err);
    },
  });
};
