import modalConfirm from 'src/utils/modalConfirm';
import { useTranslation } from 'react-i18next';
import { mapDataToPublicationConstraints } from 'src/utils/mappers/publicationConstraintsMapper';
import { mapPublicationConstraintsToData } from 'src/utils/mappers/publicationConstraintsMapper/publicationConstraintsMapper';
import { PublicationConstraintsForm } from 'src/components/generic/versioning/PublicationConstraintsForm';

function PublicationConstraintsUpdateModal({
  element,
  updatePublicationConstraints,
}) {
  const { t } = useTranslation();
  const onSubmit = (data) => {
    modalConfirm(t('versioning.updateConstraintsMessage'), () =>
      updatePublicationConstraints({
        functionalId: element.id,
        publicationConstraints: mapDataToPublicationConstraints(data),
      }),
    );
  };

  return (
    <PublicationConstraintsForm
      onSubmit={onSubmit}
      defaultValues={mapPublicationConstraintsToData(
        element.publicationConstraints,
      )}
    />
  );
}

export { PublicationConstraintsUpdateModal };
