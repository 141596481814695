import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import TranslationEn from './locales/en_US/translation.json';
import TranslationFr from './locales/fr_FR/translation.json';
import TranslationAr from './locales/ar/translation.json';

i18n

  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    debug: false,
    fallbackLng: 'en_US',
    resources: {
      en_US: {
        translation: TranslationEn,
      },
      fr_FR: {
        translation: TranslationFr,
      },
      ar: {
        translation: TranslationAr,
      },
    },
  });

export default i18n;
