import { DefaultLocale } from 'src/types/cron/types';

export const DEFAULT_LOCALE_EN: DefaultLocale = {
  everyText: 'every',
  emptyMonths: 'every month',
  emptyMonthDays: 'every day of the month',
  emptyMonthDaysShort: 'day of the month',
  emptyWeekDays: 'every day of the week',
  emptyWeekDaysShort: 'day of the week',
  emptyHours: 'every hour',
  emptyMinutes: 'every minute',
  emptyMinutesForHourPeriod: 'every',
  yearOption: 'year',
  monthOption: 'month',
  weekOption: 'week',
  dayOption: 'day',
  hourOption: 'hour',
  minuteOption: 'minute',
  rebootOption: 'reboot',
  prefixPeriod: 'Every',
  prefixMonths: 'in',
  prefixMonthDays: 'on',
  prefixWeekDays: 'on',
  prefixWeekDaysForMonthAndYearPeriod: 'and',
  prefixHours: 'at',
  prefixMinutes: ':',
  prefixMinutesForHourPeriod: 'at',
  suffixMinutesForHourPeriod: 'minute(s)',
  errorInvalidCron: 'Invalid cron expression',
  clearButtonText: 'Clear',
  weekDays: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  altWeekDays: [
    'SUN', // Sunday must always be first, it's "0"
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
  ],
  altMonths: [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ],
};
export const FRENCH_LOCALE = {
  everyText: 'chaque',
  emptyMonths: 'chaque mois',
  emptyMonthDays: 'chaque jour du mois',
  emptyMonthDaysShort: 'jour du mois',
  emptyWeekDays: 'chaque jour de la semaine',
  emptyWeekDaysShort: 'jour de la semaine',
  emptyHours: 'chaque heure',
  emptyMinutes: 'chaque minute',
  emptyMinutesForHourPeriod: 'chaque',
  yearOption: 'année',
  monthOption: 'mois',
  weekOption: 'semaine',
  dayOption: 'jour',
  hourOption: 'heure',
  minuteOption: 'minute',
  rebootOption: 'redémarrage',
  prefixPeriod: 'Chaque',
  prefixMonths: 'en',
  prefixMonthDays: 'le',
  prefixWeekDays: 'le',
  prefixWeekDaysForMonthAndYearPeriod: 'et',
  prefixHours: 'à',
  prefixMinutes: ':',
  prefixMinutesForHourPeriod: 'à',
  suffixMinutesForHourPeriod: 'minute(s)',
  errorInvalidCron: 'Expression cron invalide',
  clearButtonText: 'Effacer',
  months: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],
  weekDays: [
    'dimanche',
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi',
  ],
  altMonths: [
    'JAN',
    'FÉV',
    'MAR',
    'AVR',
    'MAI',
    'JUN',
    'JUL',
    'AOÛ',
    'SEP',
    'OCT',
    'NOV',
    'DÉC',
  ],
  altWeekDays: ['DIM', 'LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM'],
};
export const ENGLISH_VARIANT_LOCALE = {
  everyText: 'all',
  emptyHours: 'all hours',
  emptyWeekDays: 'all days of the week',
  emptyMonthDays: 'all days of the month',
  emptyMonths: 'all months',
  emptyMinutes: 'all minutes',
  emptyMinutesForHourPeriod: 'all',
  yearOption: 'years',
  monthOption: 'months',
  weekOption: 'weeks',
  dayOption: 'days',
  hourOption: 'hours',
  minuteOption: 'minutes',
  rebootOption: 'reboots',
  prefixPeriod: 'All',
};
export const NO_PREFIX_SUFFIX_LOCALE = {
  prefixPeriod: '',
  prefixMonths: '',
  prefixMonthDays: '',
  prefixWeekDays: '',
  prefixWeekDaysForMonthAndYearPeriod: '',
  prefixHours: '',
  prefixMinutes: '',
  prefixMinutesForHourPeriod: '',
  suffixMinutesForHourPeriod: '',
};
