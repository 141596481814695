import { useFormContext, useFieldArray } from 'react-hook-form';
import { PlusCircleOutlined } from '@ant-design/icons';
import TextInput from 'src/components/generic/inputs/TextInput';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';

export const ScriptFacts = ({ name, label }) => {
  const form = useFormContext();
  const { t } = useTranslation();

  const { append, remove, fields } = useFieldArray({
    control: form.control,
    name,
  });

  return (
    <div className="mt-2">
      {fields.length > 0 && <h4 className="text-lg font-semibold">{label}</h4>}
      <div className="flex flex-col gap-2">
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="flex flex-col justify-center border p-2 rounded-md"
          >
            <div className="flex items-center gap-4">
              <div className="flex-1">
                <TextInput
                  name={`factNames.${index}.name`}
                  label={t('scriptConstraint.fact_name')}
                />
              </div>
            </div>

            <div>
              <TextInput
                name={`factNames.${index}.conditionValue`}
                label={t('scriptConstraint.fact_comment')}
              />
            </div>

            <button
              data-testid={`remove-fact-${index}`}
              type="button"
              onClick={() => remove(index)}
              className="mt-1 text-right text-primary"
            >
              <span>{t('generic.remove')}</span>
            </button>
          </div>
        ))}
      </div>

      <div className="py-2 flex justify-center">
        <Button
          data-testid={'add-fact'}
          onClick={() =>
            append({ name: '', type: 'COMMENT', conditionValue: '' })
          }
        >
          <div className="flex items-center gap-2">
            <PlusCircleOutlined className="text-lg" />
            <p>{t('scriptConstraint.add_fact')}</p>
          </div>
        </Button>
      </div>
    </div>
  );
};
