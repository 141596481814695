import { FolderAddOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDownloadUrl, getImageUploadUrl, uploadFile } from 'src/api';
import { getFieldValueFromPath } from 'src/utils/formUtils';
import UUIDClass from 'uuidjs';

export default function ImageUpload(props) {
  const { name, label } = props;

  const {
    register,
    setValue,
    clearErrors,
    formState: { errors },
    formDisabled,
  } = useFormContext();

  const imageLink = useWatch({ name });

  const error = getFieldValueFromPath(errors, name);
  const { t } = useTranslation();

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    const funcId = UUIDClass.generate();
    const realFileName = fileName.substring(0, fileName.lastIndexOf('.'));
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    const uploadurl = await getImageUploadUrl(
      realFileName + funcId + extension,
      extension,
      funcId,
    );
    await uploadFile(uploadurl.preSignedUrl, file);
    const res = await getDownloadUrl(uploadurl.metaDataId, funcId);
    clearErrors(name);
    setValue(name, res);
  };

  return (
    <div className="mx-auto rounded-lg overflow-hidden min-w-full min-h-full">
      <div className="md:flex">
        <div className="w-full p-1">
          <input {...register(name, {})} type="hidden" value={imageLink} />
          <div
            style={
              imageLink
                ? {
                    backgroundImage: 'url(' + imageLink + ')',
                    backgroundSize: 'cover',
                  }
                : {}
            }
            className={
              'relative border-dotted h-32 rounded-lg border-2 bg-gray-100 flex justify-center items-center' +
              (!!error ? ' border-red-700' : ' border-orange-300')
            }
          >
            <div className="absolute">
              <div className="flex flex-col items-center">
                <FolderAddOutlined className="text-orange-300 text-5xl" />
                <span className="block text-gray-400 font-normal">
                  {t('imageUpload.add')} {label}
                </span>
              </div>
            </div>
            <input
              data-testid="hellotest"
              type="file"
              className="h-full w-full opacity-0"
              name="fileUpload"
              onChange={handleUpload}
              accept=".jpg,.jpeg,.png"
              disabled={formDisabled}
            />
          </div>
        </div>
      </div>
      <div className="text-xs text-red-700 pt-1 pl-3">{error?.message}</div>
    </div>
  );
}

ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
