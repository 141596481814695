import SelectInput from 'src/components/generic/inputs/SelectInput';
import InputConditionalDisplayer from 'src/components/generic/inputs/InputConditionalDisplayer';
import { useTranslation } from 'react-i18next';
import { mapConstraintTypesToOptions } from 'src/utils/mappers/selectOptionsMapper';
import { useGetConstraintTypes } from 'src/hooks/normalHooks/api';

function ConstraintTypesSelect({ name, label, className }) {
  const { t } = useTranslation();
  const {
    data: constraintTypes,
    isLoading: isConstraintTypesLoading,
    isError: isConstraintTypesError,
  } = useGetConstraintTypes();

  return (
    <InputConditionalDisplayer
      isLoading={isConstraintTypesLoading}
      isError={isConstraintTypesError}
    >
      <SelectInput
        name={name}
        label={t(`${label}`)}
        size="large"
        data={mapConstraintTypesToOptions(constraintTypes, t)}
        className={className}
      />
    </InputConditionalDisplayer>
  );
}

export { ConstraintTypesSelect };
