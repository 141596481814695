import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from 'src/utils/mappers/mapperHelpers';

const mapDataToExemptionEvents = (data) => {
  return {
    event: data.event,
    commission: data.commission,
    number: data.number,
    unlimited: data.unlimited,
  };
};
const mapDataToExemptionFormula = (data) => {
  return {
    functionalId: data.functionalId,
    name: mapDataToInternationalisation(data.name),
    description: mapDataToInternationalisation(data.description),
    salable: data.salable,
    details: data.details.map((elem) => mapDataToInternationalisation(elem)),
    exemptionEvents: data.exemptionEvents.map(mapDataToExemptionEvents),
  };
};
const mapExemptionEventsToData = (commission) => {
  return {
    event: commission.event,
    commission: commission.commission,
    number: commission.number,
    unlimited: commission.unlimited,
  };
};
const mapExemptionFormulaToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    name: mapInternationalisationToData(elem.name),
    description: mapInternationalisationToData(elem.description),
    salable: elem.salable,
    details: elem.details.map((elem) => mapInternationalisationToData(elem)),
    exemptionEvents: (elem.exemptionEvents || []).map(mapExemptionEventsToData),
  };
};
export const mapDataToExemption = (data) => {
  const exemptionFormulas = (data.exemptionFormulas || []).map((elem) =>
    mapDataToExemptionFormula(elem),
  );
  return {
    exemptionFormulas,
    type: 'exemption',
  };
};

export const mapExemptionToData = (exemption) => {
  return {
    exemptionFormulas: (exemption.exemptionFormulas || []).map((formule) =>
      mapExemptionFormulaToData(formule),
    ),
  };
};
