import { useFormContext } from 'react-hook-form';

export default function ComponentDisabler({ componentRender }) {
  const { props, type: Component } = componentRender();
  const { formDisabled } = useFormContext();

  return (
    <Component
      {...props}
      disabled={formDisabled || (props?.disabled ?? false)}
    />
  );
}
