import PropTypes from 'prop-types';

const EmptyResult = ({ mainMessage, secondaryMessage }) => {
  return (
    <div className="flex flex-col items-center justify-center bg-white p-9">
      <div className="inline-flex rounded-full bg-orange-100 p-4">
        <div className="rounded-full stroke-red-600 bg-orange-200 p-4">
          <svg
            className="w-12 h-12"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 8H6.01M6 16H6.01M6 12H18C20.2091 12 22 10.2091 22 8C22 5.79086 20.2091 4 18 4H6C3.79086 4 2 5.79086 2 8C2 10.2091 3.79086 12 6 12ZM6 12C3.79086 12 2 13.7909 2 16C2 18.2091 3.79086 20 6 20H14"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M17 16L22 21M22 16L17 21"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </div>
      </div>
      <div className="mt-3 font-semibold tracking-wider text-sm lg:text-lg text-gray-600">
        {mainMessage}
      </div>
      <div className="font-normal tracking-widest text-[10px] lg:text-sm text-gray-500">
        {secondaryMessage}
      </div>
    </div>
  );
};

export default EmptyResult;

EmptyResult.propTypes = {
  mainMessage: PropTypes.string,
  secondaryMessage: PropTypes.string,
};
