import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllProductsByCriteria } from 'src/api';
import {
  PRODUCT_FILTER_FIELDS,
  filterParamMapedKeys,
  filterListTypeKeys,
} from 'src/utils/filterFields';
import CustomPagination from 'src/components/generic/CustomPagination';
import ProductCard from 'src/components/product/listing/ProductCard';
import GenericFilter from 'src/components/generic/GenericFilter';
import { PRODUCT_ADD_PATH } from 'src/routes/paths';
import { Error500 } from '../../error';
import Loading from 'src/components/generic/Loading';
import {
  mapParamsToFilter,
  mapFilterToUrl,
} from 'src/utils/mappers/filterMapper';
import { USER_ROLES } from 'src/utils/constants';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';

const ELEMENT_PER_PAGE = 21;

export const ProductListingPage = () => {
  const [page, setPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState({});
  const { t } = useTranslation();
  const { state, pathname, search } = useLocation();
  const navigate = useNavigate();

  // Extract query parameters from the URL
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const { data, isLoading, isError } = useQuery(
    ['get-products', page, currentFilter],
    () => getAllProductsByCriteria(page - 1, ELEMENT_PER_PAGE, currentFilter),
  );

  useEffect(() => {
    if (state?.toastContent) toast.success(state?.toastContent);
    const filter = mapParamsToFilter(
      queryParams,
      filterParamMapedKeys,
      filterListTypeKeys,
    );

    setCurrentFilter(filter);
    setPage(1);
  }, [state, queryParams]);

  const onFilter = (param) => {
    const paramsString = mapFilterToUrl(param || {}, filterParamMapedKeys); // Get the query string
    const newUrl = `${pathname}?${paramsString}`; // Construct the new URL
    navigate(newUrl, { replace: true });
  };
  if (isError) return <Error500 />;

  return (
    <>
      <GenericFilter
        onFilter={onFilter}
        initialFilterValues={currentFilter}
        emptyFilterValues={{}}
        filterFields={PRODUCT_FILTER_FIELDS}
        disableSubmit={isLoading}
        numberElementsPerRow={5}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <div className="w-full flex flex-col px-5">
          <div className="flex items-end mb-4 mr-2 justify-between">
            <p className="mx-2">
              {data?.totalElements[0]?.totalElements
                ? data?.totalElements[0]?.totalElements +
                  ' ' +
                  t('search.numberProductsFound')
                : t('search.noResultFound')}
            </p>
            <div>
              <Link to={PRODUCT_ADD_PATH}>
                <AuthComponentDisabler
                  componentRender={() => (
                    <Button
                      type="primary"
                      className="bg-orange-500 text-white flex items-center disabled:opacity-30"
                    >
                      <PlusOutlined sx={{ fontSize: '20px' }} /> {t('crud.add')}{' '}
                      {t('subnav.product')}
                    </Button>
                  )}
                  roles={[USER_ROLES.EDIT_PRODUCTS]}
                />
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-7 gap-4 w-full mb-2">
            {data.content.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>

          <CustomPagination
            current={page}
            total={data?.totalElements[0]?.totalElements}
            pageSize={ELEMENT_PER_PAGE}
            onChange={(value) => setPage(value)}
          />
        </div>
      )}
    </>
  );
};
export default ProductListingPage;
