import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import ProductOffer from '../ProductOffer/ProductOffer';

const OfferFormulaForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]} className="mb-2">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('offerFormula.functionalId')}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.label`}
          label={t('offerFormula.label')}
        />
      </Col>
      <Col span={24}>
        <ProductOffer namePrefix={namePrefix} />
      </Col>
    </Row>
  );
};

export default OfferFormulaForm;
