import { useTranslation } from 'react-i18next';
import { EmptyState } from 'src/components/generic/EmptyState';
import {
  cancelFactResetToZeroScheduledBatch,
  getBatchResetToZeroLaunchDates,
} from 'src/api/facts';
import { useQuery, useQueryClient } from 'react-query';
import CustomTable from 'src/components/generic/Table/CustomTable';
import { mapScheduledBatchDtoToTableData } from 'src/utils';
import React from 'react';
import { CRUD_ACTIONS } from 'src/components/generic/Table/CustomTable/CustomTable';
import EmptyResult from 'src/components/generic/EmptyResult';
import { resolveErrorMessage } from 'src/utils/errorUtils';
import modalConfirm from 'src/utils/modalConfirm';
import { useUpdateMutation } from 'src/hooks/normalHooks/mutation/useUpdateMutation';
import { TECHNICAL_SETTING_PATH } from 'src/routes/paths';
import { useShowMessage } from 'src/hooks/normalHooks/useShowMessage';
import { useNavigate } from 'react-router-dom';
import { batchSchedulingColumns } from 'src/utils/tableColumns';
import { batchSchedulingQueryKeys } from 'src/utils/constants/queryKeys';
import PropTypes from 'prop-types';

function BatchSchedulingDateTable({
  currentPage,
  setCurrentPage,
  currentStatus,
}) {
  const { t } = useTranslation();
  const ELEMENTS_PER_PAGE = 5;
  const { showSuccess } = useShowMessage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  function isNotScheduled(batch) {
    return batch.batchStatus !== 'SCHEDULED';
  }

  const tagColorMapping = [
    { status: 'CANCELED', color: 'volcano' },
    { status: 'SCHEDULED', color: 'gold' },
    { status: 'COMPLETED', color: 'green' },
  ];

  function getColorByStatus(status) {
    const mapping = tagColorMapping.find(
      (item) =>
        t(`factTechnicalSetting.batch.resetZero.status.${item.status}`) ===
        status,
    );
    return mapping ? mapping.color : 'geekblue';
  }

  const BatchCancelMutation = useUpdateMutation({
    queryKey: [batchSchedulingQueryKeys.getDatesScheduling],
    update: (id) => cancelFactResetToZeroScheduledBatch(id),
    onSuccess: async () => {
      showSuccess(t('factTechnicalSetting.batch.resetZero.cancel'));
      navigate(TECHNICAL_SETTING_PATH);
      await queryClient.invalidateQueries({
        queryKey: [batchSchedulingQueryKeys.getDatesScheduling],
      });
    },
  });

  const {
    data: ScheduledBatchData,
    isLoading: isScheduledBatchLoading,
    isError: isScheduledBatchError,
    error: scheduledBatchError,
  } = useQuery(
    [
      batchSchedulingQueryKeys.getDatesScheduling,
      currentPage,
      ELEMENTS_PER_PAGE,
      currentStatus,
    ],
    () =>
      getBatchResetToZeroLaunchDates(
        currentPage - 1,
        ELEMENTS_PER_PAGE,
        currentStatus,
      ),
  );

  if (isScheduledBatchError) return <EmptyState />;

  const actions = [
    {
      key: CRUD_ACTIONS.CANCEL,
      onAction: (id) => {
        modalConfirm(
          t('factTechnicalSetting.batch.resetZero.cancelMessage'),
          () => BatchCancelMutation.mutate(id),
        );
      },
      isHidden: (id) =>
        isNotScheduled(
          ScheduledBatchData?.content.find((data) => data.id === id),
        ),
    },
  ];

  return (
    <div className="flex flex-col px-5">
      {isScheduledBatchError ? (
        <EmptyResult
          mainMessage={resolveErrorMessage(
            scheduledBatchError?.response?.status,
          )}
        />
      ) : (
        <CustomTable
          columns={batchSchedulingColumns(t)}
          data={mapScheduledBatchDtoToTableData(ScheduledBatchData, t)}
          actions={actions}
          pagination={{
            current: currentPage,
            pageSize: ELEMENTS_PER_PAGE,
            total: ScheduledBatchData?.totalElements,
            onChange: (page) => setCurrentPage(page),
          }}
          isLoading={isScheduledBatchLoading}
          getTagColor={(status) => {
            return getColorByStatus(status);
          }}
        />
      )}
    </div>
  );
}

BatchSchedulingDateTable.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  currentStatus: PropTypes.string,
};
export { BatchSchedulingDateTable };
