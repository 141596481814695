import React from 'react';
import CardDisplay from 'src/components/generic/CardDisplay';
import AdditionalAttribute from '../../generic/AdditionalAttribute';
import OfferFormula from '../OfferFormula/OfferFormula';
import { useTranslation } from 'react-i18next';

export default function OfferInfo() {
  const { t } = useTranslation();

  return (
    <CardDisplay title={t('offerFormula.info')}>
      <OfferFormula />
      <AdditionalAttribute />
    </CardDisplay>
  );
}
