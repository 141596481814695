import { Button, Select } from 'antd';
import React from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

function BatchSchedulingHeader({
  setOpen,
  handleChangeDateSchedulingStatus,
  defaultValueDateSchedulingStatus,
  dateSchedulingStatus,
  schedulingTypes,
  defaultValueCurrentType,
  handleChangeTypeScheduling,
  defaultCronSchedulingStatus,
  cronStatus,
  handleChangeCronStatus,
}) {
  const { t } = useTranslation();
  return (
    <div className="flex md:flex-row md:gap-8 gap-2 flex-col my-6 ">
      <Select
        className="min-w-[200px]"
        onChange={handleChangeTypeScheduling}
        defaultValue={defaultValueCurrentType}
      >
        {Object.keys(schedulingTypes)?.map((value) => (
          <Option key={value} value={value}>
            {t(`factTechnicalSetting.batch.resetZero.typeScheduling.${value}`)}
          </Option>
        ))}
      </Select>
      {defaultValueCurrentType !== schedulingTypes.CRON ? (
        <Select
          className="min-w-[200px]"
          onChange={handleChangeDateSchedulingStatus}
          value={defaultValueDateSchedulingStatus}
        >
          {Object.keys(dateSchedulingStatus)?.map((value) => (
            <Option key={value} value={value}>
              {t(`factTechnicalSetting.batch.resetZero.status.${value}`)}
            </Option>
          ))}
        </Select>
      ) : (
        <Select
          className="min-w-[200px]"
          onChange={handleChangeCronStatus}
          value={defaultCronSchedulingStatus}
        >
          {Object.keys(cronStatus)?.map((value) => (
            <Option key={value} value={value}>
              {t(`factTechnicalSetting.batch.resetZero.status.${value}`)}
            </Option>
          ))}
        </Select>
      )}

      <Button
        type="primary"
        className="bg-orange-500 text-white flex items-center"
        onClick={() => {
          setOpen(true);
        }}
      >
        <ClockCircleOutlined />
        {t('factTechnicalSetting.batch.resetZero.add')}
      </Button>
    </div>
  );
}

BatchSchedulingHeader.propTypes = {
  setOpen: PropTypes.func,
  handleChangeDateSchedulingStatus: PropTypes.func,
  defaultValueDateSchedulingStatus: PropTypes.string,
  dateSchedulingStatus: PropTypes.object,
  schedulingTypes: PropTypes.object,
  defaultValueCurrentType: PropTypes.string,
  handleChangeTypeScheduling: PropTypes.func,
  defaultCronSchedulingStatus: PropTypes.string,
  cronStatus: PropTypes.object,
  handleChangeCronStatus: PropTypes.func,
};
export { BatchSchedulingHeader };
