import CardDisplay from '../../../../generic/CardDisplay';
import AdditionalAttribute from '../../generic/AdditionalAttribute';
import Option from '../../generic/Option';
import { useTranslation } from 'react-i18next';

const Bancass = () => {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t('bancass.info')}>
      <Option />
      <AdditionalAttribute />
    </CardDisplay>
  );
};

export default Bancass;
