import { Table } from 'antd';

export default function ExpandedRowRender(props) {
  if (!props) return null;
  const columns = Object.keys(props.facts).map((key) => ({
    title: key,
    dataIndex: key,
  }));
  return (
    <Table
      bordered
      pagination={false}
      columns={columns}
      dataSource={[{ ...props.facts, key: 1 }]}
      rowClassName="editable-row"
    />
  );
}
