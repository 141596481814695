import HttpService from 'src/services/HttpService';
import {
  AUDIT_STATISTICS_PATH,
  AUDITABLE_ACTIONS_PATH,
} from 'src/api/apiPaths';
import { API_NAMES } from 'src/utils/constants';

const instance = HttpService.getAxiosClient(API_NAMES.CATALOG);
const ELIGIBILITY_PATH = '/eligibility';
const PRODUCT_PATH = '/product';
const TOP5_CONSTRAINTS_PATH = '/top-constraints';
const TOP_ACTS_PATH = '/top-acts';

export const getEligibilityStatisticsByFilters = async (filter) => {
  const params = {
    familyId: filter.family,
    canal: filter.canal,
    act: filter.act,
    productId: filter.product,
    action: filter.auditableAction,
  };
  const res = await instance.get(AUDIT_STATISTICS_PATH + ELIGIBILITY_PATH, {
    params,
  });
  return res.data;
};

export const getProductStatisticsByFilter = async (filter) => {
  const params = {
    familyId: filter.family,
    canal: filter.canal,
    act: filter.act,
    productId: filter.product,
    action: filter.auditableAction,
  };
  const res = await instance.get(AUDIT_STATISTICS_PATH + PRODUCT_PATH, {
    params,
  });
  return res.data;
};

export const getCatalogAuditableActions = async (domain) => {
  const params = {
    type: domain,
  };
  const res = await instance.get(
    AUDIT_STATISTICS_PATH + AUDITABLE_ACTIONS_PATH,
    {
      params,
    },
  );
  return res.data;
};

export const getTop5ConstraintsCatalog = async () => {
  const res = await instance.get(
    AUDIT_STATISTICS_PATH + TOP5_CONSTRAINTS_PATH,
    {},
  );
  return res.data;
};

export const getTop5CatalogActs = async (number) => {
  const res = await instance.get(AUDIT_STATISTICS_PATH + TOP_ACTS_PATH, {
    params: {
      numberOfTopActs: number,
    },
  });
  return res.data;
};
