import modalConfirm from 'src/utils/modalConfirm';
import { PUBLICATION_STATUS } from 'src/utils/constants/publicationStatus';
import React from 'react';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { useTranslation } from 'react-i18next';
import { PublicationConstraintsAddModal } from 'src/components/generic/versioning/PublicationConstraintsAddModal';
import getUserRolesByTypeAndResource, {
  isAuthorized,
} from 'src/utils/authUtils';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';
import { PublicationSchedulingModal } from 'src/components/generic/versioning/PublicationSchedulingModal';

function DropdownPublicationButtonRenderer({
  element,
  resource,
  publishQuery,
  partiallyPublishQuery,
  publicationSchedulingQuery,
}) {
  const { userContext, currentApi } = useUserContext();
  const { openModal } = useModalContext();
  const { t } = useTranslation();
  const items = [
    {
      label: t('versioning.publish'),
      key: '1',
      disabled: !isAuthorized(
        userContext[currentApi],
        getUserRolesByTypeAndResource('PUBLISH', resource),
      ),
    },
    {
      label: t('versioning.partiallyPublish'),
      key: '2',
      disabled: !isAuthorized(
        userContext[currentApi],
        getUserRolesByTypeAndResource('PARTIALLY_PUBLISH', resource),
      ),
    },
    {
      label: t('versioning.schedulePublication'),
      key: '3',
      disabled: !isAuthorized(
        userContext[currentApi],
        getUserRolesByTypeAndResource('SCHEDULE_PUBLICATION', resource),
      ),
    },
  ];
  const handleItemClick = (e) => {
    switch (e.key) {
      case items[0].key:
        modalConfirm(t('versioning.publishMessage'), () =>
          publishQuery(element.id),
        );
        break;
      case items[1].key:
        openModal({
          options: {
            width: '60%',
          },
          body: (
            <PublicationConstraintsAddModal
              element={element}
              partiallyPublishQuery={partiallyPublishQuery}
            />
          ),
        });
        break;
      case items[2].key:
        openModal({
          options: {
            width: '40%',
            centered: true,
          },
          body: (
            <PublicationSchedulingModal
              element={element}
              publicationSchedulingQuery={publicationSchedulingQuery}
            />
          ),
        });
        break;
    }
  };

  return (
    <Dropdown
      menu={{ items, onClick: handleItemClick }}
      placement="bottom"
      disabled={items.filter((item) => !item.disabled).length === 0}
    >
      <Button type="primary" ghost data-testid="publish-dropdown-button">
        <Space>
          {t('versioning.publish')}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}

function GeneralizeButtonRenderer({ element, resource, publishQuery }) {
  const { t } = useTranslation();
  return (
    <AuthComponentDisabler
      componentRender={() => (
        <Button
          data-testid="generalize-button"
          type="primary"
          ghost
          onClick={() => {
            modalConfirm(t('versioning.generalizeMessage'), () =>
              publishQuery(element.id),
            );
          }}
        >
          {t('versioning.generalize')}
        </Button>
      )}
      roles={getUserRolesByTypeAndResource('PUBLISH', resource)}
    />
  );
}

function CancelSchedulingButtonRenderer({
  element,
  resource,
  cancelSchedulingQuery,
}) {
  const { t } = useTranslation();
  return (
    <AuthComponentDisabler
      componentRender={() => (
        <Button
          data-testid="cancel-button"
          type="primary"
          ghost
          onClick={() => {
            modalConfirm(t('versioning.cancelPlanificationMessage'), () =>
              cancelSchedulingQuery(element.id),
            );
          }}
        >
          {t('versioning.cancelPlanification')}
        </Button>
      )}
      roles={getUserRolesByTypeAndResource('SCHEDULE_PUBLICATION', resource)}
    />
  );
}

function PublishButtonRenderer({ element, resource, publishQuery }) {
  const { t } = useTranslation();
  return (
    <AuthComponentDisabler
      componentRender={() => (
        <Button
          data-testid="publish-button"
          type="primary"
          ghost
          onClick={() => {
            modalConfirm(t('versioning.publishMessage'), () =>
              publishQuery(element.id),
            );
          }}
        >
          {t('versioning.publish')}
        </Button>
      )}
      roles={getUserRolesByTypeAndResource('PUBLISH', resource)}
    />
  );
}

const PUBLISH_BUTTON_RESOLVER = [
  {
    id: 'generalize-btn',
    handler: ({ element }) => {
      return element.status === PUBLICATION_STATUS.PARTIALLY_PUBLISHED;
    },
    buttonRenderer: ({ element, resource, publishQuery }) => (
      <GeneralizeButtonRenderer
        element={element}
        resource={resource}
        publishQuery={publishQuery}
      />
    ),
  },
  {
    id: 'cancel-scheduling-btn',
    handler: ({ element }) => {
      return element.status === PUBLICATION_STATUS.SCHEDULED;
    },
    buttonRenderer: ({ element, resource, cancelSchedulingQuery }) => (
      <CancelSchedulingButtonRenderer
        element={element}
        resource={resource}
        cancelSchedulingQuery={cancelSchedulingQuery}
      />
    ),
  },
  {
    id: 'publication-dropdown-btn',
    handler: ({ element }) => {
      return element.status === PUBLICATION_STATUS.DRAFT;
    },
    buttonRenderer: ({
      element,
      resource,
      publishQuery,
      partiallyPublishQuery,
      publicationSchedulingQuery,
    }) => (
      <DropdownPublicationButtonRenderer
        element={element}
        publishQuery={publishQuery}
        partiallyPublishQuery={partiallyPublishQuery}
        resource={resource}
        publicationSchedulingQuery={publicationSchedulingQuery}
      />
    ),
  },
  {
    id: 'publish-btn',
    handler: () => {
      return false;
    },
    buttonRenderer: ({ element, resource, publishQuery }) => (
      <PublishButtonRenderer
        element={element}
        resource={resource}
        publishQuery={publishQuery}
      />
    ),
  },
];

function PublishButton({
  element,
  publishQuery,
  partiallyPublishQuery,
  publicationSchedulingQuery,
  cancelSchedulingQuery,
  resource,
  isPublicationTypesSupported = true,
}) {
  const { userContext, currentApi } = useUserContext();

  if (
    !isPublicationTypesSupported &&
    element.status === PUBLICATION_STATUS.DRAFT
  ) {
    const content = PUBLISH_BUTTON_RESOLVER.find(
      ({ id }) => id === 'publish-btn',
    ).buttonRenderer({
      element,
      resource,
      publishQuery,
    });
    return <>{content}</>;
  }

  return PUBLISH_BUTTON_RESOLVER.map((item) => {
    const handler = item.handler({ element, userContext, currentApi });
    if (!handler) {
      return null;
    }

    return (
      <div key={item.id}>
        {item.buttonRenderer({
          element,
          resource,
          publishQuery,
          partiallyPublishQuery,
          publicationSchedulingQuery,
          cancelSchedulingQuery,
        })}
      </div>
    );
  }).filter((v) => !!v);
}

export { PublishButton };
