import { Button, Table } from 'antd';
import ExpandedRowRender from '../ExpandedRowRender';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import modalConfirm from '../../../../utils/modalConfirm';
import { deleteFactCollection } from '../../../../api/facts';

const FactTable = ({ t, isLoading, data, refetch, navigate }) => {
  const columns = [
    {
      title: t('FactCollection.clientId'),
      dataIndex: 'clientId',
    },
    {
      title: t('FactCollection.accountId'),
      dataIndex: 'accountId',
    },
    {
      title: t('FactCollection.contractId'),
      dataIndex: 'contractId',
    },
    {
      title: t('FactCollection.personId'),
      dataIndex: 'personId',
    },
    {
      title: t('FactCollection.operation'),
      dataIndex: 'operation',
      render: (_, record) => {
        return (
          <div className="flex flex-row gap-5 justify-items-start">
            <Button
              className="flex items-center mb-2"
              icon={<EditOutlined style={{ fontSize: '18px' }} />}
              ghost
              type="primary"
              onClick={() => navigate('/facts/update/' + record.id)}
            >
              {t('record.update')}
            </Button>
            <Button
              className="flex items-center"
              icon={<DeleteOutlined style={{ fontSize: '18px' }} />}
              ghost
              danger
              onClick={() => {
                modalConfirm(t('record.warnMessage'), () => {
                  deleteFactCollection(record.id).then(refetch);
                });
              }}
            >
              {t('record.delete')}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      bordered
      scroll={{ x: 100 }}
      loading={isLoading}
      pagination={{ showSizeChanger: true }}
      columns={columns}
      dataSource={data?.map((elem) => ({
        ...elem,
        key: elem.id,
      }))}
      rowClassName="editable-row"
      expandable={{
        expandedRowRender: (props) => ExpandedRowRender(props),
        defaultExpandedRowKeys: ['0'],
      }}
    />
  );
};

export default FactTable;
