export const mapDataToProfile = (data) => {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    roles: data.roles,
  };
};

export const mapProfileToData = (profile) => {
  return {
    key: profile.id,
    id: profile.id,
    name: profile.name,
    description: profile.description,
    roles: profile.roles,
  };
};

export const mapProfilesToData = (profiles) => {
  if (!profiles) {
    return [];
  }
  return profiles.map((profile) => mapProfileToData(profile));
};
