import React from 'react';
import PropTypes from 'prop-types';
import { PublishButton } from 'src/components/generic/versioning/VersioningButtons/PublishButton';
import { UnPublishButton } from 'src/components/generic/versioning/VersioningButtons/UnPublishButton';
import { UpdatePublicationConstraintsButton } from 'src/components/generic/versioning/VersioningButtons/UpdatePublicationConstraintsButton';
import { CreateDraftButton } from 'src/components/generic/versioning/VersioningButtons/CreateDraftButton';

const VersioningButtons = (props) => {
  const {
    element,
    createDraft,
    publishDraft,
    unpublishDraft,
    partiallyPublishDraft,
    updatePublicationConstraints,
    schedulePublication,
    cancelSchedulingQuery,
    updateUrl,
    resource,
    isPublicationTypesSupported = true,
  } = props;
  return (
    <div className="flex gap-5 mr-5">
      <PublishButton
        element={element}
        partiallyPublishQuery={partiallyPublishDraft}
        publishQuery={publishDraft}
        publicationSchedulingQuery={schedulePublication}
        resource={resource}
        cancelSchedulingQuery={cancelSchedulingQuery}
        isPublicationTypesSupported={isPublicationTypesSupported}
      />

      <CreateDraftButton
        element={element}
        resource={resource}
        createDraft={createDraft}
        updateUrl={updateUrl}
      />

      <UnPublishButton
        element={element}
        unpublishDraft={unpublishDraft}
        updateUrl={updateUrl}
        resource={resource}
      />

      <UpdatePublicationConstraintsButton
        element={element}
        updatePublicationConstraints={updatePublicationConstraints}
        resource={resource}
        canPartiallyPublish={isPublicationTypesSupported}
      />
    </div>
  );
};

VersioningButtons.protoTypes = {
  element: PropTypes.any,
  createDraft: PropTypes.func,
  publishDraft: PropTypes.func,
  unpublishDraft: PropTypes.func,
  partiallyPublishDraft: PropTypes.func,
  updatePublicationConstraints: PropTypes.func,
  updateUrl: PropTypes.oneOf([PropTypes.func, PropTypes.string]),
  resource: PropTypes.string,
  canSchedulePublication: PropTypes.bool,
};
export default VersioningButtons;
