import { Col, Row } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import SelectInput from '../../../generic/inputs/SelectInput';
import { getTargetTypes } from '../../../../utils/getTargetTypes';
import { AimOutlined } from '@ant-design/icons';
import Targets from '../../../eventPricing/Targets';
import TextInput from '../../../generic/inputs/TextInput';
import DocumentAttachment from '../../../product/form/generic/DocumentAttachment';
import DevelopedDetails from '../../../product/form/generic/DevelopedDetails';

const RecommendationSpecificInfo = ({ t }) => {
  const { control } = useFormContext();

  const type = useWatch({
    control,
    name: 'recommendationType',
  });
  return (
    <div>
      {type == 'product' ? (
        <Row gutter={[16, 12]}>
          <Col span={12}>
            <SelectInput
              name="targetType"
              label={t('eligibleAct.targetType')}
              data={getTargetTypes()}
              startAdornment={<AimOutlined style={{ fontSize: '25px' }} />}
            />
          </Col>
          <Col span={12}>
            <Targets namePrefix="recomended" withOptions={false} />
          </Col>
        </Row>
      ) : (
        <div>
          <Row gutter={[16, 12]}>
            <Col span={24}>
              <TextInput
                name="codeAction"
                label={t('recommendation.codeAction')}
              />
            </Col>
            <Col span={24}>
              <DocumentAttachment />
            </Col>
            <Col span={24}>
              <DevelopedDetails />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
export default RecommendationSpecificInfo;
