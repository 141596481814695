import * as yup from 'yup';

export const filtersSchema = yup.object().shape({
  dateRange: yup
    .array()
    .test('date range validation', 'range_exceeded_limit', function (value) {
      if (!value) {
        return true;
      }
      if (value.length < 2) {
        return false;
      }

      const [startDate, endDate] = value;

      return endDate.diff(startDate, 'days') < 31;
    })
    .nullable(),
});
