import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import MotifsWithConstraintsForm from '../MotifsWithConstraintsForm/MotifsWithConstraintsForm';
import { MOTIF_WITH_CONSTRAINTS_DEFAULT } from 'src/utils/defaultsSupplier';

const MotifsWithConstraints = (props) => {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={namePrefix ? `${namePrefix}.motifList` : 'motifList'}
      translationPrefix="motifs"
      Form={MotifsWithConstraintsForm}
      defaultValues={MOTIF_WITH_CONSTRAINTS_DEFAULT}
      fieldsNames={['functionalId', 'label']}
    />
  );
};

MotifsWithConstraints.propTypes = {
  namePrefix: PropTypes.string,
};

export default MotifsWithConstraints;
