import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes/AppRoutes';
import frFR from 'antd/locale/fr_FR';
import ar_EG from 'antd/locale/ar_EG';
import en_US from 'antd/locale/en_US';
import { useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import Loading from './components/generic/Loading';
import i18n from 'src/i18n';
import { UserContextProvider } from 'src/contexts/userContext';
import { YupLocaleProvider } from 'src/providers/YupLocaleProvider';
import { ErrorBoundary } from 'src/components/generic/ErrorBoundary/ErrorBoundary';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from 'src/contexts/authContext';

function App() {
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage,
  );
  const locale =
    selectedLang === 'ar' ? ar_EG : selectedLang === 'en_US' ? en_US : frFR;

  return (
    <React.Suspense fallback={<Loading />}>
      <BrowserRouter>
        <ErrorBoundary>
          <ConfigProvider
            locale={locale}
            theme={{
              token: {
                colorPrimary: '#fb923c',
              },
            }}
            direction={selectedLang === 'ar' ? 'rtl' : 'ltr'}
          >
            <AuthProvider>
              <UserContextProvider>
                <YupLocaleProvider i18n={i18n}>
                  <AppRoutes />
                  <ToastContainer hideProgressBar />
                </YupLocaleProvider>
              </UserContextProvider>
            </AuthProvider>
          </ConfigProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
