import { IMap, ParameterDTO } from 'src/types';

export const ADMIN_CANAL = 'ADMIN';

export const groupParametersByFunctionalId = (params?: ParameterDTO[]) => {
  const result: IMap<string, ParameterDTO> = {};
  if (!params) {
    return result;
  }

  params.forEach((lang) => {
    result[lang.functionalId] = lang;
  });
  return result;
};
