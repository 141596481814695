import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Form from 'src/components/generic/Form/Form';
import { useShowMessage } from 'src/hooks/normalHooks/useShowMessage';
import { TECHNICAL_SETTING_PATH } from 'src/routes/paths';
import PropTypes from 'prop-types';
import { scheduleResetToZeroCronBatch } from 'src/api/facts';
import { useAddMutation } from 'src/hooks/normalHooks/mutation/useAddMutation';
import { mapUnixCronToQuartzFormat } from 'src/utils/cronUtils/cronUtils';
import { CountFactNamesSelector } from 'src/components/administration/technicalSetting/factTechnicalSettings/countFactsNamesSelector';
import { PERIODICITY } from 'src/utils/constants';
import { BATCH_CRON_SCHEDULING_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/factCollectorSchema';
import { batchSchedulingQueryKeys } from 'src/utils/constants/queryKeys';
import { CronFormInput } from 'src/components/administration/technicalSetting/factTechnicalSettings/batchSchedulingForm/cronSchedulingForm/cronFormInput';

export function CronSchedulingForm(props) {
  const { t } = useTranslation();
  const { close } = props;
  const { showSuccess } = useShowMessage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentPeriodicity, setCurrentPeriodicity] = useState(
    PERIODICITY.DAILY,
  );

  const cronSchedulingAddMutation = useAddMutation({
    add: (data) => scheduleResetToZeroCronBatch(data),
    onSuccess: async () => {
      showSuccess(
        t('factTechnicalSetting.batch.resetZero.planificationSuccess'),
      );
      navigate(TECHNICAL_SETTING_PATH);
      await queryClient.invalidateQueries({
        queryKey: [batchSchedulingQueryKeys.getCronScheduling],
      });
      close();
    },
    basePath: TECHNICAL_SETTING_PATH,
  });
  const onSubmit = (data) => {
    data = {
      ...data,
      cronExpression: mapUnixCronToQuartzFormat(data.cronExpression),
    };
    cronSchedulingAddMutation.mutate(data);
  };
  return (
    <Form
      onSubmit={onSubmit}
      onCancel={close}
      buttonsOnTop={false}
      validationSchema={BATCH_CRON_SCHEDULING_VALIDATION_SCHEMA}
    >
      <div className={'flex flex-col gap-3 mb-4'}>
        <CountFactNamesSelector
          currentPeriodicity={currentPeriodicity}
          setCurrentPeriodicity={setCurrentPeriodicity}
          name={'counts'}
        />
        <CronFormInput
          currentPeriodicity={currentPeriodicity}
          name={'cronExpression'}
        />
      </div>
    </Form>
  );
}

CronSchedulingForm.propTypes = {
  close: PropTypes.func,
};
