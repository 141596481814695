import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Loading from 'src/components/generic/Loading';
import { AuthorizationAlertDispatcher } from 'src/components/wrapper/authorization/authorizationAlertDispatcher/AuthorizationAlertDispatcher';
import { useCheckSuperAdminRole } from 'src/utils/authUtils';

const AuthorizationWrapper = (props) => {
  const { requiredRoles, children, apis, renderOnUnauthorized } = props;
  const { userContext, setCurrentApi, isLoading } = useUserContext();
  const { isSuperAdmin } = useCheckSuperAdminRole();
  const allRoles = apis?.flatMap((api) => userContext[api]?.roles);
  const missingRoles = requiredRoles?.filter(
    (role) => !allRoles?.includes(role),
  );

  const isAuthorized = Boolean(
    requiredRoles?.length === 0 ||
      isSuperAdmin ||
      allRoles?.find((role) => requiredRoles?.includes(role)),
  );

  useEffect(() => {
    if (apis?.length === 1) setCurrentApi(apis[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apis]);

  if (isLoading) return <Loading />;
  return isAuthorized ? (
    <>
      <AuthorizationAlertDispatcher missingRoles={missingRoles} />
      {children}
    </>
  ) : (
    renderOnUnauthorized()
  );
};

AuthorizationWrapper.propTypes = {
  requiredRoles: PropTypes.array.isRequired,
  apis: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  renderOnUnauthorized: PropTypes.func.isRequired,
};

export default AuthorizationWrapper;
