import { getCorrectLabelForAdmin } from 'src/utils/internationalisationUtils';

export function getBancassNestedOptions(selectedProduct, selectedLang) {
  const res = [];
  selectedProduct.options?.forEach((option) => {
    res.push({
      functionalId: option.functionalId,
      name:
        option.functionalId +
        ' - ' +
        getCorrectLabelForAdmin(option.label, selectedLang),
    });
  });
  return res;
}
