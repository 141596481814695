import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from '../mapperHelpers';

export const mapDataToConstraint = (data) => {
  const constraint = { ...data, type: data.constraintType };
  delete constraint.constraintType;
  return constraint;
};

export const mapConstraintToData = (constraint) => {
  const data = { ...constraint, constraintType: constraint.type };
  delete data.type;
  return data;
};
export const mapDataToConstraints = (constraints) => {
  return constraints.map((elem) => {
    return elem.map((constraint) => {
      return {
        ...constraint,
        errors: mapDataToInternationalisation(constraint.errors),
      };
    });
  });
};

export const mapConstraintsToData = (constraints) => {
  return constraints.map((elem) => {
    elem.map((constraint) => {
      if (Array.isArray(constraint.errors)) {
        constraint.errors = mapInternationalisationToData(constraint.errors);
      }
    });

    return { content: elem };
  });
};

export function mapFormConstraintsToConstraintSettings(constraints) {
  return constraints.map((elem) => {
    return elem.content.map((constraint) => {
      return {
        ...constraint,
        errors: mapDataToInternationalisation(constraint.errors),
      };
    });
  });
}
