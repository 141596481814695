import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import ProductOptionPair from '../ProductOptionPair/ProductOptionPair';
import InternationalizationArrayInput from '../../../../generic/inputs/InternationalizationArrayInput';

const ProductOfferForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]} className="mb-2">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('productOffer.functionalId')}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.label`}
          label={t('productOffer.label')}
        />
      </Col>
      <Col span={24}>
        <SwitchInput
          name={`${namePrefix}.optional`}
          label={t('productOffer.optional')}
        />
      </Col>
      <Col span={24}>
        <InternationalizationArrayInput
          name={`${namePrefix}.details`}
          label={t('productOffer.details')}
          translationPrefix="details"
        />
      </Col>

      <Col span={24}>
        <ProductOptionPair namePrefix={namePrefix} />
      </Col>
    </Row>
  );
};

export default ProductOfferForm;
