import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SimulationButton = ({ simulatePage, loadingSimulate }) => {
  const { t } = useTranslation();

  return (
    <Button
      ghost
      type="primary"
      onClick={simulatePage}
      loading={loadingSimulate}
      aria-busy={loadingSimulate}
    >
      {t('testCases.simulatePage')}
    </Button>
  );
};

SimulationButton.propTypes = {
  simulatePage: PropTypes.func.isRequired,
  loadingSimulate: PropTypes.bool.isRequired,
};

export default SimulationButton;
