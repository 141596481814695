import { FormComponentType } from 'src/pages/formSchemas/types';
import React, { useEffect, useMemo } from 'react';
import { useResourceStore } from 'src/pages/formSchemas/fieldTypes/reference/store';
import { convertToSelectDataInput } from 'src/utils/parameterSchema';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import { useFormContext } from 'react-hook-form';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const { setValue, getValues } = useFormContext();

  const filters = useMemo(() => {
    const result: { [key: string]: string } = {};
    field?.config?.query?.forEach(
      ({ label, value }: { label: string; value: string }) => {
        result[label] = value;
      },
    );
    return result;
  }, [field?.config?.query]);

  const store = useResourceStore();
  const dataQuery = store.list({
    resource: field?.config?.resource,
    filter: filters,
  });

  const data = convertToSelectDataInput({
    data: dataQuery.data,
    nameKey: field?.config?.labelAttribute,
    valueKey: field?.config?.valueAttribute,
  });

  const name = `${namePrefix ?? ''}${field.name ?? ''}`;
  const mode = field.config?.multiple === true ? 'multiple' : undefined;
  const fieldResourceName = `${name}.resource`;
  const fieldResourceField = `${name}.field`;
  const fieldResourceValue = `${name}.value`;

  const setFieldResourceMetadata = () => {
    setValue(fieldResourceName, field.config?.resource);
    setValue(fieldResourceField, field?.config?.valueAttribute);
  };

  useEffect(() => {
    if (getValues(fieldResourceValue)) {
      setFieldResourceMetadata();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SelectInput
        label={field.label as string}
        name={fieldResourceValue}
        description={field.description}
        mode={mode}
        data={data}
        loading={dataQuery.isLoading}
        required={field.config?.required}
        onChange={setFieldResourceMetadata}
      />
    </>
  );
};
