import HttpService from 'src/services/HttpService';
import { DOMAIN_CATEGORY_PATH, DOMAIN_PATH } from 'src/api/apiPaths';

const instance = HttpService.getAxiosClient('catalogue');

export const getDomainCategories = async () => {
  const res = await instance.get(`${DOMAIN_CATEGORY_PATH}`);
  return res.data;
};

export const getDomainById = async (id) => {
  const res = await instance.get(`${DOMAIN_PATH}/${id}`);
  return res.data;
};

export const createDomain = async (data) => {
  const res = await instance.post(`${DOMAIN_PATH}`, data);
  return res.data;
};

export const updateDomain = async (id, data) => {
  const res = await instance.put(`${DOMAIN_PATH}/${id}`, data);
  return res.data;
};
