import PropTypes from 'prop-types';
import PricingSimulationForm from 'src/components/simulation/simulationPricing/PricingSimulationForm/PricingSimulationForm';

export const SimulationPricingPage = () => {
  return (
    <div className="w-full min-h-screen h-full bg-gray-100">
      <div className="h-full w-full">
        <PricingSimulationForm />
      </div>
    </div>
  );
};

SimulationPricingPage.propTypes = {
  children: PropTypes.any,
};
