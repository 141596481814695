import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import TextInput from '../../generic/inputs/TextInput';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import modalConfirm from '../../../utils/modalConfirm';

const FactsForm = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'facts',
  });

  const onClick = () => {
    append({ factName: '', factValue: '' });
  };
  return (
    <div className="cols-span-3 mt-2">
      {fields.map((field, index) => (
        <div className="grid grid-cols-3 gap-3 mb-2" key={field.id}>
          <div>
            <TextInput name={`facts.${index}.factName`} label="Fact Name" />
          </div>
          <div>
            <TextInput name={`facts.${index}.factValue`} label="Fact Value" />
          </div>
          <div className="flex items-center justify-end">
            <Button
              className="flex items-center"
              icon={<DeleteOutlined style={{ fontSize: '18px' }} />}
              ghost
              danger
              onClick={() => {
                modalConfirm(
                  'Voulez vous vraiment supprimer cet item ???...',
                  () => {
                    remove(index);
                  },
                );
              }}
            >
              {t('fact.remove')}
            </Button>
          </div>
        </div>
      ))}
      <Button ghost type="primary" className="w-full mb-2" onClick={onClick}>
        {t('fact.add')}
      </Button>
    </div>
  );
};

export default FactsForm;
