import HttpService from '../../services/HttpService';
import { TEST_CASES_PATH } from '../apiPaths';

const instance = HttpService.getAxiosClient('catalogue_usage');

export const getTestCases = async (data) => {
  const { productId, actId } = data;
  const res = await instance.get(TEST_CASES_PATH, {
    params: { productId, actId },
  });
  return res.data;
};
export const getSimulation = async (data) => {
  const res = await instance.post(TEST_CASES_PATH, data);
  return res.data;
};
