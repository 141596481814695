import { useDrawer } from 'src/stores/drawer';
import { Drawer } from 'antd';

export const DrawerContainer = () => {
  const { isOpen, options, body, closeDrawer } = useDrawer();
  const {
    placement = 'right',
    size = 'large',
    title,
    ...restOptions
  } = options ?? {};

  return (
    <>
      <Drawer
        title={title}
        placement={placement}
        onClose={closeDrawer}
        open={isOpen}
        key={placement}
        size={size}
        {...restOptions}
      >
        {body}
      </Drawer>
    </>
  );
};
