import { useTranslation } from 'react-i18next';
import useModal from '../../../hooks/componentHooks/useModal';
import { useState } from 'react';
import { FloatButton, notification } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import Form from '../Form';
import { CALCULATOR_VALIDATION } from '../../../utils/validationSchemas/calculatorSchema';
import CalculatorComponentModelContent from './calculatorComponentModelContent/CalculatorComponentModelContent';
import {
  calculateHt,
  calculateTtc,
  calculateDiscountRate,
  calculateDiscountedPrice,
} from '../../../api';
import { CALCULATOR_DEFAULT } from '../../../utils/defaultsSupplier';

const apiSwitch = {
  calculateHt,
  calculateTtc,
  calculateDiscountRate,
  calculateDiscountedPrice,
};

const CalculatorComponent = () => {
  const { Modal, setOpen } = useModal();
  const { t } = useTranslation();
  const [result, setResult] = useState(0);

  const onSubmit = async (data) => {
    try {
      const response = await apiSwitch[data.calculationType](
        data.value1,
        data.value2,
      );
      setResult(response);
    } catch (err) {
      notification.error({ message: err.response.data.message });
    }
  };

  return (
    <div>
      <FloatButton
        className="rounded-full w-16 h-16"
        type="primary"
        tooltip={t('calculation.use')}
        onClick={() => setOpen(true)}
        icon={
          <div className="flex justify-center text-3xl">
            <CalculatorOutlined />
          </div>
        }
      />
      <Modal title={t('calculation.title')}>
        <Form
          onSubmit={onSubmit}
          defaultValues={CALCULATOR_DEFAULT}
          validationSchema={CALCULATOR_VALIDATION}
        >
          <CalculatorComponentModelContent
            t={t}
            result={result}
            setResult={setResult}
          />
        </Form>
      </Modal>
    </div>
  );
};

export default CalculatorComponent;
