import {
  AimOutlined,
  ArrowRightOutlined,
  CalendarOutlined,
  DollarCircleOutlined,
  GroupOutlined,
  PercentageOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { Col, Row, Tabs } from 'antd';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Discount from 'src/components/eventPricing/Discount';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import Pricing from 'src/components/eventPricing/pricing/Pricing';
import SwitchInput from '../../generic/inputs/SwitchInput';
import { BillingPreferences } from 'src/components/eventPricing/billing';

const CommissionForm = (props) => {
  const { control } = useFormContext();
  const { namePrefix } = props;
  const { t } = useTranslation();
  const pricingType = useWatch({
    name: `${namePrefix}.pricingType`,
    control,
  });
  const items = [
    {
      label: 'Info',
      key: '1',
      children: (
        <Row gutter={[16, 12]} className="mb-2">
          <Col span={12}>
            <TextInput
              label={t('commission.functionalId')}
              name={`${namePrefix}.functionalId`}
              required
              startAdornment={
                <DollarCircleOutlined style={{ fontSize: '25px' }} />
              }
            />
          </Col>
          <Col span={12}>
            <InternationalizationInput
              name={`${namePrefix}.label`}
              label={t('commission.label')}
              startAdornment={<TagOutlined style={{ fontSize: '25px' }} />}
            />
          </Col>
          <Col span={12}>
            <SelectInput
              name={`${namePrefix}.pricingType`}
              label={t('commission.pricingType')}
              data={[
                {
                  functionalId: 'SETUP',
                  name: 'Tarif de mise en place',
                },
                {
                  functionalId: 'PERIODIC',
                  name: 'Tarif périodique',
                },
              ]}
              startAdornment={<AimOutlined style={{ fontSize: '25px' }} />}
            />
          </Col>
          {pricingType === 'PERIODIC' && (
            <Col span={12}>
              <ParameterSelect
                name={`${namePrefix}.periodicity`}
                label={t('commission.periodicty')}
                domain="PERIODICITY"
                startAdornment={
                  <CalendarOutlined style={{ fontSize: '25px' }} />
                }
              />
            </Col>
          )}
          <Col span={12}>
            <ParameterSelect
              name={`${namePrefix}.tva`}
              label={t('commission.tva')}
              domain="TVA"
              startAdornment={
                <PercentageOutlined style={{ fontSize: '25px' }} />
              }
            />
          </Col>
          <Col span={12}>
            <ParameterSelect
              name={`${namePrefix}.commissionType`}
              label={t('commission.commissionType')}
              domain="COMMISSION_TYPE"
              startAdornment={<AimOutlined style={{ fontSize: '25px' }} />}
            />
          </Col>

          <Col span={12}>
            <TextInput
              label={t('commission.commissionGroup')}
              name={`${namePrefix}.commissionGroup`}
              startAdornment={<GroupOutlined style={{ fontSize: '25px' }} />}
            />
          </Col>

          <Col span={12}>
            <ParameterSelect
              name={`${namePrefix}.sens`}
              label={t('commission.sens')}
              domain="SENS"
              startAdornment={
                <ArrowRightOutlined style={{ fontSize: '25px' }} />
              }
            />
          </Col>

          <Col span={12}>
            <SwitchInput
              name={`${namePrefix}.required`}
              label={t('commission.required')}
            />
          </Col>
        </Row>
      ),
      isVisible: true,
    },
    {
      label: t('eventPricing.standardPricing'),
      key: '2',
      children: <Pricing namePrefix={`${namePrefix}.standardPricing`} />,
      isVisible: true,
    },
    {
      label: t('eventPricing.preferentialPricing'),
      key: '3',
      children: <Pricing namePrefix={`${namePrefix}.preferentialPricing`} />,
      isVisible: true,
    },
    {
      label: t('eventPricing.discount'),
      key: '4',
      children: <Discount namePrefix={`${namePrefix}.discountList`} />,
      isVisible: true,
    },
    {
      label: t('eventPricing.prepaidPeriod'),
      key: '5',
      children: (
        <BillingPreferences namePrefix={`${namePrefix}.prepaidPeriodList`} />
      ),
      isVisible: pricingType === 'PERIODIC',
    },
  ];

  return <Tabs items={items.filter((tab) => tab.isVisible)} />;
};

export default CommissionForm;
