export const appendTechnicalId = (array) => {
  if (!array) return [];
  return array.map((element, index) => {
    return {
      ...element,
      id: `id${index}`,
    };
  });
};
export const filterStringArrayByPrefix = (array, prefix) => {
  if (!array) return [];
  return array?.filter((element) => element.startsWith(prefix));
};
