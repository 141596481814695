export const PLAFOND_DEFAULT = {
  functionalId: '',
  labelOperation: '',
  deviseOperation: '',
  codeOrigin: '',
  labelOrigin: '',
  codePeriodicity: '',
  periodicity: 'M',
  startDate: '',
  endDate: '',
  amount: 0,
  amountMin: 0,
  amountMax: 0,
  step: 0,
  cap: 0,
  capSharingCodeOper: '',
  plafondType: '',
  stateChangeable: false,
  ceilingChangeable: false,
  consultable: false,
  foreignService: false,
};

export const FORMULA_DETAIL_DEFAULT = {
  functionalId: '',
  productType: '',
  label: '',
  offer: '',
  providerCode: '',
  pricePeriodic: 0,
  optional: false,
  toLink: false,
  linkInPostSell: false,
  hasProductSheet: false,
  isMoneyFacility: false,
};

export const FORMULA_DEFAULT = {
  formulaCode: '',
  label: '',
  plafonds: [],
  formulaDetails: [],
};

export const THEME_DEFAULT = {
  thumbnail: '',
  largeImage: '',
  functionalId: '',
  themeDescription: '',
  themeLabel: '',
  themeDetails: [],
};

export const BANCASS_DEFAULT = {
  options: [],
};

export const CARD_DEFAULT = {
  debitType: '',
  themes: [],
};

export const PRODUCT_DEFAULT = {
  functionalId: '',
  name: '',
  description: '',
  family: '',
  provider: '',
  groups: [],
  imageLink: '',
  canals: [],
  sellingModes: [],
  active: false,
  activationStartDate: '',
  activationEndDate: '',
  standardPricing: [],
  preferentialPricing: [],
  discounts: [],
  eligibleCards: [],
  renewable: false,
  additionalAttributes: [],
};

export const PARAMETER_DEFAULT = {
  functionalId: '',
  name: '',
  description: '',
  active: false,
};

export const CAP_DEFAULT = {
  operations: [],
  amount: 0,
  currencies: [],
};

export const CONSTRAINT_DEFAULT = {
  label: '',
  description: '',
  minAge: null,
  maxAge: null,
  minBalance: 0,
  clientCategoryList: [],
  clientSegmentList: [],
  clientTypeList: [],
  accountTypeList: [],
  bilanList: [],
  canals: [],
  contractPhases: [],
  contractStatus: [],
  families: [],
  maxAllowed: 0,
  flag: '',
  minFluxExport: 0,
  markets: [],
  moduleList: [],
  products: [],
  sexe: '',
  language: 'PYTHON',
  script: 'def isValidFor(data):\n' + '    return True',
  factNames: [],
};

export const OPTIONS_DEFAULT = {
  code: '',
  label: [],
  optionDetails: [],
};
export const OPTION_DETAILS_DEFAULT = {
  code: '',
  label: '',
  plafond: '',
};

export const ELIGIBLE_ACT_DEFAULT = {
  functionalId: '',
  products: [],
  families: [],
  groups: [],
  consents: [],
  supportingDocuments: [],
  motifs: [],
};
export const BANK_ONLINE_DEFAULT = {
  offers: [],
};

export const OFFER_DEFAULT = {
  functionalId: '',
  codeOfferTech: '',
  label: [],
  description: [],
  modules: [],
};

export const MODULE_DEFAULT = {
  functionalId: '',
  codeProvider: '',
  label: [],
  optional: false,
  options: [],
};

export const MODULE_OPTION_DEFAULT = {
  description: {},
  additionalPrice: 0,
  periodicity: 'M',
  remarks: [],
};

export const ADDITIONAL_ATTRIBUTE_DEFAULT = {
  name: '',
  type: 'Text',
  value: {
    Text: '',
    Number: 0,
    ListNumber: [],
    ListText: [],
    Boolean: false,
  },
};
export const MOTIF_WITH_CONSTRAINTS_DEFAULT = {
  functionalId: '',
  label: '',
  motifList: [],
  constraints: [],
};
export const ADDITIONAL_ATTRIBUTE_WITH_CONSTRAINTS_DEFAULT = {
  ...ADDITIONAL_ATTRIBUTE_DEFAULT,
  constraints: [],
};
export const SUPPORTING_DOCUMENT_DEFAULT = {
  functionalId: '',
  label: '',
  sides: [],
  canals: [],
  mandatory: false,
  documentList: [],
};

export const CONSENT_DEFAULT = {
  functionalId: '',
  content: '',
  canals: [],
  optional: false,
  documentAttachmentList: [],
};

export const EVENT_PRICING_DEFAULT = {
  functionalId: '',
  products: [],
  families: [],
  groups: [],
  commissions: [],
  preProcessors: [],
  postProcessors: [],
};

export const PRICING_DEFAULT = {
  type: 'flat-pricing',
  amount: {
    price: 0,
    currency: 'MAD',
  },
  min: {
    price: 0,
    currency: 'MAD',
  },
  max: {
    price: 0,
    currency: 'MAD',
  },

  flexibility: 0,
  currency: null,
  percentagePrice: 0,
  levelRangeList: [],
  constraints: [],
};

export const BILAN_DEFAULT = {
  functionalId: '',
};

export const SERIALS_DEFAULT = {
  minSerial: '',
  maxSerial: '',
};
export const PRODUCT_OFFER_DEFAULT = {
  functionalId: '',
  label: {},
  products: [],
  optional: false,
};
export const DISCOUNT_DEFAULT = {
  rate: 0,
  active: false,
  dateFrom: null,
  dateTo: null,
  minPercentage: 0,
  maxPercentage: 100,
  type: 'rate',
};

export const COMMISSION_DEFAULT = {
  functionalId: '',
  label: {},
  standardPricing: [],
  preferentialPricing: [],
  discountList: [],
};

export const RECOMMENDATION_DEFAULT = {
  functionalId: '',
  description: [],
  targetType: {},
  products: [],
  families: [],
  groups: [],
  constraints: [],
  recommendationType: 'product',
};

export const EXEMPTION_OPTION_DEFAULT = {
  functionalId: '',
  label: [],
  salable: false,
};
export const EXEMPTION_EVENT_DEFAULT = {
  event: '',
  number: '',
  unlimited: false,
};

export const LEVEL_RANGE_DEFAULT = {
  type: 'flat-levelRange',
  min: 0,
  max: 0,
  percentage: 0,
  fixAmount: 0,
};

export const FACT_ELEMENT_COLLECTION_DEFAULT = {
  clientId: null,
  accountId: null,
  contractId: null,
  personId: null,
  facts: [],
};

export const DOCUMENT_ATTACHMENT_DEFAULT = {
  functionalId: '',
  descriptions: [],
};
export const GRAVE_CONCESSION_OPTIONS_OPTION_DEFAULT = {
  functionalId: '',
  label: [],
  details: [],
};
export const GRAVE_CONCESSION_OPTION_DEFAULT = {
  graveConcessionOptions: [],
};
export const VEHICLE_OPTION_DEFAULT = {
  functionalId: '',
  label: [],
  details: [],
};
export const BENEFICIARY_OPTION_DEFAULT = {
  functionalId: '',
  label: [],
  details: [],
};
export const DAMAGE_DEFAULT = {
  functionalId: '',
  name: [],
};
export const DAMAGE_GUARANTEE_DEFAULT = {
  functionalId: '',
  name: [],
};

export const USER_DEFAULT = {
  firstName: '',
  lastName: '',
  username: '',
  profile: {},
  active: true,
};

export const PROFILE_DEFAULT = {
  name: '',
  description: '',
  roles: [],
  active: true,
};
export const BILAN_AND_CURRENCY_DEFAULT = {
  functionalId: '',
};

export const CALCULATOR_DEFAULT = {
  calculationType: 'calculateHt',
  value1: 0,
  value2: 0,
};

export const SERVICE_OPTION_DEFAULT = {
  functionalId: '',
  label: {},
  description: {},
  details: [],
};
export const DAMAGE_GUARANTEE_OPTION_DEFAULT = {
  functionalId: '',
  label: {},
  description: {},
  plafond: null,
  details: [],
};
export const RULE_DEFAULT = {
  functionalId: '',
  name: [],
  description: [],
  constraints: [],
};
export const CONVENTION_CARD_FORMULA_DEFAULT = {
  functionalId: '',
  label: [],
  min: 0,
  max: 0,
  details: [],
  conventionIncludedCardList: [],
};
export const CONVENTION_INCLUDED_CARD_DEFAULT = {
  product: '',
  productOptions: [],
  minCard: 0,
  maxCard: 0,
  details: [],
};
export const PUBLICATION_CONSTRAINTS_DEFAULT = {
  constraints: [],
};
export const INCOME_AND_CATEGORY_DEFAULT = {
  incomeAndNatureWrapperList: [],
};

export const OPTION_ASSISTANCE_DEFAULT = {
  functionalId: '',
  label: [],
  description: [],
  details: [],
  beneficiaries: [],
  vehicles: [],
  graveConcessions: [],
};
