import { createSlice } from '@reduxjs/toolkit';

const errorSlice = createSlice({
  name: 'error',
  initialState: {
    message: '',
    visible: false,
    status: 500,
  },
  reducers: {
    showError: (state, action) => {
      state.message = action.payload.message;
      state.visible = true;
      state.status = action.payload.status;
    },
    hideError: (state) => {
      state.visible = false;
    },
  },
});

export const { showError, hideError } = errorSlice.actions;

export const { reducer: errorReducer } = errorSlice;
