import { fetchSupportedLanguages } from 'src/redux/api/supportedLanguages';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useLanguages = () => {
  const languages = useSelector(
    (state) => state?.supportedLanguages?.supportedLanguages ?? [],
  );
  const selectedLang = useSelector(
    (state) => state.selectedLanguage.selectedLanguage,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (languages.length === 0) {
      dispatch(fetchSupportedLanguages());
    }
  }, [dispatch, languages.length]);

  return {
    languages,
    selectedLang,
  };
};
