import { DesignerComponentType } from 'src/pages/formSchemas/types';
import {
  getDesignerComponent,
  getNestedFieldName,
} from 'src/pages/formSchemas/utils/form';
import React, { useMemo } from 'react';
import { Tabs } from 'antd';
import { Tab } from 'rc-tabs/lib/interface';

export const DesignerComponent: DesignerComponentType = ({
  fieldName,
  fieldValue,
  onEdit,
  onDelete,
  onDuplicate,
  disabled,
}) => {
  const items: Tab[] | undefined = useMemo(() => {
    return fieldValue?.config?.fields?.map((val: any, i: number) => {
      const childFieldName = getNestedFieldName(fieldName, i);
      const component = getDesignerComponent({
        fieldName: childFieldName,
        fieldValue: val,
        onEdit,
        onDelete,
        onDuplicate,
        disabled,
      });
      return {
        label: val.label,
        key: val.name || val.label,
        children: (
          <React.Fragment key={childFieldName}>{component}</React.Fragment>
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, fieldValue?.config?.fields, onDelete, onEdit]);

  return <Tabs items={items} defaultValue={items?.[0]?.key} />;
};
