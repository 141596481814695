import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { isAuthorized } from 'src/utils/authUtils';
import { API_NAMES, USER_ROLES } from 'src/utils/constants';
import { FactTechnicalSettingTabContainer } from 'src/containers/administration/technicalSettings/factTechnicalSettingContainer';

function TechnicalSettingPage() {
  const { t } = useTranslation();
  const { userContext } = useUserContext();
  const tabs = [
    {
      key: '1',
      label: t('navbar.facts'),
      children: <FactTechnicalSettingTabContainer />,
      isVisible: isAuthorized(userContext[API_NAMES.FACTS], [
        USER_ROLES.TECHNICAL_ADMIN,
      ]),
    },
  ];

  return (
    <Tabs
      defaultActiveKey={tabs[0].key}
      items={tabs.filter((tab) => tab.isVisible)}
    />
  );
}

export { TechnicalSettingPage };
