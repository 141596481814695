import React from 'react';
import {
  DesignerFormComponentType,
  FormBuilderField,
} from 'src/pages/formSchemas/types';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import TextInput from 'src/components/generic/inputs/TextInput';
import { CheckboxInput } from 'src/components/generic/inputs/CheckboxInput';
import { Button } from 'antd';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import { saveButtonClass } from 'src/pages/formSchemas/utils/constants/ui';
import { FILE_TYPE } from 'src/pages/formSchemas/utils/constants/media';

export const DesignerFormComponent: DesignerFormComponentType = ({
  fieldName,
  fieldValue,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const methods = useForm<FormBuilderField>({
    defaultValues: fieldValue,
  });

  const typesOptions = Object.keys(FILE_TYPE).map((type) => ({
    functionalId: type,
    name: t(`form.types.${type}`),
  }));

  const handleSubmit = (data: FormBuilderField) => {
    return onSubmit(fieldName, data);
  };

  return (
    <FormProvider {...methods}>
      <form className="space-y-4" onSubmit={methods.handleSubmit(handleSubmit)}>
        <TextInput type="text" name="name" label={t('form.name')} required />
        <TextInput type="text" name="label" label={t('form.label')} required />
        <SelectInput
          name="config.fileType"
          label={t('form.file_type')}
          size="large"
          data={typesOptions}
        />
        <CheckboxInput name="config.multiple" label={t('form.multiple')} />
        <CheckboxInput name="config.required" label={t('form.required')} />
        <Button
          type="primary"
          ghost
          htmlType="submit"
          className={saveButtonClass}
        >
          {t('form.save')}
        </Button>
      </form>
    </FormProvider>
  );
};
