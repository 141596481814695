import ProductCardInfo from './../productCardInfo';
import ParameterCardInfo from './../parameterCardInfo';
import EligibleActCardInfo from './../eligibleActCardInfo';
import RecommendationCardInfo from './../recommendationCardInfo';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

export default function Cards() {
  const { t } = useTranslation();
  return (
    <Row className="mb-4" gutter={24}>
      <Col span={6}>
        <ProductCardInfo title={t('dashboard.total.products')} />
      </Col>

      <Col span={6}>
        <ParameterCardInfo title={t('dashboard.total.parameters')} />
      </Col>
      <Col span={6}>
        <EligibleActCardInfo title={t('dashboard.total.eligible.act')} />
      </Col>
      <Col span={6}>
        <RecommendationCardInfo title={t('dashboard.total.recommendations')} />
      </Col>
    </Row>
  );
}
