import { PropsWithChildren } from 'react';
import clsx from 'clsx';

const colors = {
  primary: 'bg-primary text-white ring-primary/10',
  secondary: 'bg-gray-50 text-gray-600 ring-gray-500/10',
  success: 'bg-green-600 text-white ring-green-600/10',
  lightSuccess: 'text-green-600 bg-green-50 ring-green-600/10',
  lightPrimary: 'text-primary bg-orange-50 ring-primary/10',
} as const;

export type BadgeColors = keyof typeof colors;

interface BadgeProps extends PropsWithChildren {
  className?: string;
  color?: BadgeColors;
}

export function Badge({ color = 'primary', className, children }: BadgeProps) {
  return (
    <span
      className={clsx(
        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
        colors[color],
        className,
      )}
    >
      {children}
    </span>
  );
}
