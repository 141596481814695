import { Button, Timeline, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PUBLICATION_STATUS } from 'src/utils/constants/publicationStatus';
import { FieldTimeOutlined } from '@ant-design/icons';
import { formatUnixTimestamp } from 'src/utils/mappers/dateMapper';

export default function VersionsTimeLine(props) {
  const { versions, path, onCompare } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const colorResolver = {
    [PUBLICATION_STATUS.UNPUBLISHED]: 'gray',
    [PUBLICATION_STATUS.SCHEDULED]: 'green',
    [PUBLICATION_STATUS.PUBLISHED]: 'green',
    [PUBLICATION_STATUS.SCHEDULE_CANCELED]: 'gray',
    [PUBLICATION_STATUS.PARTIALLY_PUBLISHED]: 'orange',
    [PUBLICATION_STATUS.DRAFT]: 'gray',
  };

  const getTooltipTitle = (version, status) => {
    if (status === PUBLICATION_STATUS.SCHEDULED) {
      return (
        <div className="text-center">
          {t('versions.publicationDatePlanned') +
            formatUnixTimestamp(
              version.scheduledPublicationDate,
              'dd/MM/yyyy HH:mm',
            )}
        </div>
      );
    } else if (status === PUBLICATION_STATUS.SCHEDULE_CANCELED) {
      return (
        <div className="text-center">
          {t('versions.publicationDatePlanned')}
          {formatUnixTimestamp(
            version.scheduledPublicationDate,
            'dd/MM/yyyy HH:mm',
          ) + ' '}
          <span className="text-red-300">{t('versions.canceled')}</span>
        </div>
      );
    }
    return (
      <div className="text-center">{t(`publicationStatus.${status}`)}</div>
    );
  };

  const resolvePath = (id) =>
    typeof path === 'function' ? path(id) : path + id;

  return versions.length ? (
    <div>
      <Timeline
        items={versions.map((version) => {
          const className = location.pathname.endsWith(version.id)
            ? 'font-semibold cursor-pointer'
            : 'cursor-pointer ';
          return {
            children: (
              <h1
                className={className}
                onClick={() => {
                  navigate(resolvePath(version.id));
                }}
              >
                {t('versions.view')}
              </h1>
            ),
            dot: [
              PUBLICATION_STATUS.SCHEDULED,
              PUBLICATION_STATUS.SCHEDULE_CANCELED,
            ].includes(version.status) ? (
              <FieldTimeOutlined style={{ fontSize: '12px' }} />
            ) : null,
            color: colorResolver[version.status],
            label: (
              <Tooltip title={getTooltipTitle(version, version.status)}>
                <span
                  className={
                    location.pathname.endsWith(version.id)
                      ? 'font-semibold'
                      : ''
                  }
                >
                  {`${t('versions.version')} : ${version.version}`}
                </span>
              </Tooltip>
            ),
          };
        })}
        mode="right"
      />
      {versions.length > 1 && !!onCompare && (
        <div className={'flex justify-center items-center'} onClick={onCompare}>
          <Button>{t('diffing.compare_versions')}</Button>
        </div>
      )}
    </div>
  ) : (
    <div className="flex justify-center my-5">{t('versions.noAdded')}</div>
  );
}

VersionsTimeLine.propTypes = {
  versions: PropTypes.array.isRequired,
  onCompare: PropTypes.func,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isVersionDiffingEnabled: PropTypes.bool,
};
