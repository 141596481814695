import { useState } from 'react';
import { useQuery } from 'react-query';
import { getCountOfEligibleAct } from 'src/api';
import CardInfo from './../../cardInfo/CardInfo';
import { mapPublicationStatusesToOptions } from 'src/utils/mappers/selectOptionsMapper';
import { PUBLICATION_STATUS } from 'src/utils/constants/publicationStatus';

export default function EligibleActCardInfo(props) {
  const { title } = props;
  const [status, setStatus] = useState(PUBLICATION_STATUS.PUBLISHED);
  const { data, isLoading } = useQuery(
    ['get-count-eligible-act', status],
    () => {
      return getCountOfEligibleAct(status);
    },
  );
  const handleStatusChange = (value) => {
    setStatus(value);
  };
  return (
    <CardInfo
      statusOptions={() => mapPublicationStatusesToOptions()}
      isLoading={isLoading}
      handleStatusChange={handleStatusChange}
      value={status}
      number={data}
      title={title}
    />
  );
}
