import { Card, Space, Select } from 'antd';
import { Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import { useRef, useState, useEffect, useMemo } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';

const { Option } = Select;

export default function Chart(props) {
  const {
    data,
    filterOptions,
    title,
    isLoading,
    onPartSelect,
    handleShowAll,
    statusOptions,
  } = props;
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(null);
  const [transformedData, setTransformedData] = useState(null);
  const colors = useMemo(
    () => [
      'hsl(0, 100%, 60%)',
      'hsl(220, 100%, 60%)',
      'hsl(400, 100%, 60%)',
      'hsl(260, 100%, 60%)',
      'hsl(240, 100%, 60%)',
      'hsl(300, 100%, 60%)',
      'hsl(30, 100%, 60%)',
      'hsl(190, 100%, 60%)',
      'hsl(280, 100%, 60%)',
      'hsl(210, 100%, 60%)',
    ],
    [],
  );

  const updateContainerWidth = () => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth
        ? (containerRef.current.offsetWidth * 3) / 4
        : 650;
      setChartWidth(width);
    }
  };
  useEffect(() => {
    updateContainerWidth();
    const colorGenerator = () => {
      const numKeys = Object.keys(data || {}).length;
      if (numKeys > colors.length) {
        for (let i = 0; i < numKeys - colors.length; i++) {
          const hue = ((i * 360) / (numKeys - colors.length)) % 360;
          colors.push(`hsl(${hue}, 100%, 80%)`);
        }
      }
    };
    colorGenerator();
    const datat = Object.keys(data || {}).map((key) => {
      return {
        name: key.substring(0, key.indexOf('_')),
        count: data[key],
        functionnalId: key.substring(key.indexOf('_') + 1),
      };
    });
    setTransformedData(datat);
    window.addEventListener('resize', updateContainerWidth);
    return () => window.removeEventListener('resize', updateContainerWidth);
  }, [isLoading, data, colors]);

  const handleChartClick = (data) => {
    const partName = data.functionnalId;
    onPartSelect(partName, data.name);
  };

  return (
    <Card
      ref={containerRef}
      loading={isLoading}
      title={
        <div className="flex justify-between items-center ">
          <span>{title}</span>
          <div className="flex gap-2">
            <Select
              className="w-36"
              onChange={props.handleFilterChange}
              value={props.value}
              size="small"
            >
              {filterOptions()?.map((value) => (
                <Option key={value.functionalId} value={value.functionalId}>
                  {value.name}
                </Option>
              ))}
            </Select>
            <Select
              className="w-32"
              onChange={props.handleStatusChange}
              value={props.valueStatus}
              size="small"
            >
              {statusOptions()?.map((value) => (
                <Option key={value.functionalId} value={value.functionalId}>
                  {value.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      }
      className="h-full"
    >
      <Space
        size={16}
        style={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '340px',
        }}
      >
        {data && Object.keys(data).length === 0 ? (
          <Empty description={t('data.noDataFound')} />
        ) : (
          <div className="mx-14">
            {
              <PieChart width={chartWidth} height={300}>
                <Pie
                  data-testid="piechart"
                  data={transformedData}
                  onClick={handleChartClick}
                  dataKey="count"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label
                >
                  {transformedData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            }
            <div className="text-center">
              <Button
                type="primary"
                onClick={handleShowAll}
                ghost
                className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
              >
                {t('dashboard.show.all')}
              </Button>
            </div>
          </div>
        )}
      </Space>
    </Card>
  );
}
