import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import InternationalizationInput from '../InternationalizationInput';
import modalConfirm from '../../../../utils/modalConfirm';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ComponentDisabler from '../../ComponentDisabler';
import { InputLabel } from 'src/components/generic/inputs/InputLabel';

const InternationalizationArrayInput = ({
  name,
  label,
  translationPrefix,
  required,
  placeholder,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const onClick = () => {
    append({});
  };
  return (
    <div className="w-full mt-2">
      {label && (
        <InputLabel className="block mb-2" label={label} required={required} />
      )}
      {fields.length > 0 ? (
        fields.map((field, index) => (
          <div className="grid grid-cols-3 gap-3 mb-2 items-end" key={field.id}>
            <div className="col-span-2">
              <InternationalizationInput
                name={`${name}[${index}]`}
                label={`${label} ${index + 1}`}
                required={required}
                placeholder={placeholder}
              />
            </div>
            <div className="col-span-1 grid justify-end items-center">
              <ComponentDisabler
                componentRender={() => (
                  <Button
                    icon={<DeleteOutlined style={{ fontSize: '18px' }} />}
                    ghost
                    danger
                    onClick={() => {
                      modalConfirm(t(`${translationPrefix}.warn`), () => {
                        remove(index);
                      });
                    }}
                  >
                    {t(`${translationPrefix}.remove`)}
                  </Button>
                )}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center mb-2">
          {t(`${translationPrefix}.empty`)}
        </div>
      )}
      <ComponentDisabler
        componentRender={() => (
          <Button
            ghost
            type="primary"
            className="w-full mb-2"
            onClick={onClick}
          >
            {'+ ' + t(`${translationPrefix}.add`)}
          </Button>
        )}
      />
    </div>
  );
};

export default InternationalizationArrayInput;
