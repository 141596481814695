import React, { FunctionComponent, useMemo } from 'react';
import { FormFieldV2, IMap } from 'src/types';
import { getFormComponent } from 'src/pages/formSchemas/utils/form';
import { FormComponentType } from 'src/pages/formSchemas/types';
import { resolveFormDefaultValueForContainerFields } from 'src/pages/formSchemas/utils/common';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';

const MyArrayFormX: FunctionComponent<{
  namePrefix: string;
  field: FormFieldV2;
}> = ({ namePrefix, field }) => {
  return (
    <div className="space-y-4 mb-4">
      {field.config?.fields?.map((field: FormFieldV2, i: number) => {
        const key = `${field.label}-${i}`;
        const component = getFormComponent({
          field,
          namePrefix: `${namePrefix}.`,
        });
        return <React.Fragment key={key}>{component}</React.Fragment>;
      })}
    </div>
  );
};

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;
  const MyArrayForm = useMemo(
    () =>
      ({ namePrefix }: { namePrefix: string }) =>
        <MyArrayFormX namePrefix={namePrefix} field={field} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.config?.fields],
  );

  const defaultValues = useMemo(() => {
    const res: IMap<string, any> = {};
    resolveFormDefaultValueForContainerFields(res, {
      field: {
        ...field,
        name: '', // Setting name to empty string to create the sub object of the array
      },
      namePrefix: '',
    });
    return res;
  }, [field]);

  return (
    <div className="space-y-4">
      <ArrayAccordion
        label={field.label ?? ''}
        name={name}
        Form={MyArrayForm}
        defaultValues={defaultValues}
        translationPrefix={'form'}
      />
    </div>
  );
};
