import { CopyOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import clsx from 'clsx';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConstraintOptimizerWrapper } from 'src/components/constraints/optimizer/ConstraintOptimizerWrapper';
import ComponentDisabler from 'src/components/generic/ComponentDisabler';
import { getItem, setItem } from 'src/utils/localStorageUtils';
import UUIDClass from 'uuidjs';
import ConstraintsAdd from '../ConstraintsAdd/ConstraintsAdd';

export default function ConstraintWrapper({
  namePrefix,
  className,
  hasErrorMessage,
}) {
  const name = namePrefix ? namePrefix + '.constraints' : 'constraints';
  const { t } = useTranslation();

  // Form
  const { control, formDisabled } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const controlledFields = useWatch({
    control,
    name,
  });
  const canOptimize = !formDisabled;

  return (
    <div className={clsx('mb-2 space-y-4', className)}>
      <div>
        {fields.map((constraints, index) => {
          return (
            <div className="mb-2" key={constraints.id}>
              {index !== 0 && <div className="flex">{t('constraints.or')}</div>}
              <div className="w-full border-2 border-gray-200 rounded-xl p-2">
                <ConstraintsAdd
                  key={constraints.id}
                  //can't have an (empty) array as default value in useFieldArray
                  name={`${name}[${index}].content`}
                  hasErrorMessage={hasErrorMessage}
                />
              </div>
              <div className="w-full flex justify-between mt-2 flex-wrap gap-2">
                <ComponentDisabler
                  componentRender={() => (
                    <Button
                      icon={<CopyOutlined style={{ fontSize: '18px' }} />}
                      className="flex items-center"
                      ghost
                      type="primary"
                      onClick={() => {
                        append(controlledFields[index]);
                      }}
                    >
                      {t('constraints.duplicateAlternative')}
                    </Button>
                  )}
                />

                <Button
                  icon={<CopyOutlined style={{ fontSize: '18px' }} />}
                  className="flex items-center"
                  ghost
                  type="primary"
                  onClick={() => {
                    try {
                      setItem(
                        'copied-constraints-alternative',
                        controlledFields[index],
                      );
                      message.success(t('copy.success.constraintAlternative'));
                    } catch (error) {
                      message.error(t('copy.fail.constraintAlternative'));
                    }
                  }}
                >
                  {t('constraints.copyAlternative')}
                </Button>

                <ComponentDisabler
                  componentRender={() => (
                    <Button
                      icon={<DeleteOutlined style={{ fontSize: '18px' }} />}
                      className="flex items-center"
                      danger
                      ghost
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      {t('constraints.deleteAlternative')}
                    </Button>
                  )}
                />
              </div>
            </div>
          );
        })}
        <div className="flex flex-col gap-2">
          <ComponentDisabler
            componentRender={() => (
              <Button
                type="primary"
                ghost
                className="w-full"
                onClick={() => {
                  append({ content: [] });
                }}
              >
                + {t('constraints.addAlternative')}
              </Button>
            )}
          />
          <ComponentDisabler
            componentRender={() => (
              <Button
                type="primary"
                ghost
                className="w-full flex justify-center items-center"
                onClick={() => {
                  try {
                    const items = getItem('copied-constraints-alternative');
                    items.content.forEach(
                      (item) => (item.id = UUIDClass.generate()),
                    );
                    append(items);
                    message.success(t('paste.success.constraintAlternative'));
                  } catch (error) {
                    message.error(t('paste.fail.constraintAlternative'));
                  }
                }}
              >
                <FormOutlined /> {t('constraints.pasteAlternative')}
              </Button>
            )}
          />
        </div>
      </div>
      <ConstraintOptimizerWrapper
        namePrefix={namePrefix}
        isOptimizerEnabled={canOptimize}
      />
    </div>
  );
}
