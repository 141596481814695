import {
  ADMIN_TECH_CONSULT_AUDIT_CATALOGUE_PATH,
  ADMIN_TECH_CONSULT_AUDIT_FACTS_PATH,
  ADMIN_TECH_CONSULT_AUDIT_PATH,
  ADMIN_TECH_CONSULT_AUDIT_PRICING_PATH,
  AUDIT_STATISTICS_PATH,
  CACHING_BASE_PATH,
  DASHBOARD_BASE_PATH,
  DOMAINS_BASE_PATH,
  DOMAINS_CREATE_PATH,
  DOMAINS_UPDATE_PATH,
  ELIGIBILITY_TEST_CASES_PATH,
  ELIGIBLE_ACT_ADD_PATH,
  ELIGIBLE_ACT_BASE_PATH,
  ELIGIBLE_ACT_UPDATE_PATH,
  ERROR_PATH,
  EVENT_PRICING_ADD_PATH,
  EVENT_PRICING_BASE_PATH,
  EVENT_PRICING_UPDATE_PATH,
  FACT_ADD_PATH,
  FACT_BASE_PATH,
  FACT_UPDATE_PATH,
  FORM_SCHEMAS_BASE_PATH,
  FORM_SCHEMAS_EDIT_PATH,
  FORM_SCHEMAS_PREVIEW_PATH,
  PARAMETER_BASE_PATH,
  PARAMETER_SCHEMAS_BASE_PATH,
  PARAMETER_SCHEMAS_CREATE_PATH,
  PARAMETER_SCHEMAS_UPDATE_PATH,
  PRODUCT_ADD_PATH,
  PRODUCT_BASE_PATH,
  PRODUCT_UPDATE_PATH,
  PROFILES_BASE_PATH,
  RECOMMENDATION_ADD_PATH,
  RECOMMENDATION_BASE_PATH,
  RECOMMENDATION_UPDATE_PATH,
  REUSABLE_FORM_SCHEMAS_BASE_PATH,
  REUSABLE_FORM_SCHEMAS_CREATE_PATH,
  REUSABLE_FORM_SCHEMAS_EDIT_PATH,
  RULE_ADD_PATH,
  RULE_BASE_PATH,
  RULE_UPDATE_PATH,
  SIMULATION_ELIGIBILITY_PATH,
  SIMULATION_PATH,
  SIMULATION_PRICING_PATH,
  TECHNICAL_ADMIN_BASE_PATH,
  TECHNICAL_SETTING_PATH,
  USER_MANAGEMENT_BASE_PATH,
  USERS_BASE_PATH,
} from './paths';
import {
  DashboardPage,
  ParameterListingPage,
  ProductAddPage,
  ProductListingPage,
  ProfilesPage,
  UsersPage,
} from 'src/pages';
import { ProductUpdatePage } from 'src/pages/products/ProductUpdatePage';
import { ParameterSchemasListing } from 'src/pages/parameterSchemas/ParameterSchemasListing';
import { ParameterSchemaCreation } from 'src/pages/parameterSchemas/ParameterSchemaCreation';
import { ParameterSchemaUpdate } from 'src/pages/parameterSchemas/ParameterSchemaUpdate';
import EligibleActUpdatePage from 'src/pages/eligibleActs/EligibleActUpdatePage';
import EligibleActsListingPage from '../pages/eligibleActs/EligibleActsListingPage';
import EventPricingAddPage from '../pages/eventPricing/eventPricingAddPage';
import EventPricingListingPage from '../pages/eventPricing/eventPricingListingPage';
import EventPricingUpdatePage from '../pages/eventPricing/eventPricingUpdatePage';
import EligibleActAddPage from '../pages/eligibleActs/EligibleActAddPage';
import { Error500 } from 'src/pages/error/Error500';
import RecommendationAddPage from 'src/pages/recommendation/RecommendationAddPage';
import RecommendationListingPage from 'src/pages/recommendation/RecommendationListingPage';
import RecommendationUpdatePage from 'src/pages/recommendation/RecommendationUpdatePage';
import FactListingPage from '../pages/facts/FactsListingPage/FactListingPage';
import FactAddPage from '../pages/facts/FactAddPage';
import FactUpdatePage from '../pages/facts/FactUpdatePage';
import { AuditStatisticsBasePage } from 'src/pages/administration/auditStatistics/AuditStatisticsBasePage';
import { SimulationPage } from 'src/pages/simulation';
import AuditPricingPage from 'src/pages/administration/AuditPricingPage';
import AuditCataloguePage from 'src/pages/administration/AuditCataloguePage';

import {
  AdjustmentsVertical,
  Banknotes,
  CogSixTooth,
  FourSquares,
  InboxStack,
  PresentationChartLine,
} from 'src/components/icons';
import { API_NAMES, USER_ROLES } from 'src/utils/constants';
import { PlayCircleOutlined } from '@ant-design/icons';
import { SimulationPricingPage } from 'src/pages/simulation/simulationPricing';
import { DomainsListing } from 'src/pages/domains/DomainsListing';
import { DomainCreation } from 'src/pages/domains/DomainCreation';
import { DomainUpdate } from 'src/pages/domains/DomainUpdate';
import RuleAddPage from '../pages/rules/RuleAddPage/RuleAddPage';
import RuleListingPage from '../pages/rules/RuleListingPage';
import RuleUpdatePage from '../pages/rules/RuleUpdatePage';
import { TechnicalSettingPage } from 'src/pages/administration/technicalSetting';
import FormSchemaListing from 'src/pages/formSchemas/pages/FormSchemaListing';
import FormSchemaUpdate from 'src/pages/formSchemas/pages/FormSchemaUpdate';
import ReusableFormSchemaListing from 'src/pages/formSchemas/pages/ReusableFormSchemaListing';
import ReusableFormSchemaCreate from 'src/pages/formSchemas/pages/ReusableFormSchemaCreate';
import ReusableFormSchemaUpdate from 'src/pages/formSchemas/pages/ReusableFormSchemaUpdate';
import FormSchemaPreview from 'src/pages/formSchemas/pages/FormSchemaPreview';
import CachingListingPage from 'src/pages/administration/caching/CachingListingPage/CachingListingPage';
import { AuditFactsPage } from 'src/pages/administration/AuditFactsPage';
import TestCasesPage from '../pages/testCases/TestCasesPage/TestCasesPage';

export const APP_ROUTES = [
  {
    path: DASHBOARD_BASE_PATH,
    name: 'dashboard',
    component: DashboardPage,
    requiredRoles: [USER_ROLES.CONSULT_DASHBOARD],
    apis: [API_NAMES.CATALOG],
    hidden: false,
  },
  {
    path: PRODUCT_BASE_PATH,
    name: 'products',
    component: ProductListingPage,
    requiredRoles: [USER_ROLES.CONSULT_PRODUCTS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    hidden: false,
  },
  {
    path: PRODUCT_ADD_PATH,
    name: 'Product Add',
    component: ProductAddPage,
    requiredRoles: [USER_ROLES.EDIT_PRODUCTS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    hidden: true,
  },
  {
    path: PRODUCT_UPDATE_PATH,
    name: 'Product Update',
    component: ProductUpdatePage,
    requiredRoles: [
      USER_ROLES.CONSULT_PRODUCTS,
      USER_ROLES.EDIT_PRODUCTS,
      USER_ROLES.PUBLISH_PRODUCTS,
      USER_ROLES.PARTIALLY_PUBLISH_PRODUCTS,
    ],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    hidden: true,
  },
  {
    path: PARAMETER_BASE_PATH,
    name: 'parameters',
    component: ParameterListingPage,
    requiredRoles: [USER_ROLES.CONSULT_PARAMETERS, USER_ROLES.EDIT_PARAMETERS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    hidden: false,
  },
  {
    path: USERS_BASE_PATH,
    name: 'Users',
    component: UsersPage,
    requiredRoles: [
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.SUPER_ADMIN_CATALOGUE,
      USER_ROLES.SUPER_ADMIN_PRICING,
      USER_ROLES.SUPER_ADMIN_FACTS,
    ],
    apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING, API_NAMES.FACTS],
    hidden: true,
  },
  {
    path: PROFILES_BASE_PATH,
    name: 'Profiles',
    component: ProfilesPage,
    requiredRoles: [
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.SUPER_ADMIN_CATALOGUE,
      USER_ROLES.SUPER_ADMIN_PRICING,
      USER_ROLES.SUPER_ADMIN_FACTS,
    ],
    apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING, API_NAMES.FACTS],
    hidden: true,
  },
  {
    path: USER_MANAGEMENT_BASE_PATH,
    name: 'User Management',
    component: UsersPage,
    requiredRoles: [
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.SUPER_ADMIN_CATALOGUE,
      USER_ROLES.SUPER_ADMIN_PRICING,
      USER_ROLES.SUPER_ADMIN_FACTS,
    ],
    apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING, API_NAMES.FACTS],
    hidden: true,
  },
  {
    path: ELIGIBLE_ACT_ADD_PATH,
    name: 'Eligible Act Add',
    component: EligibleActAddPage,
    requiredRoles: [USER_ROLES.EDIT_EVENTS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    hidden: true,
  },
  {
    path: ELIGIBLE_ACT_BASE_PATH,
    name: 'eligibleActs',
    component: EligibleActsListingPage,
    requiredRoles: [USER_ROLES.CONSULT_EVENTS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    hidden: false,
  },
  {
    path: ELIGIBLE_ACT_UPDATE_PATH,
    name: 'Eligible Act Update',
    component: EligibleActUpdatePage,
    requiredRoles: [
      USER_ROLES.CONSULT_EVENTS,
      USER_ROLES.EDIT_EVENTS,
      USER_ROLES.PUBLISH_EVENTS,
      USER_ROLES.PARTIALLY_PUBLISH_EVENTS,
    ],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    hidden: true,
  },
  {
    path: EVENT_PRICING_ADD_PATH,
    name: 'Event Pricing Add',
    component: EventPricingAddPage,
    requiredRoles: [USER_ROLES.EDIT_PRICINGS],
    apis: [API_NAMES.EVENT_PRICING],
    targetApi: API_NAMES.EVENT_PRICING,
    hidden: true,
  },
  {
    path: EVENT_PRICING_BASE_PATH,
    name: 'eventPricings',
    component: EventPricingListingPage,
    requiredRoles: [USER_ROLES.CONSULT_PRICINGS],
    apis: [API_NAMES.EVENT_PRICING],
    targetApi: API_NAMES.EVENT_PRICING,
    hidden: false,
  },
  {
    path: EVENT_PRICING_UPDATE_PATH,
    name: 'Event Pricing Update',
    component: EventPricingUpdatePage,
    requiredRoles: [
      USER_ROLES.CONSULT_PRICINGS,
      USER_ROLES.EDIT_PRICINGS,
      USER_ROLES.PUBLISH_PRICINGS,
      USER_ROLES.PARTIALLY_PUBLISH_PRICINGS,
    ],
    apis: [API_NAMES.EVENT_PRICING],
    targetApi: API_NAMES.EVENT_PRICING,
    hidden: true,
  },
  {
    path: RECOMMENDATION_ADD_PATH,
    name: 'Recommendation Add',
    component: RecommendationAddPage,
    requiredRoles: [USER_ROLES.EDIT_RECOMMENDATIONS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    hidden: true,
  },
  {
    path: RECOMMENDATION_BASE_PATH,
    name: 'recommendations',
    component: RecommendationListingPage,
    requiredRoles: [USER_ROLES.CONSULT_RECOMMENDATIONS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    hidden: false,
  },
  {
    path: RECOMMENDATION_UPDATE_PATH,
    name: 'Recommendation Update',
    component: RecommendationUpdatePage,
    requiredRoles: [
      USER_ROLES.CONSULT_RECOMMENDATIONS,
      USER_ROLES.EDIT_RECOMMENDATIONS,
      USER_ROLES.PUBLISH_RECOMMENDATIONS,
      USER_ROLES.PARTIALLY_PUBLISH_RECOMMENDATIONS,
    ],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    hidden: true,
  },
  {
    path: ERROR_PATH,
    name: 'Error',
    component: Error500,
    requiredRoles: [],
    apis: [],
    hidden: true,
  },
  {
    path: FACT_ADD_PATH,
    name: 'Fact Add',
    component: FactAddPage,
    requiredRoles: [],
    apis: [],
    targetApi: API_NAMES.FACTS,
    hidden: true,
  },
  {
    path: FACT_BASE_PATH,
    name: 'facts',
    component: FactListingPage,
    requiredRoles: [],
    apis: [],
    targetApi: API_NAMES.FACTS,
    hidden: false,
  },
  {
    path: FACT_UPDATE_PATH,
    name: 'Fact Update',
    component: FactUpdatePage,
    requiredRoles: [],
    apis: [],
    targetApi: API_NAMES.FACTS,
    hidden: true,
  },
  {
    path: AUDIT_STATISTICS_PATH,
    name: 'Audit Statistics',
    component: AuditStatisticsBasePage,
    requiredRoles: [USER_ROLES.TECHNICAL_ADMIN],
    apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING, API_NAMES.FACTS],
    hidden: true,
  },
  {
    path: TECHNICAL_SETTING_PATH,
    name: 'technical Setting',
    component: TechnicalSettingPage,
    requiredRoles: [USER_ROLES.TECHNICAL_ADMIN],
    apis: [API_NAMES.FACTS],
    hidden: true,
  },
  {
    path: PARAMETER_SCHEMAS_BASE_PATH,
    name: 'parameter_schemas',
    requiredRoles: [USER_ROLES.CONSULT_PARAMETERS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
    component: ParameterSchemasListing,
    hidden: false,
  },
  {
    path: PARAMETER_SCHEMAS_CREATE_PATH,
    name: 'new_parameter_schema',
    requiredRoles: [USER_ROLES.EDIT_PARAMETERS],
    apis: [API_NAMES.CATALOG],
    component: ParameterSchemaCreation,
    targetApi: API_NAMES.CATALOG,
    hidden: false,
  },
  {
    path: PARAMETER_SCHEMAS_UPDATE_PATH,
    name: 'update_parameter_schema',
    requiredRoles: [USER_ROLES.EDIT_PARAMETERS],
    apis: [API_NAMES.CATALOG],
    component: ParameterSchemaUpdate,
    targetApi: API_NAMES.CATALOG,
    hidden: true,
  },
  {
    path: SIMULATION_ELIGIBILITY_PATH,
    name: 'Eligibility simulation',
    requiredRoles: [],
    apis: [],
    component: SimulationPage,
    targetApi: API_NAMES.CATALOG,
    hidden: false,
  },
  {
    path: SIMULATION_PRICING_PATH,
    name: 'Simulation Tarification',
    requiredRoles: [],
    apis: [],
    component: SimulationPricingPage,
    hidden: false,
  },
  {
    path: ELIGIBILITY_TEST_CASES_PATH,
    name: 'Test Cases',
    requiredRoles: [USER_ROLES.MANAGE_TEST_CASES],
    apis: [API_NAMES.CATALOG],
    component: TestCasesPage,
    hidden: false,
  },
  {
    path: DOMAINS_BASE_PATH,
    name: 'domains',
    component: DomainsListing,
    hidden: false,
    requiredRoles: [],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
  },
  {
    path: DOMAINS_CREATE_PATH,
    name: 'create_domain',
    component: DomainCreation,
    hidden: false,
    requiredRoles: [USER_ROLES.CREATE_DOMAINS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
  },
  {
    path: DOMAINS_UPDATE_PATH,
    name: 'update_domain',
    component: DomainUpdate,
    hidden: true,
    requiredRoles: [USER_ROLES.EDIT_DOMAINS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
  },
  {
    path: ADMIN_TECH_CONSULT_AUDIT_CATALOGUE_PATH,
    name: 'Catalogue',
    component: AuditCataloguePage,
    hidden: true,
    requiredRoles: [USER_ROLES.TECHNICAL_ADMIN],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
  },
  {
    path: ADMIN_TECH_CONSULT_AUDIT_PRICING_PATH,
    name: 'Tarification',
    component: AuditPricingPage,
    hidden: true,
    requiredRoles: [USER_ROLES.TECHNICAL_ADMIN],
    apis: [API_NAMES.EVENT_PRICING],
    targetApi: API_NAMES.EVENT_PRICING,
  },
  {
    path: ADMIN_TECH_CONSULT_AUDIT_FACTS_PATH,
    name: 'Facts',
    component: AuditFactsPage,
    hidden: true,
    requiredRoles: [USER_ROLES.TECHNICAL_ADMIN],
    apis: [API_NAMES.FACTS],
  },
  {
    path: RULE_ADD_PATH,
    name: 'Rule Add',
    component: RuleAddPage,
    requiredRoles: [
      USER_ROLES.EDIT_CATALOG_RULES,
      USER_ROLES.EDIT_EVENT_PRICING_RULES,
    ],
    apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING],
    hidden: true,
  },
  {
    path: RULE_BASE_PATH,
    name: 'rules',
    component: RuleListingPage,
    requiredRoles: [USER_ROLES.CONSULT_CATALOG_RULES],
    apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING],
    hidden: false,
  },
  {
    path: RULE_UPDATE_PATH,
    name: 'Rule Update',
    component: RuleUpdatePage,
    requiredRoles: [
      USER_ROLES.EDIT_CATALOG_RULES,
      USER_ROLES.EDIT_EVENT_PRICING_RULES,
    ],
    apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING],
    hidden: true,
  },
  {
    path: FORM_SCHEMAS_BASE_PATH,
    name: 'form_schemas',
    component: FormSchemaListing,
    hidden: true,
    requiredRoles: [USER_ROLES.CONSULT_FORM_SCHEMAS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
  },
  {
    path: FORM_SCHEMAS_EDIT_PATH,
    name: 'edit_form_schemas',
    component: FormSchemaUpdate,
    hidden: true,
    requiredRoles: [USER_ROLES.EDIT_FORM_SCHEMAS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
  },
  {
    path: FORM_SCHEMAS_PREVIEW_PATH,
    name: 'preview_form_schemas',
    component: FormSchemaPreview,
    hidden: true,
    requiredRoles: [USER_ROLES.EDIT_FORM_SCHEMAS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
  },
  {
    path: REUSABLE_FORM_SCHEMAS_BASE_PATH,
    name: 'reusable_form_schemas',
    component: ReusableFormSchemaListing,
    hidden: true,
    requiredRoles: [USER_ROLES.EDIT_FORM_SCHEMAS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
  },
  {
    path: REUSABLE_FORM_SCHEMAS_CREATE_PATH,
    name: 'create_reusable_form_schemas',
    component: ReusableFormSchemaCreate,
    hidden: true,
    requiredRoles: [USER_ROLES.EDIT_FORM_SCHEMAS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
  },
  {
    path: REUSABLE_FORM_SCHEMAS_EDIT_PATH,
    name: 'edit_reusable_form_schemas',
    component: ReusableFormSchemaUpdate,
    hidden: true,
    requiredRoles: [USER_ROLES.EDIT_FORM_SCHEMAS],
    apis: [API_NAMES.CATALOG],
    targetApi: API_NAMES.CATALOG,
  },
  {
    path: CACHING_BASE_PATH,
    name: 'caches',
    component: CachingListingPage,
    hidden: true,
    requiredRoles: [
      USER_ROLES.CONSULT_CATALOG_CACHE,
      USER_ROLES.CONSULT_EVENT_PRICING_CACHE,
    ],
    apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING],
  },
];

export const MENU = [
  {
    path: DASHBOARD_BASE_PATH,
    name: 'navbar.dashboard',
    parent: true,
    icon: <PresentationChartLine />,
  },
  {
    path: PRODUCT_BASE_PATH,
    name: 'navbar.catalog',
    icon: <FourSquares />,
    parent: true,
    children: [
      {
        path: PRODUCT_BASE_PATH,
        name: 'navbar.products',
        children: [
          {
            path: PRODUCT_BASE_PATH,
            name: 'navbar.manage_products',
          },
          {
            path: PRODUCT_ADD_PATH,
            name: 'navbar.add_product',
          },
        ],
      },
      {
        path: ELIGIBLE_ACT_BASE_PATH,
        name: 'navbar.eligibleActs',
        children: [
          {
            path: ELIGIBLE_ACT_BASE_PATH,
            name: 'navbar.manage_eligible_acts',
          },
          {
            path: ELIGIBLE_ACT_ADD_PATH,
            name: 'navbar.add_eligible_act',
          },
        ],
      },
      {
        path: RECOMMENDATION_BASE_PATH,
        name: 'navbar.recommendations',
        children: [
          {
            path: RECOMMENDATION_BASE_PATH,
            name: 'navbar.manage_recommendations',
          },
          {
            path: RECOMMENDATION_ADD_PATH,
            name: 'navbar.add_recommendation',
          },
        ],
      },
      {
        path: FORM_SCHEMAS_BASE_PATH,
        name: 'navbar.form_schemas',
        children: [
          {
            path: FORM_SCHEMAS_BASE_PATH + '?schemableType=products',
            name: 'navbar.product_form_schemas',
          },
          {
            path: FORM_SCHEMAS_BASE_PATH + '?schemableType=eligibleActs',
            name: 'navbar.eligible_acts_form_schemas',
          },
          {
            path: REUSABLE_FORM_SCHEMAS_BASE_PATH,
            name: 'navbar.reusable_form_schemas',
          },
        ],
      },
    ],
  },
  {
    path: PARAMETER_BASE_PATH,
    name: 'navbar.parameters',
    parent: true,
    icon: <AdjustmentsVertical />,
    children: [
      {
        path: PARAMETER_BASE_PATH,
        name: 'navbar.manage_parameters',
      },
      {
        path: DOMAINS_BASE_PATH,
        name: 'navbar.domains',
        children: [
          {
            path: DOMAINS_BASE_PATH,
            name: 'navbar.manage_domains',
          },
          {
            path: DOMAINS_CREATE_PATH,
            name: 'navbar.new_domain',
          },
        ],
      },
      {
        path: PARAMETER_SCHEMAS_BASE_PATH,
        name: 'navbar.parameter_schemas',
        children: [
          {
            path: PARAMETER_SCHEMAS_BASE_PATH,
            name: 'navbar.manage_parameter_schemas',
          },
          {
            path: PARAMETER_SCHEMAS_CREATE_PATH,
            name: 'navbar.new_parameter_schema',
          },
        ],
      },
      {
        path: RULE_BASE_PATH,
        name: 'navbar.rules',
        children: [
          {
            path: RULE_BASE_PATH,
            name: 'navbar.manage_rules',
          },
        ],
      },
    ],
  },
  {
    path: EVENT_PRICING_BASE_PATH,
    name: 'navbar.eventPricings',
    icon: <Banknotes />,
    parent: true,
    children: [
      {
        path: EVENT_PRICING_BASE_PATH,
        name: 'navbar.manage_event_pricings',
      },
      {
        path: EVENT_PRICING_ADD_PATH,
        name: 'navbar.add_event_pricing',
      },
    ],
  },
  {
    path: FACT_BASE_PATH,
    name: 'navbar.facts',
    icon: <InboxStack />,
    parent: true,
    children: [
      {
        path: FACT_BASE_PATH,
        name: 'navbar.manage_facts',
      },
      {
        path: FACT_ADD_PATH,
        name: 'navbar.add_fact',
      },
    ],
  },
  {
    path: TECHNICAL_ADMIN_BASE_PATH,
    name: 'navbar.technical_administration',
    icon: <CogSixTooth />,
    parent: true,
    apis: [API_NAMES.CATALOG],
    children: [
      {
        path: AUDIT_STATISTICS_PATH,
        name: 'navbar.audit_statistics',
        requiredRoles: [USER_ROLES.TECHNICAL_ADMIN],
        apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING, API_NAMES.FACTS],
      },
      {
        path: ADMIN_TECH_CONSULT_AUDIT_PATH,
        name: 'navbar.consult_audit',
        children: [
          {
            path: ADMIN_TECH_CONSULT_AUDIT_CATALOGUE_PATH,
            name: 'navbar.catalogue',
          },
          {
            path: ADMIN_TECH_CONSULT_AUDIT_PRICING_PATH,
            name: 'navbar.pricing',
          },
          {
            path: ADMIN_TECH_CONSULT_AUDIT_FACTS_PATH,
            name: 'navbar.facts',
          },
        ],
      },
      {
        path: TECHNICAL_SETTING_PATH,
        name: 'navbar.technical_Settings',
        apis: [API_NAMES.FACTS],
        requiredRoles: [USER_ROLES.TECHNICAL_ADMIN],
      },
      {
        path: USER_MANAGEMENT_BASE_PATH,
        name: 'navbar.user_management',
        isParent: true,
        requiredRoles: [
          USER_ROLES.SUPER_ADMIN_CATALOGUE,
          USER_ROLES.SUPER_ADMIN_PRICING,
        ],
        apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING],
        children: [
          {
            path: USERS_BASE_PATH,
            name: 'navbar.users',
          },
          {
            path: PROFILES_BASE_PATH,
            name: 'navbar.profiles',
          },
        ],
      },
      {
        path: CACHING_BASE_PATH,
        name: 'navbar.caches',
        requiredRoles: [
          USER_ROLES.CONSULT_CATALOG_CACHE,
          USER_ROLES.CONSULT_EVENT_PRICING_CACHE,
        ],
        apis: [API_NAMES.CATALOG, API_NAMES.EVENT_PRICING],
      },
    ],
  },
  {
    path: SIMULATION_PATH,
    name: 'navbar.simulation',
    icon: <PlayCircleOutlined />,
    parent: true,
    children: [
      {
        path: SIMULATION_ELIGIBILITY_PATH,
        name: 'navbar.eligibility',
      },
      {
        path: SIMULATION_PRICING_PATH,
        name: 'navbar.simulationPricing',
      },
      {
        path: ELIGIBILITY_TEST_CASES_PATH,
        name: 'navbar.testCases',
        requiredRoles: [USER_ROLES.MANAGE_TEST_CASES],
        apis: [API_NAMES.CATALOG],
      },
    ],
  },
];
