import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../generic/inputs/TextInput';
import InternationalizationInput from '../../../../generic/inputs/InternationalizationInput';
import { FileTextOutlined, QrcodeOutlined } from '@ant-design/icons';
import FileUpload from '../../../../generic/inputs/FileUpload';

const DocumentAttachmentForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Row gutter={[16, 12]}>
        <Col span={12}>
          <TextInput
            label={t('documentAttachment.functionalId')}
            name={`${namePrefix}.functionalId`}
            startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
        <Col span={12}>
          <InternationalizationInput
            name={`${namePrefix}.descriptions`}
            label={t('documentAttachment.descriptions')}
            startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
        <Col span={12}>
          <FileUpload name={`${namePrefix}.file`} />
        </Col>
      </Row>
    </div>
  );
};

export default DocumentAttachmentForm;
