import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from 'src/utils/mappers/mapperHelpers';

const mapBeneficiariesToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    min: elem.min,
    max: elem.max,
    details: (elem.details || []).map((elem) =>
      mapInternationalisationToData(elem),
    ),
  };
};
const mapVehiclesToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    min: elem.min,
    max: elem.max,
    details: (elem.details || []).map((elem) =>
      mapInternationalisationToData(elem),
    ),
  };
};
const mapDataToOptions = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapDataToInternationalisation(elem.label),
    amount: elem.amount,
    details: (elem.details || []).map((elem) =>
      mapDataToInternationalisation(elem),
    ),
  };
};
const mapOptionsToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    amount: elem.amount,
    details: (elem.details || []).map((elem) =>
      mapInternationalisationToData(elem),
    ),
  };
};
const mapDataToBeneficiaries = (data) => {
  return {
    functionalId: data.functionalId,
    label: mapDataToInternationalisation(data.label),
    min: data.min,
    max: data.max,
    details: (data.details || []).map((elem) =>
      mapDataToInternationalisation(elem),
    ),
  };
};
const mapDataToVehicles = (data) => {
  return {
    functionalId: data.functionalId,
    label: mapDataToInternationalisation(data.label),
    min: data.min,
    max: data.max,
    details: (data.details || []).map((elem) =>
      mapDataToInternationalisation(elem),
    ),
  };
};
const mapDataToGraveConcessions = (data) => {
  return {
    graveConcessionOptions: (data.graveConcessionOptions || []).map((option) =>
      mapDataToOptions(option),
    ),
    details: (data.details || []).map((elem) =>
      mapDataToInternationalisation(elem),
    ),
  };
};
const mapGraveConcessionsToData = (elem) => {
  return {
    graveConcessionOptions: (elem.graveConcessionOptions || []).map((option) =>
      mapOptionsToData(option),
    ),
    details: (elem.details || []).map((elem) =>
      mapInternationalisationToData(elem),
    ),
  };
};

const mapAssistanceOptionToData = (assistanceOption) => {
  return {
    functionalId: assistanceOption.functionalId,
    label: mapInternationalisationToData(assistanceOption.label),
    description: mapInternationalisationToData(assistanceOption.description),
    details: (assistanceOption.details || []).map((elem) =>
      mapInternationalisationToData(elem),
    ),
    beneficiaries: (assistanceOption.beneficiaries || []).map((beneficiary) =>
      mapBeneficiariesToData(beneficiary),
    ),
    vehicles: (assistanceOption.vehicles || []).map((vehicle) =>
      mapVehiclesToData(vehicle),
    ),
    graveConcessions: (assistanceOption.graveConcessions || []).map(
      (graveConcession) => mapGraveConcessionsToData(graveConcession),
    ),
  };
};
export const mapDataToAssistanceOption = (data) => {
  return {
    functionalId: data.functionalId,
    label: mapDataToInternationalisation(data.label),
    description: mapDataToInternationalisation(data.description),
    details: (data.details || []).map((elem) =>
      mapDataToInternationalisation(elem),
    ),
    beneficiaries: (data.beneficiaries || []).map((elem) =>
      mapDataToBeneficiaries(elem),
    ),
    vehicles: (data.vehicles || []).map((elem) => mapDataToVehicles(elem)),
    graveConcessions: (data.graveConcessions || []).map((elem) =>
      mapDataToGraveConcessions(elem),
    ),
  };
};

export const mapAssistanceToData = (assistance) => {
  return {
    assistanceOptionList: (assistance.assistanceOptionList || []).map(
      (assistanceOption) => mapAssistanceOptionToData(assistanceOption),
    ),
  };
};
export const mapDataToAssistance = (data) => {
  const assistanceOptionList = (data.assistanceOptionList || []).map((elem) =>
    mapDataToAssistanceOption(elem),
  );

  return {
    assistanceOptionList,
    type: 'assistance',
  };
};
