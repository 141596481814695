export function mapUsersToTableData(users) {
  if (!users) return [];
  return users?.content.map((user) => ({
    key: user.id,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    username: user.username,
    profile: user.profile.name,
    roles: user.profile.roles,
    status: user.status,
  }));
}
