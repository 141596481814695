export const getChartFilters = (t) => {
  return [
    {
      functionalId: 'family',
      name: t('dashboard.family'),
    },
    {
      functionalId: 'provider',
      name: t('dashboard.provider'),
    },
    {
      functionalId: 'groups',
      name: t('dashboard.groups'),
    },
    {
      functionalId: 'sellingModes',
      name: t('dashboard.sellingModes'),
    },
    {
      functionalId: 'canals',
      name: t('dashboard.canals'),
    },
  ];
};
