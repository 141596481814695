import TextInput from 'src/components/generic/inputs/TextInput/TextInput';
import { AimOutlined, DollarCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Amount from '../../Amount/Amount';
import ParameterSelect from '../../../../generic/inputs/ParameterSelect';

export default function PercentagePricingForm({ namePrefix }) {
  const { t } = useTranslation();
  return (
    <>
      <Amount name={`${namePrefix}.min`} label={t('pricing.min')} />
      <Amount name={`${namePrefix}.max`} label={t('pricing.max')} />

      <ParameterSelect
        name={`${namePrefix}.amountType`}
        label={t('pricing.amountType')}
        domain="AMOUNT_TYPE"
        startAdornment={<AimOutlined style={{ fontSize: '25px' }} />}
      />

      <TextInput
        label={t('pricing.percentagePrice')}
        type="number"
        name={`${namePrefix}.percentagePrice`}
        startAdornment={<DollarCircleOutlined style={{ fontSize: '25px' }} />}
        endAdornment={<span className="text-lg ml-2">%</span>}
      />
    </>
  );
}
