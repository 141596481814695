import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { PLAFOND_DEFAULT } from 'src/utils/defaultsSupplier';
import PlafondForm from '../PlafondForm';

export default function Plafond(props) {
  const { namePrefix } = props;
  const name = namePrefix ? `${namePrefix}.plafonds` : 'plafonds';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="plafond"
      Form={PlafondForm}
      defaultValues={PLAFOND_DEFAULT}
      fieldsNames={['operation', 'devise']}
    />
  );
}

Plafond.propTypes = {
  namePrefix: PropTypes.string,
};
