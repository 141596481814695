import { Col } from 'antd';
import { useSelector } from 'react-redux';

export default function ArrayAccordionPreviewFields(props) {
  const { fieldsNames, array, index, t, translationPrefix } = props;
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage,
  );

  if (!fieldsNames?.length) return null;
  if (fieldsNames.length > 3) {
    throw new Error("Can't have more than 3 fields");
  }
  if (!array || !array[index]) return null;

  const columnSpan = 18 / fieldsNames.length;

  const renderField = (name) => {
    if (typeof array[index][name] === 'string') return array[index][name];
    if (typeof array[index][name] === 'number') return array[index][name];
    if (typeof array[index][name] === 'object' && array[index][name])
      return array[index][name][selectedLang] || '';
    return '';
  };

  return fieldsNames.map((name) => (
    <Col className="text-gray-500" key={name} span={columnSpan}>
      {t(`${translationPrefix}.${name}`) + ' : ' + renderField(name)}
    </Col>
  ));
}
