import Form from 'src/components/generic/Form';
import { CONSTRAINT_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/constraintsSchema';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';
import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { useTranslation } from 'react-i18next';

function PublicationConstraintsForm({ onSubmit, defaultValues }) {
  const { t } = useTranslation();
  const { closeModal } = useModalContext();
  return (
    <div>
      <h5 className="text-center font-medium">
        {t('versioning.publicationConstraints')}
      </h5>
      <Form
        defaultValues={defaultValues}
        validationSchema={CONSTRAINT_VALIDATION_SCHEMA}
        onSubmit={onSubmit}
        onCancel={closeModal}
      >
        <ConstraintWrapper className={'pb-4'} />
      </Form>
    </div>
  );
}

export { PublicationConstraintsForm };
