import PropTypes from 'prop-types';
import { getOptions } from '../../../../utils/mappers/targetOptionMapper/targetOptionMapper';
import { getAllProductsWithoutPagination } from '../../../../api';
import ProductSelect from '../../../generic/inputs/ProductMultiSelect';

const PossessionForm = (props) => {
  const { namePrefix } = props;

  return (
    <div className="w-full">
      <ProductSelect
        getProducts={getAllProductsWithoutPagination}
        translationPrefix="constraints"
        namePrefix={namePrefix}
        getOptions={getOptions}
      />
    </div>
  );
};

PossessionForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default PossessionForm;
