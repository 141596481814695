import * as yup from 'yup';

function YupLocaleProvider({ i18n, children }) {
  i18n.on('languageChanged', function () {
    const { t } = i18n;
    yup.setLocale({
      mixed: {
        default: t('form_validation.invalid'),
        required: ({ path }) =>
          t('form_validation.required', { field: t(`form.${path}`) }),
      },
      array: {
        min: ({ min, path }) =>
          t('form_validation.array_min', {
            field: t(`form.${path}`).toLowerCase(),
            min,
          }),
      },
    });
  });

  return children;
}

export { YupLocaleProvider };
