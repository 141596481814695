import React from 'react';
import AuditTabHandler from '../../../components/generic/AuditTabHandler';

export const AuditAdminPricingPage = () => {
  const tabs = [
    {
      tabName: 'navbar.adminPricing',
      key: 'adminPricing',
    },
    {
      tabName: 'navbar.usagePricing',
      key: 'usagePricing',
    },
  ];

  return <AuditTabHandler tabs={tabs} defaultTab={'adminPricing'} />;
};

export default AuditAdminPricingPage;
