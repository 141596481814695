import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useShowMessage } from '../../useShowMessage';
import { useTranslation } from 'react-i18next';

export const useAddMutation = ({
  add,
  basePath,
  translationPrefix,
  onSettled,
  onSuccess,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showSuccess, showError } = useShowMessage();

  return useMutation(add, {
    onSuccess: onSuccess
      ? onSuccess
      : (res) => {
          showSuccess(t(translationPrefix + '.added'));
          navigate(basePath + '/update/' + res.id);
        },
    onError: (err) => {
      showError(err);
    },
    onSettled,
  });
};
