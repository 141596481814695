import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from '../../mapperHelpers';

const mapOptionToData = (option) => {
  return {
    description: mapInternationalisationToData(option.description),
    additionalPrice: option.additionalPrice,
    periodicity: option.periodicity,
    remarks: option.remarks,
  };
};

const mapDataToOption = (data) => {
  return {
    description: mapDataToInternationalisation(data.description),
    additionalPrice: data.additionalPrice,
    periodicity: data.periodicity,
    remarks: data.remarks,
  };
};

const mapDataToModule = (data) => {
  return {
    functionalId: data.functionalId,
    codeProvider: data.codeProvider,
    label: mapDataToInternationalisation(data.label),
    optional: data.optional,
    options: data.options.map(mapDataToOption),
  };
};
const mapModuleToData = (offerModule) => {
  return {
    functionalId: offerModule.functionalId,
    codeProvider: offerModule.codeProvider,
    label: mapInternationalisationToData(offerModule.label),
    optional: !!offerModule.optional,
    options: offerModule.options.map(mapOptionToData),
  };
};

const mapOfferToData = (offer) => {
  return {
    functionalId: offer.functionalId,
    codeOfferTech: offer.codeOfferTech,
    label: mapInternationalisationToData(offer.label),
    description: mapInternationalisationToData(offer.description),
    modules: offer.modules.map(mapModuleToData),
  };
};

const mapDataToOffer = (data) => {
  return {
    functionalId: data.functionalId,
    codeOfferTech: data.codeOfferTech,
    label: mapDataToInternationalisation(data.label),
    description: mapDataToInternationalisation(data.description),
    modules: data.modules.map(mapDataToModule),
  };
};

export const mapBankOnlineToData = (bankOnline) => {
  return {
    offers: bankOnline.offers.map(mapOfferToData),
  };
};

export const mapDataToBankOnline = (data) => {
  return {
    type: 'bankOnline',
    offers: data.offers.map(mapDataToOffer),
  };
};
