import { useQuery } from 'react-query';
import {
  getAllProductsCodes,
  getCatalogAuditableActions,
  getEligibilityStatisticsByFilters,
  getPricingAuditableActions,
  getPricingStatisticsByFilter,
  getProductStatisticsByFilter,
  getTop5CatalogActs,
  getTop5ConstraintsCatalog,
  getTop5ConstraintsPricing,
  getTopPricingActs,
} from 'src/api';
import { ActionType, API_NAMES } from 'src/utils/constants';
import { APIS_TOP5, TOP5_TYPES } from 'src/components/dashboard/constaint';
import { getFactsStatisticsByFilter } from 'src/api/audit/factsStatistics/factsAuditStatistics';

const statisticsHookByApiAndDomain = {
  [API_NAMES.CATALOG]: {
    [ActionType.usageEligibility]: getEligibilityStatisticsByFilters,
    [ActionType.usageProduct]: getProductStatisticsByFilter,
  },
  [API_NAMES.EVENT_PRICING]: {
    [ActionType.usagePricing]: getPricingStatisticsByFilter,
  },
  [API_NAMES.FACTS]: {
    [ActionType.usageFacts]: getFactsStatisticsByFilter,
  },
};

const top5HookByApiAndType = {
  [APIS_TOP5.EVENT_PRICING]: {
    [TOP5_TYPES[APIS_TOP5.EVENT_PRICING].CONSTRAINT]: getTop5ConstraintsPricing,
    [TOP5_TYPES[APIS_TOP5.EVENT_PRICING].ACT]: getTopPricingActs,
  },
  [APIS_TOP5.CATALOG]: {
    [TOP5_TYPES[APIS_TOP5.CATALOG].CONSTRAINT]: getTop5ConstraintsCatalog,
    [TOP5_TYPES[APIS_TOP5.CATALOG].ACT]: getTop5CatalogActs,
  },
};

function useGetTOP5ByApiAndType(api, type) {
  let catalogueQueryKey = 'get-top5';
  let queryFn = () => top5HookByApiAndType[api][type]();
  return useQuery([catalogueQueryKey, type, api], queryFn);
}

function useGetAllProductCodes() {
  return useQuery(['productsCodes'], () => getAllProductsCodes());
}

function useGetStatisticsByApiAndDomainAndFilter(api, domain, currentFilter) {
  let catalogueQueryKey = 'get-statistics';
  let queryFn = () => statisticsHookByApiAndDomain[api][domain](currentFilter);
  return useQuery([catalogueQueryKey, domain, currentFilter], queryFn);
}

function useGetAuditableActions(domain) {
  let auditableActionsQueryKey = 'get-auditable-actions';
  let queryFn =
    domain === 'usagePricing'
      ? () => getPricingAuditableActions(domain)
      : () => getCatalogAuditableActions(domain);
  return useQuery([auditableActionsQueryKey, domain], queryFn);
}

export {
  useGetAllProductCodes,
  useGetStatisticsByApiAndDomainAndFilter,
  useGetAuditableActions,
  useGetTOP5ByApiAndType,
};
