import { JSONViewerModal } from 'src/components/generic/JSONViewerModal/JSONViewerModal';
import { formattedDate } from './mappers/dateMapper';

export const adminColumns = [
  {
    title: 'administration.auditEventDate',
    dataIndex: 'auditEventDate',
    key: 'auditEventDate',
    render: (text) => formattedDate(text),
  },
  {
    title: 'administration.eventType',
    dataIndex: 'auditEventType',
    key: 'auditEventType',
  },
  {
    title: 'administration.correlationId',
    dataIndex: 'correlationId',
    key: 'correlationId',
  },
  {
    title: 'administration.clientId',
    dataIndex: 'clientId',
    key: 'clientId',
  },
  {
    title: 'administration.userName',
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: 'administration.matricule',
    dataIndex: 'matricule',
    key: 'matricule',
  },
  {
    title: 'administration.status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'administration.userIp',
    dataIndex: 'userIp',
    key: 'userIp',
  },
  {
    title: 'administration.profile',
    dataIndex: 'profile',
    key: 'profile',
  },
  {
    title: 'administration.failureMessage',
    dataIndex: 'failureMessage',
    key: 'failureMessage',
  },
  {
    title: 'administration.payLoad',
    dataIndex: 'payLoad',
    key: 'payLoad',
    render: (text, record) => (
      <JSONViewerModal
        payload={record.payLoad}
        maxLength={30}
      ></JSONViewerModal>
    ),
  },
];
export const usagePricingColumns = [
  {
    title: 'administration.auditEventDate',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (text) => formattedDate(text),
  },
  {
    title: 'administration.eventType',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'administration.correlationId',
    dataIndex: 'correlationId',
    key: 'correlationId',
  },
  {
    title: 'administration.requestId',
    dataIndex: 'requestId',
    key: 'requestId',
  },
  {
    title: 'administration.clientId',
    dataIndex: 'clientId',
    key: 'clientId',
  },
  {
    title: 'administration.userName',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'administration.matricule',
    dataIndex: 'matricule',
    key: 'matricule',
  },
  {
    title: 'administration.act',
    dataIndex: 'act',
    key: 'act',
  },
  {
    title: 'administration.canal',
    dataIndex: 'canal',
    key: 'canal',
  },
  {
    title: 'administration.userIp',
    dataIndex: 'userIp',
    key: 'userIp',
  },
  {
    title: 'administration.profile',
    dataIndex: 'profile',
    key: 'profile',
  },
  {
    title: 'administration.executionTime',
    dataIndex: 'executionTime',
    key: 'executionTime',
    render: (text) => (text ? text + ' ms' : ''),
  },
  {
    title: 'administration.payLoad',
    dataIndex: 'payload',
    key: 'payload',
    render: (_, record) => (
      <JSONViewerModal payload={record.payLoad}></JSONViewerModal>
    ),
  },
];
export const usageEligibilityColumns = [
  {
    title: 'administration.auditEventDate',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (text) => formattedDate(text),
  },
  {
    title: 'administration.eventType',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'administration.correlationId',
    dataIndex: 'correlationId',
    key: 'correlationId',
  },
  {
    title: 'administration.requestId',
    dataIndex: 'requestId',
    key: 'requestId',
  },
  {
    title: 'administration.clientId',
    dataIndex: 'clientId',
    key: 'clientId',
  },
  {
    title: 'administration.userName',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'administration.matricule',
    dataIndex: 'matricule',
    key: 'matricule',
  },
  {
    title: 'administration.act',
    dataIndex: 'act',
    key: 'act',
  },
  {
    title: 'administration.canal',
    dataIndex: 'canal',
    key: 'canal',
  },
  {
    title: 'administration.userIp',
    dataIndex: 'userIp',
    key: 'userIp',
  },
  {
    title: 'administration.profile',
    dataIndex: 'profile',
    key: 'profile',
  },
  {
    title: 'administration.executionTime',
    dataIndex: 'executionTime',
    key: 'executionTime',
    render: (text) => (text ? text + ' ms' : ''),
  },
  {
    title: 'administration.payLoad',
    dataIndex: 'payload',
    key: 'payload',
    render: (_, record) => (
      <JSONViewerModal payload={record.payLoad}></JSONViewerModal>
    ),
  },
];
export const usageProductColumns = [
  {
    title: 'administration.auditEventDate',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (text) => formattedDate(text),
  },
  {
    title: 'administration.eventType',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'administration.correlationId',
    dataIndex: 'correlationId',
    key: 'correlationId',
  },
  {
    title: 'administration.requestId',
    dataIndex: 'requestId',
    key: 'requestId',
  },
  {
    title: 'administration.clientId',
    dataIndex: 'clientId',
    key: 'clientId',
  },
  {
    title: 'administration.userName',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'administration.matricule',
    dataIndex: 'matricule',
    key: 'matricule',
  },
  {
    title: 'administration.productId',
    dataIndex: 'productId',
    key: 'productId',
  },
  {
    title: 'administration.canal',
    dataIndex: 'canal',
    key: 'canal',
  },
  {
    title: 'administration.userIp',
    dataIndex: 'userIp',
    key: 'userIp',
  },
  {
    title: 'administration.profile',
    dataIndex: 'profile',
    key: 'profile',
  },
  {
    title: 'administration.executionTime',
    dataIndex: 'executionTime',
    key: 'executionTime',
    render: (text) => (text ? text + ' ms' : ''),
  },
  {
    title: 'administration.payLoad',
    dataIndex: 'payload',
    key: 'payload',
    render: (_, record) => (
      <JSONViewerModal payload={record.payLoad}></JSONViewerModal>
    ),
  },
];
export const usageFactsColumns = [
  {
    title: 'administration.auditEventDate',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (text) => formattedDate(text),
  },
  {
    title: 'administration.eventType',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'administration.correlationId',
    dataIndex: 'correlationId',
    key: 'correlationId',
  },
  {
    title: 'administration.requestId',
    dataIndex: 'requestId',
    key: 'requestId',
  },
  {
    title: 'administration.clientId',
    dataIndex: 'clientId',
    key: 'clientId',
  },
  {
    title: 'administration.userName',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'administration.matricule',
    dataIndex: 'matricule',
    key: 'matricule',
  },
  {
    title: 'administration.canal',
    dataIndex: 'canal',
    key: 'canal',
  },
  {
    title: 'administration.userIp',
    dataIndex: 'userIp',
    key: 'userIp',
  },
  {
    title: 'administration.profile',
    dataIndex: 'profile',
    key: 'profile',
  },
  {
    title: 'administration.executionTime',
    dataIndex: 'executionTime',
    key: 'executionTime',
    render: (text) => (text ? text + ' ms' : ''),
  },
  {
    title: 'administration.payLoad',
    dataIndex: 'payload',
    key: 'payload',
    render: (_, record) => (
      <JSONViewerModal payload={record.payLoad}></JSONViewerModal>
    ),
  },
];
