import PropTypes from 'prop-types';
import { getAuditEventTypes } from '../../../../api/audit';
import DataMultiSelect from '../DataMultiSelect';
import { useQuery } from 'react-query';
import {
  auditCataloguePricingTypes,
  auditTabKeyMap,
} from 'src/utils/constants/auditTabKeyMap';
import { API_NAMES } from 'src/utils/constants';

const API_FETCHER_NAME_MAP = [
  {
    supports: (typeDomain) =>
      auditCataloguePricingTypes.catalogue.includes(typeDomain),
    handle: (typeDomain) =>
      getAuditEventTypes(auditTabKeyMap[typeDomain], API_NAMES.CATALOG),
  },
  {
    supports: (typeDomain) =>
      auditCataloguePricingTypes.pricing.includes(typeDomain),
    handle: (typeDomain) =>
      getAuditEventTypes(auditTabKeyMap[typeDomain], API_NAMES.EVENT_PRICING),
  },
  {
    supports: (typeDomain) =>
      auditCataloguePricingTypes.facts.includes(typeDomain),
    handle: (typeDomain) =>
      getAuditEventTypes(auditTabKeyMap[typeDomain], API_NAMES.FACTS),
  },
];

export const fetcherQuery = (typeDomain) => {
  const resolver = API_FETCHER_NAME_MAP.find((fetcher) =>
    fetcher.supports(typeDomain),
  );
  if (!resolver) {
    return Promise.reject('unknown domain');
  }
  return resolver.handle(typeDomain);
};
export default function AuditEventTypesMultiSelect(props) {
  const { name, label, className, typeDomain } = props;

  const { data: eventTypes, isLoading } = useQuery([typeDomain], () =>
    fetcherQuery(typeDomain),
  );

  const parseData = (data) => {
    return data.map((item) => ({ name: item, functionalId: item }));
  };

  return (
    !isLoading && (
      <DataMultiSelect
        name={name}
        label={label}
        className={className}
        data={parseData(eventTypes)}
      />
    )
  );
}

AuditEventTypesMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  typeDomain: PropTypes.string,
};
