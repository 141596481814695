export const auditTabKeyMap = {
  adminCatalogue: 'admin/catalogue',
  usageProduct: 'usage/product',
  usageEligibility: 'usage/eligibility',
  adminPricing: 'admin/pricing',
  usagePricing: 'usage/pricing',
  adminFacts: 'admin/facts',
  usageFacts: 'usage/facts',
};
export const auditCataloguePricingTypes = {
  catalogue: ['adminCatalogue', 'usageProduct', 'usageEligibility'],
  pricing: ['adminPricing', 'usagePricing'],
  facts: ['adminFacts', 'usageFacts'],
};
