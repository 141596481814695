import { Fn, ParameterDTO } from 'src/types';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { getParametersByDomain } from 'src/api';
import { addParameters } from 'src/api/parameter/parameter';

export interface ParametersStore {
  getByDomain: (
    domain: string,
    query?: string,
  ) => UseQueryResult<ParameterDTO[]>;

  addParametersList: Fn<void, UseMutationResult<void, void, ParameterDTO[]>>;
}
export function useParametersStore(): ParametersStore {
  const baseKey = 'parameters';
  const client = useQueryClient();

  const getByDomain = (domain: string, query?: string) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery<ParameterDTO[]>({
      queryKey: [baseKey, domain, query],
      queryFn: () => getParametersByDomain(domain, query),
    });
  const addParametersList = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation<void, void, ParameterDTO[]>({
      mutationFn: (data) => {
        return addParameters(data);
      },
      onSuccess: () => {
        client.invalidateQueries(['parametersByDomain']);
      },
    });

  return { getByDomain, addParametersList };
}
