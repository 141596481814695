import {
  AimOutlined,
  HighlightOutlined,
  QrcodeOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  getEventPricingById,
  getEventPricingVersions,
} from 'src/api/eventPricing';
import CardDisplay from 'src/components/generic/CardDisplay';
import Form from 'src/components/generic/Form';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import ParameterMultiSelect from 'src/components/generic/inputs/ParameterMultiSelect';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import Loading from 'src/components/generic/Loading';
import VersionsTimeLine from 'src/components/product/form/generic/VersionsTimeLine';
import useVersions from 'src/hooks/normalHooks/useVersions';
import { Error500 } from 'src/pages/error';
import { EVENT_PRICING_BASE_PATH } from 'src/routes/paths';
import { EVENT_PRICING_DEFAULT } from 'src/utils/defaultsSupplier';
import { getTargetTypes } from 'src/utils/getTargetTypes';
import { mapEventPricingToData } from 'src/utils/mappers/eventPricingMapper';
import { EVENT_PRICING_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/eventPricingSchema';
import Commission from 'src/components/eventPricing/Commission/Commission';
import Targets from 'src/components/eventPricing/Targets';
import ExportImport from 'src/components/generic/ExportImport';
import { PROCESSING_PHASE } from 'src/components/eventPricing/EventPricingForm/constants';
import { lazy, Suspense } from 'react';
import { USER_ROLES } from 'src/utils/constants';
import { AuthFormDisabler } from 'src/components/generic/AuthFormDisabler';
import { useCompareVersions } from 'src/hooks/componentHooks/useCompareVersions';
import CalculatorComponent from '../../generic/CalculatorComponent/CalculatorComponent';

const PricingProcessorsContainer = lazy(() =>
  import(
    'src/components/eventPricing/EventPricingForm/containers/PricingProcessorsContainer'
  ),
);

export default function EventPricingForm(props) {
  const {
    onSubmit,
    eventPricing,
    submitDisabled,
    additionalButtons,
    isUpdateForm,
    formDisabled,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { versions, isLoading, isError } = useVersions(
    eventPricing?.id,
    getEventPricingVersions,
  );

  const { onCompare } = useCompareVersions({
    versionFetcherQuery: (id) => getEventPricingById(id),
    versions,
  });

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;

  return (
    <>
      <CalculatorComponent />
      <div className="grid grid-cols-5">
        <CardDisplay title="Versions" titleCentered={true} className="h-fit">
          <VersionsTimeLine
            versions={versions}
            path="/event-pricings/update/"
            onCompare={onCompare}
          />
        </CardDisplay>
        <div className="col-span-4">
          <AuthFormDisabler
            componentRender={({ isAuth }) => (
              <Form
                onSubmit={onSubmit}
                submitDisabled={submitDisabled}
                formDisabled={formDisabled || !isAuth}
                defaultValues={
                  eventPricing
                    ? mapEventPricingToData(eventPricing)
                    : EVENT_PRICING_DEFAULT
                }
                onCancel={() => navigate(EVENT_PRICING_BASE_PATH)}
                validationSchema={EVENT_PRICING_VALIDATION_SCHEMA}
                additionalButtons={
                  <>
                    {additionalButtons}
                    <ExportImport dataName="eventPricing" />
                  </>
                }
              >
                <Row gutter={[16, 12]} className="mb-5">
                  <Col span={12}>
                    <TextInput
                      name="functionalId"
                      label={t('eventPricing.functionalId')}
                      startAdornment={
                        <QrcodeOutlined style={{ fontSize: '25px' }} />
                      }
                      disabled={isUpdateForm}
                    />
                  </Col>
                  <Col span={12}>
                    <InternationalizationInput
                      name="descriptions"
                      label={t('eventPricing.description')}
                      placeholder="Description"
                    />
                  </Col>
                  <Col span={12}>
                    <SelectInput
                      name="targetType"
                      label={t('eventPricing.targetType')}
                      data={getTargetTypes()}
                      startAdornment={
                        <AimOutlined style={{ fontSize: '25px' }} />
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <Targets namePrefix="targets" />
                  </Col>
                  <Col span={12}>
                    <ParameterSelect
                      name="act"
                      label={t('eventPricing.act')}
                      domain="ACT"
                      startAdornment={
                        <HighlightOutlined style={{ fontSize: '25px' }} />
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <ParameterMultiSelect
                      name="tags"
                      label={t('eventPricing.tags')}
                      domain="TAG"
                      startAdornment={
                        <TagsOutlined style={{ fontSize: '25px' }} />
                      }
                    />
                  </Col>
                  <Col span={24}>
                    <Suspense fallback={null}>
                      <PricingProcessorsContainer
                        phase={PROCESSING_PHASE.PRE}
                      />
                    </Suspense>
                  </Col>
                  <Col span={24}>
                    <Commission />
                  </Col>
                  <Col span={24}>
                    <Suspense fallback={null}>
                      <PricingProcessorsContainer
                        phase={PROCESSING_PHASE.POST}
                      />
                    </Suspense>
                  </Col>
                </Row>
              </Form>
            )}
            roles={[USER_ROLES.EDIT_PRICINGS]}
          />
        </div>
      </div>
    </>
  );
}
