import { Button } from 'antd';
import { useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getPublishedProductsWithoutPagination } from 'src/api';
import CardDisplay from 'src/components/generic/CardDisplay';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import ProductSelect from 'src/components/generic/inputs/ProductMultiSelect/ProductSelect';

import { FactsContext } from '../context/FactsContext';
import useSelectProductSimulationForm from './useSelectProductSimulationForm';

export default function SelectProductSimulation() {
  const { t } = useTranslation();

  const { setEventChoice, setFacts, setChosenFacts } = useContext(FactsContext);

  const { form, handleSubmit } = useSelectProductSimulationForm();

  const onSubmit = async (data) => {
    setEventChoice({ ...data });
    setFacts(null);
    setChosenFacts([]);
  };

  return (
    <FormProvider {...form} className="p-5">
      <CardDisplay
        title={t('simulation.productCardTitle')}
        titleCentered={true}
      >
        <div className="grid grid-cols-2 gap-3">
          <div>
            <ProductSelect
              getProducts={getPublishedProductsWithoutPagination}
              translationPrefix={'simulation'}
              namePrefix="product"
              name="product"
            />
          </div>
          <div>
            <ParameterSelect
              name="act"
              label={t('simulation.event')}
              domain="ACT"
            />
          </div>
        </div>
        <div className="flex justify-end pt-4">
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={!form.formState.isValid}
            data-testid="extract-button"
            className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
          >
            {t('simulation.extractFacts')}
          </Button>
        </div>
      </CardDisplay>
    </FormProvider>
  );
}
