import HttpService from 'src/services/HttpService';
import { AUDIT_STATISTICS_PATH } from 'src/api/apiPaths';
import { API_NAMES } from 'src/utils/constants';

const instance = HttpService.getAxiosClient(API_NAMES.FACTS);
const FACTS_PATH = '/facts';

export const getFactsStatisticsByFilter = async (filter) => {
  const params = {
    canal: filter.canal,
    action: filter.auditableAction,
  };
  const res = await instance.get(AUDIT_STATISTICS_PATH + FACTS_PATH, {
    params,
  });
  return res.data;
};
