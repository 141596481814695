import * as yup from 'yup';

export const USER_VALIDATION_SCHEMA = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    username: yup.string().required(),
    //profileName: yup.string().required(),
    //status: yup.string().required(),
  })
  .required();
