import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addEligibleAct } from 'src/api/eligibleAct';
import EligibleActForm from 'src/components/eligibleAct/form/EligibleActForm';
import CardDisplay from 'src/components/generic/CardDisplay';
import { useAddMutation } from 'src/hooks/normalHooks/mutation/useAddMutation';
import { ELIGIBLE_ACT_BASE_PATH } from 'src/routes/paths';
import { mapDataToEligibleAct } from 'src/utils/mappers/eligibleActMapper';

function EligibleActAddPage() {
  const { t } = useTranslation();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const eligibleActAddMutation = useAddMutation({
    add: addEligibleAct,
    basePath: ELIGIBLE_ACT_BASE_PATH,
    translationPrefix: 'eligibleAct',
    onSettled: () => setSubmitDisabled(false),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    const eligibleAct = mapDataToEligibleAct(data);
    eligibleActAddMutation.mutate(eligibleAct);
  };

  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t('eligibleAct.add')}>
        <EligibleActForm submitDisabled={submitDisabled} onSubmit={onSubmit} />
      </CardDisplay>
    </div>
  );
}

export default EligibleActAddPage;
