import {
  BarcodeOutlined,
  QrcodeOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import ModuleOption from '../ModuleOption';

export default function ModuleForm(props) {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]}>
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('modules.functionalId')}
          startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.codeProvider`}
          label={t('modules.codeProvider')}
          startAdornment={<BarcodeOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.label`}
          label={t('modules.label')}
          startAdornment={<TagOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <SwitchInput
          name={`${namePrefix}.optional`}
          label={t('modules.optional')}
        />
      </Col>
      <Col span={24}>
        <ModuleOption namePrefix={`${namePrefix}.options`} />
      </Col>
    </Row>
  );
}
