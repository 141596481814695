import { PUBLICATION_STATUS } from 'src/utils/constants/publicationStatus';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';
import { Button } from 'antd';
import modalConfirm from 'src/utils/modalConfirm';
import getUserRolesByTypeAndResource from 'src/utils/authUtils';
import React from 'react';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function UnPublishButtonRenderer({
  element,
  unpublishDraft,
  updateUrl,
  resource,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <AuthComponentDisabler
      componentRender={() => (
        <Button
          data-testid="unpublish-button"
          type="primary"
          ghost
          onClick={() => {
            modalConfirm(t('versioning.unpublishMessage'), () =>
              unpublishDraft(element.id).then((res) => {
                navigate(
                  typeof updateUrl === 'function'
                    ? updateUrl(res.id)
                    : updateUrl + res.id,
                );
                window.location.reload();
              }),
            );
          }}
        >
          {t('versioning.unpublish')}
        </Button>
      )}
      roles={getUserRolesByTypeAndResource('PUBLISH', resource)}
    />
  );
}

const UNPUBLISH_BUTTON_RESOLVER = [
  {
    id: 'unpublish-partially-published-btn',
    handler: ({ element }) => {
      return element.status === PUBLICATION_STATUS.PARTIALLY_PUBLISHED;
    },
    buttonRenderer: ({ element, unpublishDraft, updateUrl, resource }) => (
      <UnPublishButtonRenderer
        updateUrl={updateUrl}
        element={element}
        unpublishDraft={unpublishDraft}
        resource={resource}
      />
    ),
  },
  {
    id: 'unpublish-published-btn',
    handler: ({ element }) => {
      return element.status === PUBLICATION_STATUS.PUBLISHED;
    },
    buttonRenderer: ({ element, unpublishDraft, updateUrl, resource }) => (
      <UnPublishButtonRenderer
        element={element}
        unpublishDraft={unpublishDraft}
        updateUrl={updateUrl}
        resource={resource}
      />
    ),
  },
];

function UnPublishButton({ element, unpublishDraft, updateUrl, resource }) {
  const { userContext, currentApi } = useUserContext();
  return UNPUBLISH_BUTTON_RESOLVER.map((item) => {
    const handler = item.handler({ element, userContext, currentApi });
    if (!handler) {
      return null;
    }

    return (
      <div key={item.id}>
        {item.buttonRenderer({
          element,
          unpublishDraft,
          updateUrl,
          resource,
        })}
      </div>
    );
  }).filter((v) => !!v);
}

export { UnPublishButton };
