import React from 'react';

const ReusableFormSchemaContainer = React.lazy(
  () => import('src/pages/formSchemas/containers/ReusableFormSchemaContainer'),
);

export default function ReusableFormSchemaCreate() {
  return (
    <React.Suspense fallback={null}>
      <ReusableFormSchemaContainer />
    </React.Suspense>
  );
}
