import { CALCULATOR_PATH } from 'src/api/apiPaths';
import HttpService from '../../services/HttpService';

const instance = HttpService.getAxiosClient('eventPricing');

export const calculateHt = async (ttc, tva) => {
  const res = await instance.get(`${CALCULATOR_PATH}/calculate-ht`, {
    params: {
      ttc,
      tva: Number(tva),
    },
  });
  return res.data;
};

export const calculateTtc = async (ht, tva) => {
  const res = await instance.get(`${CALCULATOR_PATH}/calculate-ttc`, {
    params: {
      ht,
      tva: Number(tva),
    },
  });
  return res.data;
};

export const calculateDiscountRate = async (originalAmount, finalAmount) => {
  const res = await instance.get(`${CALCULATOR_PATH}/calculate-discount-rate`, {
    params: {
      originalAmount,
      finalAmount,
    },
  });
  return res.data;
};

export const calculateDiscountedPrice = async (amount, rate) => {
  const res = await instance.get(
    `${CALCULATOR_PATH}/calculate-discounted-price`,
    {
      params: {
        amount,
        rate,
      },
    },
  );
  return res.data;
};
