import HttpService from '../../services/HttpService';
import {
  ELIGIBILITY_SIMULATION_PATH,
  PRICING_SIMULATION_PATH,
  PRICING_FACTS_PATH,
  PRODUCT_PATH,
} from '../apiPaths';

const instance = HttpService.getAxiosClient('catalogue_usage');

const instancePricingUsage = HttpService.getAxiosClient('eventPricingUsage');

export const eligibilitySimulation = async (facts) => {
  const res = await instance.get(ELIGIBILITY_SIMULATION_PATH, {
    params: { ...facts },
  });
  return res.data;
};

export const pricingSimulation = async (facts) => {
  const res = await instancePricingUsage.get(PRICING_SIMULATION_PATH, {
    params: { ...facts },
  });
  return res.data;
};
export const pricingFacts = async (data) => {
  const { productId, actId } = data;
  const res = await instancePricingUsage.get(PRICING_FACTS_PATH, {
    params: { productId, actId },
  });
  return res.data;
};
export const getFacts = async (data) => {
  const { product, act } = data;
  const res = await instance.get(`${PRODUCT_PATH}/constraints/facts`, {
    params: { productId: product.product, actId: act },
  });
  return res.data;
};
