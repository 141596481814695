import { mapDateToData } from '../dateMapper';
import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from '../mapperHelpers';

export const mapDiscountsToData = (discounts) => {
  return discounts.map((elem) => ({
    dateFrom: mapDateToData(elem.dateFrom),
    dateTo: mapDateToData(elem.dateTo),
    active: elem.active,
    perpetual: elem.perpetual,
    discountPeriodsAmount: elem.discountPeriodsAmount,
    rate: elem.rate,
    formula: elem.formula,
    minPercentage: elem.minPercentage,
    maxPercentage: elem.maxPercentage,
    type: elem.type,
    description: mapInternationalisationToData(elem.description),
    constraints: elem.constraints.map((elem) => ({ content: elem })),
  }));
};

export const mapDataToDiscounts = (discounts) => {
  return discounts.map((elem) => ({
    dateFrom: elem.dateFrom,
    dateTo: elem.dateTo,
    active: !!elem.active,
    perpetual: elem.perpetual,
    discountPeriodsAmount: elem.discountPeriodsAmount,
    rate: elem.rate,
    formula: elem.formula,
    minPercentage: elem.minPercentage,
    maxPercentage: elem.maxPercentage,
    type: elem.type,
    description: mapDataToInternationalisation(elem.description),
    constraints: elem.constraints.map((elem) => elem.content),
  }));
};
