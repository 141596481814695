import PropTypes from 'prop-types';
import { Card, Checkbox, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCorrectLabelForAdmin } from 'src/utils/internationalisationUtils';
import { useExportSelectionContext } from 'src/hooks/normalHooks/context/useExportSelectionContext';

function ParameterCard({ parameter, onClick }) {
  const { t } = useTranslation();
  const selectedLang = useSelector(
    (state) => state.selectedLanguage.selectedLanguage,
  );
  const { selectable, handleSelection, isSelected } =
    useExportSelectionContext();

  const handleSelect = (e) => {
    e.stopPropagation();
    handleSelection(parameter);
  };

  const titleWithCheckBox = (
    <div className={'flex justify-between'}>
      <Tooltip title={parameter.name}>
        <span className={'max-w-[80%] text-ellipsis overflow-hidden'}>
          {parameter.name}
        </span>
      </Tooltip>
      {selectable && (
        <Checkbox checked={isSelected(parameter)} onClick={handleSelect} />
      )}
    </div>
  );

  return (
    <Card
      onClick={onClick}
      title={titleWithCheckBox}
      className={'w-full mb-3 h-full shadow-none cursor-pointer'}
      sx={{ borderRadius: '18px' }}
    >
      <div>
        <span className="text-gray-500 font-light">
          {t('parameters.code')}:{' '}
        </span>
        <span>{parameter.functionalId}</span>
      </div>

      <div>
        <span className="text-gray-500 font-light">
          {t('parameters.description')}:{' '}
        </span>
        <span>
          {getCorrectLabelForAdmin(parameter.descriptions, selectedLang)}
        </span>
      </div>

      <div>
        <span className="text-gray-500 font-light">
          {t('confirmation.active')}:{' '}
        </span>
        <span>
          {parameter.active ? t('confirmation.yes') : t('confirmation.no')}
        </span>
      </div>
    </Card>
  );
}

ParameterCard.propTypes = {
  parameter: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onSelected: PropTypes.func,
  onUnSelected: PropTypes.func,
};
export { ParameterCard };
