import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { VersionsComparatorModal } from 'src/containers/VersionsComparatorModal';

const useCompareVersions = ({ versionFetcherQuery, versions }) => {
  const { openModal } = useModalContext();
  const onCompare = () => {
    openModal({
      options: {
        width: '80%',
      },
      body: (
        <VersionsComparatorModal
          versions={versions.map((v) => ({
            functionalId: v.id,
            id: v.id,
            name: v.version,
          }))}
          versionFetcherQuery={versionFetcherQuery}
        />
      ),
    });
  };

  return { onCompare };
};
export { useCompareVersions };
