import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { Button, Row } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import TextInput from '../inputs/TextInput';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import ParameterMultiSelect from 'src/components/generic/inputs/ParameterMultiSelect';
import { ConstraintTypesSelect } from 'src/components/generic/inputs/ConstraintTypesSelect';
import DataMultiSelect from '../inputs/DataMultiSelect/DataMultiSelect';
import GenericDatePicker from '../inputs/GenericDatePicker';
import AuditEventTypesMultiSelect from '../inputs/AuditEventTypesMultiSelect/AuditEventTypesMultiSelect';

const GenericFilter = (props) => {
  const {
    onFilter,
    initialFilterValues,
    emptyFilterValues,
    filterFields,
    numberElementsPerRow,
    disableSubmit,
    customField,
  } = props;
  const [open, setOpen] = useState(true);
  const formMethods = useForm({
    initialFilterValues,
  });
  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset(initialFilterValues);
  }, [initialFilterValues, reset]);

  const submit = (e) => {
    onFilter(e);
  };

  const toggle = () => {
    setOpen(!open);
  };

  const renderField = (field) => {
    const { label, type, name, domain, style, data, typeDomain } = field;

    switch (type) {
      case 'TextInput':
        return (
          <div className={style} key={name}>
            <TextInput name={name} label={t(label)} />
          </div>
        );

      case 'ParameterMultiSelect':
        return (
          <div className={style} key={name}>
            <ParameterMultiSelect
              name={name}
              label={t(label)}
              domain={domain}
            />
          </div>
        );

      case 'SelectInput':
        return (
          <div className={style} key={name}>
            <SelectInput name={name} label={t(label)} data={data} />
          </div>
        );

      case 'DatePicker':
        return (
          <div className={style} key={name}>
            <GenericDatePicker
              name={name}
              label={t(label)}
              showTime
              format="YYYY-MM-DD HH:mm:ss"
            />
          </div>
        );

      case 'AuditEventTypesMultiSelect':
        return (
          <div className={style} key={name}>
            <AuditEventTypesMultiSelect
              name={name}
              label={t(label)}
              typeDomain={typeDomain}
            />
          </div>
        );
      case 'Toggle':
        return (
          <div className={style} key={name}>
            {!open && <DownOutlined onClick={toggle} className="mt-3" />}
            {open && <UpOutlined onClick={toggle} className="mt-3" />}
          </div>
        );

      case 'ConstraintTypesSelect':
        return (
          <div className={style} key={name}>
            <ConstraintTypesSelect name={name} label={label} />
          </div>
        );

      case 'DataMultiSelect':
        return (
          <div className={style} key={name}>
            <DataMultiSelect name={name} label={t(label)} data={data} />
          </div>
        );

      default:
        return null;
    }
  };

  const renderedFields = () => {
    const rows = [];
    let rowFields = [];
    let currentRow = 0;

    const pushInRow = () =>
      rows.push(
        <div
          key={currentRow}
          className={`justify-between ${
            currentRow === 0 || open ? 'max-h-full' : 'max-h-0 overflow-hidden'
          }`}
        >
          <Row span={12}>{rowFields}</Row>
        </div>,
      );

    for (let f of filterFields) {
      const field = renderField(f);
      rowFields.push(field);

      // add 'numberElementsPerRow' elements per Row
      if (rowFields.length === numberElementsPerRow) {
        pushInRow();
        rowFields = [];
        currentRow++;
      }
    }

    // add any remaining fields
    if (rowFields.length > 0) {
      pushInRow();
      currentRow++;
    }

    return rows;
  };

  let onReset = () => {
    onFilter(emptyFilterValues);
    reset(emptyFilterValues);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="p-5">
      <FormProvider {...{ ...formMethods }}>
        {renderedFields()}
        <Row
          span={12}
          className="mt-4 mr-2 items-center justify-between"
          key={'buttons'}
        >
          {customField ? <>{customField}</> : <div></div>}
          <div className="space-x-3">
            <Button type="primary" ghost onClick={onReset}>
              {t('search.resetFilter')}
            </Button>
            <Button
              type="primary"
              disabled={disableSubmit}
              ghost
              htmlType="submit"
              className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
            >
              {t('search.search')}
            </Button>
          </div>
        </Row>
      </FormProvider>
    </form>
  );
};

GenericFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  filterFields: PropTypes.array.isRequired,
};
GenericFilter.defaultProps = {
  numberElementsPerRow: 5,
};
export default GenericFilter;
