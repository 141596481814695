import { FormBuilderFieldUtil } from 'src/pages/formSchemas/types';
import { resolveFormDefaultValueForContainerFields } from 'src/pages/formSchemas/utils/common';
import { DesignerComponent } from './DesignerComponent';
import { designerDefaultFieldValues } from './designerDefaultFieldValues';
import { FormComponent } from './FormComponent';

export const rowFieldType: FormBuilderFieldUtil = {
  designerComponent: DesignerComponent,
  designerDefaultFieldValues,
  formComponent: FormComponent,
  formResolveDefaultValue: resolveFormDefaultValueForContainerFields,
};
