import { create } from 'zustand';

const useModalStore = create((set) => ({
  isOpen: false,
  body: null,
  options: {},
  openModal: ({ options, body }) => {
    set((state) => ({
      isOpen: true,
      options: {
        ...state.options,
        ...options,
      },
      body,
    }));
  },
  closeModal: () => {
    set(() => ({
      isOpen: false,
      body: null,
      options: {},
    }));
  },
}));

const useModalContext = () => {
  return useModalStore();
};

export { useModalContext };
