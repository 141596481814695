import { useFormSchemasStore } from 'src/stores/formSchemas';
import { useFormContext } from 'react-hook-form';
import FormSchemaParserContainer from 'src/pages/formSchemas/containers/FormSchemaParserContainer';

export const EligibleActFormSchemaLoader = () => {
  const { setValue, watch } = useFormContext();

  const [act, targetType, families, groups, products] = watch([
    'act',
    'targetType',
    'families',
    'groups',
    'products',
  ]);

  const { getByActAndTargetTypeAndTarget } = useFormSchemasStore();

  const { data: schema } = getByActAndTargetTypeAndTarget({
    act: act,
    targetType: targetType,
    target: {
      family: families,
      group: groups,
      product: products,
    },
  });

  if (schema?.id) {
    setValue('formSchema', {
      id: schema.id,
      version: schema.version,
      __schema: schema,
    });

    return (
      <FormSchemaParserContainer
        schema={schema}
        withForm={false}
        namePrefix="formSchemaValues."
      />
    );
  } else {
    setValue('formSchema', null);
    return null;
  }
};
