import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import CardDisplay from '../../../components/generic/CardDisplay';
import ProductSelect from '../../../components/generic/inputs/ProductMultiSelect';
import { getPublishedProductsWithoutPagination } from '../../../api';
import ParameterSelect from '../../../components/generic/inputs/ParameterSelect';
import { Button } from 'antd';

const ProductAndActSelection = ({ formMethods }) => {
  const { t } = useTranslation();

  return (
    <FormProvider {...formMethods}>
      <CardDisplay
        title={t('simulation.productCardTitle')}
        titleCentered={true}
      >
        <div className="grid grid-cols-2 gap-3">
          <div>
            <ProductSelect
              getProducts={getPublishedProductsWithoutPagination}
              translationPrefix="simulation"
              namePrefix="product"
              name="product"
            />
          </div>
          <div>
            <ParameterSelect
              name="act"
              label={t('simulation.event')}
              domain="ACT"
            />
          </div>
        </div>
        <div className="flex justify-end pt-4">
          <Button
            htmlType="submit"
            data-testid="extract-button"
            className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
          >
            {t('testCases.generate')}
          </Button>
        </div>
      </CardDisplay>
    </FormProvider>
  );
};

export default ProductAndActSelection;
