import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const EditButton = ({ canEdit, onEdit }) => {
  if (!canEdit) {
    return <></>;
  }

  return (
    <Button
      data-testid="edit-button"
      htmlType="button"
      onClick={onEdit}
      className="inline-flex items-center justify-center bg-white"
      size="middle"
      shape="circle"
      icon={<EditOutlined className="text-xs" />}
    />
  );
};
