import { mapReservedSchemaFields } from 'src/pages/formSchemas/utils/parser';
import { CustomizedGenericProductSettingsDto } from 'src/types/models/product';

export const mapDataToCustomizedGenericProduct = (
  data: CustomizedGenericProductSettingsDto,
): CustomizedGenericProductSettingsDto => ({
  formSchema: { ...data.formSchema, __schema: undefined },
  formSchemaValues: mapReservedSchemaFields(
    data.formSchemaValues,
    data.formSchema?.__schema,
  ),
  type: 'customizedGenericProduct',
});

export const mapCustomizedGenericProductToData = (
  customizedGenericProduct: CustomizedGenericProductSettingsDto,
): CustomizedGenericProductSettingsDto => ({
  formSchema: customizedGenericProduct.formSchema,
  formSchemaValues: customizedGenericProduct.formSchemaValues,
});
