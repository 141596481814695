export const mapSavingToData = (saving) => {
  return {
    minCotisation: saving.minCotisation,
    maxCotisation: saving.maxCotisation,
    periodicities: saving.periodicities,
    minVersementExceptionnel: saving.minVersementExceptionnel,
    maxVersementExceptionnel: saving.maxVersementExceptionnel,
    paliers: saving.paliers,
    maximumBeneficiaries: saving.maximumBeneficiaries,
  };
};

export const mapDataToSaving = (data) => {
  return {
    type: 'saving',
    minCotisation: data.minCotisation,
    maxCotisation: data.maxCotisation,
    periodicities: data.periodicities,
    minVersementExceptionnel: data.minVersementExceptionnel,
    maxVersementExceptionnel: data.maxVersementExceptionnel,
    paliers: data.paliers,
    maximumBeneficiaries: data.maximumBeneficiaries,
  };
};
