import { Button, Upload } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const UploadSection = ({ beforeUpload, handleImport, loadingImport }) => {
  const { t } = useTranslation();

  return (
    <Upload
      accept=".json"
      customRequest={() => false}
      beforeUpload={beforeUpload}
      onChange={handleImport}
      itemRender={() => null}
      maxCount={1}
    >
      <Button
        ghost
        type="primary"
        className="flex justify-center items-center"
        loading={loadingImport}
      >
        {t('testCases.import')}
      </Button>
    </Upload>
  );
};

UploadSection.propTypes = {
  beforeUpload: PropTypes.func.isRequired,
  handleImport: PropTypes.func.isRequired,
  loadingImport: PropTypes.bool.isRequired,
};

export default UploadSection;
