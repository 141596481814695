import { Button, Dropdown, MenuProps, Space, Upload } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { toast } from 'react-toastify';
import { useParametersStore } from 'src/stores/parameters';
import { useShowMessage } from 'src/hooks/normalHooks/useShowMessage';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';
import { USER_ROLES } from 'src/utils/constants';
import { useExportSelectionContext } from 'src/hooks/normalHooks/context/useExportSelectionContext';
import { exportToJsonFile } from 'src/utils/fileUtils';

interface ExportImportProps {
  allParameters: any[];
  domain: any;
}

export const ExportImport: FunctionComponent<ExportImportProps> = ({
  domain,
  allParameters,
}) => {
  const { t } = useTranslation();
  const { showSuccess, showError } = useShowMessage();
  const { setSelectable } = useExportSelectionContext();
  const JSONMimeType = 'application/json';
  const store = useParametersStore();
  const { mutateAsync } = store.addParametersList();

  const validateContent = (jsonContent: any, domain: any) => {
    if (!Array.isArray(jsonContent) || jsonContent.length === 0) {
      return {
        isValid: false,
        message: t('validation.content'),
      };
    }
    const isValid = jsonContent.every((obj) => obj.domain === domain.id);
    return {
      isValid: isValid,
      message: isValid
        ? ''
        : t('validation.incoherentDomain', { domain: domain.name }),
    };
  };

  const handleImport = ({ file }: UploadChangeParam) => {
    const fileReader = new FileReader();
    fileReader.onload = function () {
      const content = fileReader.result;
      const jsonContent = JSON.parse(content as string);
      const validationResult = validateContent(jsonContent, domain);
      if (!validationResult.isValid) {
        toast.error(t(validationResult.message) as string);
        return;
      }
      mutateAsync(jsonContent)
        .then(() => {
          showSuccess(t(`parameters.added`));
        })
        .catch((err) => {
          showError(err);
        });
    };
    fileReader.readAsText(file.originFileObj as RcFile);
  };

  const validateUpload = (file: RcFile) => {
    if (file.type !== JSONMimeType) {
      toast.error(t('validation.json') as string);
      return false;
    }
    return true;
  };

  const items: MenuProps['items'] = [
    {
      label: t('parameters.exportAll'),
      key: '1',
    },
    {
      label: t('parameters.selected'),
      key: '2',
    },
  ];

  const handleMenuClick = (e: { key: string }) => {
    switch (e.key) {
      case items[0]?.key: {
        exportToJsonFile(
          allParameters,
          `parameters-${domain.id.toUpperCase()}`,
        );
        break;
      }
      case items[1]?.key: {
        setSelectable(true);
        break;
      }
      default:
        break;
    }
  };

  const shouldDisplayExport = allParameters && allParameters.length > 0;
  return (
    <div className={'flex gap-2'}>
      {shouldDisplayExport && (
        <AuthComponentDisabler
          componentRender={() => (
            <Dropdown
              menu={{ items, onClick: handleMenuClick }}
              placement={'bottom'}
            >
              <Button type="primary" ghost data-testid="export-dropdown-button">
                <Space>
                  {t('parameters.export')}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
          roles={[USER_ROLES.DATA_IMPORT_EXPORT]}
        />
      )}
      <Upload
        accept=".json"
        customRequest={() => false}
        beforeUpload={validateUpload}
        onChange={handleImport}
        itemRender={() => null}
        maxCount={1}
        className=" w-full"
        data-testid="upload"
      >
        <AuthComponentDisabler
          componentRender={() => (
            <Button
              ghost
              type="primary"
              className="flex justify-center items-center"
            >
              {t('data.import')}
            </Button>
          )}
          roles={[USER_ROLES.DATA_IMPORT_EXPORT]}
        />{' '}
      </Upload>
    </div>
  );
};
