const CalculatorLabelsMap = {
  calculateHt: {
    value1: 'calculation.amountTtc',
    value2: 'calculation.vat',
    result: 'result.ht',
  },
  calculateTtc: {
    value1: 'calculation.amountHt',
    value2: 'calculation.vat',
    result: 'result.ttc',
  },
  calculateDiscountRate: {
    value1: 'calculation.originalAmount',
    value2: 'calculation.finalAmount',
    result: 'result.rate',
  },

  calculateDiscountedPrice: {
    value1: 'calculation.originalAmount',
    value2: 'calculation.rate',
    result: 'result.finalPrice',
  },
};

export default CalculatorLabelsMap;
