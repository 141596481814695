import CardDisplay from 'src/components/generic/CardDisplay';
import ExemptionFormula from '../ExemptionFormula';
import AdditionalAttribute from '../../generic/AdditionalAttribute';
import { useTranslation } from 'react-i18next';

export default function Exemption() {
  const { t } = useTranslation();

  return (
    <CardDisplay title={t('exemptionOption.info')}>
      <ExemptionFormula />
      <AdditionalAttribute />
    </CardDisplay>
  );
}
