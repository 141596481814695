import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputLabel } from 'src/components/generic/inputs/InputLabel';
import { DesignerComponentType } from 'src/pages/formSchemas/types';

export const DesignerComponent: DesignerComponentType = ({ fieldValue }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-2">
      <InputLabel
        label={fieldValue.label ?? ''}
        required={fieldValue.config?.required}
      />
      <Upload disabled={true}>
        <Button icon={<UploadOutlined />}>{t('form.upload_file')}</Button>
      </Upload>
    </div>
  );
};
