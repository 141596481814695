import { AUDIT_PATH } from 'src/api/apiPaths';
import HttpService from '../../../services/HttpService';
import { mapAuditToParams } from 'src/utils/mappers/filterMapper';
import { getApiInstance } from 'src/utils';
import { API_NAMES } from 'src/utils/constants';

const instanceCatalogue = HttpService.getAxiosClient('catalogue');
const instanceEventPricing = HttpService.getAxiosClient('eventPricing');
const instanceFacts = HttpService.getAxiosClient(API_NAMES.FACTS);

export const getAuditsCatalogueByCriteria = async (
  page,
  size,
  filter,
  typeDomain,
) => {
  const params = mapAuditToParams(page, size, filter);
  const res = await instanceCatalogue.get(`${AUDIT_PATH}/${typeDomain}`, {
    params,
  });
  return res.data;
};

export const getAuditsPricingByCriteria = async (
  page,
  size,
  filter,
  typeDomain,
) => {
  const params = mapAuditToParams(page, size, filter);
  const res = await instanceEventPricing.get(`${AUDIT_PATH}/${typeDomain}`, {
    params,
  });
  return res.data;
};

export const getAuditsFactsByCriteria = async (
  page,
  size,
  filter,
  typeDomain,
) => {
  const params = mapAuditToParams(page, size, filter);
  const res = await instanceFacts.get(`${AUDIT_PATH}/${typeDomain}`, {
    params,
  });
  return res.data;
};

export const getAuditEventTypes = async (type, sourceApi) => {
  const res = await getApiInstance(sourceApi).get(`${AUDIT_PATH}/event-types`, {
    params: {
      type: type,
    },
  });
  return res.data;
};
