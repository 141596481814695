import { PROFILES_PATH } from '../apiPaths';
import { mapFilterToParams } from 'src/utils/mappers/filterMapper';
import { getApiInstance } from 'src/utils';

export const addProfile = async (sourceApi, profile) => {
  const res = await getApiInstance(sourceApi).post(PROFILES_PATH, profile);
  return res.data;
};

export const updateProfile = async (sourceApi, profile, id) => {
  const res = await getApiInstance(sourceApi).put(
    `${PROFILES_PATH}/${id}`,
    profile,
  );
  return res.data;
};

export const getProfilesByCriteria = async (sourceApi, page, size, filter) => {
  const params = mapFilterToParams(page, size, filter);
  const res = await getApiInstance(sourceApi).get(PROFILES_PATH, { params });
  return res.data;
};

export const getProfileById = async (sourceApi, id) => {
  const res = await getApiInstance(sourceApi).get(`${PROFILES_PATH}/${id}`);
  return res.data;
};

export const getAllRoles = async (sourceApi) => {
  const res = await getApiInstance(sourceApi).get(PROFILES_PATH + '/roles');
  return res.data;
};

export const getProfileNames = async (sourceApi) => {
  const res = await getApiInstance(sourceApi).get(PROFILES_PATH + '/names');
  return res.data;
};

export const deleteProfile = async (sourceApi, id) => {
  const res = await getApiInstance(sourceApi).delete(`${PROFILES_PATH}/${id}`);
  return res.data;
};
