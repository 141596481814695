//Utils
const EVALUATION_ICONS = {
  true: '✔',
  false: '✘',
};

const EVALUATION_COLORS = {
  true: 'text-green-500',
  false: 'text-red-500',
};

const getEvaluationIcon = (evaluation) => EVALUATION_ICONS[evaluation];

const getEvaluationColor = (evaluation) => EVALUATION_COLORS[evaluation];

export const createNodeTitle = (title, evaluation) => {
  return (
    <span>
      <span className={`ml-2 mr-2 ${getEvaluationColor(evaluation)}`}>
        {title}
      </span>
      <span className={`${getEvaluationColor(evaluation)}`}>
        {getEvaluationIcon(evaluation)}
      </span>
    </span>
  );
};

export const createNodeTitleFromSimpleConstraint = (constraint) => {
  return createNodeTitle(constraint.constraintDto.label, constraint.evaluation);
};
