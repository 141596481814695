import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row } from 'antd';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ArrayAccordionPreviewFields from 'src/components/generic/ArrayAccordionPreviewFields';
import ComponentDisabler from 'src/components/generic/ComponentDisabler';
import { useState, FunctionComponent, PropsWithChildren } from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import clsx from 'clsx';
import isArray from 'lodash/isArray';

interface ArrayAccordionProps {
  name: string;
  translationPrefix: string;
  label?: string;
  Form: FunctionComponent<{ namePrefix: string }>;
  defaultValues?: any;
  isDuplicatable?: boolean;
  fieldsNames?: string[];
  isSortable?: boolean;

  collapseWrapper?: FunctionComponent<
    PropsWithChildren<{
      fields: any[];
      moveField: (oldIndex: number, newIndex: number) => void;
    }>
  >;
  panelWrapper?: FunctionComponent<
    PropsWithChildren<{ field: any; header: React.ReactElement; key: string }>
  >;
}

interface HeaderProps {
  index: number;
  fieldsNames?: string[];
  updatedFields: any;
  translationPrefix: string;
  title?: string;
}

const Header = ({
  index,
  fieldsNames,
  updatedFields,
  translationPrefix,
  title,
}: HeaderProps) => {
  const { t } = useTranslation();
  if (!fieldsNames) {
    return <>{`${title} ${index + 1}`}</>;
  }
  return (
    <Row>
      <Col span={6}>
        {t(`${translationPrefix}.titleSingle`) + ' ' + (index + 1)}
      </Col>
      <ArrayAccordionPreviewFields
        fieldsNames={fieldsNames}
        array={updatedFields}
        index={index}
        t={t}
        translationPrefix={translationPrefix}
      />
    </Row>
  );
};

export default function ArrayAccordion({
  name,
  translationPrefix,
  Form,
  defaultValues,
  fieldsNames,
  isDuplicatable = true,
  label = undefined,
  isSortable,
}: ArrayAccordionProps) {
  // State
  const [activeCollapseKeys, setActiveCollapseKeys] = useState<string[]>([]);

  // I18n
  const { t } = useTranslation();

  // Form
  const { control } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name,
  });
  const updatedFields = useWatch({
    control,
    name,
  });

  // Handlers
  const handleCollapseChange = (key: string | string[]) => {
    if (isArray(key)) {
      setActiveCollapseKeys(key);
      return;
    }

    setActiveCollapseKeys([key]);
  };

  // Computed
  const title = label || t(`${translationPrefix}.title`);
  const ResolvedCollapse = isSortable ? SortableCollapse : Collapse;
  const ResolvedCollapsePanel = isSortable
    ? SortableCollapsePanel
    : Collapse.Panel;

  return (
    <>
      <div className="py-2">
        <h1 className="mb-1">{title}</h1>
        {/* @ts-ignore */}
        <ResolvedCollapse
          fields={fields}
          moveField={move}
          expandIconPosition="start"
          onChange={handleCollapseChange}
        >
          {fields.length > 0 ? (
            fields.map((field, index) => {
              return (
                <ResolvedCollapsePanel
                  key={field.id}
                  field={isSortable ? field : undefined}
                  header={
                    <Header
                      fieldsNames={fieldsNames}
                      updatedFields={updatedFields}
                      index={index}
                      translationPrefix={translationPrefix}
                      title={title}
                    />
                  }
                  // @ts-ignore
                  movingDisabled={activeCollapseKeys?.includes(field.id)}
                >
                  <div>
                    <Form namePrefix={`${name}[${index}]`} />
                    <div className="flex justify-between">
                      {isDuplicatable ? (
                        <ComponentDisabler
                          componentRender={() => (
                            <Button
                              icon={
                                <CopyOutlined style={{ fontSize: '18px' }} />
                              }
                              className="flex items-center"
                              ghost
                              type="primary"
                              onClick={() => {
                                append(updatedFields[index]);
                              }}
                            >
                              {t(`${translationPrefix}.duplicate`)}
                            </Button>
                          )}
                        />
                      ) : (
                        <div />
                      )}
                      <ComponentDisabler
                        componentRender={() => (
                          <Button
                            icon={
                              <DeleteOutlined style={{ fontSize: '18px' }} />
                            }
                            className="flex items-center"
                            danger
                            ghost
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            {t(`${translationPrefix}.delete`)}
                          </Button>
                        )}
                      />
                    </div>
                  </div>
                </ResolvedCollapsePanel>
              );
            })
          ) : (
            <div className="w-full bg-white p-2 flex justify-center">
              <p> {t(`${translationPrefix}.noAdded`)} </p>
            </div>
          )}
        </ResolvedCollapse>
        <ComponentDisabler
          componentRender={() => (
            <Button
              className="w-full mt-2"
              type="primary"
              ghost
              onClick={() => {
                append(defaultValues);
              }}
            >
              + {t(`${translationPrefix}.add`)}
            </Button>
          )}
        />
      </div>
    </>
  );
}

interface SortableCollapseProps extends PropsWithChildren {
  fields: any[];
  moveField: (oldIndex: number, newIndex: number) => void;
  expandIconPosition: any;
}

const SortableCollapse = ({
  fields,
  moveField,
  children,
  ...props
}: SortableCollapseProps) => {
  // Form
  const { formDisabled } = useFormContext() as any;

  // Dnd
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = fields.findIndex((a) => a.id === active.id);
      const newIndex = fields.findIndex((a) => a.id === over?.id);
      moveField(oldIndex, newIndex);
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={fields}
        strategy={verticalListSortingStrategy}
        disabled={formDisabled}
      >
        <Collapse {...props}>{children}</Collapse>
      </SortableContext>
    </DndContext>
  );
};

interface SortableCollapsePanelProps extends PropsWithChildren {
  header: React.ReactElement;
  field: any;
  movingDisabled?: boolean;
}
const SortableCollapsePanel = ({
  header,
  children,
  field,
  movingDisabled,
  ...props
}: SortableCollapsePanelProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: field.id,
      disabled: movingDisabled,
    });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: '10000000',
        transition,
      }
    : undefined;

  return (
    <Collapse.Panel
      {...props}
      key={field.id}
      className={clsx('cursor-move')}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      header={header}
    >
      {children}
    </Collapse.Panel>
  );
};
