import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { ADDITIONAL_ATTRIBUTE_DEFAULT } from 'src/utils/defaultsSupplier';
import AdditionalAttributeForm from '../AdditionalAttributeForm';

export default function AdditionalAttribute(props) {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={
        namePrefix
          ? `${namePrefix}.additionalAttributes`
          : 'additionalAttributes'
      }
      translationPrefix="additionalAttributes"
      Form={AdditionalAttributeForm}
      defaultValues={ADDITIONAL_ATTRIBUTE_DEFAULT}
      fieldsNames={['name']}
    />
  );
}

AdditionalAttribute.propTypes = {
  namePrefix: PropTypes.string,
};
