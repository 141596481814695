import TextInput from 'src/components/generic/inputs/TextInput/TextInput';
import { RiseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export default function RatePricingForm({ namePrefix }) {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        label={t('pricing.rate')}
        type="number"
        name={`${namePrefix}.rate`}
        startAdornment={<RiseOutlined style={{ fontSize: '25px' }} />}
        endAdornment={<span className="text-lg ml-2">%</span>}
      />
    </>
  );
}
