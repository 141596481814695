import { Col, Row } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import MultiChipsInput from 'src/components/generic/inputs/MultiChipInput';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import SwitchInput from '../../../../generic/inputs/SwitchInput';

export default function AdditionalAttributeForm(props) {
  const { namePrefix } = props;
  const { control } = useFormContext();
  const attribute = useWatch({
    control,
    name: namePrefix,
  });
  const { t } = useTranslation();

  const data = [
    {
      functionalId: 'Text',
      name: t('additionalAttributes.text'),
    },
    {
      functionalId: 'Number',
      name: t('additionalAttributes.number'),
    },
    {
      functionalId: 'ListText',
      name: t('additionalAttributes.listOfText'),
    },
    {
      functionalId: 'ListNumber',
      name: t('additionalAttributes.listOfNumber'),
    },
    {
      functionalId: 'Boolean',
      name: t('additionalAttributes.boolean'),
    },
  ];

  const componentMap = {
    Text: {
      component: TextInput,
      props: { type: 'text' },
    },
    Number: {
      component: TextInput,
      props: { type: 'number' },
    },
    ListText: {
      component: MultiChipsInput,
      props: { type: 'text' },
    },
    ListNumber: {
      component: MultiChipsInput,
      props: { type: 'number' },
    },
    Boolean: {
      component: SwitchInput,
    },
  };

  const selectedComponent = componentMap[attribute.type] || {
    component: () => <div>{t('additionalAttributes.notSupported')}</div>,
  };

  const InputComponent = selectedComponent.component;

  return (
    <div>
      <Row gutter={[16, 12]} className="m-1 mb-5">
        <Col span={12}>
          <TextInput
            name={`${namePrefix}.name`}
            label={t('additionalAttributes.name')}
            type="text"
          />
        </Col>
        <Col span={12}>
          <SelectInput
            name={`${namePrefix}.type`}
            label={t('additionalAttributes.type')}
            data={data}
          />
        </Col>
        <Col span={24}>
          <InputComponent
            name={`${namePrefix}.value[${attribute.type}]`}
            label={t('additionalAttributes.value')}
            {...selectedComponent.props}
          />
        </Col>
      </Row>
    </div>
  );
}
