import {
  FormSchemaAdminDto,
  ObjectVersionDto,
  PageRequest,
  PageResult,
} from 'src/types';
import HttpService from 'src/services/HttpService';
import { FORM_SCHEMAS_PATH } from 'src/api/apiPaths';

const instance = HttpService.getAxiosClient('catalogue');

export const listFormSchemas = async (
  page?: PageRequest,
  filter?: string,
): Promise<PageResult<FormSchemaAdminDto>> => {
  const res = await instance.get(`${FORM_SCHEMAS_PATH}`, {
    params: {
      ...page,
      filter,
    },
  });
  return res.data;
};

export const getFormSchemaById = async (id: string) => {
  const res = await instance.get(`${FORM_SCHEMAS_PATH}/${id}`);
  return res.data;
};

export const createFormSchema = async (data: FormSchemaAdminDto) => {
  const res = await instance.post(`${FORM_SCHEMAS_PATH}`, data);
  return res.data;
};

export const updateFormSchema = async (
  id: string,
  data: FormSchemaAdminDto,
) => {
  const res = await instance.patch(`${FORM_SCHEMAS_PATH}/${id}`, data);
  return res.data;
};

export const getFormSchemaVersionsById = async (
  id: string,
): Promise<ObjectVersionDto[]> => {
  const res = await instance.get(`${FORM_SCHEMAS_PATH}/${id}/versions`);
  return res.data;
};

export const createFormSchemaDraft = async (
  id: string,
): Promise<FormSchemaAdminDto> => {
  const res = await instance.post(`${FORM_SCHEMAS_PATH}/${id}/draft`);
  return res.data;
};

export const publishFormSchema = async (
  id: string,
): Promise<FormSchemaAdminDto> => {
  const res = await instance.post(`${FORM_SCHEMAS_PATH}/${id}/publish`);
  return res.data;
};

export const unpublishFormSchema = async (
  id: string,
): Promise<FormSchemaAdminDto> => {
  const res = await instance.post(`${FORM_SCHEMAS_PATH}/${id}/unpublish`);
  return res.data;
};
