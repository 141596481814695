import { FormBuilderFieldUtil } from 'src/pages/formSchemas/types';
import { resolveFormDefaultValueForContainerFields } from 'src/pages/formSchemas/utils/common';
import { designerDefaultFieldValues } from './designerDefaultFieldValues';
import { FormComponent } from './FormComponent';
import { arrayFieldType } from 'src/pages/formSchemas/fieldTypes/array';

export const groupFieldType: FormBuilderFieldUtil = {
  designerComponent: arrayFieldType.designerComponent,
  designerFormComponent: arrayFieldType.designerFormComponent,
  designerDefaultFieldValues,
  formComponent: FormComponent,
  formResolveDefaultValue: resolveFormDefaultValueForContainerFields,
};
