import i18n from 'src/i18n';

export const getAuditStatus = () => {
  const { t } = i18n;
  return [
    {
      functionalId: 'SUCCESS',
      name: t('administration.success'),
    },
    {
      functionalId: 'BEGIN',
      name: t('administration.begin'),
    },
    {
      functionalId: 'FAILURE',
      name: t('administration.failure'),
    },
  ];
};
