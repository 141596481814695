import { DesignerDefaultFieldValuesType } from 'src/pages/formSchemas/types';

import { FIELD_TYPES } from 'src/pages/formSchemas/utils/constants/form';

export const designerDefaultFieldValues: DesignerDefaultFieldValuesType =
  () => ({
    name: '',
    label: 'Reference field',
    description: '',
    type: FIELD_TYPES.reference,
    config: {
      valueAttribute: 'id',
    },
  });
