import { API_DATE_FORMAT } from 'src/constants/dates';

export const addDateRangeFilter = (filter) => {
  if (!filter || !filter.dateRange || filter.dateRange.length < 2) {
    return {};
  }

  const [startDate, endDate] = filter.dateRange;

  return {
    startDate: startDate.format(API_DATE_FORMAT),
    endDate: endDate.format(API_DATE_FORMAT),
  };
};
