import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from 'src/components/generic/Loading';
import { useReusableFormSchemasStore } from 'src/stores/reusableFormSchemas';

const ReusableFormSchemaContainer = React.lazy(
  () => import('src/pages/formSchemas/containers/ReusableFormSchemaContainer'),
);

export default function ReusableFormSchemaUpdate() {
  const { id } = useParams();
  const store = useReusableFormSchemasStore();
  const schema = store.getById(id ?? '');

  if (schema.isLoading) {
    return <Loading />;
  }

  return (
    <React.Suspense fallback={null}>
      <ReusableFormSchemaContainer reusableFormSchema={schema.data} />
    </React.Suspense>
  );
}
