import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import { HighlightOutlined } from '@ant-design/icons';
import TextInput from 'src/components/generic/inputs/TextInput';
import { useQuery } from 'react-query';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import InputConditionalDisplayer from 'src/components/generic/inputs/InputConditionalDisplayer';
import { useFormContext, useWatch } from 'react-hook-form';
import { getCommissionsByEvent } from 'src/api/eventPricing';

const ExemptionEventForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const event = useWatch({
    control,
    name: `${namePrefix}.event`,
  });
  const { data, isLoading, isError } = useQuery(
    ['get-commissions-by-event', event],
    () => getCommissionsByEvent(event),
  );
  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={12}>
        <ParameterSelect
          name={`${namePrefix}.event`}
          label={t('exemptionOption.act')}
          domain="ACT"
          startAdornment={<HighlightOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <InputConditionalDisplayer isLoading={isLoading} isError={isError}>
          <SelectInput
            data={data}
            name={`${namePrefix}.commission`}
            label={t('exemptionOption.commission')}
          />
        </InputConditionalDisplayer>
      </Col>
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.number`}
          label={t('exemptionOption.numbre')}
        />
      </Col>
      <Col span={12}>
        <SwitchInput
          name={`${namePrefix}.unlimited`}
          label={t('ExemptionEvent.unlimited')}
        />
      </Col>
    </Row>
  );
};

export default ExemptionEventForm;
