import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getGenericConstraintOperations } from 'src/api';
import MultiChipsInput from 'src/components/generic/inputs/MultiChipInput';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import TextInput from 'src/components/generic/inputs/TextInput';

const supportedInputs = {
  Number: (
    <TextInput name="conditionValue" label="Condition Value" type="number" />
  ),
  Text: <TextInput name="conditionValue" label="Condition Value" />,
  'List.Text': (
    <MultiChipsInput name="conditionValue" label="Condition Value" />
  ),
  NumberRange: (
    <>
      <TextInput name="conditionValue[0]" label="Min" type="number" />
      <TextInput name="conditionValue[1]" label="Max" type="number" />
    </>
  ),
  Hidden: <div />,
};

const defaultValues = {
  Number: 0,
  Text: '',
  'List.Text': [],
  NumberRange: [],
};

export default function GenericConstraintForm() {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { data, isLoading } = useQuery(
    'operation-list',
    getGenericConstraintOperations,
  );

  return (
    !isLoading && (
      <div className="grid grid-cols-1 gap-3">
        <ParameterSelect
          name="field"
          label={t('constraintsSpecific.Field')}
          domain="FACTS_ELEMENT"
        />
        <SelectInput
          name="operation"
          label={t('constraintsSpecific.Operation')}
          data={data.map(({ functionalId, name }) => ({
            functionalId,
            name: t('constraintOperation.' + name),
          }))}
          onChange={(e) => {
            setValue(
              'conditionValue',
              defaultValues[
                data.find((elem) => elem.functionalId === e)?.input
              ],
            );
          }}
        />
        <RightInput operationsList={data} t={t} />
      </div>
    )
  );
}

function RightInput(props) {
  const { operationsList, t } = props;
  const { control } = useFormContext();
  const operation = useWatch({
    name: 'operation',
    control,
  });

  return (
    supportedInputs[
      operationsList.find((e) => e.functionalId === operation)?.input
    ] || (
      <div className="flex justify-center">
        {t('constraintOperation.noneSelected')}
      </div>
    )
  );
}
