import { COMPOUND_CONSTRAINT_TYPES } from 'src/constants/constraints';
import { mapConstraint } from '../simulationResultMapper';
import { createNodeTitle } from '../utils/utils';

const createNodeTitleFromListConstraint = (constraint) => {
  const constraints = constraint.constraintEvaluationDtoList;
  const type = constraint.constraintDto.type;
  let title = {
    [COMPOUND_CONSTRAINT_TYPES.OR]:
      'Une doit être vrai' +
      (constraint.constraintDto.description
        ? ': ' + constraint.constraintDto.description
        : ''),
    [COMPOUND_CONSTRAINT_TYPES.AND]: 'Tous doit être vrai',
  }[type];

  if (type == COMPOUND_CONSTRAINT_TYPES.AND) {
    const passedConstraints = constraints.reduce((sum, con) => {
      return con.evaluation + sum;
    }, 0);
    title += ` (${passedConstraints} / ${constraints.length}) `;
  }

  return createNodeTitle(title, constraint.evaluation);
};

export const mapListConstraint = (constraint) => {
  const constraints = constraint.constraintEvaluationDtoList;

  if (
    constraints.length == 1 &&
    constraints[0].constraintDto.type.includes('list')
  )
    return mapConstraint(constraints[0]);

  return {
    title: createNodeTitleFromListConstraint(constraint),
    key: Math.random() + '',
    children: constraints.map((constraint) => mapConstraint(constraint)),
  };
};
