import { Skeleton } from 'antd';
import { DotChartOutlined } from '@ant-design/icons';

function StatisticCardSkeleton() {
  return (
    <div data-testid="StatisticCardSkeleton">
      <Skeleton.Node active={true} className="w-full">
        <DotChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
      </Skeleton.Node>
    </div>
  );
}

export { StatisticCardSkeleton };
