import PropTypes from 'prop-types';
import { getAuditsCatalogueByCriteria } from 'src/api/audit/consultAudit';
import AuditDataFilter from '../AuditDataFilter';
import {
  auditParamMappedKeys,
  auditListTypeKeys,
  auditFilterFieldsMap,
} from 'src/utils/filterFields';
import { auditTabKeyMap } from 'src/utils/constants/auditTabKeyMap';

export const AuditCatalogue = (props) => {
  const { typeDomain, columns } = props;
  const fetchDataFunction = (page, pageSize, filter) =>
    getAuditsCatalogueByCriteria(
      page,
      pageSize,
      filter,
      auditTabKeyMap[typeDomain],
    );

  return (
    <AuditDataFilter
      columns={columns}
      paramMap={auditParamMappedKeys}
      allowedKeys={auditListTypeKeys}
      filterFields={auditFilterFieldsMap[typeDomain]}
      queryKey={typeDomain}
      fetchDataFunction={fetchDataFunction}
      tab={typeDomain}
    />
  );
};

AuditCatalogue.propTypes = {
  typeDomain: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
};
export default AuditCatalogue;
