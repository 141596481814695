import { FileExcelOutlined } from '@ant-design/icons';
import { exportDataAsExcel } from 'src/api';
import { downloadBlob } from 'src/utils/fileUtils';

function ExportButton({ filter, status, functionnalIdOfPart }) {
  const exportToExcel = async () => {
    try {
      const data = await exportDataAsExcel(filter, status, functionnalIdOfPart);
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      downloadBlob('product_data.csv', blob);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  return (
    <FileExcelOutlined
      onClick={() => exportToExcel()}
      className="text-green-600 text-20 cursor-pointer"
    />
  );
}

export default ExportButton;
