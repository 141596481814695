import * as yup from 'yup';
import i18n from 'src/i18n';

const REQUIRED_ARRAY = yup.array().required(i18n.t('validator.blank'));

export const CONSTRAINT_VALIDATION_SCHEMA = yup
  .object({
    constraints: REQUIRED_ARRAY,
  })
  .required();
