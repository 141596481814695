import { SchemableTypeUtil } from 'src/pages/formSchemas/types';
import { SchemaCreationFields } from 'src/pages/formSchemas/schemableTypes/eligibleActs/SchemaCreationFields';

export const eligibleActsSchemableType: SchemableTypeUtil = {
  schemaCreationFields: SchemaCreationFields,
  reservedNames: [
    'act',
    'targetType',
    'targets',
    'tags',
    'descriptions',
    'supportingDocuments',
    'consents',
    'constraints',
    'additionalAttributeList',
    'motifList',
    'formSchema',
    'formSchemaValues',
  ],
};
