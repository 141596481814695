import {
  DesignerComponentType,
  FormBuilderField,
} from 'src/pages/formSchemas/types';
import {
  getDesignerComponent,
  getNestedFieldName,
} from 'src/pages/formSchemas/utils/form';
import React from 'react';

export const DesignerComponent: DesignerComponentType = ({
  fieldValue,
  fieldName,
  onEdit,
  onDelete,
  onDuplicate,
  disabled,
}) => {
  return (
    <div className="space-x-2 flex">
      {((fieldValue.config?.fields as FormBuilderField[]) ?? []).map(
        (val, i) => {
          const fName = getNestedFieldName(fieldName, i);
          const component = getDesignerComponent({
            fieldName: fName,
            fieldValue: val,
            onEdit,
            onDelete,
            onDuplicate,
            disabled,
          });
          return (
            <div key={fName} className="flex-1">
              {component}
            </div>
          );
        },
      )}
    </div>
  );
};
