import { CACHE_PATH } from 'src/api/apiPaths';
import HttpService from 'src/services/HttpService';

export const createApisForCache = (type) => {
  const instance = HttpService.getAxiosClient(type);

  const getCaches = async () => {
    const res = await instance.get(CACHE_PATH);
    return res.data;
  };

  const clearCache = async (cacheNames) => {
    const res = await instance.put(CACHE_PATH + '/clear', cacheNames);
    return res.data;
  };

  return {
    getCaches,
    clearCache,
  };
};
