import { useEffect } from 'react';
import SelectInput from '../../inputs/SelectInput';
import TextInput from '../../inputs/TextInput';
import useDynamicLabel from '../hooks/useDynamicLabel';

function CalculatorComponentModelContent({ t, result, setResult }) {
  useEffect(() => {
    return () => setResult(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const value1Label = useDynamicLabel('value1');
  const value2Label = useDynamicLabel('value2');
  const resultLabel = useDynamicLabel('result');
  return (
    <>
      <SelectInput
        label={t('calculation.type')}
        name="calculationType"
        data={[
          { functionalId: 'calculateHt', name: t('calculation.ht') },
          { functionalId: 'calculateTtc', name: t('calculation.ttc') },
          {
            functionalId: 'calculateDiscountRate',
            name: t('calculation.discountRate'),
          },
          {
            functionalId: 'calculateDiscountedPrice',
            name: t('calculation.discountedPrice'),
          },
        ]}
      />
      <TextInput name="value1" label={t(value1Label)} type="number" />
      <TextInput name="value2" label={t(value2Label)} type="number" />

      <div className="m-5 text-center">
        <span className="text-lg font-bold">
          {t(resultLabel)} : {result}
        </span>
      </div>
    </>
  );
}
export default CalculatorComponentModelContent;
