import { Spin } from 'antd';

export default function Loading() {
  return (
    <Spin
      data-testid="loading-spinner"
      tip="Chargement ..."
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      size="large"
    />
  );
}
