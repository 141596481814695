import {
  FormBuilderFieldsUtils,
  FormBuilderSchema,
  FormBuilderUIFieldType,
  GroupedFormBuilderUIFieldTypes,
} from 'src/pages/formSchemas/types';
import { textFieldType } from 'src/pages/formSchemas/fieldTypes/text';
import { textareaFieldType } from 'src/pages/formSchemas/fieldTypes/textarea';
import { rowFieldType } from 'src/pages/formSchemas/fieldTypes/row';
import { checkboxFieldType } from 'src/pages/formSchemas/fieldTypes/checkbox';
import { enumerationFieldType } from 'src/pages/formSchemas/fieldTypes/enumeration';
import { numberFieldType } from 'src/pages/formSchemas/fieldTypes/number';
import { datetimeFieldType } from 'src/pages/formSchemas/fieldTypes/datetime';
import { groupFieldType } from 'src/pages/formSchemas/fieldTypes/group';
import { arrayFieldType } from 'src/pages/formSchemas/fieldTypes/array';
import { referenceFieldType } from 'src/pages/formSchemas/fieldTypes/reference';
import { collapsibleFieldType } from 'src/pages/formSchemas/fieldTypes/collapsible';
import { tabsFieldType } from 'src/pages/formSchemas/fieldTypes/tabs';
import { tabPaneFieldType } from 'src/pages/formSchemas/fieldTypes/tab_pane';
import { localizedTextFieldType } from 'src/pages/formSchemas/fieldTypes/localized_text';
import { uploadFieldType } from 'src/pages/formSchemas/fieldTypes/upload';
import { switchFieldType } from 'src/pages/formSchemas/fieldTypes/switch';

export const EDITOR_DROPPABLE_SPACE_ID = 'editor';
export const REUSABLE_FORM_SCHEMA_DRAGGABLE = 'reusable-form-schema';

export const FIELD_TYPES = {
  text: 'text',
  textarea: 'textarea',
  checkbox: 'checkbox',
  switch: 'switch',
  enumeration: 'enumeration',
  reference: 'reference',
  row: 'row',
  form: 'form',
  number: 'number',
  datetime: 'datetime',
  group: 'group',
  array: 'array',
  collapsible: 'collapsible',
  tabs: 'tabs',
  tab_pane: 'tab_pane',
  localized_text: 'localized_text',
  upload: 'upload',
} as const;

export const BUILDER_FIELD_TYPES: { [key: string]: FormBuilderUIFieldType } = {
  [FIELD_TYPES.row]: {
    title: 'form_schema.field_types.row',
    type: FIELD_TYPES.row,
    droppable: true,
  },
  [FIELD_TYPES.collapsible]: {
    title: 'form_schema.field_types.collapsible',
    type: FIELD_TYPES.collapsible,
    droppable: true,
  },
  [FIELD_TYPES.group]: {
    title: 'form_schema.field_types.group',
    type: FIELD_TYPES.group,
    droppable: true,
  },
  [FIELD_TYPES.array]: {
    title: 'form_schema.field_types.array',
    type: FIELD_TYPES.array,
    droppable: true,
  },
  [FIELD_TYPES.text]: {
    title: 'form_schema.field_types.text',
    type: FIELD_TYPES.text,
  },
  [FIELD_TYPES.textarea]: {
    title: 'form_schema.field_types.textarea',
    type: FIELD_TYPES.textarea,
  },
  [FIELD_TYPES.checkbox]: {
    title: 'form_schema.field_types.checkbox',
    type: FIELD_TYPES.checkbox,
  },
  [FIELD_TYPES.switch]: {
    title: 'form_schema.field_types.switch',
    type: FIELD_TYPES.switch,
  },
  [FIELD_TYPES.enumeration]: {
    title: 'form_schema.field_types.enumeration',
    type: FIELD_TYPES.enumeration,
  },
  [FIELD_TYPES.reference]: {
    title: 'form_schema.field_types.reference',
    type: FIELD_TYPES.reference,
  },
  [FIELD_TYPES.number]: {
    title: 'form_schema.field_types.number',
    type: FIELD_TYPES.number,
  },
  [FIELD_TYPES.datetime]: {
    title: 'form_schema.field_types.datetime',
    type: FIELD_TYPES.datetime,
  },
  [FIELD_TYPES.tabs]: {
    title: 'form_schema.field_types.tabs',
    type: FIELD_TYPES.tabs,
    droppable: true,
    limitedDroppableTypes: [FIELD_TYPES.tab_pane],
    limitedDraggableTypes: [
      EDITOR_DROPPABLE_SPACE_ID,
      FIELD_TYPES.row,
      FIELD_TYPES.collapsible,
      FIELD_TYPES.group,
      FIELD_TYPES.array,
    ],
  },
  [FIELD_TYPES.tab_pane]: {
    title: 'form_schema.field_types.tab_pane',
    type: FIELD_TYPES.tab_pane,
    droppable: true,
    limitedDraggableTypes: [FIELD_TYPES.tabs],
  },
  [FIELD_TYPES.localized_text]: {
    title: 'form_schema.field_types.localized_text',
    type: FIELD_TYPES.localized_text,
  },
  [FIELD_TYPES.upload]: {
    title: 'form_schema.field_types.upload',
    type: FIELD_TYPES.upload,
  },
};

export const GROUPED_BUILDER_FIELD_TYPES: GroupedFormBuilderUIFieldTypes[] = [
  {
    title: 'form_schema.field_types.input_elements',
    subtitle: 'form_schema.field_types.input_elements_subtitle',
    fieldTypes: [
      BUILDER_FIELD_TYPES.text,
      BUILDER_FIELD_TYPES.textarea,
      BUILDER_FIELD_TYPES.localized_text,
      BUILDER_FIELD_TYPES.checkbox,
      BUILDER_FIELD_TYPES.switch,
      BUILDER_FIELD_TYPES.number,
      BUILDER_FIELD_TYPES.datetime,
      BUILDER_FIELD_TYPES.upload,
    ],
  },
  {
    title: 'form_schema.field_types.data_selectors',
    subtitle: 'form_schema.field_types.data_selectors_subtitle',
    fieldTypes: [
      BUILDER_FIELD_TYPES.enumeration,
      BUILDER_FIELD_TYPES.reference,
    ],
  },
  {
    title: 'form_schema.field_types.structural_components',
    subtitle: 'form_schema.field_types.structural_components_subtitle',
    fieldTypes: [
      BUILDER_FIELD_TYPES.row,
      BUILDER_FIELD_TYPES.group,
      BUILDER_FIELD_TYPES.array,
    ],
  },
  {
    title: 'form_schema.field_types.navigation_elements',
    subtitle: 'form_schema.field_types.navigation_elements_subtitle',
    fieldTypes: [
      BUILDER_FIELD_TYPES.collapsible,
      BUILDER_FIELD_TYPES.tabs,
      BUILDER_FIELD_TYPES.tab_pane,
    ],
  },
];

export const FORM_BUILDER_UTILITY: FormBuilderFieldsUtils = {
  [FIELD_TYPES.text]: textFieldType,
  [FIELD_TYPES.textarea]: textareaFieldType,
  [FIELD_TYPES.row]: rowFieldType,
  [FIELD_TYPES.checkbox]: checkboxFieldType,
  [FIELD_TYPES.switch]: switchFieldType,
  [FIELD_TYPES.enumeration]: enumerationFieldType,
  [FIELD_TYPES.number]: numberFieldType,
  [FIELD_TYPES.datetime]: datetimeFieldType,
  [FIELD_TYPES.group]: groupFieldType,
  [FIELD_TYPES.array]: arrayFieldType,
  [FIELD_TYPES.reference]: referenceFieldType,
  [FIELD_TYPES.collapsible]: collapsibleFieldType,
  [FIELD_TYPES.tabs]: tabsFieldType,
  [FIELD_TYPES.tab_pane]: tabPaneFieldType,
  [FIELD_TYPES.localized_text]: localizedTextFieldType,
  [FIELD_TYPES.upload]: uploadFieldType,
};

export const FORM_BUILDER_SCHEMA_DEFAULT: FormBuilderSchema = {
  functionalId: '',
  label: '',
  description: '',
  type: FIELD_TYPES.form,
  fields: [],
};
