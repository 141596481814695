export const JSONMimeType = 'application/json';

const generateAndDownloadFile = (fileLink: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = fileLink;
  link.download = fileName;
  link.target = '_blank';
  link.click();
  URL.revokeObjectURL(fileLink);
  link.remove();
};

const downloadBlob = (fileName: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
};

const extractFileExtension = (fileName: string): string => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return '';
  }
  return fileName.substring(lastDotIndex);
};

const revokeFileExtension = (fileName: string): string => {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return fileName;
  }
  return fileName.substring(0, lastDotIndex);
};

const exportToJsonFile = (data: any, fileName: string) => {
  if (!data || data.length === 0) {
    throw new Error('Invalid data');
  }
  const jsonData = JSON.stringify(data);
  const blob = new Blob([jsonData], { type: JSONMimeType });
  downloadBlob(fileName, blob);
};

export {
  generateAndDownloadFile,
  downloadBlob,
  extractFileExtension,
  revokeFileExtension,
  exportToJsonFile,
};
