export const add = async (instance, PATH, element) => {
  const res = await instance.post(PATH, element);
  return res.data;
};

export const publishDraft = async (instance, PATH, functionalId) => {
  const res = await instance.patch(`${PATH}/${functionalId}/publish`);
  return res.data;
};

export const partiallyPublishDraft = async (
  instance,
  PATH,
  functionalId,
  publicationConstraints,
) => {
  const res = await instance.patch(
    `${PATH}/${functionalId}/partially-publish`,
    publicationConstraints,
  );
  return res.data;
};

export const createDraft = async (instance, PATH, functionalId) => {
  const res = await instance.put(`${PATH}/${functionalId}/draft`);
  return res.data;
};

export const unPublishDraft = async (instance, PATH, functionalId) => {
  const res = await instance.patch(`${PATH}/${functionalId}/unpublish`);
  return res.data;
};

export const updatePublicationConstraints = async (
  instance,
  PATH,
  functionalId,
  publicationConstraints,
) => {
  const res = await instance.patch(
    `${PATH}/${functionalId}/partially-publish/constraints`,
    publicationConstraints,
  );
  return res.data;
};

export const getVersions = async (instance, PATH, functionalId) => {
  const res = await instance.get(`${PATH}/${functionalId}/versions`);
  return res.data;
};

export const schedulePublicationDraft = async (
  instance,
  PATH,
  functionalId,
  publicationDate,
) => {
  const params = {
    publicationDate: publicationDate,
  };
  const res = await instance.patch(
    `${PATH}/${functionalId}/deferred-publication`,
    null,
    { params },
  );

  return res.data;
};

export const cancelPublication = async (instance, PATH, functionalId) => {
  const res = await instance.delete(
    `${PATH}/${functionalId}/deferred-publication`,
  );
  return res.data;
};
