import { create } from 'zustand';

export const useDrawer = create((set) => ({
  isOpen: false,
  body: null,
  options: {},
  openDrawer: ({ options, body }) => {
    set((state) => ({
      isOpen: true,
      options: {
        ...state.options,
        ...options,
      },
      body,
    }));
  },
  closeDrawer: () => {
    set(() => ({
      isOpen: false,
      body: null,
      options: {},
    }));
  },
}));
