import React from 'react';
import { Badge } from 'src/components/generic/Badge';
import { FormBuilderField } from 'src/pages/formSchemas/types';
import clsx from 'clsx';
import { BUILDER_FIELD_TYPES } from 'src/pages/formSchemas/utils/constants/form';
import { useTranslation } from 'react-i18next';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { useWatch } from 'react-hook-form';
import { getNestedFieldNameWithoutIndex } from 'src/pages/formSchemas/utils/form';
import { getDraggableElementStyle } from 'src/pages/formSchemas/utils/common';

interface DesignerComponentWrapperProps extends React.PropsWithChildren {
  fieldName: string;
  fieldValue: FormBuilderField;
  disabled: boolean;
  extra?: React.ReactNode;
}

export default function DesignerComponentWrapper({
  fieldName,
  fieldValue,
  extra,
  children,
  disabled,
}: Readonly<DesignerComponentWrapperProps>) {
  const { t } = useTranslation();

  // DnD
  const { isOver, attributes, listeners, transform, setNodeRef } = useSortable({
    id: fieldName,
    data: {
      fieldName,
      fieldValue,
    },
    disabled,
  });
  const style = getDraggableElementStyle({ transform });

  // Computed
  const fieldType = BUILDER_FIELD_TYPES[fieldValue.type];
  const droppableDisabled = !fieldType?.droppable || disabled;
  const subFieldsName = getNestedFieldNameWithoutIndex(fieldName);

  // Form
  const subFields = useWatch({ name: subFieldsName });
  const sortedSubFieldsNames = React.useMemo(
    () => subFields?.map((_: any, i: number) => `${subFieldsName}.${i}`) ?? [],
    [subFields, subFieldsName],
  );

  return (
    <div
      ref={setNodeRef}
      className={clsx(
        'bg-white border border-border relative p-2 rounded-md group cursor-move',
        {
          'bg-border': isOver && fieldType?.droppable,
          'min-h-[150px]': !droppableDisabled,
        },
      )}
      style={style}
      {...listeners}
      {...attributes}
    >
      <div className="flex justify-between space-x-4 items-center mb-2">
        <Badge color="secondary">
          {t(`form_schema.field_types.${fieldValue.type}`)}
        </Badge>
        <div className="invisible group-hover:visible transition-all">
          {extra}
        </div>
      </div>
      <SortableContext items={sortedSubFieldsNames} id={subFieldsName}>
        {children}
      </SortableContext>
    </div>
  );
}
