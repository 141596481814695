import React from 'react';

const ReusableFormSchemaListingContainer = React.lazy(
  () =>
    import(
      'src/pages/formSchemas/containers/ReusableFormSchemaListingContainer'
    ),
);

export default function ReusableFormSchemaListing() {
  return (
    <React.Suspense fallback={null}>
      <ReusableFormSchemaListingContainer />
    </React.Suspense>
  );
}
