export function setItem(key, value) {
  try {
    const stringValue =
      typeof value === 'string' || value instanceof String
        ? value
        : JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  } catch (e) {
    console.error(`Error setting item in localStorage: ${e}`);
  }
}

export function getItem(key) {
  try {
    const value = localStorage.getItem(key);
    if (value === null || value === undefined) return null;
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  } catch (e) {
    console.error(`Error getting item from localStorage: ${e}`);
    return null;
  }
}
