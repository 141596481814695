import ExportButton from '../ExportButton/ExportButton';
import { useEffect } from 'react';
import { getListProductsStatistics } from 'src/api';
import CustomPagination from 'src/components/generic/CustomPagination';
import { Table } from 'antd';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

function ProductListModal({
  functionnalIdOfPart,
  status,
  filter,
  showAll,
  nameOfPart,
  Modal,
  page,
  handlePageChange,
}) {
  useEffect(() => {});
  const { t } = useTranslation();
  const ELEMENT_PER_PAGE = 10;
  const columns = [
    {
      title: t('domains.' + filter),
      dataIndex: 'filter',
      key: 'filter',
    },
    {
      title: t('dashboard.functionalId'),
      dataIndex: 'functionalId',
      key: 'functionalId',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: t('dashboard.status'),
      dataIndex: 'status',
      key: 'status',
    },
  ];
  const { data: list } = useQuery(
    ['get-product-list', functionnalIdOfPart, status, filter, page, showAll],
    () =>
      getListProductsStatistics(
        functionnalIdOfPart,
        filter,
        status,
        page - 1,
        ELEMENT_PER_PAGE,
      ),
  );

  return (
    <Modal
      title={
        <div className="flex flex-grow">
          <div className="float-left">
            <ExportButton
              filter={filter}
              status={status}
              functionnalIdOfPart={functionnalIdOfPart}
              nameOfPart={nameOfPart}
            />
          </div>
          <div className="text-center flex-1 mr-4">
            {t('dashboard.list')}
            {t(`dashboard.${filter}`)} {nameOfPart || ''}
          </div>
        </div>
      }
      widthFull={'fit-content'}
    >
      <div>
        <Table dataSource={list} columns={columns} pagination={false} />
        <CustomPagination
          current={page}
          total={list?.[0]?.totalElements}
          pageSize={ELEMENT_PER_PAGE}
          onChange={handlePageChange}
        />
      </div>
    </Modal>
  );
}
export default ProductListModal;
