import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';

interface FieldEditButtonProps {
  onEdit: () => void;
}

export function FieldEditButton({ onEdit }: Readonly<FieldEditButtonProps>) {
  return (
    <Button
      data-testid="field-edit-button"
      onClick={onEdit}
      className="inline-flex items-center justify-center bg-white"
      size="middle"
      shape="circle"
      icon={<EditOutlined className="text-xs" />}
    />
  );
}
