import { QrcodeOutlined, TagOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InternationalizationInput from '../../../generic/inputs/InternationalizationInput';
import TextInput from '../../../generic/inputs/TextInput';
import OptionDetails from '../generic/OptionDetails';

const OptionsForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 12]}>
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('options.functionalId')}
          type="text"
          startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.label`}
          label={t('options.label')}
          startAdornment={<TagOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>

      <Col span={24}>
        <OptionDetails namePrefix={`${namePrefix}`} />
      </Col>
    </Row>
  );
};

OptionsForm.propTypes = {
  namePrefix: PropTypes.string,
};

export default OptionsForm;
