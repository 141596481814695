import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import VehicleForm from '../VehicleForm';
import { VEHICLE_OPTION_DEFAULT } from 'src/utils/defaultsSupplier';

export default function Vehicle(props) {
  const { namePrefix } = props;
  const name = namePrefix ? `${namePrefix}.vehicles` : 'vehicles';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="vehicle"
      Form={VehicleForm}
      defaultValues={VEHICLE_OPTION_DEFAULT}
      fieldsNames={['functionalId', 'label']}
    />
  );
}

Vehicle.propTypes = {
  namePrefix: PropTypes.string,
};
