import { DAMAGE_GUARANTEE_OPTION_DEFAULT } from '../../../../../utils/defaultsSupplier';
import ArrayAccordion from '../../../../generic/ArrayAccordion';
import DamageGuaranteeOptionForm from '../DamageGuaranteeOptionForm';
import PropTypes from 'prop-types';

const DamageGuaranteeOption = (props) => {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={`${namePrefix}.damageGuaranteeOptionList`}
      translationPrefix={'damageGuaranteeOptionList'}
      Form={DamageGuaranteeOptionForm}
      defaultValues={DAMAGE_GUARANTEE_OPTION_DEFAULT}
      fieldsNames={[]}
    />
  );
};

DamageGuaranteeOption.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default DamageGuaranteeOption;
