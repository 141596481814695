import { FormComponentType } from 'src/pages/formSchemas/types';
import { TextAreaInput } from 'src/components/generic/inputs/TextAreaInput';
import React from 'react';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;
  return (
    <TextAreaInput
      label={field.label as string}
      name={name}
      description={field.description}
      required={field.config?.required}
      placeholder={field.config?.placeholder}
      minLength={field.config?.minLength}
      maxLength={field.config?.maxLength}
    />
  );
};
