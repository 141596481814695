import ProductChart from './../productChart';
import { Top5ChartCard } from 'src/components/dashboard/charts/top5ChartCard/Top5ChartCard';
import { Col, Row } from 'antd';
import React from 'react';

export default function Charts() {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <ProductChart />
      </Col>
      <Col span={12}>{/*<Top5ChartCard />*/}</Col>
    </Row>
  );
}
