import { mapProductToData } from 'src/utils';
import { PRODUCT_DEFAULT } from 'src/utils/defaultsSupplier';
import { PRODUCT_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/productSchema';
import CardDisplay from '../../../generic/CardDisplay';
import Form from '../../../generic/Form';
import MainInfo from '../generic/MainInfo';
import Selling from '../generic/Selling';
import Status from '../generic/Status';
import VersionsTimeLine from '../generic/VersionsTimeLine';
import Visuel from '../generic/Visuel';
import SpecificInfo from '../generic/SpecificInfo';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { PRODUCT_BASE_PATH } from 'src/routes/paths';
import useVersions from 'src/hooks/normalHooks/useVersions';
import { getProductById, getProductVersions } from 'src/api';
import Loading from 'src/components/generic/Loading';
import { Error500 } from 'src/pages/error';
import { useTranslation } from 'react-i18next';
import ExportImport from '../../../generic/ExportImport';
import { AuthFormDisabler } from 'src/components/generic/AuthFormDisabler';
import { USER_ROLES } from 'src/utils/constants';
import { useCompareVersions } from 'src/hooks/componentHooks/useCompareVersions';

export default function ProductForm(props) {
  const {
    product,
    onSubmit,
    submitDisabled,
    isUpdateForm,
    formDisabled,
    additionalButtons,
  } = props;

  const navigate = useNavigate();

  const { versions, isLoading, isError } = useVersions(
    product?.id,
    getProductVersions,
  );
  const { t } = useTranslation();
  const { onCompare } = useCompareVersions({
    versionFetcherQuery: (id) => getProductById(id),
    versions,
  });

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <Error500 />;
  }

  const exportMapper = (data) => {
    const result = {
      ...data,
      functionalId: undefined,
    };
    if (result.formSchema) {
      result.formSchema = { ...result.formSchema, __schema: undefined };
    }
    return result;
  };
  return (
    <AuthFormDisabler
      componentRender={({ isAuth }) => (
        <Form
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
          formDisabled={formDisabled || !isAuth}
          isUpdateForm={isUpdateForm}
          onCancel={() => navigate(PRODUCT_BASE_PATH)}
          defaultValues={product ? mapProductToData(product) : PRODUCT_DEFAULT}
          validationSchema={PRODUCT_VALIDATION_SCHEMA}
          buttonsOnTop={true}
          additionalButtons={
            <>
              {additionalButtons}
              <ExportImport dataName="product" onExportMapper={exportMapper} />
            </>
          }
        >
          <Row gutter={[16, 12]} className="px-3 mt-5">
            <Col span={8}>
              <Visuel />
              <CardDisplay title={t('versions.versions')}>
                <VersionsTimeLine
                  versions={versions}
                  path="/products/update/"
                  onCompare={onCompare}
                />
              </CardDisplay>
              <Status />
              <Selling />
            </Col>
            <Col span={16}>
              <MainInfo />
              <SpecificInfo />
            </Col>
          </Row>
        </Form>
      )}
      roles={[USER_ROLES.EDIT_PRODUCTS]}
    />
  );
}
