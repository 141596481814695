import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const initialFormValues = {
  product: {
    product: '',
    options: [],
  },
  act: '',
};

const schema = yup.object().shape({
  product: yup.object().required(),
  act: yup.string().required(),
});

export const useSelectProductSimulationForm = () => {
  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: initialFormValues,
  });

  const { handleSubmit } = form;

  return {
    form,
    handleSubmit,
  };
};
