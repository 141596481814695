import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ParameterMultiSelect from 'src/components/generic/inputs/ParameterMultiSelect';

export const SchemaCreationFields: FunctionComponent<{
  disabled?: boolean;
}> = ({ disabled }) => {
  const { t } = useTranslation();
  return (
    <ParameterMultiSelect
      domain="FAMILY"
      name="schemableId.family"
      label={t(`targets.families`)}
      disabled={disabled}
    />
  );
};
