import React from 'react';
import { FormComponentType } from 'src/pages/formSchemas/types';
import FormUpload from 'src/pages/formSchemas/components/FormUpload';
import { FILE_MIME_TYPES } from 'src/pages/formSchemas/utils/constants/media';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;

  return (
    <FormUpload
      label={field.label}
      name={name}
      multiple={field.config?.multiple}
      required={field.config?.required}
      mimeTypes={FILE_MIME_TYPES[field.config?.fileType]}
    />
  );
};
