import {
  DesignerFormComponentType,
  FormBuilderField,
} from 'src/pages/formSchemas/types';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import TextInput from 'src/components/generic/inputs/TextInput';
import { TextAreaInput } from 'src/components/generic/inputs/TextAreaInput';
import { CheckboxInput } from 'src/components/generic/inputs/CheckboxInput';
import { Button } from 'antd';
import React from 'react';

export const DesignerFormComponent: DesignerFormComponentType = ({
  fieldName,
  fieldValue,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const methods = useForm<FormBuilderField>({
    defaultValues: fieldValue,
  });

  const handleSubmit = (data: FormBuilderField) => {
    return onSubmit(fieldName, data);
  };

  return (
    <FormProvider {...methods}>
      <form className="space-y-4" onSubmit={methods.handleSubmit(handleSubmit)}>
        <TextInput type="text" name="name" label={t('form.name')} required />
        <TextInput type="text" name="label" label={t('form.label')} required />
        <TextInput
          type="number"
          name="config.minLength"
          label={t('form.min_length')}
        />
        <TextInput
          type="number"
          name="config.maxLength"
          label={t('form.max_length')}
        />
        <TextInput
          type="text"
          name="config.placeholder"
          label={t('form.placeholder')}
        />
        <TextAreaInput
          name="config.defaultValue"
          label={t('form.default_value')}
        />
        <CheckboxInput name="config.required" label={t('form.required')} />
        <Button
          type="primary"
          ghost
          htmlType="submit"
          className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
        >
          {t('form.save')}
        </Button>
      </form>
    </FormProvider>
  );
};
