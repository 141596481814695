export const APP_BASE_PATH = '/';
export const DASHBOARD_BASE_PATH = '/dashboard';
export const PRODUCT_BASE_PATH = '/products';
export const PRODUCT_ADD_PATH = PRODUCT_BASE_PATH + '/new';
export const PRODUCT_UPDATE_PATH = PRODUCT_BASE_PATH + '/update/:functionalId';
export const ELIGIBLE_ACT_BASE_PATH = '/eligible-acts';
export const ELIGIBLE_ACT_ADD_PATH = ELIGIBLE_ACT_BASE_PATH + '/new';
export const ELIGIBLE_ACT_UPDATE_PATH =
  ELIGIBLE_ACT_BASE_PATH + '/update/:eligibleActId';
export const PARAMETER_BASE_PATH = '/parameters';
export const HOME_BASE_PATH = DASHBOARD_BASE_PATH;

export const EVENT_PRICING_BASE_PATH = '/event-pricings';
export const EVENT_PRICING_ADD_PATH = EVENT_PRICING_BASE_PATH + '/new';
export const EVENT_PRICING_UPDATE_PATH =
  EVENT_PRICING_BASE_PATH + '/update/:eventPricingId';

export const RECOMMENDATION_BASE_PATH = '/recommendations';
export const RECOMMENDATION_ADD_PATH = RECOMMENDATION_BASE_PATH + '/new';
export const RECOMMENDATION_UPDATE_PATH =
  RECOMMENDATION_BASE_PATH + '/update/:recommendationId';
export const ERROR_PATH = '/error';

export const FACT_BASE_PATH = '/facts';
export const FACT_ADD_PATH = FACT_BASE_PATH + '/new';
export const FACT_UPDATE_PATH = FACT_BASE_PATH + '/update/:FactCollectionId';

export const PARAMETER_SCHEMAS_BASE_PATH = '/parameter-schemas';
export const PARAMETER_SCHEMAS_CREATE_PATH = '/parameter-schemas/create';
export const PARAMETER_SCHEMAS_UPDATE_PATH = '/parameter-schemas/:id/edit';
export const SIMULATION_PATH = '/simulation';
export const SIMULATION_ELIGIBILITY_PATH = SIMULATION_PATH + '/eligibility';
export const SIMULATION_PRICING_PATH = SIMULATION_PATH + '/pricing';

export const TEST_CASES_PATH = '/test-cases';
export const ELIGIBILITY_TEST_CASES_PATH = TEST_CASES_PATH + '/eligibility';
export const PRICING_TEST_CASES_PATH = TEST_CASES_PATH + '/pricing';

// Administration technique
export const TECHNICAL_ADMIN_BASE_PATH = '/administration';
export const ADMIN_TECH_CONSULT_AUDIT_PATH =
  TECHNICAL_ADMIN_BASE_PATH + '/consultAudit';
export const ADMIN_TECH_CONSULT_AUDIT_CATALOGUE_PATH =
  TECHNICAL_ADMIN_BASE_PATH + '/consultAudit/catalogue';
export const ADMIN_TECH_CONSULT_AUDIT_PRICING_PATH =
  TECHNICAL_ADMIN_BASE_PATH + '/consultAudit/pricing';

export const ADMIN_TECH_CONSULT_AUDIT_FACTS_PATH =
  TECHNICAL_ADMIN_BASE_PATH + '/consultAudit/facts';

export const AUDIT_STATISTICS_PATH =
  TECHNICAL_ADMIN_BASE_PATH + '/audit-statistics';
export const USER_MANAGEMENT_BASE_PATH =
  TECHNICAL_ADMIN_BASE_PATH + '/user-management';
export const USERS_BASE_PATH = USER_MANAGEMENT_BASE_PATH + '/users';
export const PROFILES_BASE_PATH = USER_MANAGEMENT_BASE_PATH + '/profiles';

export const TECHNICAL_SETTING_PATH =
  TECHNICAL_ADMIN_BASE_PATH + '/technical-setting';

export const DOMAINS_BASE_PATH = '/domains';
export const DOMAINS_CREATE_PATH = '/domains/create';
export const DOMAINS_UPDATE_PATH = '/domains/:id/edit';

export const RULE_BASE_PATH = '/rules';
export const EVENT_PRICING_RULE_BASE_PATH = '/event-pricings/rules';

export const RULE_ADD_PATH = RULE_BASE_PATH + '/:type/new';
export const RULE_UPDATE_PATH = RULE_BASE_PATH + '/:type/update/:ruleId';

export const FORM_SCHEMAS_BASE_PATH = '/form-schemas';
export const FORM_SCHEMAS_EDIT_PATH = `${FORM_SCHEMAS_BASE_PATH}/:id/edit`;
export const FORM_SCHEMAS_PREVIEW_PATH = `${FORM_SCHEMAS_BASE_PATH}/:id/preview`;

export const REUSABLE_FORM_SCHEMAS_BASE_PATH = `${FORM_SCHEMAS_BASE_PATH}/reusables`;
export const REUSABLE_FORM_SCHEMAS_CREATE_PATH = `${REUSABLE_FORM_SCHEMAS_BASE_PATH}/create`;
export const REUSABLE_FORM_SCHEMAS_EDIT_PATH = `${REUSABLE_FORM_SCHEMAS_BASE_PATH}/:id/edit`;

export const CACHING_BASE_PATH = `/caches`;
