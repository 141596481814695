const ErrorDisplayer = ({ message, fieldErrors }) => {
  return (
    <>
      {message}
      {fieldErrors && Array.isArray(fieldErrors) && fieldErrors.length > 0 && (
        <ul className="m-2">
          {fieldErrors.map((elem, i) => {
            const errorMessage = elem.rejectedValue
              ? elem.rejectedValue + ' ' + elem.message
              : elem.message;
            return <ol key={i}>{errorMessage}</ol>;
          })}
        </ul>
      )}
    </>
  );
};

export default ErrorDisplayer;
