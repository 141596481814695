import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from '../../mapperHelpers';

export const mapDataToCard = (data) => {
  const defaultTheme = data.themes?.[0]?.functionalId;
  const themeList = data.themes.map((d) => mapDataToTheme(d));

  return {
    debitType: data.debitType,
    type: 'card',
    defaultTheme,
    themeList,
    authorizedHolders: data.authorizedHolders,
    tokenizable: data.tokenizable,
    purchaser: data.purchaser,
  };
};

const mapDataToTheme = (data) => {
  return {
    functionalId: data.functionalId,
    descriptions: mapDataToInternationalisation(data.descriptions),
    label: mapDataToInternationalisation(data.label),
    details: mapDataToInternationalisation(data.details),
    thumbnail: data.thumbnail,
    largeImage: data.largeImage,
    formulaList: mapDataToFormulas(data),
    virtual: data.virtual,
  };
};

const mapDataToFormulas = (data) => {
  return data.formulas.map((formula) => ({
    functionalId: formula.functionalId,
    label: mapDataToInternationalisation(formula.label),
    description: mapDataToInternationalisation(formula.description),
    details: formula.details.map((elem) => mapDataToInternationalisation(elem)),
    plafonds: formula.plafonds,
    caps: formula.caps,
  }));
};

export const mapCardToData = (card) => {
  return {
    debitType: card.debitType,
    themes: card.themeList.map((theme) => mapThemeToData(theme)),
    authorizedHolders: card.authorizedHolders,
    tokenizable: card.tokenizable,
    purchaser: card.purchaser,
  };
};

const mapThemeToData = (theme) => {
  return {
    functionalId: theme.functionalId,
    descriptions: mapInternationalisationToData(theme.descriptions),
    label: mapInternationalisationToData(theme.label),
    details: mapInternationalisationToData(theme.details),
    thumbnail: theme.thumbnail,
    largeImage: theme.largeImage,
    formulas: mapFormulasToData(theme.formulaList),
    virtual: theme.virtual,
  };
};

const mapFormulasToData = (formulas) => {
  return formulas.map((formula) => ({
    functionalId: formula.functionalId,
    label: mapInternationalisationToData(formula.label),
    description: mapInternationalisationToData(formula.description),
    details: formula.details.map((elem) => mapInternationalisationToData(elem)),
    plafonds: formula.plafonds,
    caps: formula.caps,
  }));
};
