import { createContext, useContext } from 'react';

const SideBarContext = createContext({
  collapsed: false,
  defaultSelected: null,
});

const useSideBarContext = () => useContext(SideBarContext);

export { SideBarContext, useSideBarContext };
