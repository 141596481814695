import React from 'react';
import clsx from 'clsx';

function ScrollArea({ children, className }) {
  return (
    <div className={clsx('overflow-y-auto h-[200px] max-h-[200px]', className)}>
      {children}
    </div>
  );
}

export { ScrollArea };
