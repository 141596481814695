import { FormPropertySchema } from 'src/types';
import { FormField } from 'src/containers/DynamicFormContainer/components/FormField';
import React from 'react';

export function resolvePropertiesDefaultValues(
  properties: FormPropertySchema[] | null | undefined,
  formDefaultValues: any,
) {
  if (!properties) {
    return formDefaultValues;
  }

  const result = { ...formDefaultValues };
  properties.forEach(function ({ id: fieldName, ui }) {
    if (
      !formDefaultValues ||
      !Object.getOwnPropertyDescriptor(formDefaultValues, fieldName)
    ) {
      if (ui?.defaultValue) {
        result[fieldName] = ui.defaultValue;
      }
    }
  });

  return result;
}

interface ParseFormFieldsProps {
  properties?: FormPropertySchema[];
  namePrefix?: string;
}

export function parseFormFields({
  properties,
  namePrefix = '',
}: ParseFormFieldsProps): React.ReactNode[] {
  if (!properties?.length) {
    return [];
  }

  return properties.map((field) => (
    <FormField key={field.id} schema={field} namePrefix={namePrefix} />
  ));
}
