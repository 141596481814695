import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllEligibleActsByCriteria } from '../../../api/eligibleAct';
import { ELIGIBLE_ACT_ADD_PATH } from '../../../routes/paths';
import { useQuery } from 'react-query';
import { Button, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Error500 } from 'src/pages/error';
import {
  ELIGIBLEACT_FILTER_FIELDS,
  filterParamMapedKeys,
  filterListTypeKeys,
} from 'src/utils/filterFields';
import GenericFilter from 'src/components/generic/GenericFilter';
import EventCard from '../../../components/generic/EventCard';
import CustomPagination from 'src/components/generic/CustomPagination';
import Loading from 'src/components/generic/Loading';
import {
  mapFilterToUrl,
  mapParamsToFilter,
} from 'src/utils/mappers/filterMapper';
import { USER_ROLES } from 'src/utils/constants';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';

const ELEMENT_PER_PAGE = 20;

export const EligibleActsListingPage = () => {
  const { t } = useTranslation();
  const { state, pathname, search } = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState({});

  // Extract query parameters from the URL
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const {
    data: eligibleActs,
    isLoading,
    isError,
  } = useQuery(['get-eligible-acts', page, currentFilter], () =>
    getAllEligibleActsByCriteria(page - 1, ELEMENT_PER_PAGE, currentFilter),
  );

  useEffect(() => {
    if (state?.toastContent) toast.success(state?.toastContent);
    const filter = mapParamsToFilter(
      queryParams,
      filterParamMapedKeys,
      filterListTypeKeys,
    );

    setCurrentFilter(filter);
    setPage(1);
  }, [state, queryParams]);

  const onFilter = (param) => {
    const paramsString = mapFilterToUrl(param || {}, filterParamMapedKeys);
    const newUrl = `${pathname}?${paramsString}`;
    navigate(newUrl, { replace: true });
  };

  if (isError) return <Error500 />;

  return (
    <>
      <div className="h-auto">
        <GenericFilter
          onFilter={onFilter}
          initialFilterValues={currentFilter}
          emptyFilterValues={{}}
          filterFields={ELIGIBLEACT_FILTER_FIELDS}
          disableSubmit={isLoading}
        />

        {isLoading ? (
          <Loading />
        ) : (
          <div className="w-full h-full flex flex-col p-5">
            <div className="flex items-end mb-4 mr-2 justify-between">
              <p className="mx-2">
                {eligibleActs?.totalElements[0]?.totalElements
                  ? eligibleActs?.totalElements[0]?.totalElements +
                    ' ' +
                    t('search.numberEligibleActsFound')
                  : t('search.noResultFound')}
              </p>
              <Link to={ELIGIBLE_ACT_ADD_PATH}>
                <AuthComponentDisabler
                  componentRender={() => (
                    <Button
                      type="primary"
                      className="bg-orange-500 text-white flex items-center disabled:opacity-30"
                    >
                      <PlusOutlined sx={{ fontSize: '20px' }} /> {t('crud.add')}{' '}
                      {t('subnav.eligibleAct')}
                    </Button>
                  )}
                  roles={[USER_ROLES.EDIT_EVENTS]}
                />
              </Link>
            </div>

            {eligibleActs?.content.length > 0 ? (
              <div className="grid grid-cols-5 gap-4 w-full mb-2">
                {eligibleActs?.content?.map((act) => (
                  <Link key={act.id} to={`/eligible-acts/update/${act.id}`}>
                    <EventCard event={act} />
                  </Link>
                ))}
              </div>
            ) : (
              <div className="h-96">
                <Empty />
              </div>
            )}
            <CustomPagination
              current={page}
              total={eligibleActs?.totalElements[0]?.totalElements}
              pageSize={ELEMENT_PER_PAGE}
              onChange={(value) => setPage(value)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default EligibleActsListingPage;
