import { Controller, useFormContext } from 'react-hook-form';
import { Form } from 'antd';
import { KeyValue } from './KeyValue';
import React from 'react';
import PropTypes from 'prop-types';

export function KeyValueInput({
  name,
  label,
  description,
  onChange,
  disabled,
  keyLabel = 'Key',
  valueLabel = 'Value',
  keyName = 'key',
  valueName = 'value',
}) {
  const { control, formDisabled } = useFormContext();

  return (
    <Form.Item className="mb-0" help={description}>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <KeyValue
            keyLabel={keyLabel}
            valueLabel={valueLabel}
            disabled={disabled || formDisabled}
            rows={field.value ?? []}
            keyName={keyName}
            valueName={valueName}
            onChange={(rows) => {
              field.onChange(rows);
              onChange?.(rows);
            }}
          />
        )}
      />
    </Form.Item>
  );
}

KeyValueInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  keyLabel: PropTypes.string,
  valueLabel: PropTypes.string,
  keyName: PropTypes.string,
  valueName: PropTypes.string,
};
