import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addRecommendation } from 'src/api/recommendation';
import CardDisplay from 'src/components/generic/CardDisplay';
import RecommendationForm from 'src/components/recommendation/form/RecommendationForm';
import { useAddMutation } from 'src/hooks/normalHooks/mutation/useAddMutation';
import { RECOMMENDATION_BASE_PATH } from 'src/routes/paths';
import { mapDataToRecommendation } from 'src/utils/mappers/recommendationMapper';
function RecommendationAddPage() {
  const { t } = useTranslation();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const recommendationAddMutation = useAddMutation({
    add: addRecommendation,
    basePath: RECOMMENDATION_BASE_PATH,
    translationPrefix: 'recommendation',
    onSettled: () => setSubmitDisabled(false),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    const mappedRecommendation = mapDataToRecommendation(data);
    recommendationAddMutation.mutate(mappedRecommendation);
  };

  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t('recommendation.add')}>
        <RecommendationForm
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
        />
      </CardDisplay>
    </div>
  );
}

export default RecommendationAddPage;
