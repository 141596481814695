import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { mapDataToFactCollection } from '../../../utils/mappers/factMapper';
import {
  getFactCollectionById,
  updateFactCollection,
} from '../../../api/facts';
import { FACT_BASE_PATH } from '../../../routes/paths';
import Loading from '../../../components/generic/Loading';
import { Error500 } from '../../error';
import CardDisplay from '../../../components/generic/CardDisplay';
import FactCollectionForm from '../../../components/fact/FactCollectionForm';
import { useUpdateMutation } from '../../../hooks/normalHooks/mutation/useUpdateMutation';

const FactUpdatePage = () => {
  const { FactCollectionId } = useParams();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { t } = useTranslation();
  const {
    data: FactCollection,
    isLoading,
    isError,
  } = useQuery(['get-fact-element-collection', FactCollectionId], () =>
    getFactCollectionById(FactCollectionId),
  );

  const FactCollectionUpdateMutation = useUpdateMutation({
    update: (FactCollection) =>
      updateFactCollection(FactCollection, FactCollectionId),
    basePath: FACT_BASE_PATH,
    queryKey: ['get-FactCollection', FactCollectionId],
    translationPrefix: 'FactCollection',
    onSettled: () => setSubmitDisabled(false),
  });
  const onSubmit = (data) => {
    setSubmitDisabled(true);
    const mappedFactCollection = mapDataToFactCollection(data);
    FactCollectionUpdateMutation.mutate(mappedFactCollection);
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;
  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t('FactCollection.update')}>
        <FactCollectionForm
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
          FactCollection={FactCollection}
        />
      </CardDisplay>
    </div>
  );
};

export default FactUpdatePage;
