import { PercentageOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/generic/inputs/TextInput';
import SelectInput from '../../../../generic/inputs/SelectInput';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';

import SpecificPricingInfo from 'src/components/eventPricing/pricing/SpecificPricingInfo/SpecificPricingInfo';

export default function PricingForm(props) {
  const { namePrefix } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <SelectInput
          name={`${namePrefix}.type`}
          label="Type"
          data={[
            {
              functionalId: 'flat-pricing',
              name: t('priceType.flat'),
            },
            {
              functionalId: 'percentage-pricing',
              name: t('priceType.percentage'),
            },
            {
              functionalId: 'level-pricing',
              name: t('priceType.level'),
            },
            {
              functionalId: 'formula-pricing',
              name: t('priceType.formula'),
            },
            {
              functionalId: 'rate-pricing',
              name: t('priceType.rate'),
            },
          ]}
        />
        <TextInput
          name={`${namePrefix}.flexibility`}
          label={t('pricing.flexibility')}
          type="number"
          startAdornment={<PercentageOutlined style={{ fontSize: '25px' }} />}
        />
        <SpecificPricingInfo namePrefix={namePrefix} />
      </div>
      <p className="font-medium">{t('pricing.constraints')}</p>
      <ConstraintWrapper namePrefix={namePrefix} />
    </div>
  );
}
