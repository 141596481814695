import { DatePicker, Form } from 'antd';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { mapDateToData } from 'src/utils/mappers/dateMapper';

export default function CustomDatePicker(props) {
  const {
    label,
    name,
    placeholder,
    disabled = false,
    description = undefined,
    size = 'large',
    dateMapper = mapDateToData,
    showTime = false,
    disabledDate,
    showSecond = false,
    showNow = false,
    showInlineErrors = false,
  } = props;

  const { control, formDisabled, formState } = useFormContext();

  return (
    <Form.Item
      style={{ marginBottom: '0px' }}
      validateStatus={formState.errors[name] ? 'error' : ''}
      help={
        showInlineErrors && formState.errors[name]
          ? formState.errors[name].message
          : description
      }
    >
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            value={dateMapper(field.value)}
            size={size}
            className="w-full"
            placeholder={placeholder}
            disabled={formDisabled || disabled}
            showTime={showTime}
            disabledDate={disabledDate}
            showSecond={showSecond}
            showNow={showNow}
          />
        )}
      />
    </Form.Item>
  );
}

CustomDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  control: PropTypes.object,
  errors: PropTypes.object,
  dateMapper: PropTypes.func,
  showTime: PropTypes.bool,
  disabledDate: PropTypes.func,
  showSecond: PropTypes.bool,
  showNow: PropTypes.bool,
  placeholder: PropTypes.string,
};
