import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import BeneficiaryForm from '../BeneficiaryForm/BeneficiaryForm';
import { BENEFICIARY_OPTION_DEFAULT } from 'src/utils/defaultsSupplier';

export default function Beneficiary(props) {
  const { namePrefix } = props;
  const name = namePrefix ? `${namePrefix}.beneficiaries` : 'beneficiaries';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="beneficiary"
      Form={BeneficiaryForm}
      defaultValues={BENEFICIARY_OPTION_DEFAULT}
      fieldsNames={['functionalId', 'label']}
    />
  );
}

Beneficiary.propTypes = {
  namePrefix: PropTypes.string,
};
