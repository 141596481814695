import clsx from 'clsx';
import React from 'react';

function SidebarLogo() {
  const logoSrc = 'Catanem';
  return (
    <div className="w-full p-3 shadow-sm mb-3">
      <span className={clsx('font-medium text-xl block py-2')}>{logoSrc}</span>
    </div>
  );
}

export { SidebarLogo };
