import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, UploadFile } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UUIDClass from 'uuidjs';
import { getDownloadUrl, getImageUploadUrl, uploadFile } from 'src/api';
import { MediaMetadataResponseDto } from 'src/types';
import { RcFile } from 'antd/es/upload';
import { extractFileExtension, revokeFileExtension } from 'src/utils/fileUtils';
import { InputLabel } from 'src/components/generic/inputs/InputLabel';
import React from 'react';
import ComponentDisabler from 'src/components/generic/ComponentDisabler';

interface FormUploadProps {
  name: string;
  multiple?: boolean;
  mimeTypes?: string[];
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

interface UploadFieldTypeContent {
  mediaId: string;
  fileName: string;
  fileSize?: number;
  url: string;
}

export default function FormUpload({
  name,
  multiple,
  mimeTypes,
  label,
  required,
  disabled,
}: FormUploadProps) {
  // Hooks
  const { t } = useTranslation();

  // Form
  const { setValue, clearErrors, control } = useFormContext();
  const fieldFiles:
    | UploadFieldTypeContent[]
    | UploadFieldTypeContent
    | undefined = useWatch({
    control,
    name,
  });
  const appendFileToForm = (file: UploadFieldTypeContent) => {
    if (!multiple) {
      setValue(name, file);
      return;
    }

    const oldValues = fieldFiles
      ? (fieldFiles as UploadFieldTypeContent[])
      : [];
    setValue(name, [...oldValues, file]);
  };

  const removeFileFromForm = (file: UploadFile<any>) => {
    if (!multiple) {
      setValue(name, undefined);
      return;
    }

    const oldValues = fieldFiles
      ? (fieldFiles as UploadFieldTypeContent[])
      : [];
    setValue(
      name,
      oldValues.filter(({ mediaId }) => mediaId !== file.uid),
    );
  };

  // Computed
  const files: UploadFile<any>[] = React.useMemo(() => {
    if (!fieldFiles) {
      return [];
    }

    return (
      (multiple ? fieldFiles : [fieldFiles]) as UploadFieldTypeContent[]
    )?.map((field) => ({
      uid: field.mediaId,
      name: field.fileName,
      status: 'done',
      url: field.url,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldFiles]);

  // Handlers
  const handleRemove = (file: UploadFile<any>) => {
    removeFileFromForm(file);
    return true;
  };

  const handleUpload = async (file: RcFile) => {
    const originalFileName = file.name;
    const fileName = revokeFileExtension(originalFileName);
    const extension = extractFileExtension(originalFileName);
    const funcId = UUIDClass.generate();

    const mediaMetadata: MediaMetadataResponseDto = await getImageUploadUrl(
      fileName + funcId + extension,
      extension,
      funcId,
    );

    await uploadFile(mediaMetadata.preSignedUrl, file);
    const url = await getDownloadUrl(mediaMetadata.metaDataId, funcId);

    clearErrors(name);
    appendFileToForm({
      mediaId: mediaMetadata.metaDataId,
      fileName: originalFileName,
      fileSize: file.size,
      url,
    });

    return false;
  };

  return (
    <div className="flex flex-col space-y-2">
      {label && <InputLabel label={label} required={required} />}
      <Upload
        onRemove={handleRemove}
        beforeUpload={handleUpload}
        fileList={files}
        multiple={multiple}
        accept={mimeTypes?.join(',')}
      >
        <ComponentDisabler
          componentRender={() => (
            <Button icon={<UploadOutlined />} disabled={disabled}>
              {t('form.upload_file')}
            </Button>
          )}
        />
      </Upload>
    </div>
  );
}
