import HttpService from '../../services/HttpService';
import { DASHBOARD_PATH } from '../apiPaths';

const instance = HttpService.getAxiosClient('catalogue');

export const getCountOfProducts = async (status) => {
  const res = await instance.get(`${DASHBOARD_PATH}/products/count`, {
    params: { status },
  });
  return res.data;
};
export const getCountOfRecommendations = async (status) => {
  const res = await instance.get(`${DASHBOARD_PATH}/recommendations/count`, {
    params: { status },
  });
  return res.data;
};
export const getCountOfParameters = async () => {
  const res = await instance.get(`${DASHBOARD_PATH}/parameters/count`);
  return res.data;
};

export const getCountOfEligibleAct = async (status) => {
  const res = await instance.get(`${DASHBOARD_PATH}/eligible-acts/count`, {
    params: { status },
  });
  return res.data;
};
export const getStasticsOfProductsByFilter = async (filter, status) => {
  const res = await instance.get(`${DASHBOARD_PATH}/statistics`, {
    params: { filter, status },
  });
  return res.data;
};

export const getListProductsStatistics = async (
  filterValue,
  filter,
  status,
  page = 0,
  size = 10,
) => {
  const res = await instance.get(`${DASHBOARD_PATH}/products/summaries`, {
    params: {
      filterValue,
      filter,
      status,
      page,
      size,
    },
  });
  return res.data;
};

export const exportDataAsExcel = async (filter, status, valueOfFilterField) => {
  const res = await instance.get(`${DASHBOARD_PATH}/exports/statistics`, {
    params: { filter, status, valueOfFilterField },
    responseType: 'arraybuffer',
  });
  return res.data;
};
