import { FormProvider, useForm } from 'react-hook-form';
import SelectInput from 'src/components/generic/inputs/SelectInput';
import { StatisticFilterSelect } from 'src/components/administration/audit/StatisticsFilterSelect';
import { Button } from 'antd';
import { MagnifyingGlass } from 'src/components/icons';
import useParameters from 'src/hooks/normalHooks/useParameters';
import {
  useGetAllProductCodes,
  useGetAuditableActions,
} from 'src/pages/administration/auditStatistics/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AuditableAction } from 'src/utils/constants';
import InputConditionalDisplayer from 'src/components/generic/inputs/InputConditionalDisplayer';
import {
  mapAuditableActionsToOptions,
  mapProductsToOptions,
} from 'src/utils/mappers/selectOptionsMapper';
import { yupResolver } from '@hookform/resolvers/yup';
import { filtersSchema } from 'src/components/administration/audit/StatisticsFilters/form';

function StatisticFilters({ api, domain, setFilter }) {
  const { t } = useTranslation();
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(filtersSchema),
  });
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage,
  );

  useEffect(() => {
    formMethods.reset({
      ...Object.keys(formMethods.getValues()).reduce((acc, field) => {
        acc[field] = null;
        return acc;
      }, {}),
      auditableAction: Object.values(AuditableAction[api][domain])[0],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  const onSearch = (formData) => {
    setFilter(formData);
  };

  const {
    data: canals,
    isLoading: isCanalsLoading,
    isError: isCanalsError,
  } = useParameters('CANAL');
  const {
    data: families,
    isLoading: isFamiliesLoading,
    isError: isFamiliesError,
  } = useParameters('FAMILY');
  const {
    data: acts,
    isLoading: isActsLoading,
    isError: isActsError,
  } = useParameters('ACT');
  const {
    data: products,
    isLoading: isProductsLoading,
    isError: isProductsError,
  } = useGetAllProductCodes();
  const {
    data: auditableActions,
    isLoading: isAuditableActionsLoading,
    isError: isAuditableActionsError,
  } = useGetAuditableActions(domain);

  function onClear(name) {
    return () =>
      setFilter((prevFilter) => ({ ...prevFilter, [name]: undefined }));
  }

  return (
    <form onSubmit={formMethods.handleSubmit(onSearch)}>
      <FormProvider {...formMethods}>
        <div className="flex gap-1 flex-wrap">
          <InputConditionalDisplayer
            isLoading={isAuditableActionsLoading}
            isError={isAuditableActionsError}
            size="middle"
          >
            <SelectInput
              size="middle"
              placeholder={t('statistics.filter.auditableAction')}
              data={mapAuditableActionsToOptions(auditableActions, t)}
              name="auditableAction"
              className="w-fit"
              disabled={auditableActions?.length === 1}
            />
          </InputConditionalDisplayer>
          <StatisticFilterSelect
            placeholder={t('statistics.filter.canal')}
            name="canal"
            options={canals}
            onClear={onClear}
            isLoading={isCanalsLoading}
            isError={isCanalsError}
          />

          <StatisticFilterSelect
            placeholder={t('statistics.filter.family')}
            name="family"
            options={families}
            onClear={onClear}
            isLoading={isFamiliesLoading}
            isError={isFamiliesError}
          />

          <StatisticFilterSelect
            placeholder={t('statistics.filter.act')}
            name="act"
            options={acts}
            onClear={onClear}
            isLoading={isActsLoading}
            isError={isActsError}
          />
          <StatisticFilterSelect
            placeholder={t('statistics.filter.product')}
            name="product"
            options={
              !products ? [] : mapProductsToOptions(products, selectedLang)
            }
            onClear={onClear}
            isLoading={isProductsLoading}
            isError={isProductsError}
          />

          <div className={'flex items-end justify-end flex-1'}>
            <Button
              disabled={!formMethods.formState.isValid}
              type="primary"
              htmlType="submit"
              className="ml-auto text-white bg-primary hover:bg-orange-500  disabled:opacity-30 flex items-center font-medium"
              icon={<MagnifyingGlass className="h-4 w-4 mr-2" />}
            >
              {t('search.search')}
            </Button>
          </div>
        </div>
      </FormProvider>
    </form>
  );
}

export { StatisticFilters };
