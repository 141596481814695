import { Collapse, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PanelList from '../PanelList';
import PanelHeader from '../PanelHeader';

const { Text } = Typography;
const { Panel } = Collapse;

const RuleUpdateConfirmation = ({ usages }) => {
  const { t } = useTranslation();

  const totalAffectedElements =
    usages.eligibleActsResponseList.length +
    usages.recommendationResponseList.length +
    usages.productResponseList.length +
    usages.eventPricingResponseList.length;

  return (
    <div className="py-5">
      <Text type="danger">
        {totalAffectedElements
          ? t('rule.updateConfirmation', { count: totalAffectedElements })
          : t('rule.updateConfirmationWithoutCount')}
      </Text>
      {totalAffectedElements ? (
        <Collapse className="mt-3">
          {usages.eligibleActsResponseList.length > 0 && (
            <Panel
              header={
                <PanelHeader
                  title="EligibleActs"
                  count={usages.eligibleActsResponseList.length}
                />
              }
              key={'EligibleActs'}
            >
              <PanelList
                title="EligibleActs"
                items={usages.eligibleActsResponseList}
                linkPrefix="/eligible-acts"
              />
            </Panel>
          )}
          {usages.recommendationResponseList.length > 0 && (
            <Panel
              header={
                <PanelHeader
                  title="Recommendations"
                  count={usages.recommendationResponseList.length}
                />
              }
              key={'Recommendations'}
            >
              <PanelList
                title="Recommendations"
                items={usages.recommendationResponseList}
                linkPrefix="/recommendations"
              />
            </Panel>
          )}
          {usages.productResponseList.length > 0 && (
            <Panel
              header={
                <PanelHeader
                  title="Products"
                  count={usages.productResponseList.length}
                />
              }
              key={'Products'}
            >
              <PanelList
                items={usages.productResponseList}
                linkPrefix="/products"
              />
            </Panel>
          )}
          {usages.eventPricingResponseList.length > 0 && (
            <Panel
              header={
                <PanelHeader
                  title="EventPricing"
                  count={usages.eventPricingResponseList.length}
                />
              }
              key={'EventPricing'}
            >
              <PanelList
                title="EventPricing"
                items={usages.eventPricingResponseList}
                linkPrefix="/event-pricings"
              />
            </Panel>
          )}
        </Collapse>
      ) : null}
    </div>
  );
};

export default RuleUpdateConfirmation;
