import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ComponentDisabler from '../../ComponentDisabler';
import { Button } from 'antd';
import modalConfirm from 'src/utils/modalConfirm';
import { DeleteOutlined } from '@ant-design/icons';
import { InputLabel } from '../InputLabel';
import FlatInternationalizationInput from '../FlatInternationalizationInput';
import { If } from '../../If';

interface FlatInternationalizationArrayInputProps {
  name: string;
  label?: string;
  required?: boolean;
}

export default function FlatInternationalizationArrayInput({
  name,
  label,
  required,
}: FlatInternationalizationArrayInputProps) {
  // Hooks
  const { t } = useTranslation();

  // Form
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const hasFields = fields.length > 0;

  const handleAdd = () => {
    append([{}]);
  };

  const handleDelete = (index: number) => {
    modalConfirm(t(`confirmationDialogue.deleteMessage`), () => remove(index));
  };

  return (
    <div className="w-full mt-2">
      {label && (
        <InputLabel className="block mb-2" label={label} required={required} />
      )}
      <If condition={!hasFields}>
        {() => (
          <div className="flex justify-center items-center mb-2">
            {t(`form.empty`)}
          </div>
        )}
      </If>
      <If condition={hasFields}>
        {() => (
          <div className="space-y-2">
            {fields.map((field, index) => (
              <div
                className="flex space-x-2 items-end space-between"
                key={field.id}
              >
                <FlatInternationalizationInput
                  name={`${name}[${index}]`}
                  label={`${label} ${index + 1}`}
                  required={required}
                  className="w-full"
                />
                <ComponentDisabler
                  componentRender={() => (
                    <Button
                      data-testid="delete-button"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDelete(index)}
                      shape="circle"
                      className="inline-flex items-center justify-center"
                    />
                  )}
                />
              </div>
            ))}
          </div>
        )}
      </If>
      <ComponentDisabler
        componentRender={() => (
          <Button
            ghost
            type="primary"
            className="w-full mt-4 mb-2"
            onClick={handleAdd}
          >
            {'+ ' + t(`form.add`)}
          </Button>
        )}
      />
    </div>
  );
}
