import React from 'react';
import { useUIOptions } from 'src/containers/DynamicFormContainer/hooks/useUIOptions';
import SelectInput from 'src/components/generic/inputs/SelectInput';

function FormSelectField({ schema, ...props }) {
  const { options, isLoading } = useUIOptions(schema?.ui?.source);

  return (
    <SelectInput
      name={schema.id}
      label={schema.title}
      description={schema.description ?? ''}
      loading={isLoading}
      data={options}
      placeholder={String(schema?.ui?.placeholder ?? '')}
      disabled={Boolean(schema?.ui?.disabled)}
      {...props}
      mode={Boolean(schema?.ui?.multiple) ? 'multiple' : undefined}
    />
  );
}

export { FormSelectField };
