import DataMultiSelect from 'src/components/generic/inputs/DataMultiSelect';
import React from 'react';
import { useQuery } from 'react-query';
import { getCountsByPeriodicity } from 'src/api/facts';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { PERIODICITY } from 'src/utils/constants';
import { TYPES_SCHEDULING } from 'src/utils/constants/SchedulingTypes';
import { useFormContext } from 'react-hook-form';

const { Option } = Select;

export const CountFactNamesSelector = ({
  typeScheduling,
  currentPeriodicity,
  setCurrentPeriodicity,
  name,
}) => {
  const { t } = useTranslation();

  const handleChangeType = (data) => setCurrentPeriodicity(data);
  const { data: countsFactNames, isLoading } = useQuery(
    ['counts', currentPeriodicity],
    () => getCountsByPeriodicity(currentPeriodicity),
  );
  const { setValue } = useFormContext();

  const handleSelectChange = (value) => {
    if (value.includes('all')) {
      let allValuesExceptAll = value.filter((item) => item !== 'all');
      setValue(name, [...new Set([...allValuesExceptAll, ...countsFactNames])]);
    }
  };

  return (
    <div
      className={`flex ${
        typeScheduling === TYPES_SCHEDULING.IMMEDIATE ||
        typeScheduling === TYPES_SCHEDULING.DATE
          ? 'flex-col min-w-[600px]'
          : 'flex-row'
      } gap-2`}
    >
      <div>
        <label>
          {t('factTechnicalSetting.batch.resetZero.input.periodicity')}
        </label>
        <Select
          onChange={handleChangeType}
          defaultValue={currentPeriodicity}
          style={{ width: '100%', borderRadius: '0%' }}
          size="large"
        >
          {Object.keys(PERIODICITY)?.map((value) => (
            <Option key={value} value={value}>
              {t(`factTechnicalSetting.batch.resetZero.periodicity.${value}`)}
            </Option>
          ))}
        </Select>
      </div>
      <div className="w-full">
        <DataMultiSelect
          maxTagCount={'responsive'}
          name={name}
          label={t(`factTechnicalSetting.batch.resetZero.input.factName`)}
          data={
            [
              {
                functionalId: 'all',
                name: t(`factTechnicalSetting.batch.resetZero.input.selectAll`),
              },
              ...(countsFactNames?.map((role) => ({
                functionalId: role,
                name: role,
              })) ?? []),
            ] ?? []
          }
          className={'mb-7 w-full'}
          placeholder={t(
            `factTechnicalSetting.batch.resetZero.placeholder.factName`,
          )}
          onChange={(e) => {
            handleSelectChange(e);
          }}
          loading={isLoading}
        />
      </div>
    </div>
  );
};
