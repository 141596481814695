import { DatePicker, Form } from 'antd';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

export default function GenericDatePicker(props) {
  const { label, name, placeholder, showTime, format } = props;

  const { control, formDisabled } = useFormContext();

  return (
    <Form.Item style={{ marginBottom: '0px' }}>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            size="large"
            className="w-full"
            format={format}
            placeholder={placeholder}
            disabled={formDisabled}
            showTime={showTime}
          />
        )}
      />
    </Form.Item>
  );
}

GenericDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  showTime: PropTypes.bool,
  format: PropTypes.string,
};
