import React from 'react';

const FormSchemaListingContainer = React.lazy(
  () => import('src/pages/formSchemas/containers/FormSchemaListingContainer'),
);

export default function FormSchemaListing() {
  return (
    <React.Suspense fallback={null}>
      <FormSchemaListingContainer />
    </React.Suspense>
  );
}
