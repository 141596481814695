import React, { lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';

const ParameterSchemaUpdateContainer = lazy(() =>
  import('src/containers/parameterSchema/ParameterSchemaUpdateContainer'),
);

function ParameterSchemaUpdate() {
  const { id } = useParams();
  return (
    <Suspense fallback={null}>
      <ParameterSchemaUpdateContainer id={id} />
    </Suspense>
  );
}

export { ParameterSchemaUpdate };
