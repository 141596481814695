import { createContext, useEffect, useState } from 'react';
import { getUserContext } from 'src/api/userContext';
import { useQuery } from 'react-query';
import { API_NAMES } from 'src/utils/constants/ApiNames';

export const UserContext = createContext({
  userContext: {
    [API_NAMES.CATALOG]: {},
    [API_NAMES.EVENT_PRICING]: {},
    [API_NAMES.FACTS]: {},
  },
  currentApi: '',
  setCurrentApi: () => {},
  isLoading: false,
});
UserContext.displayName = 'UserContext';

const DEFAULT_STATE = {
  roles: [],
};

export const UserContextProvider = ({ children }) => {
  const [userContextCatalogue, setUserContextCatalogue] =
    useState(DEFAULT_STATE);
  const [userContextPricing, setUserContextPricing] = useState(DEFAULT_STATE);
  const [userContextFacts, setUserContextFacts] = useState(DEFAULT_STATE);
  const [isLoading, setIsLoading] = useState(true);

  const [currentApi, setCurrentApi] = useState(API_NAMES.CATALOG);

  const userContext = {
    [API_NAMES.CATALOG]: userContextCatalogue,
    [API_NAMES.EVENT_PRICING]: userContextPricing,
    [API_NAMES.FACTS]: userContextFacts,
  };
  const {
    data: catalogueData,
    isLoading: isCatalogLoading,
    isSuccess: catalogueIsSuccess,
  } = useQuery(
    ['get-user-context', API_NAMES.CATALOG],
    () => getUserContext(API_NAMES.CATALOG),
    {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: pricingData,
    isLoading: isPricingLoading,
    isSuccess: pricingIsSuccess,
  } = useQuery(
    ['get-user-context', API_NAMES.EVENT_PRICING],
    () => getUserContext(API_NAMES.EVENT_PRICING),
    {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: factData,
    isLoading: isFactContextLoading,
    isSuccess: isFactContextSuccess,
  } = useQuery(
    ['get-user-context', API_NAMES.FACTS],
    () => getUserContext(API_NAMES.FACTS),
    {
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (catalogueIsSuccess) setUserContextCatalogue(catalogueData);
    if (pricingIsSuccess) setUserContextPricing(pricingData);
    if (isFactContextSuccess) setUserContextFacts(factData);

    setIsLoading(isCatalogLoading || isPricingLoading || isFactContextLoading);
  }, [
    catalogueIsSuccess,
    pricingIsSuccess,
    catalogueData,
    pricingData,
    isCatalogLoading,
    isPricingLoading,
    isFactContextLoading,
    factData,
    isFactContextSuccess,
  ]);

  return (
    <UserContext.Provider
      value={{ userContext, currentApi, setCurrentApi, isLoading }}
    >
      {children}
    </UserContext.Provider>
  );
};
