import { DryRunResult } from 'src/components/constraint/form/ScriptConstraint/Dryrun/DryRunResult';

export const SyntaxCheckDryRunResult = ({ isError, error }) => {
  const hasErrorData = !!error && !!error.response && !!error.response.data;
  return (
    <div data-testid={'syntax-check-dry-run'}>
      {isError && hasErrorData && (
        <DryRunResult variant="error" result={error.response.data.message} />
      )}
    </div>
  );
};
