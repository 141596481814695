import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getNameWithNamePrefix } from 'src/utils';
import SelectInput from '../SelectInput';
import { getCorrectLabelForAdmin } from 'src/utils/internationalisationUtils';

export default function ProductSelect(props) {
  const {
    namePrefix,
    translationPrefix,
    className,
    getProducts,
    getOptions,
    disabled,
    required,
    productFormName,
  } = props;
  const { t } = useTranslation();
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage,
  );
  const { data: products, isLoading } = useQuery(getProducts.name, getProducts);
  const { control, setValue } = useFormContext();
  const code = useWatch({
    name: 'functionalId',
    control,
  });

  const selectedProductFunctionalId = useWatch({
    name: getNameWithNamePrefix('product', namePrefix),
    control,
  });

  const selectedProduct = products?.find(
    (elem) => elem.functionalId === selectedProductFunctionalId,
  );

  function filterAndMapProducts() {
    return products
      .filter((e) => e.functionalId !== code)
      .map((elem) => ({
        functionalId: elem.functionalId,
        name:
          elem.functionalId +
          ' - ' +
          getCorrectLabelForAdmin(elem.label, selectedLang),
      }));
  }

  return (
    !isLoading && (
      <div className="mb-2">
        <SelectInput
          name={productFormName ?? getNameWithNamePrefix('product', namePrefix)}
          label={t(`${translationPrefix}.product`)}
          className={className}
          data={filterAndMapProducts()}
          onChange={() => setValue(namePrefix + '.options', [])}
          disabled={disabled}
          required={required}
        />
        {getOptions && (
          <SelectInput
            mode="multiple"
            name={getNameWithNamePrefix('options', namePrefix)}
            label={t(`${translationPrefix}.options`)}
            data={getOptions(selectedProduct, selectedLang)}
            disabled={disabled}
            required={required}
          />
        )}
      </div>
    )
  );
}
