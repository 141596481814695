import { IMap } from 'src/types';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import isArray from 'lodash/isArray';

export const DEFAULT_KEYS_DELIMITER = ',';
export const DEFAULT_VALUE_DELIMITER = ':';

export function createScoperChainedFilter(
  filters: IMap<string, any> | undefined,
  options?: {
    appendOperator?: boolean;
    keysDelimiter?: string;
    valueDelimiter?: string;
  },
): string {
  if (!filters) {
    return '';
  }
  options = {
    ...options,
    keysDelimiter: options?.keysDelimiter ?? DEFAULT_KEYS_DELIMITER,
    valueDelimiter: options?.valueDelimiter ?? DEFAULT_VALUE_DELIMITER,
  };
  return Object.entries(filters)
    .filter(([, v]) => {
      return (
        !!v &&
        (isNumber(v) || isBoolean(v) || isString(v) || (isArray(v) && v.length))
      );
    })
    .sort(([a], [b]) => a.localeCompare(b))
    .map(([key, values]) => {
      const valDelimiter = options?.appendOperator
        ? `${options.valueDelimiter}eq${options.valueDelimiter}`
        : options?.valueDelimiter;
      if (isArray(values) && values.length > 0) {
        return `${values
          .map((v) => `${key}${valDelimiter}${v}`)
          .join(options?.keysDelimiter)}`;
      }

      return `${key}${valDelimiter}${values}`;
    })
    .join(options.keysDelimiter);
}

export const mapChainedString = (
  value?: string,
  options?: {
    keysDelimiter?: string;
    valueDelimiter?: string;
  },
): IMap<string, string | string[]> => {
  const result: IMap<string, string | string[]> = {};
  if (!value) {
    return result;
  }
  options = {
    ...options,
    keysDelimiter: options?.keysDelimiter ?? DEFAULT_KEYS_DELIMITER,
    valueDelimiter: options?.valueDelimiter ?? DEFAULT_VALUE_DELIMITER,
  };

  const valueSplit = value.split(options.keysDelimiter as string);
  valueSplit.forEach((kv) => {
    const kvSplit = kv.split(options?.valueDelimiter as string, 2);
    const oldValue = result[kvSplit[0]];
    if (oldValue) {
      if (isArray(oldValue)) {
        result[kvSplit[0]] = [...oldValue, kvSplit[1]];
      } else {
        result[kvSplit[0]] = [oldValue, kvSplit[1]];
      }
    } else {
      result[kvSplit[0]] = kvSplit[1];
    }
  });
  return result;
};

export const appendToChainedString = (
  key: string,
  value: string,
  fullValue?: string,
  options?: { keysDelimiter?: string; valueDelimiter?: string },
): string => {
  options = {
    ...options,
    keysDelimiter: options?.keysDelimiter ?? DEFAULT_KEYS_DELIMITER,
    valueDelimiter: options?.valueDelimiter ?? DEFAULT_VALUE_DELIMITER,
  };
  const fullValueMap = mapChainedString(fullValue, options);
  fullValueMap[key] = value;
  return createScoperChainedFilter(fullValueMap, options);
};
