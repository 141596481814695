import { createSlice } from '@reduxjs/toolkit';

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    type: '',
    message: '',
    visible: false,
  },
  reducers: {
    showMessage: (state, action) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.visible = true;
    },
    hideMessage: (state) => {
      state.visible = false;
    },
  },
});

export const { showMessage, hideMessage } = messageSlice.actions;

export const { reducer: messageReducer } = messageSlice;
