import { PageRequest } from 'src/types/models/common';

export const truncateText = (
  text: string,
  maxLength: number,
  ellipsis = '...',
): string => {
  return text.length > maxLength ? text.slice(0, maxLength) + ellipsis : text;
};

export const getPageRequestCacheKey = (page: PageRequest): string[] => {
  const result = [];
  if (page.sortBy) {
    result.push(`sort_by:${page.sortBy}`);
  }

  if (page.sortDir) {
    result.push(`sort_dir:${page.sortDir}`);
  }

  if (page.pageSize) {
    result.push(`page_size:${page.pageSize}`);
  }

  if (page.pageNumber) {
    result.push(`page_number:${page.pageNumber}`);
  }

  return result;
};
