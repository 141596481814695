import { lazy, Suspense } from 'react';

const DomainCreationContainer = lazy(() =>
  import('src/containers/domain/DomainCreationContainer'),
);

export function DomainCreation() {
  return (
    <Suspense fallback={null}>
      <DomainCreationContainer />
    </Suspense>
  );
}
