import { Modal } from 'antd';
import { t } from 'i18next';
export default function modalConfirm(content, onConfirm, onCancel, width) {
  Modal.confirm({
    title: t('confirmationDialogue.title'),
    content,
    cancelText: t('confirmationDialogue.cancel'),
    okText: t('confirmationDialogue.confirm'),
    cancelButtonProps: {
      className:
        'text-red-500 border-red-500 hover:text-red-400 hover:border-red-400',
    },
    okButtonProps: {
      className:
        'text-orange-500 border-orange-500 hover:text-orange-400 hover:border-orange-400 hover:bg-white',
    },
    onOk: onConfirm,
    onCancel: onCancel,
    width: width,
  });
}
