import { QrcodeOutlined, TagOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import DocumentAttachment from 'src/components/product/form/generic/DocumentAttachment';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';

const ConsentForm = ({ namePrefix }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 8]} className="mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('consent.functionalId')}
          startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.descriptions`}
          label={t('consent.descriptions')}
          startAdornment={<TagOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={24}>
        <SwitchInput
          name={`${namePrefix}.optional`}
          label={t('consent.optional')}
        />
      </Col>
      <Col span={24}>
        <DocumentAttachment namePrefix={namePrefix} />
      </Col>
      <Col span={24}>
        <h2>{t('eligibleAct.constraints')}</h2>
        <ConstraintWrapper namePrefix={namePrefix} />
      </Col>
    </Row>
  );
};

export default ConsentForm;
