import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import BilanAndCurrencyForm from '../BilanAndCurrencyForm';
import { BILAN_AND_CURRENCY_DEFAULT } from 'src/utils/defaultsSupplier';
export default function BilanAndCurrency(props) {
  const { namePrefix } = props;
  return (
    <ArrayAccordion
      name={`${namePrefix}`}
      translationPrefix="bilan"
      Form={BilanAndCurrencyForm}
      defaultValues={BILAN_AND_CURRENCY_DEFAULT}
      fieldsNames={['functionalId']}
    />
  );
}

BilanAndCurrency.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
