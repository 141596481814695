import { PUBLICATION_STATUS } from 'src/utils/constants/publicationStatus';
import { Badge, BadgeColors } from './Badge';
import { useTranslation } from 'react-i18next';

interface StatusBadgeProps {
  status: string;
  className?: string;
}

const statusBadgeColor = {
  [PUBLICATION_STATUS.DRAFT]: 'secondary',
  [PUBLICATION_STATUS.PUBLISHED]: 'lightSuccess',
  [PUBLICATION_STATUS.UNPUBLISHED]: 'lightPrimary',
};

export function StatusBadge({ status, className }: StatusBadgeProps) {
  const { t } = useTranslation();

  const badgeColor = (statusBadgeColor[status] ||
    statusBadgeColor[PUBLICATION_STATUS.DRAFT]) as BadgeColors;

  return (
    <Badge color={badgeColor} className={className}>
      {t(`status.${status.toLocaleLowerCase()}`)}
    </Badge>
  );
}
