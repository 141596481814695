export const mapAdditionalAttributesToData = (attributes) => {
  if (!attributes) {
    return [];
  }

  return Object.keys(attributes).map((key) => ({
    name: key,
    type: attributes[key].type,
    value: { [attributes[key].type]: attributes[key].value },
  }));
};

export const mapDataToAdditionAttributes = (data) => {
  if (!data) {
    return {};
  }

  const res = {};
  data.forEach(
    (elem) =>
      (res[elem.name] = {
        type: elem.type,
        value: elem.value[elem.type],
      }),
  );
  return res;
};
