import isEmpty from 'lodash/isEmpty';

function toForm(property) {
  return {
    id: property.id,
    type: property.type,
    title: property.title,
    description: property.description,
  };
}

function toEntity(formValues) {
  return {
    id: formValues.id,
    title: formValues.title,
    type: formValues.type,
    description: formValues.description,
  };
}

function convertToSelectDataInput({
  data,
  nameKey,
  valueKey,
  t = (k) => k,
  tPrefix = '',
}) {
  return (data ?? []).map((e) => ({
    name: t(`${tPrefix ?? ''}${e[nameKey]}`),
    functionalId: e[valueKey],
  }));
}

/**
 * Determines whether the provided list of properties contains the specified ID.
 *
 * @param {Array<{ id: string }>} properties - The array of properties to check.
 * @param {string} id - The ID to check for uniqueness.
 * @param {?number} indexToIgnore - The optional index of the property to exclude from the check.
 *
 * @returns {boolean} Returns true if the ID is not used; otherwise, returns false.
 */
function checkIdUniqueness(properties, id, indexToIgnore = undefined) {
  if (isEmpty(properties)) {
    return true;
  }

  for (let i = 0; i < properties.length; i++) {
    if (properties[i].id === id) {
      if (indexToIgnore === undefined) {
        return false;
      }

      if (i !== indexToIgnore) {
        return false;
      }
    }
  }

  return true;
}

export { toForm, toEntity, convertToSelectDataInput, checkIdUniqueness };
