import { CONSTRAINT_PATH, SCRIPT_VALIDATION_PATH } from 'src/api/apiPaths';
import HttpService from '../../services/HttpService';

const instance = HttpService.getAxiosClient('catalogue');

export const getConstraintTypes = async () => {
  const res = await instance.get(`${CONSTRAINT_PATH}/types`);
  return res.data;
};

export const getGenericConstraintOperations = async () => {
  const res = await instance.get(`${CONSTRAINT_PATH}/operations`);
  return res.data;
};

export const getConstraintScriptLanguages = async () => {
  const res = await instance.get(`${CONSTRAINT_PATH}/languages`);
  return res.data;
};

export const performDryRun = async (data) => {
  const res = await instance.post(`${SCRIPT_VALIDATION_PATH}/dry-run`, data);
  return res.data;
};

export const optimize = async (data) => {
  const res = await instance.post(
    `${CONSTRAINT_PATH}/transformations/optimizations`,
    data,
  );
  return res.data;
};
