import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteUser } from 'src/api/user';
import CustomTable from 'src/components/generic/Table/CustomTable';
import GenericFilter from 'src/components/generic/GenericFilter';
import UserForm from 'src/components/user/form/UserForm';
import useModal from 'src/hooks/componentHooks/useModal';
import { useDeleteMutation } from 'src/hooks/normalHooks/mutation/useDeleteMutation/useDeleteMutation';
import { USERS_FILTER_FIELDS } from 'src/utils/filterFields';
import modalConfirm from 'src/utils/modalConfirm';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { resolveErrorMessage } from 'src/utils/errorUtils';
import ApiSwitcher from 'src/components/apiSwitcher/ApiSwitcher';
import {
  COLUMN_TYPES,
  CRUD_ACTIONS,
} from 'src/components/generic/Table/CustomTable/CustomTable';
import {
  useGetAllRoles,
  useGetProfileNames,
  useGetUsersByCriteria,
} from 'src/pages/userManagement/store';
import EmptyResult from 'src/components/generic/EmptyResult';
import {
  mapProfileNamesToOptions,
  mapRolesToOptions,
} from 'src/utils/mappers/selectOptionsMapper/userManagement';
import { mapUsersToTableData } from 'src/utils';
import { USER_ROLES } from 'src/utils/constants';

function columns(t) {
  return [
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('user.firstName'),
      key: 'firstName',
    },
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('user.lastName'),
      key: 'lastName',
    },
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('user.username'),
      key: 'username',
    },
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('user.profile'),
      key: 'profile',
    },
    {
      type: COLUMN_TYPES.TAG,
      title: t('profile.roles'),
      key: 'roles',
    },
    {
      type: COLUMN_TYPES.DEFAULT,
      title: t('user.status'),
      key: 'status',
    },
    {
      type: COLUMN_TYPES.ACTION,
      title: t('table.columns.actions'),
      key: 'action',
    },
  ];
}

const ELEMENTS_PER_PAGE = 20;

function UsersPage() {
  const { t } = useTranslation();
  const [currentFilter, setCurrentFilter] = useState({});
  const { Modal, setOpen } = useModal();
  const [user, setUser] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const { currentApi } = useUserContext();

  const onFilter = (formData) => {
    setCurrentPage(1);
    setCurrentFilter(formData);
  };

  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
    error: usersError,
  } = useGetUsersByCriteria(
    currentApi,
    currentPage - 1,
    ELEMENTS_PER_PAGE,
    currentFilter,
  );
  const { data: profileNames } = useGetProfileNames(currentApi);
  const { data: roles } = useGetAllRoles(currentApi);

  const userDeleteMutation = useDeleteMutation({
    translationPrefix: 'user',
    deleteFunc: (id) => deleteUser(currentApi, id),
  });

  const isSuperAdmin = (user) => {
    return user.profile.roles?.includes(USER_ROLES.SUPER_ADMIN);
  };

  const actions = [
    {
      key: CRUD_ACTIONS.UPDATE,
      onAction: (id) => {
        setUser(users?.content.find((user) => user.id === id));
        setOpen(true);
      },
      isHidden: (id) =>
        isSuperAdmin(users?.content.find((user) => user.id === id)),
    },
    {
      key: CRUD_ACTIONS.DELETE,
      onAction: (id) => {
        modalConfirm(t('confirmationDialogue.deleteMessage'), () =>
          userDeleteMutation.mutate(id),
        );
      },
      isHidden: (id) =>
        isSuperAdmin(users?.content.find((user) => user.id === id)),
    },
  ];

  return (
    <>
      <GenericFilter
        onFilter={onFilter}
        initialFilterValues={currentFilter}
        emptyFilterValues={{
          profile: undefined,
          label: undefined,
          status: undefined,
          roles: undefined,
        }}
        filterFields={USERS_FILTER_FIELDS(
          mapProfileNamesToOptions(profileNames),
          mapRolesToOptions(roles),
        )}
        disableSubmit={isUsersLoading}
        customField={
          <ApiSwitcher
            catalogueRoles={[USER_ROLES.SUPER_ADMIN_CATALOGUE]}
            pricingRoles={[USER_ROLES.SUPER_ADMIN_PRICING]}
            factRoles={[USER_ROLES.SUPER_ADMIN_FACTS]}
          />
        }
      />

      <div className="flex flex-col px-5">
        <div className="flex items-end mb-4 mr-2 justify-between">
          <p className="mx-2">
            {users?.totalElements > 0 &&
              users?.totalElements + ' ' + t('search.numberUsersFound')}
          </p>
          <Button
            type="primary"
            className="bg-orange-500 text-white flex items-center"
            onClick={() => {
              setUser(null);
              setOpen(true);
            }}
          >
            <PlusOutlined />
            {t('crud.add') + ' ' + t('subnav.user')}
          </Button>
        </div>
        {isUsersError ? (
          <EmptyResult
            mainMessage={resolveErrorMessage(usersError?.response?.status)}
          />
        ) : (
          <CustomTable
            columns={columns(t)}
            data={mapUsersToTableData(users)}
            actions={actions}
            pagination={{
              current: currentPage,
              pageSize: ELEMENTS_PER_PAGE,
              total: users?.totalElements,
              onChange: (page) => setCurrentPage(page),
            }}
            isLoading={isUsersLoading}
          />
        )}
      </div>

      <Modal title={t('crud.add') + ' ' + t('subnav.user')}>
        <UserForm
          close={() => setOpen(false)}
          user={user}
          profiles={mapProfileNamesToOptions(profileNames)}
        />
      </Modal>
    </>
  );
}

export { UsersPage };
