import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from 'src/utils/mappers/mapperHelpers';
import { mapConstraintsToData } from '../constraintMapper';

export const mapRuleToData = (rule) => {
  return {
    functionalId: rule.functionalId,
    name: mapInternationalisationToData(rule.name),
    description: mapInternationalisationToData(rule.description),
    constraints: mapConstraintsToData(rule.constraints),
  };
};

export const mapDataToRule = (data) => {
  return {
    functionalId: data.functionalId,
    name: mapDataToInternationalisation(data.name),
    description: mapDataToInternationalisation(data.description),
    constraints: mapDataToConstraints(data.constraints),
  };
};

function mapDataToConstraints(constraints) {
  return constraints.map((elem) => {
    return elem.content.map((constraint) => {
      return {
        ...constraint,
        errors: mapDataToInternationalisation(constraint.errors),
      };
    });
  });
}
