import { createContext, ReactNode, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { exportToJsonFile } from 'src/utils/fileUtils';

interface ExportSelectionContextType<T> {
  selectable: boolean;
  setSelectable: (selectable: boolean) => void;
  onExport: (fileName: string) => void;
  handleSelection: (item: T) => void;
  resetSelection: () => void;
  isSelected: (item: T) => boolean;
}

export const ExportSelectionContext = createContext<
  ExportSelectionContextType<any>
>({
  selectable: false,
  setSelectable: () => {},
  onExport: () => {},
  handleSelection: () => {},
  resetSelection: () => {},
  isSelected: () => false,
});

ExportSelectionContext.displayName = 'ExportSelectionContext';

export const ExportSelectionContextProvider = <T,>({
  children,
}: {
  children: ReactNode;
}) => {
  const { t } = useTranslation();
  const [selectable, setSelectable] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  const handleSelection = (item: T) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const isSelected = (item: T) => {
    return selectedItems.includes(item);
  };

  const resetSelection = () => {
    setSelectedItems([]);
    setSelectable(false);
  };

  const onExport = (fileName: string) => {
    if (!selectedItems || selectedItems.length === 0) {
      toast.error(t('validator.itemsMustBeSelected') as string);
      return;
    }
    exportToJsonFile(selectedItems, fileName);
    resetSelection();
  };

  return (
    <ExportSelectionContext.Provider
      value={{
        selectable,
        setSelectable,
        onExport,
        handleSelection,
        resetSelection,
        isSelected,
      }}
    >
      {children}
    </ExportSelectionContext.Provider>
  );
};
