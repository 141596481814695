const mapUnixCronToQuartzFormat = function (crontab) {
  let data = [];
  let quartzEntry = [];

  quartzEntry = parseCronMagics(crontab);

  if (quartzEntry) {
    data = data.concat(quartzEntry);
  } else {
    const crontabEntry = crontab.split(' ');
    quartzEntry = parseCronSyntax(crontabEntry);

    data = data.concat(quartzEntry);
    if (crontabEntry[2] !== '*' && crontabEntry[4] !== '*') {
      crontabEntry[2] = '*';
      quartzEntry = parseCronSyntax(crontabEntry);
      data = data.concat(quartzEntry);
    }
  }

  return data.join(' ');
};

function advanceNumber(str) {
  let quartzCompatibleStr = '';
  let num;
  str.split('').forEach(function (chr) {
    num = parseInt(chr);

    if (!isNaN(num)) {
      if (num >= 0 && num <= 7) {
        quartzCompatibleStr += num + 1;
      } else {
        quartzCompatibleStr = 1;
      }
    } else {
      quartzCompatibleStr += chr;
    }
  });

  return quartzCompatibleStr;
}

/**
 * parse cron
 * parseCronMagics
 *
 * @param {string} a string representation of a unix CRON entry
 * @return {array} an array representation of a Quartz CRON entry
 *
 */
function parseCronSyntax(crontabEntry) {
  const quartzEntry = [];

  quartzEntry.push('0');

  let toggleQuartzCompat = false;

  if (crontabEntry.length === 6) {
    crontabEntry.shift();
  }

  crontabEntry.forEach(function (item, index) {
    if (index === 0 || index === 1) {
      quartzEntry.push(item);
    }

    if (index === 2) {
      if (item !== '?') {
        toggleQuartzCompat = true;
      }

      if (item === '*') {
        toggleQuartzCompat = false;
        item = '?';
      }

      quartzEntry.push(item);
    }

    if (index === 3) {
      quartzEntry.push(item);
    }

    if (index === 4) {
      var itemAbbreviated = advanceNumber(item);

      if (toggleQuartzCompat === true) {
        quartzEntry.push('?');
      } else {
        quartzEntry.push(itemAbbreviated);
      }
    }

    if (index >= 5) {
      return true;
    }
  });

  quartzEntry.push('*');

  return quartzEntry;
}

function parseCronMagics(crontab) {
  let quartzEntry = false;

  if (crontab.indexOf('@hourly') === 0) {
    quartzEntry = ['0', '0', '*', '*', '*', '?', '*'];
  }

  if (crontab.indexOf('@daily') === 0 || crontab.indexOf('@midnight') === 0) {
    quartzEntry = ['0', '0', '0', '*', '*', '?', '*'];
  }

  if (crontab.indexOf('@weekly') === 0) {
    quartzEntry = ['0', '0', '0', '?', '*', '1', '*'];
  }

  if (crontab.indexOf('@monthly') === 0) {
    quartzEntry = ['0', '0', '0', '1', '*', '?', '*'];
  }

  if (crontab.indexOf('@yearly') === 0 || crontab.indexOf('@annually') === 0) {
    quartzEntry = ['0', '0', '0', '1', '1', '?', '*'];
  }

  return quartzEntry || false;
}

export { mapUnixCronToQuartzFormat };
