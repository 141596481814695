export function mapDataToPublicationConstraints(data) {
  if (!data) return;
  return {
    constraints: data.constraints.map((elem) => elem.content),
  };
}

export function mapPublicationConstraintsToData(constraints) {
  if (!constraints) return;
  return {
    constraints: constraints.map((elem) => ({ content: elem })),
  };
}
