export const mapPricesToData = (prices) => {
  return prices.map((elem) => ({
    ...elem,
    constraints: elem.constraints.map((e) => ({ content: e })),
  }));
};

export const mapDataToPrices = (prices, isStandard) => {
  return prices.map((elem) => ({
    ...elem,
    standard: isStandard,
    constraints: elem.constraints.map((e) => e.content),
  }));
};
