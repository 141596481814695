import { ActionType, API_NAMES, AuditableAction } from 'src/utils/constants';

const filters = {
  PRODUCT: 'product',
  FAMILY: 'family',
  ACT: 'act',
  CANAL: 'canal',
  DATE_RANGE: 'dateRange',
};

export const filtersMap = {
  [AuditableAction[API_NAMES.CATALOG][ActionType.usageProduct].GET_PRODUCT]: [
    filters.PRODUCT,
    filters.CANAL,
    filters.DATE_RANGE,
  ],
  [AuditableAction[API_NAMES.CATALOG][ActionType.usageProduct]
    .GET_PRODUCTS_BY_FAMILY]: [
    filters.FAMILY,
    filters.CANAL,
    filters.DATE_RANGE,
  ],
  [AuditableAction[API_NAMES.CATALOG][ActionType.usageEligibility]
    .IS_ELIGIBLE_BY_ACT_AND_PRODUCT]: [
    filters.FAMILY,
    filters.CANAL,
    filters.PRODUCT,
    filters.ACT,
    filters.DATE_RANGE,
  ],
  [AuditableAction[API_NAMES.CATALOG][ActionType.usageEligibility]
    .GET_ELIGIBLE_ACT_FOR_PRODUCT_BY_ID]: [
    filters.FAMILY,
    filters.CANAL,
    filters.PRODUCT,
    filters.ACT,
    filters.DATE_RANGE,
  ],
  [AuditableAction[API_NAMES.CATALOG][ActionType.usageEligibility]
    .GET_ELIGIBLE_ACTS_FOR_PRODUCT_BY_ID]: [
    filters.FAMILY,
    filters.CANAL,
    filters.PRODUCT,
    filters.ACT,
    filters.DATE_RANGE,
  ],
  [AuditableAction[API_NAMES.EVENT_PRICING][ActionType.usagePricing]
    .GET_PRICES]: [
    filters.FAMILY,
    filters.CANAL,
    filters.PRODUCT,
    filters.ACT,
    filters.DATE_RANGE,
  ],
};
