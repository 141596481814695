import { Col } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from 'src/components/generic/inputs/DatePicker';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import TextInput from 'src/components/generic/inputs/TextInput';

function getPriceTypeName(namePrefix) {
  if (!namePrefix) return;
  return namePrefix.split('.')[0] + '.pricingType';
}

const DiscountPeriod = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const pricingType = useWatch({
    control,
    name: getPriceTypeName(namePrefix),
  });
  const perpetual = useWatch({
    control,
    name: namePrefix + '.perpetual',
  });

  return (
    <>
      {pricingType === 'PERIODIC' && (
        <>
          <Col span={12}>
            <SwitchInput
              label={t('discount.perpetual')}
              name={`${namePrefix}.perpetual`}
            />
          </Col>
          {!perpetual && (
            <Col span={12}>
              <TextInput
                label={t('discount.discountPeriodsAmount')}
                name={`${namePrefix}.discountPeriodsAmount`}
                type="number"
              />
            </Col>
          )}
        </>
      )}

      <Col span={24}>
        <CustomDatePicker
          label={t('discount.startDate')}
          name={`${namePrefix}.dateFrom`}
        />
      </Col>
      <Col span={24}>
        <CustomDatePicker
          label={t('discount.endDate')}
          name={`${namePrefix}.dateTo`}
        />
      </Col>
    </>
  );
};

export default DiscountPeriod;
