import React, { useEffect } from 'react';
import { Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { hideAlert } from 'src/redux/slices/alertSlice';

function AlertDisplayer() {
  const dispatch = useDispatch();
  const { message, visible } = useSelector((state) => state.alert);

  const path = useLocation().pathname;

  useEffect(() => {
    if (!path.includes('/update')) {
      dispatch(hideAlert());
    }
  }, [path, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [message]);

  if (!visible) {
    return null;
  }

  return (
    <Alert
      banner
      className="mx-5 my-3 p-3 font-semibold"
      message={'WARNING : ' + message}
      type={'warning'}
      showIcon
    />
  );
}

export { AlertDisplayer };
