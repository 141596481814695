import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

function EmptyState({ message }) {
  const { t } = useTranslation();
  const actualMessage = message ?? t('an_error_occurred');
  return (
    <Empty
      data-testid="EmptyState"
      className="p-5 bg-white rounded-lg"
      description={actualMessage}
    />
  );
}

export { EmptyState };
