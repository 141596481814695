import useParameters from '../../../../hooks/normalHooks/useParameters';
import { useFormContext, useWatch } from 'react-hook-form';
import DataMultiSelect from '../DataMultiSelect';
import PropTypes from 'prop-types';

const CurrencyPlafondMultiSelect = (props) => {
  const { name, label, className } = props;

  const { control } = useFormContext();

  const { data: currencies, isLoading } = useParameters('CURRENCY');

  const plafonds = useWatch({
    control,
    name: name.split('.').slice(0, 2).join('.') + '.plafonds',
  });

  const operations = useWatch({
    control,
    name: name.split('.').slice(0, 3).join('.') + '.operations',
  });

  const getParams = (params) =>
    params.filter((par) =>
      plafonds
        .filter((plafond) => operations.includes(plafond.operation))
        .map((plafond) => plafond.devise)
        .includes(par.functionalId),
    );

  return (
    !isLoading && (
      <DataMultiSelect
        name={name}
        label={label}
        className={className}
        data={getParams(currencies)}
      />
    )
  );
};

CurrencyPlafondMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.bool,
};

export default CurrencyPlafondMultiSelect;
