import HttpService from 'src/services/HttpService';
import { API_NAMES } from 'src/utils/constants';

export function getApiInstance(api) {
  const API_MAP = {
    [API_NAMES.CATALOG]: HttpService.getAxiosClient(API_NAMES.CATALOG),
    [API_NAMES.FACTS]: HttpService.getAxiosClient(API_NAMES.FACTS),
    [API_NAMES.EVENT_PRICING]: HttpService.getAxiosClient(
      API_NAMES.EVENT_PRICING,
    ),
  };
  const apiInstance = API_MAP[api];
  if (!apiInstance) {
    throw new Error(`Unknown API: ${api}`);
  }
  return apiInstance;
}
