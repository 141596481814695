import i18n from 'src/i18n';
import * as yup from 'yup';

const REQUIRED_STRING = yup.string().required(i18n.t('validator.blank'));

export const RECOMMENDATION_VALIDATION_SCHEMA = yup
  .object({
    functionalId: REQUIRED_STRING,
  })
  .required();
