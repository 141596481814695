import { HourglassOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import CardDisplay from 'src/components/generic/CardDisplay';
import MultiChipsInput from 'src/components/generic/inputs/MultiChipInput';
import ParameterMultiSelect from 'src/components/generic/inputs/ParameterMultiSelect';
import TextInput from 'src/components/generic/inputs/TextInput';
import AdditionalAttribute from '../../generic/AdditionalAttribute';

export default function SavingInfo() {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t('saving.info')}>
      <Row gutter={[16, 12]}>
        <Col span={12}>
          <TextInput
            type="number"
            name="minCotisation"
            label={t('saving.minCotisation')}
          />
        </Col>

        <Col span={12}>
          <TextInput
            type="number"
            name="maxCotisation"
            label={t('saving.maxCotisation')}
          />
        </Col>

        <Col span={12}>
          <TextInput
            type="number"
            name="minVersementExceptionnel"
            label={t('saving.minVersementExceptionnel')}
          />
        </Col>

        <Col span={12}>
          <TextInput
            type="number"
            name="maxVersementExceptionnel"
            label={t('saving.maxVersementExceptionnel')}
          />
        </Col>

        <Col span={12}>
          <ParameterMultiSelect
            name="periodicities"
            label={t('saving.periodicities')}
            domain="PERIODICITY"
            startAdornment={<HourglassOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
        <Col span={12}>
          <TextInput
            type="number"
            name="maximumBeneficiaries"
            label={t('saving.maximumBeneficiaries')}
            startAdornment={
              <UsergroupAddOutlined style={{ fontSize: '25px' }} />
            }
          />
        </Col>
        <Col span={24}>
          <MultiChipsInput
            name="paliers"
            label={t('saving.paliers')}
            type="number"
          />
        </Col>
        <Col span={24}>
          <AdditionalAttribute />
        </Col>
      </Row>
    </CardDisplay>
  );
}
