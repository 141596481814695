import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ParameterMultiSelect from '../../../generic/inputs/ParameterMultiSelect';
import TextInput from '../../../generic/inputs/TextInput';

const IncomeAndNatureForm = (props) => {
  const { namePrefix } = props;

  const { t } = useTranslation();

  return (
    <div className="w-full mb-2">
      <ParameterMultiSelect
        name={`${namePrefix}.incomesNature`}
        domain="INCOME_NATURE"
        label={t('incomeNature.nature')}
      />
      <div className="border-gray-200 border-2 shadow-sm rounded-lg p-2 mt-2">
        <ParameterMultiSelect
          name={`${namePrefix}.incomesCategory`}
          domain="INCOME"
          label={t('incomeNature.category')}
        />
        <div className="flex items-center justify-center my-4">
          <hr className="flex-1 border-t border-gray-300" />
          <span className="px-4 text-gray-500 font-medium">
            {t('constraints.or')}
          </span>
          <hr className="flex-1 border-t border-gray-300" />
        </div>
        <TextInput
          name={`${namePrefix}.minAmount`}
          label={t('incomeNature.minAmount')}
        />
        <TextInput
          name={`${namePrefix}.maxAmount`}
          label={t('incomeNature.maxAmount')}
        />
      </div>
    </div>
  );
};

IncomeAndNatureForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};

export default IncomeAndNatureForm;
