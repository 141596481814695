import { PRICING_DEFAULT } from 'src/utils/defaultsSupplier';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import PricingForm from 'src/components/eventPricing/pricing/form/PricingForm/PricingForm';

export default function Pricing({ namePrefix }) {
  return (
    <>
      <ArrayAccordion
        name={`${namePrefix}`}
        translationPrefix="pricing"
        Form={PricingForm}
        defaultValues={PRICING_DEFAULT}
        fieldsNames={['type', 'flexibility']}
        isSortable
      />
    </>
  );
}
