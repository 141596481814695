import i18n from 'src/i18n';
import * as yup from 'yup';

const REQUIRED_STRING = yup.string().required(i18n.t('validator.blank'));

export const FACT_ELEMENT_COLLECTOR_VALIDATION_SCHEMA = yup
  .object({
    clientId: REQUIRED_STRING,
  })
  .required();

export const BATCH_DATE_SCHEDULING_VALIDATION_SCHEMA = yup
  .object({
    date: REQUIRED_STRING,
    counts: yup
      .array()
      .min(1, i18n.t('validator.blank'))
      .required(i18n.t('validator.blank')),
  })
  .required();
export const BATCH_IMMEDIATE_SCHEDULING_VALIDATION_SCHEMA = yup
  .object({
    counts: yup
      .array()
      .min(1, i18n.t('validator.blank'))
      .required(i18n.t('validator.blank')),
  })
  .required();
export const BATCH_CRON_SCHEDULING_VALIDATION_SCHEMA = yup
  .object({
    counts: yup
      .array()
      .min(1, i18n.t('validator.blank'))
      .required(i18n.t('validator.blank')),
    cronExpression: REQUIRED_STRING,
  })
  .required();
