import { PublicationConstraintsUpdateModal } from 'src/components/generic/versioning/PublicationConstraintsUpdateModal';
import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { PUBLICATION_STATUS } from 'src/utils/constants/publicationStatus';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import getUserRolesByTypeAndResource from 'src/utils/authUtils';

function UpdatePublicationConstraintsButton({
  element,
  updatePublicationConstraints,
  resource,
  canPartiallyPublish = true,
}) {
  const { openModal } = useModalContext();
  const { t } = useTranslation();
  if (
    canPartiallyPublish &&
    element.status === PUBLICATION_STATUS.PARTIALLY_PUBLISHED
  ) {
    return (
      <AuthComponentDisabler
        componentRender={() => (
          <Button
            data-testid="update-publication-constraints-button"
            type="primary"
            ghost
            onClick={() => {
              openModal({
                options: {
                  width: '60%',
                },
                body: (
                  <PublicationConstraintsUpdateModal
                    element={element}
                    updatePublicationConstraints={updatePublicationConstraints}
                  />
                ),
              });
            }}
          >
            {t('versioning.updateConstraints')}
          </Button>
        )}
        roles={getUserRolesByTypeAndResource('PARTIALLY_PUBLISH', resource)}
      />
    );
  }

  return null;
}

export { UpdatePublicationConstraintsButton };
