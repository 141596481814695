import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useConstraintsOptimizer } from 'src/hooks/normalHooks/api/useConstraintsOptimizer';
import { useDrawer } from 'src/stores/drawer';
import { ConstraintsBuilder } from 'src/components/constraints/builder';
import { appendLabelsToConstraints } from 'src/utils/builderUtils';
import { mapConstraintsToData, mapDataToConstraints } from '../../../utils';

export const ConstraintOptimizerWrapper = ({
  namePrefix,
  isOptimizerEnabled = false,
}) => {
  const name = namePrefix ? namePrefix + '.constraints' : 'constraints';

  const { t } = useTranslation();
  const { openDrawer, closeDrawer } = useDrawer();
  const { mutate: optimize, isLoading } = useConstraintsOptimizer();

  // Form
  const { control, setValue } = useFormContext();
  const controlledFields = useWatch({
    control,
    name,
  });

  const constraints = (controlledFields ?? []).map(
    (alternative) => alternative.content,
  );

  const onOptimize = ({ constraints }) => {
    const constraintsWithLabels = appendLabelsToConstraints(constraints);
    setValue(name, mapConstraintsToData(constraintsWithLabels));
  };

  const handleOptimizationSubmit = (optimizationResult) => {
    onOptimize(optimizationResult);
    closeDrawer();
  };

  const handleConstraintOptimization = () => {
    optimize(
      { constraints: mapDataToConstraints(constraints) },
      {
        onSuccess: (result) => {
          openDrawer({
            options: {
              title: t('constraints-builder.optimized_result'),
              style: {
                padding: 0,
              },
            },
            body: (
              <ConstraintsBuilder
                constraints={result}
                onSubmit={handleOptimizationSubmit}
                readOnly
              />
            ),
          });
        },
      },
    );
  };

  return (
    <>
      {!!controlledFields?.length && isOptimizerEnabled && (
        <div className={'py-2'}>
          <div className={'flex gap-2 justify-end items-center'}>
            <Button
              htmlType={'button'}
              ghost
              className="bg-orange-500 text-white flex items-center disabled:opacity-30"
              onClick={handleConstraintOptimization}
              loading={isLoading}
            >
              {t('constraints-builder.optimize')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
