import { USER_CONTEXT_PATH } from '../apiPaths';
import { getApiInstance } from 'src/utils';

const TIMEOUT = 3000;

export const getUserContext = async (sourceApi) => {
  const res = await getApiInstance(sourceApi).get(USER_CONTEXT_PATH, {
    timeout: TIMEOUT,
  });
  return res.data;
};
