import { result, set } from 'lodash';
import { FormFieldV2, FormSchemaAdminDto, IMap } from 'src/types';
import { FIELD_TYPES } from './constants/form';

export const mapReservedSchemaFields = (
  newData: IMap<string, any>,
  schema?: FormSchemaAdminDto,
): IMap<string, any> => {
  if (!schema) {
    return newData;
  }

  const result: IMap<string, any> = {};
  schema.fields.forEach((field) =>
    mapReservedSchemaFieldsRecursively(result, newData, field, ''),
  );
  return result;
};

const mapReservedSchemaFieldsRecursively = (
  acc: IMap<string, any>,
  data: IMap<string, any>,
  field: FormFieldV2,
  namePrefix: string,
) => {
  const name = `${namePrefix}${field.name}`;
  if (field.name && !field.config?.fields) {
    set(acc, name, result(data, name));
    return;
  }

  if (field.type === FIELD_TYPES.array && field.config?.fields) {
    const arrayContent: any = result(data, name);
    for (let i = 0; i < arrayContent?.length ?? 0; i++) {
      const newNamePrefix = `${namePrefix ?? ''}${
        field.name ? field.name + '.' : ''
      }[${i}]`;
      field.config.fields.forEach((subField: FormFieldV2) => {
        mapReservedSchemaFieldsRecursively(acc, data, subField, newNamePrefix);
      });
    }
    return;
  }

  if (field.config?.fields) {
    const newNamePrefix = `${namePrefix ?? ''}${
      field.name ? field.name + '.' : ''
    }`;
    field.config.fields.forEach((subField: FormFieldV2) => {
      mapReservedSchemaFieldsRecursively(acc, data, subField, newNamePrefix);
    });
  }
};
