import { useState } from 'react';
import { addProduct } from 'src/api';
import ProductForm from 'src/components/product/form/ProductForm';
import { useLocation } from 'react-router-dom';
import { mapDataToProduct } from 'src/utils/mappers/productMapper/productMapper';
import { PRODUCT_BASE_PATH } from 'src/routes/paths';
import { useAddMutation } from 'src/hooks/normalHooks/mutation/useAddMutation';

export const ProductAddPage = () => {
  const { state } = useLocation();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const productAddMutation = useAddMutation({
    add: addProduct,
    basePath: PRODUCT_BASE_PATH,
    translationPrefix: 'product',
    onSettled: () => setSubmitDisabled(false),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    const product = mapDataToProduct(data);
    productAddMutation.mutate(product);
  };

  return (
    <ProductForm
      {...{
        onSubmit,
        submitDisabled,
        versions: [],
      }}
      product={state?.product}
    />
  );
};
