import { lazy, Suspense } from 'react';

const ParameterSchemaListingContainer = lazy(() =>
  import('src/containers/parameterSchema/ParameterSchemaListingContainer'),
);

function ParameterSchemasListing() {
  return (
    <Suspense fallback={null}>
      <ParameterSchemaListingContainer />
    </Suspense>
  );
}

export { ParameterSchemasListing };
