import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import GraveConcessionOptionForm from '../GraveConcessionOptionForm';
import { GRAVE_CONCESSION_OPTIONS_OPTION_DEFAULT } from 'src/utils/defaultsSupplier';

export default function GraveConcessionOption(props) {
  const { namePrefix } = props;
  const name = namePrefix
    ? `${namePrefix}.graveConcessionOptions`
    : 'graveConcessionOptions';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="graveConcessionOption"
      Form={GraveConcessionOptionForm}
      defaultValues={GRAVE_CONCESSION_OPTIONS_OPTION_DEFAULT}
      fieldsNames={['functionalId', 'label']}
    />
  );
}

GraveConcessionOption.propTypes = {
  namePrefix: PropTypes.string,
};
