import React from 'react';
import { Select } from 'antd';
import { APIS_TOP5, TOP5_TYPES } from 'src/components/dashboard/constaint';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const Top5Filters = ({ api, handleApiChange, handleTypeChange, type }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2">
      <Select
        className="w-36"
        onChange={handleApiChange}
        value={api}
        size="small"
        disabled={Object.values(APIS_TOP5).length === 1}
      >
        {Object.values(APIS_TOP5)?.map((value) => (
          <Option key={value} value={value}>
            {t(`statistics.top5Api.${value}`)}
          </Option>
        ))}
      </Select>
      <Select
        className="w-36"
        onChange={handleTypeChange}
        value={type}
        size="small"
        disabled={Object.values(TOP5_TYPES[api]).length === 1}
      >
        {Object.values(TOP5_TYPES[api])?.map((value) => (
          <Option key={value} value={value}>
            {t(`statistics.top5Type.${value}`)}
          </Option>
        ))}
      </Select>
    </div>
  );
};
Top5Filters.propTypes = {
  api: PropTypes.string.isRequired,
  handleApiChange: PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export { Top5Filters };
