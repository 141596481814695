import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSupportedLanguages } from 'src/redux/api/supportedLanguages';
import i18n from 'src/i18n';
import { GlobalOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { updateSelectedlanguage } from 'src/redux/slices/parameterSlice';

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const languages = useSelector(
    (state) => state?.supportedLanguages?.supportedLanguages,
  );
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage,
  );

  useEffect(() => {
    if (languages.length === 0) {
      dispatch(fetchSupportedLanguages());
    }
  }, [dispatch, languages.length]);
  return (
    <div className="flex flex-row space-x-2 items-center hover:text-primary h-fit p-2 rounded-sm">
      <div className="text-primary">
        <GlobalOutlined />
      </div>
      <Select
        getPopupContainer={(trigger) => trigger.parentElement}
        defaultValue={selectedLang}
        className="w-28 bg-transparent border-none"
        popupClassName="border border-gray-300 rounded-sm shadow-sm"
        bordered={false}
        fieldNames={{ label: 'name', value: 'functionalId' }}
        options={languages}
        onChange={(value) => {
          dispatch(updateSelectedlanguage(value));
          i18n.changeLanguage(value);
        }}
      />
    </div>
  );
};

export default LanguageSelector;
