import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import OfferFormulaForm from '../OfferFormulaForm/OfferFormulaForm';

export default function OfferFormula() {
  return (
    <ArrayAccordion
      name="formulaList"
      translationPrefix="formulaList"
      Form={OfferFormulaForm}
      defaultValues={{}}
      fieldsNames={[]}
    />
  );
}
