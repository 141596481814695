import PropTypes from 'prop-types';
import { useState } from 'react';
import { useQuery } from 'react-query';
import ConstraintFormContent from 'src/components/constraint/form/ConstraintFormContent/ConstraintFormContent';
import Form from 'src/components/generic/Form';
import Loading from 'src/components/generic/Loading';
import { CONSTRAINT_DEFAULT } from 'src/utils/defaultsSupplier';
import { CONSTRAINT_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/constraintSchema';
import { getConstraintTypes } from '../../../../api';
import { useLocation } from 'react-router-dom';

export default function ConstraintAddForm(props) {
  const {
    addConstraint,
    updateConstraint,
    onCancel,
    constraint,
    formDisabled,
    hasErrorMessage,
  } = props;

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const pathname = useLocation().pathname;
  const { data: constraintTypes, isLoading } = useQuery(
    'constraint-types',
    getConstraintTypes,
  );

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    if (!constraint) addConstraint(data);
    else updateConstraint(data);
    setSubmitDisabled(false);
  };

  if (isLoading) return <Loading />;

  return (
    <Form
      onCancel={onCancel}
      onSubmit={onSubmit}
      defaultValues={
        constraint || { ...CONSTRAINT_DEFAULT, type: constraintTypes[0].id }
      }
      submitDisabled={submitDisabled}
      validationSchema={CONSTRAINT_VALIDATION_SCHEMA}
      formDisabled={formDisabled}
    >
      <ConstraintFormContent
        constraintTypes={
          !pathname.includes('rule')
            ? constraintTypes
            : constraintTypes?.filter((con) => {
                return con.id !== 'rule-constraint';
              })
        }
        hasErrorMessage={hasErrorMessage}
      />
    </Form>
  );
}

ConstraintAddForm.propTypes = {
  addConstraint: PropTypes.func,
  targetType: PropTypes.string,
};
