import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getDomains } from 'src/api';
import {
  createParameterSchema,
  getParameterSchemaById,
  getParameterSchemas,
  updateParameterSchema,
} from 'src/api/parameterSchema';
import { useShowMessage } from 'src/hooks/normalHooks/useShowMessage';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PARAMETER_SCHEMAS_BASE_PATH } from 'src/routes/paths';
import { createScoperChainedFilter } from 'src/utils/scoper';

const PARAMETER_SCHEMA_QUERY_KEY = 'parameter-schemas';

function useDomains() {
  return useQuery({
    queryKey: ['domains'],
    queryFn: () => getDomains(),
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
}

function useGetParameterSchema(id) {
  return useQuery({
    queryKey: [PARAMETER_SCHEMA_QUERY_KEY, id],
    queryFn: () => getParameterSchemaById(id),
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
}

function useGetParameterSchemaList(filters = {}) {
  const filterQuery = createScoperChainedFilter(filters);

  return useQuery({
    queryKey: [PARAMETER_SCHEMA_QUERY_KEY, filterQuery],
    queryFn: () => getParameterSchemas(filterQuery),
    staleTime: 1000 * 60 * 10,
    refetchOnWindowFocus: false,
  });
}

function useCreateParameterSchema() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSuccess, showError } = useShowMessage();
  return useMutation({
    mutationFn: (data) => createParameterSchema(data),
    onSuccess: (res) => {
      queryClient.invalidateQueries([PARAMETER_SCHEMA_QUERY_KEY]);
      showSuccess(t(`parameter_schema.added`));
      navigate(`${PARAMETER_SCHEMAS_BASE_PATH}/${res.id}/edit`);
    },
    onError: (err) => {
      showError(err);
    },
  });
}

function useUpdateParameterSchema(id) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showSuccess, showError } = useShowMessage();
  return useMutation({
    mutationFn: (data) => updateParameterSchema(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries([PARAMETER_SCHEMA_QUERY_KEY]);
      showSuccess(t(`parameter_schema.updated`));
    },
    onError: (err) => {
      showError(err);
    },
  });
}

export {
  useDomains,
  useGetParameterSchema,
  useGetParameterSchemaList,
  useCreateParameterSchema,
  useUpdateParameterSchema,
};
