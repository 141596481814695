import PropTypes from 'prop-types';
import ConstraintCompsite from 'src/components/constraint/form/ConstraintCompsite/ConstraintCompsite';
import MultiChipsInput from 'src/components/generic/inputs/MultiChipInput';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import { getNameWithNamePrefix } from 'src/utils';
import ParameterMultiSelect from '../../../generic/inputs/ParameterMultiSelect';
import TextInput from '../../../generic/inputs/TextInput';
import ProductMultiSelect from '../../../generic/ProductMultiSelect';
import Bilan from '../Bilan';
import BilanAndCurrency from '../BilanAndCurrency';
import GenericConstraintForm from '../GenericConstraintForm';
import ModuleMultiSelect from '../ModuleMultiSelect';
import { useTranslation } from 'react-i18next';
import ScriptConstraint from '../ScriptConstraint';
import RuleSelect from '../../../generic/RuleSelect';
import Possession from '../Possession';
import IncomeAndNature from '../IncomeAndNature';
import { ConstraintCombinerWrapper } from 'src/components/constraints/builder/components';
import { useFormContext } from 'react-hook-form';
import { COMPOUND_CONSTRAINT_TYPES } from 'src/constants/constraints';
import ConstraintFormContent from '../ConstraintFormContent/ConstraintFormContent';

export default function ConstraintSpecificFields(props) {
  const { type, constraintTypes, namePrefix } = props;
  const { t } = useTranslation();
  const { getValues } = useFormContext();

  return (
    <div className="w-full">
      {type?.constraintInputList.map((constraintInput, key) =>
        getRightInput(
          constraintInput,
          key,
          constraintTypes,
          namePrefix,
          t,
          getValues,
        ),
      )}
    </div>
  );
}

ConstraintSpecificFields.propTypes = {
  type: PropTypes.object,
};

function getRightInput(
  constraintInput,
  key,
  constraintTypes,
  namePrefix,
  t,
  getValues,
) {
  const { type, label, name } = constraintInput;

  if (type === 'Integer')
    return (
      <TextInput
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        className="mb-2"
        type="number"
        label={t(`constraintsSpecific.${label}`)}
      />
    );
  if (type === 'List.Module')
    return (
      <ModuleMultiSelect
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        label={t(`constraintsSpecific.${label}`)}
      />
    );

  if (type === 'CustomForms.Bilan')
    return (
      <Bilan key={key} namePrefix={getNameWithNamePrefix(name, namePrefix)} />
    );

  if (type?.startsWith('List.Parameter'))
    return (
      <ParameterMultiSelect
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        label={t(`constraintsSpecific.${label}`)}
        className="mb-2"
        domain={type.substring(type.lastIndexOf('.') + 1)}
      />
    );

  if (type?.startsWith('Parameter'))
    return (
      <ParameterSelect
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        label={t(`constraintsSpecific.${label}`)}
        domain={type.substring(type.lastIndexOf('.') + 1)}
      />
    );

  if (type?.startsWith('List.Product'))
    return (
      <ProductMultiSelect
        className="mb-2"
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        label={t(`constraintsSpecific.${label}`)}
      />
    );

  if (type === 'CustomForms.Generic')
    return <GenericConstraintForm key={key} />;
  if (type === 'CustomForms.Script') {
    return <ScriptConstraint key={key} />;
  }

  if (type === 'CustomForms.Rule') {
    return (
      <RuleSelect
        className="mb-2"
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        label={t(`constraintsSpecific.${label}`)}
      />
    );
  }

  if (type === 'Text')
    return (
      <TextInput
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        className="mb-2"
        type="text"
        label={t(`constraintsSpecific.${label}`)}
      />
    );

  if (type === 'List.Text')
    return (
      <MultiChipsInput
        key={key}
        name={getNameWithNamePrefix(name, namePrefix)}
        className="mb-2"
        label={t(`constraintsSpecific.${label}`)}
      />
    );

  if (type === 'Constraints')
    return (
      <ConstraintCompsite
        key={key}
        namePrefix={namePrefix}
        constraintTypes={constraintTypes}
      />
    );

  if (type === 'PossessionWithOptions')
    return (
      <Possession
        key={key}
        namePrefix={getNameWithNamePrefix(name, namePrefix)}
      />
    );
  if (type === 'NonPossessionWithOptions')
    return (
      <Possession
        key={key}
        namePrefix={getNameWithNamePrefix(name, namePrefix)}
        hasMaxAllowed
      />
    );
  if (type === 'CustomForms.BilanAndCurrency')
    return (
      <BilanAndCurrency
        key={key}
        namePrefix={getNameWithNamePrefix(name, namePrefix)}
      />
    );

  if (type === 'CustomForms.Income')
    return (
      <IncomeAndNature
        key={key}
        namePrefix={getNameWithNamePrefix(name, namePrefix)}
      />
    );

  if (type === 'CustomForms.OrList') {
    return (
      <ConstraintCombinerWrapper
        key={key}
        type={'OR'}
        constraint={{
          type: COMPOUND_CONSTRAINT_TYPES.OR,
          constraints: getValues('constraints'),
        }}
        namePrefix={'constraints'}
      />
    );
  }

  if (type === 'CustomForms.AndList') {
    return (
      <ConstraintCombinerWrapper
        key={key}
        type={'AND'}
        constraint={{
          type: COMPOUND_CONSTRAINT_TYPES.AND,
          constraints: getValues('constraints'),
        }}
        namePrefix={'constraints'}
      />
    );
  }

  if (type === 'CustomForms.NotConstraint')
    return (
      <div key={key}>
        <label>{t('constraints.innerConstraint')}</label>
        <div className="p-2">
          <ConstraintFormContent
            namePrefix={getNameWithNamePrefix('constraint', namePrefix)}
            constraintTypes={constraintTypes}
          />
        </div>
      </div>
    );
}
