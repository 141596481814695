import { downloadBlob } from '../../../utils/fileUtils';
import { message } from 'antd';

export const handleFormSubmit = async (data, getTestCases, t) => {
  const {
    product: { product: productId },
    act: actId,
  } = data;

  try {
    const response = await getTestCases({ productId, actId });
    const fileName = `test-cases-${productId}-${actId}.json`;
    downloadBlob(
      fileName,
      new Blob([JSON.stringify(response)], { type: 'application/json' }),
    );
  } catch (error) {
    message.error(t('error.fetchingTestCases'));
  }
};
