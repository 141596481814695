import CardDisplay from 'src/components/generic/CardDisplay';
import AdditionalAttribute from '../../generic/AdditionalAttribute';
import Offer from '../Offer';
import { useTranslation } from 'react-i18next';

export default function BankOnline() {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t('bankOnline.info')}>
      <Offer />
      <AdditionalAttribute />
    </CardDisplay>
  );
}
