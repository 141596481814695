import { Radio } from 'antd';
import { API_NAMES } from 'src/utils/constants/ApiNames';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from 'src/utils/constants';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { useEffect } from 'react';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';

const hasRole = (userRoles, allowedRoles) => {
  return userRoles.some((userRole) => allowedRoles.includes(userRole));
};
const hasApi = (apiList, api) => {
  return apiList.includes(api);
};

export default function ApiSwitcher({
  catalogueRoles = [],
  pricingRoles = [],
  factRoles = [],
  concernedApis = [API_NAMES.CATALOG, API_NAMES.FACTS, API_NAMES.EVENT_PRICING],
}) {
  const { currentApi, setCurrentApi, userContext } = useUserContext();
  const { t } = useTranslation();
  const onChange = (e) => {
    setCurrentApi(e.target.value);
  };

  const allCatalogueRoles = [USER_ROLES.SUPER_ADMIN, ...catalogueRoles];
  const allPricingRoles = [USER_ROLES.SUPER_ADMIN, ...pricingRoles];

  const allFactRoles = [USER_ROLES.SUPER_ADMIN, ...factRoles];

  useEffect(() => {
    if (!userContext || currentApi) {
      return;
    }
    const currentUserRoles = Object.values(API_NAMES).flatMap(
      (api) => userContext[api]?.roles,
    );
    let targetApi;

    if (
      hasRole(currentUserRoles, allFactRoles) &&
      hasApi(concernedApis, API_NAMES.FACTS)
    ) {
      targetApi = API_NAMES.FACTS;
    }

    if (
      hasRole(currentUserRoles, allPricingRoles) &&
      hasApi(concernedApis, API_NAMES.EVENT_PRICING)
    ) {
      targetApi = API_NAMES.EVENT_PRICING;
    }

    if (
      hasRole(currentUserRoles, allCatalogueRoles) &&
      hasApi(concernedApis, API_NAMES.CATALOG)
    ) {
      targetApi = API_NAMES.CATALOG;
    }

    setCurrentApi(targetApi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Radio.Group value={currentApi} onChange={onChange}>
      {hasApi(concernedApis, API_NAMES.CATALOG) && (
        <AuthComponentDisabler
          componentRender={() => (
            <Radio value={API_NAMES.CATALOG}>{t('navbar.products')}</Radio>
          )}
          roles={allCatalogueRoles}
          api={API_NAMES.CATALOG}
        />
      )}
      {hasApi(concernedApis, API_NAMES.EVENT_PRICING) && (
        <AuthComponentDisabler
          componentRender={() => (
            <Radio value={API_NAMES.EVENT_PRICING}>
              {t('navbar.eventPricings')}
            </Radio>
          )}
          roles={allPricingRoles}
          api={API_NAMES.EVENT_PRICING}
        />
      )}
      {hasApi(concernedApis, API_NAMES.FACTS) && (
        <AuthComponentDisabler
          componentRender={() => (
            <Radio value={API_NAMES.FACTS}>{t('navbar.facts')}</Radio>
          )}
          roles={allFactRoles}
          api={API_NAMES.FACTS}
        />
      )}
    </Radio.Group>
  );
}
