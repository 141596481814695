import { Input, Table, Empty } from 'antd';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { createApisForCache } from 'src/api/administration/caches/caches';
import ApiSwitcher from 'src/components/apiSwitcher';
import Loading from 'src/components/generic/Loading';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { API_NAMES, USER_ROLES } from 'src/utils/constants';
import modalConfirm from 'src/utils/modalConfirm/modalConfirm';
import ClearButton from './components/ClearButton';
import useMutateClearCache from './hooks/useMutateClearCache';
import { useTranslation } from 'react-i18next';

export default function CachingListingPage() {
  const { currentApi } = useUserContext();
  const { t } = useTranslation();

  const { getCaches, clearCache } = createApisForCache(currentApi);

  const {
    data: caches,
    error,
    isLoading,
  } = useQuery(['caches', currentApi], getCaches);

  const { mutate } = useMutateClearCache({
    update: clearCache,
    queryKey: ['caches', currentApi],
  });

  const [filter, setFilter] = useState('');

  const handleClearCache = async (id) => {
    modalConfirm(t('caches.confirmClear'), async () => {
      mutate([id]);
    });
  };

  const handleClearAll = async () => {
    modalConfirm(t('caches.confirmClearAll'), async () => {
      if (filteredCaches?.length > 0) {
        mutate(filteredCaches);
      }
    });
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredCaches = caches?.filter((cache) =>
    cache.toLowerCase().includes(filter.toLowerCase()),
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Empty description={<span>{t('caches.errorLoadingCaches')}</span>} />
    );
  }

  const columns = [
    {
      title: t('caches.name'),
      dataIndex: 'cache',
      key: 'cache',
      render: (text) => <p>{text}</p>,
    },
    {
      title: t('cache.action'),
      key: 'action',
      render: (text, record) => (
        <ClearButton
          title={t('caches.clear')}
          onClick={() => handleClearCache(record.cache)}
        />
      ),
    },
  ];

  return (
    <div className="mx-auto p-4">
      <header className="mb-4">
        <h1 className="text-2xl font-bold">{t('caches.title')}</h1>
      </header>
      <div className="my-3">
        <ApiSwitcher
          catalogueRoles={[USER_ROLES.CONSULT_CATALOG_CACHE]}
          pricingRoles={[USER_ROLES.CONSULT_EVENT_PRICING_CACHE]}
          concernedApis={[API_NAMES.CATALOG, API_NAMES.EVENT_PRICING]}
        />
      </div>
      <div className="mb-4 flex justify-between items-center gap-3">
        <Input
          type="text"
          placeholder={t('caches.filterPlaceholder')}
          className="p-2 border rounded w-full"
          value={filter}
          onChange={handleFilterChange}
        />
        <ClearButton title={t('caches.clearAll')} onClick={handleClearAll} />
      </div>
      <Table
        columns={columns}
        dataSource={filteredCaches?.map((elem) => ({ key: elem, cache: elem }))}
      />
    </div>
  );
}
