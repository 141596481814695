import { createContext, useState } from 'react';

const FactsContext = createContext();

const FactsProvider = ({ children }) => {
  const [eventChoice, setEventChoice] = useState(null);
  const [chosenFacts, setChosenFacts] = useState([]);
  const [facts, setFacts] = useState(null);

  return (
    <FactsContext.Provider
      value={{
        eventChoice,
        setEventChoice,
        facts,
        setFacts,
        chosenFacts,
        setChosenFacts,
      }}
    >
      {children}
    </FactsContext.Provider>
  );
};

export { FactsProvider, FactsContext };
