import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import TextInput from 'src/components/generic/inputs/TextInput';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import DamageGuarantee from '../DamageGuarantee';

const DamageFormulaForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('damageFormula.functionalId')}
        />
      </Col>

      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.name`}
          label={t('damageFormula.name')}
        />
      </Col>
      <Col span={24}>
        <DamageGuarantee namePrefix={`${namePrefix}`} />
      </Col>
    </Row>
  );
};

export default DamageFormulaForm;
