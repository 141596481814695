import { Button, Col, Row } from 'antd';
import TextInput from 'src/components/generic/inputs/TextInput';
import FlatInternationalizationInput from 'src/components/generic/inputs/FlatInternationalizationInput';
import { useFieldArray, useFormContext } from 'react-hook-form';
import React from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

function GroupsLabelsInput({ name }) {
  const { control, formDisabled } = useFormContext();
  const { fields, append, remove } = useFieldArray({ name, control });
  const { t } = useTranslation();
  return (
    <>
      <Row justify="end" gutter={16} className="mb-3">
        <Button
          data-testid="add-group-label-btn"
          onClick={() => append({})}
          className="flex justify-center items-center mr-2"
          icon={<PlusOutlined />}
          disabled={formDisabled}
        >
          {t('commissionGroupingProcessor.addGroupLabel')}
        </Button>
      </Row>

      {fields?.length > 0 && (
        <Row> {t('commissionGroupingProcessor.groupLabels')}</Row>
      )}

      {fields?.map((label, i) => (
        <Row key={label.group} gutter={10} className="mb-3" align="top">
          <Col span={8}>
            <TextInput
              name={`${name}[${i}].group`}
              placeholder={t('commissionGroupingProcessor.groupCode')}
            />
          </Col>
          <Col span={14}>
            <FlatInternationalizationInput
              name={`${name}[${i}].label`}
              placeholder={t('form.label')}
            />
          </Col>
          <Col span={2}>
            <Button
              data-testid={`delete-${i}-group-label-btn`}
              disabled={formDisabled}
              type="ghost"
              onClick={() => remove(i)}
              icon={<DeleteOutlined />}
            />
          </Col>
        </Row>
      ))}
    </>
  );
}

export { GroupsLabelsInput };
