import { FunctionComponent, useMemo } from 'react';
import { Button, Upload, message } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from 'src/utils/constants';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload';
import { downloadBlob } from 'src/utils/fileUtils';
import ComponentDisabler from '../ComponentDisabler';

const JSONMimeType = 'application/json';

interface ExportImportProps {
  dataName: string;
  roles?: string[];
  onExportMapper?: (data: any) => any;
}

const ExportImport: FunctionComponent<ExportImportProps> = ({
  dataName,
  roles = [USER_ROLES.DATA_IMPORT_EXPORT],
  onExportMapper,
}) => {
  const { t } = useTranslation();
  const { getValues, reset } = useFormContext();

  const beforeUpload = (file: RcFile) => {
    if (file.type !== JSONMimeType) {
      message.error(t('validation.json'), 5);
      return false;
    }
    return true;
  };

  const exportMapData = useMemo(() => {
    if (onExportMapper) {
      return onExportMapper;
    }

    return (data: any) => {
      return { ...data, functionalId: undefined };
    };
  }, [onExportMapper]);

  const handleExport = () => {
    const values = getValues();
    const obj = exportMapData(values);
    const data = JSON.stringify(obj);
    const blob = new Blob([data], { type: JSONMimeType });
    downloadBlob(dataName + '-' + values.functionalId, blob);
  };

  const handleImport = ({ file }: UploadChangeParam<UploadFile<any>>) => {
    const fileReader = new FileReader();
    fileReader.onload = function () {
      const content = fileReader.result;
      const newData = JSON.parse(content as string);

      const existingData = getValues();

      reset({ ...existingData, ...newData });
    };

    fileReader.readAsText(file.originFileObj as RcFile);
  };

  return (
    <div className="flex space-x-5 mr-5">
      <AuthComponentDisabler
        componentRender={() => (
          <Button
            ghost
            type="primary"
            className="flex justify-center items-center"
            onClick={handleExport}
          >
            {t('data.export')}
          </Button>
        )}
        roles={roles}
      />

      <Upload
        accept=".json"
        customRequest={() => false}
        beforeUpload={beforeUpload}
        onChange={handleImport}
        itemRender={() => null}
        maxCount={1}
      >
        <AuthComponentDisabler
          componentRender={() => (
            <ComponentDisabler
              componentRender={() => (
                <Button
                  ghost
                  type="primary"
                  className="flex justify-center items-center"
                >
                  {t('data.import')}
                </Button>
              )}
            />
          )}
          roles={roles}
        />
      </Upload>
    </div>
  );
};

export default ExportImport;
