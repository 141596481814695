import React from 'react';
import TextInput from 'src/components/generic/inputs/TextInput';
import { Button, Select } from 'antd';
import { TranslationOutlined } from '@ant-design/icons';
import { useParametersStore } from 'src/stores/parameters';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DescriptionSettingsDto, IMap } from 'src/types';
import { useTranslation } from 'react-i18next';
import { ADMIN_CANAL, groupParametersByFunctionalId } from './util';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import useModal from 'src/hooks/componentHooks/useModal';
import { useSelector } from 'react-redux';

interface FlatInternationalizationInputProps {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  size?: SizeType;
}

export default function FlatInternationalizationInput({
  name,
  label,
  required,
  placeholder,
}: FlatInternationalizationInputProps) {
  // Ref
  const mounted = React.useRef<boolean>(false);

  // Hooks
  const { t } = useTranslation();
  const { Modal, setOpen } = useModal();

  // Store
  const store = useParametersStore();
  const { data: languages } = store.getByDomain('SUPPORTED_LANGUAGE');
  const { data: canals } = store.getByDomain('CANAL');
  const selectedLang = useSelector(
    (state: any) => state.selectedLanguage.selectedLanguage,
  );

  // Form
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  // Computed
  const languagesAsMap = React.useMemo(
    () => groupParametersByFunctionalId(languages),
    [languages],
  );
  const canalsAsMap = React.useMemo(
    () => groupParametersByFunctionalId(canals),
    [canals],
  );
  const groupedFields = React.useMemo(() => {
    if (!fields) {
      return {};
    }

    const result: IMap<string, DescriptionSettingsDto[]> = {};
    (fields as DescriptionSettingsDto[]).forEach(
      (field: DescriptionSettingsDto) => {
        const canal = field.canal ?? '';
        if (!result[canal]) {
          result[canal] = [];
        }
        result[canal].push(field);
      },
    );

    return result;
  }, [fields]);
  const canalsCodes = React.useMemo(() => {
    return Object.keys(groupedFields).filter((canal) => canal !== ADMIN_CANAL);
  }, [groupedFields]);
  const selectableCanals = React.useMemo(() => {
    return canals?.filter((canal) => !groupedFields[canal.functionalId]);
  }, [groupedFields, canals]);
  const currentLangFieldName = React.useMemo(() => {
    for (let i = 0; i < fields?.length; i++) {
      const field = fields[i] as DescriptionSettingsDto;
      if (field.locale === selectedLang) {
        return `${name}.${i}.content`;
      }
    }

    return `${name}.0.content`;
  }, [name, fields, selectedLang]);

  React.useEffect(() => {
    if (!languages) {
      return;
    }

    if (fields?.length) {
      mounted.current = true;
      return;
    }

    if (!mounted.current) {
      mounted.current = true;
      languages.forEach((lang) => {
        append({
          locale: lang.functionalId,
          canal: ADMIN_CANAL,
          content: '',
        });
      });
    }
  }, [append, fields, languages]);

  if (!languagesAsMap) {
    return <></>;
  }

  // Handlers
  const getName = (fieldId: string): number => {
    for (let i = 0; i < fields?.length; i++) {
      if (fields[i].id === fieldId) {
        return i;
      }
    }
    return -1;
  };

  const deleteByCanal = (canal: string): void => {
    const indexes: number[] = [];
    (fields as DescriptionSettingsDto[])?.forEach((field, index) => {
      if (field.canal === canal) {
        indexes.push(index);
      }
    });
    remove(indexes);
  };

  return (
    <>
      {!!(fields.length > 0) && !!currentLangFieldName && (
        <TextInput
          name={currentLangFieldName}
          label={label}
          placeholder={placeholder}
          endAdornment={
            <TranslationOutlined
              data-testid="input-icon"
              style={{ fontSize: '25px' }}
              onClick={() => setOpen(true)}
            />
          }
          required={required}
        />
      )}
      <Modal title={`${t('internationalisation.of')}  ${label}`}>
        <div className="space-y-4">
          <div>
            {groupedFields[ADMIN_CANAL]?.map(
              (field: DescriptionSettingsDto) => {
                return (
                  <TextInput
                    key={field.id}
                    name={`${name}.${getName(field.id as string)}.content`}
                    label={languagesAsMap[field.locale ?? '']?.name}
                  />
                );
              },
            )}
          </div>
          <div className="space-y-4">
            {canalsCodes.map((canal) => {
              const canalFields = groupedFields[canal];
              return (
                <div className="border p-4 rounded-xl space-y-4" key={canal}>
                  <div className="flex space-x-4 justify-between mb-4">
                    <label className="font-medium text-xl">
                      {canalsAsMap[canal]?.name ?? t('default')}
                    </label>
                  </div>
                  {canalFields.map((field: DescriptionSettingsDto) => {
                    return (
                      <TextInput
                        key={field.id}
                        name={`${name}.${getName(field.id as string)}.content`}
                        label={languagesAsMap[field.locale ?? '']?.name}
                      />
                    );
                  })}
                  <Button
                    danger
                    htmlType="button"
                    className="w-full"
                    onClick={(e) => {
                      deleteByCanal(canal);
                      e.stopPropagation();
                    }}
                  >
                    {t('constraints.deleteAlternative')}
                  </Button>
                </div>
              );
            })}
          </div>
          <div>
            <Select
              options={selectableCanals}
              showSearch
              optionFilterProp="name"
              onSelect={(canal) => {
                languages?.forEach((lang) => {
                  append({
                    locale: lang.functionalId,
                    canal: canal,
                    content: '',
                  });
                });
              }}
              fieldNames={{ label: 'name', value: 'functionalId' }}
              showArrow
              bordered={false}
              placeholder={t('constraints.addAlternative')}
              className="w-full p-1 px-3 rounded-lg border border-green-600 text-green-600 hover:border-green-500 disabled:opacity-30"
              value={null}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
