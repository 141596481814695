import { getTargetTypes } from 'src/utils/getTargetTypes';
import { getAuditStatus } from './getAuditStatus';

export const PRODUCT_FILTER_FIELDS = [
  {
    label: 'search.label',
    type: 'TextInput',
    name: 'label',
    style: 'w-1/4 px-2',
  },
  {
    label: 'mainInfo.family',
    type: 'ParameterMultiSelect',
    name: 'family',
    domain: 'FAMILY',
    style: 'w-1/4 px-2',
  },
  {
    label: 'mainInfo.excludedFamilies',
    type: 'ParameterMultiSelect',
    name: 'excludedFamilies',
    domain: 'FAMILY',
    style: 'w-1/4 px-2',
  },
  {
    label: 'mainInfo.provider',
    type: 'ParameterMultiSelect',
    name: 'provider',
    domain: 'PROVIDER',
    style: 'w-1/5 px-2',
  },
  {
    type: 'Toggle',
    name: 'toggle',
    style: 'w-1/20 px-2 flex justify-center items-center',
  },
  {
    label: 'mainInfo.tags',
    type: 'ParameterMultiSelect',
    name: 'tagList',
    domain: 'TAG',
    style: 'w-1/4 px-2',
  },

  {
    label: 'selling.sellingCanals',
    type: 'ParameterMultiSelect',
    name: 'canals',
    domain: 'CANAL',
    style: 'w-1/4 px-2',
  },
  {
    label: 'selling.sellingModes',
    type: 'ParameterMultiSelect',
    name: 'sellingModes',
    domain: 'SELLING_MODE',
    style: 'w-1/4 px-2',
  },
  {
    label: 'mainInfo.groups',
    type: 'ParameterMultiSelect',
    name: 'groups',
    domain: 'GROUP',
    style: 'w-1/6 px-2',
  },
  {
    label: 'search.version',
    type: 'TextInput',
    name: 'version',
    style: 'w-1/12 px-2',
  },
  {
    label: 'mainInfo.periodeValidity',
    type: 'ParameterMultiSelect',
    name: 'periodValidity',
    domain: 'PERIODICITY',
    style: 'w-1/4 px-2',
  },
];

export const ELIGIBLEACT_FILTER_FIELDS = [
  {
    label: 'search.label',
    type: 'TextInput',
    name: 'label',
    style: 'w-1/5 px-2',
  },
  {
    label: 'eligibleAct.targetType',
    type: 'SelectInput',
    name: 'targetType',
    style: 'w-1/4 px-2',
    data: getTargetTypes(true),
  },
  {
    label: 'eligibleAct.act',
    type: 'ParameterMultiSelect',
    name: 'acts',
    domain: 'ACT',
    style: 'w-1/4 px-2',
  },
  {
    label: 'mainInfo.tags',
    type: 'ParameterMultiSelect',
    name: 'tagList',
    domain: 'TAG',
    style: 'w-1/5 px-2',
  },
  {
    label: 'search.version',
    type: 'TextInput',
    name: 'version',
    style: 'w-1/12 px-2',
  },
  {
    label: 'search.constraint',
    type: 'ConstraintTypesSelect',
    name: 'constraintId',
    style: 'w-1/4 px-2',
  },
];

export const EVENTPRICING_FILTER_FIELDS = [
  {
    label: 'search.label',
    type: 'TextInput',
    name: 'label',
    style: 'w-1/5 px-2',
  },
  {
    label: 'eventPricing.targetType',
    type: 'SelectInput',
    name: 'targetType',
    style: 'w-1/4 px-2',
    data: getTargetTypes(),
  },
  {
    label: 'eventPricing.act',
    type: 'ParameterMultiSelect',
    name: 'acts',
    domain: 'ACT',
    style: 'w-1/4 px-2',
  },
  {
    label: 'mainInfo.tags',
    type: 'ParameterMultiSelect',
    name: 'tagList',
    domain: 'TAG',
    style: 'w-1/5 px-2',
  },
  {
    label: 'search.version',
    type: 'TextInput',
    name: 'version',
    style: 'w-1/12 px-2',
  },
  {
    label: 'search.constraint',
    type: 'ConstraintTypesSelect',
    name: 'constraintId',
    style: 'w-1/4 px-2',
  },
];

export const RECOMMENDATION_FILTER_FIELDS = [
  {
    label: 'search.label',
    type: 'TextInput',
    name: 'label',
    style: 'w-1/4 px-2',
  },
  {
    label: 'eligibleAct.targetType',
    type: 'SelectInput',
    name: 'targetType',
    style: 'w-1/4 px-2',
    data: getTargetTypes(),
  },
  {
    label: 'search.version',
    type: 'TextInput',
    name: 'version',
    style: 'w-1/4 px-2',
  },
  {
    label: 'search.constraint',
    type: 'ConstraintTypesSelect',
    name: 'constraintId',
    style: 'w-1/4 px-2',
  },
];

export const USERS_FILTER_FIELDS = (profiles, roles) => [
  {
    label: 'user.searchLabel',
    type: 'TextInput',
    name: 'label',
    style: 'w-1/4 pr-2',
  },
  {
    label: 'user.profile',
    type: 'SelectInput',
    name: 'profile',
    style: 'w-1/4 pr-2',
    data: profiles,
  },
  {
    label: 'user.status',
    type: 'SelectInput',
    name: 'status',
    style: 'w-1/4 pr-2',
    data: [
      { name: 'ACTIVE', functionalId: 'ACTIVE' },
      { name: 'DISABLED', functionalId: 'DISABLED' },
    ],
  },
  {
    label: 'profile.roles',
    type: 'DataMultiSelect',
    name: 'roles',
    style: 'w-1/4 px-2',
    data: roles,
  },
];

export const PROFILES_FILTER_FIELDS = (roles) => [
  {
    label: 'profile.searchLabel',
    type: 'TextInput',
    name: 'label',
    style: 'w-1/4 pr-2',
  },
  {
    label: 'profile.roles',
    type: 'DataMultiSelect',
    name: 'roles',
    style: 'w-1/4 pr-2',
    data: roles,
  },
];

export const ADMIN_CATALOGUE_AUDIT_FILTER_FIELDS = [
  {
    label: 'administration.correlationId',
    type: 'TextInput',
    name: 'correlationId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.clientId',
    type: 'TextInput',
    name: 'clientId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.eventType',
    type: 'AuditEventTypesMultiSelect',
    name: 'eventTypes',
    style: 'w-1/5 px-2',
    typeDomain: 'adminCatalogue',
  },
  {
    label: 'administration.userName',
    type: 'TextInput',
    name: 'userName',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.matricule',
    type: 'TextInput',
    name: 'matricule',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.status',
    type: 'SelectInput',
    name: 'status',
    style: 'w-1/5 px-2',
    data: getAuditStatus(),
  },
  {
    label: 'administration.profile',
    type: 'TextInput',
    name: 'profile',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.startDate',
    type: 'DatePicker',
    name: 'startDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.endDate',
    type: 'DatePicker',
    name: 'endDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.userIp',
    type: 'TextInput',
    name: 'userIp',
    style: 'w-1/5 px-2',
  },
];
export const ADMIN_PRICING_AUDIT_FILTER_FIELDS = [
  {
    label: 'administration.correlationId',
    type: 'TextInput',
    name: 'correlationId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.clientId',
    type: 'TextInput',
    name: 'clientId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.eventType',
    type: 'AuditEventTypesMultiSelect',
    name: 'eventTypes',
    style: 'w-1/5 px-2',
    typeDomain: 'adminPricing',
  },
  {
    label: 'administration.userName',
    type: 'TextInput',
    name: 'userName',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.matricule',
    type: 'TextInput',
    name: 'matricule',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.status',
    type: 'SelectInput',
    name: 'status',
    style: 'w-1/5 px-2',
    data: getAuditStatus(),
  },
  {
    label: 'administration.profile',
    type: 'TextInput',
    name: 'profile',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.startDate',
    type: 'DatePicker',
    name: 'startDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.endDate',
    type: 'DatePicker',
    name: 'endDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.userIp',
    type: 'TextInput',
    name: 'userIp',
    style: 'w-1/5 px-2',
  },
];
export const USAGE_PRODUCT_FILTER_FIELDS = [
  {
    label: 'administration.correlationId',
    type: 'TextInput',
    name: 'correlationId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.requestId',
    type: 'TextInput',
    name: 'requestId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.clientId',
    type: 'TextInput',
    name: 'clientId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.eventType',
    type: 'AuditEventTypesMultiSelect',
    name: 'eventTypes',
    style: 'w-1/5 px-2',
    typeDomain: 'usageProduct',
  },
  {
    label: 'administration.userName',
    type: 'TextInput',
    name: 'userName',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.matricule',
    type: 'TextInput',
    name: 'matricule',
    style: 'w-1/5 px-2',
  },
  {
    label: 'selling.sellingCanals',
    type: 'ParameterMultiSelect',
    name: 'canals',
    domain: 'CANAL',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.productId',
    type: 'TextInput',
    name: 'productId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.startDate',
    type: 'DatePicker',
    name: 'startDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.endDate',
    type: 'DatePicker',
    name: 'endDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.userIp',
    type: 'TextInput',
    name: 'userIp',
    style: 'w-1/5 px-2',
  },
];
export const USAGE_ELIGIBILITY_FILTER_FIELDS = [
  {
    label: 'administration.correlationId',
    type: 'TextInput',
    name: 'correlationId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.requestId',
    type: 'TextInput',
    name: 'requestId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.clientId',
    type: 'TextInput',
    name: 'clientId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.eventType',
    type: 'AuditEventTypesMultiSelect',
    name: 'eventTypes',
    style: 'w-1/5 px-2',
    typeDomain: 'usageEligibility',
  },
  {
    label: 'administration.userName',
    type: 'TextInput',
    name: 'userName',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.matricule',
    type: 'TextInput',
    name: 'matricule',
    style: 'w-1/5 px-2',
  },
  {
    label: 'selling.sellingCanals',
    type: 'ParameterMultiSelect',
    name: 'canals',
    domain: 'CANAL',
    style: 'w-1/5 px-2',
  },
  {
    label: 'eventPricing.act',
    type: 'ParameterMultiSelect',
    name: 'acts',
    domain: 'ACT',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.startDate',
    type: 'DatePicker',
    name: 'startDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.endDate',
    type: 'DatePicker',
    name: 'endDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.userIp',
    type: 'TextInput',
    name: 'userIp',
    style: 'w-1/5 px-2',
  },
];
export const USAGE_PRICING_FILTER_FIELDS = [
  {
    label: 'administration.correlationId',
    type: 'TextInput',
    name: 'correlationId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.requestId',
    type: 'TextInput',
    name: 'requestId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.clientId',
    type: 'TextInput',
    name: 'clientId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.eventType',
    type: 'AuditEventTypesMultiSelect',
    name: 'eventTypes',
    style: 'w-1/5 px-2',
    typeDomain: 'usagePricing',
  },
  {
    label: 'administration.userName',
    type: 'TextInput',
    name: 'userName',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.matricule',
    type: 'TextInput',
    name: 'matricule',
    style: 'w-1/5 px-2',
  },
  {
    label: 'selling.sellingCanals',
    type: 'ParameterMultiSelect',
    name: 'canals',
    domain: 'CANAL',
    style: 'w-1/5 px-2',
  },
  {
    label: 'eventPricing.act',
    type: 'ParameterMultiSelect',
    name: 'acts',
    domain: 'ACT',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.startDate',
    type: 'DatePicker',
    name: 'startDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.endDate',
    type: 'DatePicker',
    name: 'endDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.userIp',
    type: 'TextInput',
    name: 'userIp',
    style: 'w-1/5 px-2',
  },
];

export const filterParamMapedKeys = {
  label: 'label',
  targetType: 'targetType',
  family: 'family',
  excludedFamilies: 'excludedFamilies',
  provider: 'provider',
  acts: 'acts',
  periodValidity: 'periodValidity',
  groups: 'groups',
  tagList: 'tagList',
  sellingModes: 'sellingModes',
  canals: 'canals',
  version: 'version',
  constraintId: 'constraintId',
  roles: 'roles',
  status: 'status',
  profile: 'profile',
};
export const filterListTypeKeys = [
  'family',
  'excludedFamilies',
  'provider',
  'acts',
  'periodValidity',
  'groups',
  'tagList',
  'sellingModes',
  'canals',
  'roles',
  'status',
  'profile',
];
export const auditListTypeKeys = ['eventTypes', 'canals', 'acts'];
export const auditParamMappedKeys = {
  tab: 'tab',
  correlationId: 'correlationId',
  requestId: 'requestId',
  clientId: 'clientId',
  eventTypes: 'eventTypes',
  status: 'status',
  userName: 'userName',
  matricule: 'matricule',
  startDate: 'startDate',
  endDate: 'endDate',
  userIp: 'userIp',
  profile: 'profile',
  productId: 'productId',
  canals: 'canals',
  acts: 'acts',
};

export const ruleParamMappedKeys = {
  label: 'label',
  constraintId: 'constraintId',
};

export const RULE_FILTER_FIELDS = [
  {
    label: 'search.label',
    type: 'TextInput',
    name: 'label',
    style: 'w-1/4 px-2',
  },
  {
    label: 'search.constraint',
    type: 'ConstraintTypesSelect',
    name: 'constraintId',
    style: 'w-1/4 px-2',
  },
];

export const ADMIN_FACTS_AUDIT_FILTER_FIELDS = [
  {
    label: 'administration.correlationId',
    type: 'TextInput',
    name: 'correlationId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.clientId',
    type: 'TextInput',
    name: 'clientId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.eventType',
    type: 'AuditEventTypesMultiSelect',
    name: 'eventTypes',
    style: 'w-1/5 px-2',
    typeDomain: 'adminFacts',
  },
  {
    label: 'administration.userName',
    type: 'TextInput',
    name: 'userName',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.matricule',
    type: 'TextInput',
    name: 'matricule',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.status',
    type: 'SelectInput',
    name: 'status',
    style: 'w-1/5 px-2',
    data: getAuditStatus(),
  },
  {
    label: 'administration.profile',
    type: 'TextInput',
    name: 'profile',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.startDate',
    type: 'DatePicker',
    name: 'startDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.endDate',
    type: 'DatePicker',
    name: 'endDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.userIp',
    type: 'TextInput',
    name: 'userIp',
    style: 'w-1/5 px-2',
  },
];
export const USAGE_FACTS_FILTER_FIELDS = [
  {
    label: 'administration.correlationId',
    type: 'TextInput',
    name: 'correlationId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.requestId',
    type: 'TextInput',
    name: 'requestId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.clientId',
    type: 'TextInput',
    name: 'clientId',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.eventType',
    type: 'AuditEventTypesMultiSelect',
    name: 'eventTypes',
    style: 'w-1/5 px-2',
    typeDomain: 'usageFacts',
  },
  {
    label: 'administration.userName',
    type: 'TextInput',
    name: 'userName',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.matricule',
    type: 'TextInput',
    name: 'matricule',
    style: 'w-1/5 px-2',
  },
  {
    label: 'selling.sellingCanals',
    type: 'ParameterMultiSelect',
    name: 'canals',
    domain: 'CANAL',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.startDate',
    type: 'DatePicker',
    name: 'startDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.endDate',
    type: 'DatePicker',
    name: 'endDate',
    style: 'w-1/5 px-2',
  },
  {
    label: 'administration.userIp',
    type: 'TextInput',
    name: 'userIp',
    style: 'w-1/5 px-2',
  },
];

export const auditFilterFieldsMap = {
  adminPricing: ADMIN_PRICING_AUDIT_FILTER_FIELDS,
  adminCatalogue: ADMIN_CATALOGUE_AUDIT_FILTER_FIELDS,
  usageProduct: USAGE_PRODUCT_FILTER_FIELDS,
  usageEligibility: USAGE_ELIGIBILITY_FILTER_FIELDS,
  usagePricing: USAGE_PRICING_FILTER_FIELDS,
  adminFacts: ADMIN_FACTS_AUDIT_FILTER_FIELDS,
  usageFacts: USAGE_FACTS_FILTER_FIELDS,
};
