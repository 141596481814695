import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/generic/inputs/TextInput';
import InternationalizationArrayInput from 'src/components/generic/inputs/InternationalizationArrayInput';
import ProductSelect from 'src/components/generic/inputs/ProductMultiSelect/ProductSelect';
import { getAllProductsByFamily } from 'src/api';
import { getOptions } from 'src/utils/mappers/targetOptionMapper/targetOptionMapper';
import { Col, Row } from 'antd';

const ConventionIncludedCardForm = (props) => {
  const { namePrefix, label } = props;
  const { t } = useTranslation();

  return (
    <div>
      <h3>{label}</h3>
      <Row gutter={[16, 12]} className="m-1 mb-5">
        <Col span={24}>
          <ProductSelect
            getProducts={() => getAllProductsByFamily('MONETIQUE')}
            translationPrefix="conventionIncludedCard"
            namePrefix={namePrefix}
            getOptions={getOptions}
          />
        </Col>
        <Col span={12}>
          <TextInput
            type="number"
            name={`${namePrefix}.minNumberCards`}
            label={t('conventionIncludedCard.minNumberCards')}
          />
        </Col>
        <Col span={12}>
          <TextInput
            type="number"
            name={`${namePrefix}.maxNumberCards`}
            label={t('conventionIncludedCard.maxNumberCards')}
          />
        </Col>
        <Col span={24}>
          <InternationalizationArrayInput
            name={`${namePrefix}.details`}
            label={t('conventionIncludedCard.details')}
            translationPrefix="details"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ConventionIncludedCardForm;
