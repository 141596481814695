import EligibilitySimulationResult from 'src/components/simulation/EligibilitySimulationResult/EligibilitySimulationResult';
import SelectProductSimulation from 'src/components/simulation/SelectProductSimulation/SelectProductSimulation';
import SimulationFacts from 'src/components/simulation/SimulationFacts/SimulationFacts';
import { FactsProvider } from '../context';
import FactsDisplayer from '../SimulationFacts/FactsDisplayer';

export default function EligibilitySimulationForm() {
  return (
    <div className="p-5">
      <FactsProvider>
        <SelectProductSimulation />
        <SimulationFacts FactsDisplayer={FactsDisplayer} />
        <EligibilitySimulationResult />
      </FactsProvider>
    </div>
  );
}
