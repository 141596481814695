import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getAllProductsCodes } from 'src/api';
import DataMultiSelect from 'src/components/generic/inputs/DataMultiSelect';
import { getCorrectLabelForAdmin } from 'src/utils/internationalisationUtils';

export default function ProductMultiSelect(props) {
  const { name, label, className, family } = props;
  const { data, isLoading } = useQuery(['productsCodes', family], () =>
    getAllProductsCodes(family),
  );
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage,
  );

  return (
    !isLoading && (
      <DataMultiSelect
        name={name}
        label={label}
        className={className}
        data={Object.keys(data).map((e) => ({
          functionalId: e,
          name: e + ' - ' + getCorrectLabelForAdmin(data[e], selectedLang),
        }))}
      />
    )
  );
}
