import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import ConventionIncludedCardForm from '../ConventionIncludedCardForm';
import { CONVENTION_INCLUDED_CARD_DEFAULT } from 'src/utils/defaultsSupplier';

export default function ConventionIncludedCard(props) {
  const { namePrefix } = props;
  const name = namePrefix
    ? `${namePrefix}.conventionIncludedCardList`
    : 'conventionIncludedCardList';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="conventionIncludedCard"
      Form={ConventionIncludedCardForm}
      defaultValues={CONVENTION_INCLUDED_CARD_DEFAULT}
      fieldsNames={['product']}
    />
  );
}
