import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/generic/inputs/TextInput';

export default function SimulationFactsForm() {
  const { t } = useTranslation();
  const { append, fields, remove } = useFormContext();

  return (
    <div>
      {(fields || []).map((field, index) => (
        <div key={field.id} className="flex items-center gap-2">
          <div className="w-full">
            <TextInput
              name={`facts.${index}.name`}
              label={t('simulation.name')}
            />
          </div>
          <div className="w-full">
            <TextInput
              name={`facts.${index}.value`}
              label={t('simulation.value')}
            />
          </div>
          <button
            type="button"
            data-testid="remove-button"
            onClick={() => remove(index)}
            className="pt-6"
          >
            <CloseCircleOutlined className="text-lg" />
          </button>
        </div>
      ))}

      <div className="py-2 flex justify-center">
        <Button
          data-testid="addFact-button"
          onClick={() => {
            append({ name: '', value: '' });
          }}
        >
          <div className="flex items-center gap-2">
            <PlusCircleOutlined className="text-lg" />
            <p>{t('simulation.addFact')}</p>
          </div>
        </Button>
      </div>
    </div>
  );
}
