import { QrcodeOutlined, TagOutlined } from '@ant-design/icons';
import { Col, Row, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InternationalizationInput from '../../../../generic/inputs/InternationalizationInput';
import TextInput from '../../../../generic/inputs/TextInput';
import Cap from '../Cap';
import Plafond from '../Plafond';
import InternationalizationArrayInput from '../../../../generic/inputs/InternationalizationArrayInput';

export default function FormulaForm(props) {
  const { namePrefix } = props;
  const { t } = useTranslation();

  const items = [
    {
      label: t('formulas.info'),
      key: '1',
      children: (
        <Row gutter={[16, 12]} className="m-5">
          <Col span={12}>
            <TextInput
              name={`${namePrefix}.functionalId`}
              label={t('formulas.functionalId')}
              startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
            />
          </Col>
          <Col span={12}>
            <InternationalizationInput
              name={`${namePrefix}.label`}
              label={t('formulas.label')}
              startAdornment={<TagOutlined style={{ fontSize: '25px' }} />}
            />
          </Col>
          <Col span={24}>
            <InternationalizationInput
              name={`${namePrefix}.description`}
              label={t('formulas.description')}
              startAdornment={<TagOutlined style={{ fontSize: '25px' }} />}
            />
          </Col>
          <Col span={24}>
            <InternationalizationArrayInput
              name={`${namePrefix}.details`}
              label={t('formulas.details')}
              translationPrefix="details"
            />
          </Col>
        </Row>
      ),
    },

    {
      label: t('plafond.title'),
      key: '2',
      children: <Plafond namePrefix={namePrefix} />,
    },

    {
      label: t('cap.title'),
      key: '3',
      children: <Cap namePrefix={namePrefix} />,
    },
  ];

  return <Tabs items={items} />;
}

FormulaForm.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
