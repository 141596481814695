import { Table } from 'antd';
import { map } from '../../mapper';
import ExpandedRowRender from '../../ExpandedRowRender';

const TestCasesTable = ({
  dataSource,
  columns,
  pagination,
  setPagination,
  expandedRowKeys,
  setExpandedRowKeys,
}) => {
  return (
    <Table
      dataSource={dataSource.map((ele) => map(ele))}
      columns={columns}
      pagination={pagination}
      onChange={(pagination) => setPagination(pagination)}
      rowClassName="editable-row"
      expandable={{
        expandedRowRender: (props) => ExpandedRowRender(props),
        expandedRowKeys: expandedRowKeys,
        onExpand: (expanded, record) => {
          setExpandedRowKeys(expanded ? [record.key] : []);
        },
      }}
    />
  );
};

export default TestCasesTable;
