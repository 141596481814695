import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  useAuth as useBaseAuth,
  hasAuthParams,
  AuthProvider as BaseAuthProvider,
} from 'react-oidc-context';
import Loading from 'src/components/generic/Loading';
import { ErrorPage } from 'src/pages/error/ErrorPage';
import HttpService from 'src/services/HttpService';

const TOKEN_STORAGE_PREFIX = 'auth_token_';
const APP_URL = window.ENV.APP_URL || window.location.origin;
const OIDC_AUTHORITY = `${window.ENV.KEYCLOAK_URL}/realms/${window.ENV.KEYCLOAK_REALM}`;
const OIDC_CLIENT_ID = window.ENV.KEYCLOAK_CLIENT_ID;
const TOKEN_STORAGE_NAME = `${TOKEN_STORAGE_PREFIX}user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`;

const userManager = new UserManager({
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTimeInSeconds: 30,
  authority: OIDC_AUTHORITY,
  client_id: OIDC_CLIENT_ID,
  redirect_uri: APP_URL,
  post_logout_redirect_uri: APP_URL + '/logout',
  userStore: new WebStorageStateStore({
    prefix: TOKEN_STORAGE_PREFIX,
    store: window.localStorage,
  }),
});

const AuthProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <BaseAuthProvider userManager={userManager}>
      <AuthSignIn>{children}</AuthSignIn>
    </BaseAuthProvider>
  );
};

const AuthSignIn: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const auth = useBaseAuth();
  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

  React.useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return <Loading />;
  }

  if (auth.error || !auth.isAuthenticated) {
    return <ErrorPage message="Failed to authenticate" />;
  }

  HttpService.configure();
  return <>{children}</>;
};

const useAuth = () => {
  const auth = useBaseAuth();

  return {
    ...auth,
    logout: auth.signoutSilent,
    user: {
      ...auth.user,
      username: auth.user?.profile.preferred_username,
    },
  };
};

const getAuthToken = (): string | null => {
  return getOidcProfile()?.accessToken || null;
};

interface OidcProfileType {
  username?: string;
  userId: string;
  accessToken: string;
}

const getOidcProfile = (): OidcProfileType | null => {
  const oidcStorage = localStorage.getItem(TOKEN_STORAGE_NAME);
  if (!oidcStorage) {
    return null;
  }

  const user = User.fromStorageString(oidcStorage);
  return {
    username: user.profile.preferred_username,
    userId: user.profile.sub,
    accessToken: user.access_token,
  };
};

export {
  AuthProvider,
  useAuth,
  getAuthToken,
  getOidcProfile,
  TOKEN_STORAGE_NAME,
};
