import React from 'react';
import { useSelector } from 'react-redux';
import { ErrorPage } from 'src/pages/error/ErrorPage';

function ErrorPageDisplayer() {
  const { status, message, visible } = useSelector((state) => state.error);
  if (!visible) {
    return null;
  }
  return <ErrorPage status={status} message={message} />;
}

export { ErrorPageDisplayer };
