import React from 'react';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PercentageOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useWatch, useFormContext } from 'react-hook-form';
import SelectInput from '../../../../generic/inputs/SelectInput';
import TextInput from '../../../../generic/inputs/TextInput';
import { Price } from '../../Price/Price';

export default function LevelRangeForm({ namePrefix }) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const type = useWatch({
    control,
    name: `${namePrefix}.type`,
  });

  return (
    <div className="grid grid-cols-2 gap-2 mb-2">
      <SelectInput
        name={`${namePrefix}.type`}
        label="Type"
        data={[
          {
            functionalId: 'flat-levelRange',
            name: t('levelRangeList.flatRange'),
          },
          {
            functionalId: 'percentage-levelRange',
            name: t('levelRangeList.percentageRange'),
          },
        ]}
      />

      {type === 'percentage-levelRange' ? (
        <TextInput
          name={`${namePrefix}.percentage`}
          label={t('pricing.percentage')}
          type="number"
          endAdornment={<PercentageOutlined style={{ fontSize: '25px' }} />}
        />
      ) : null}

      {type === 'flat-levelRange' ? (
        <Price
          name={`${namePrefix}.fixAmount`}
          label={t('pricing.fixAmount')}
          currencyName={getCurrencyName(namePrefix)}
          startAdornment={<DollarCircleOutlined style={{ fontSize: '25px' }} />}
        />
      ) : null}

      <Price
        name={`${namePrefix}.min`}
        label={t('pricing.min')}
        currencyName={getCurrencyName(namePrefix)}
        startAdornment={<ArrowDownOutlined style={{ fontSize: '25px' }} />}
      />

      <Price
        name={`${namePrefix}.max`}
        label={t('pricing.max')}
        currencyName={getCurrencyName(namePrefix)}
        startAdornment={<ArrowUpOutlined style={{ fontSize: '25px' }} />}
      />
    </div>
  );
}

function getCurrencyName(name) {
  return name.split('.').splice(0, 2).join('.') + '.currency';
}
