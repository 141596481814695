import {
  DeleteOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Space, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import ActionButton from '../../ActionButton/ActionButton';
import PropTypes from 'prop-types';

export const COLUMN_TYPES = {
  DEFAULT: 'default',
  TAG: 'tag',
  ACTION: 'action',
};
export const CRUD_ACTIONS = {
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  CANCEL: 'CANCEL',
};
export default function CustomTable(props) {
  const { t } = useTranslation();
  const {
    columns,
    data,
    actions,
    pagination,
    isLoading,
    getTagColor = () => 'geekblue',
  } = props;

  const renderType = {
    [COLUMN_TYPES.DEFAULT]: (text, record) => (
      <span key={record.id}>{text}</span>
    ),
    [COLUMN_TYPES.TAG]: (text, record) => (
      <div key={record.id}>
        {text.map((tag) => {
          return (
            <Tag key={tag} color={getTagColor(tag)}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </div>
    ),
  };

  const resolveActionComponent = (action, record) => {
    switch (action.key) {
      case CRUD_ACTIONS.UPDATE:
        return (
          <ActionButton
            key={`edit-record-${record.id}`}
            tooltipTitle={t('crud.update')}
            icon={<EditOutlined />}
            onClick={() => action.onAction(record.id)}
            isHidden={action.isHidden(record.id)}
          />
        );
      case CRUD_ACTIONS.DELETE:
        return (
          <ActionButton
            key={`delete-record-${record.id}`}
            tooltipTitle={t('crud.delete')}
            icon={<DeleteOutlined />}
            onClick={() => action.onAction(record.id)}
            isHidden={action.isHidden(record.id)}
          />
        );
      case CRUD_ACTIONS.CANCEL:
        return (
          <ActionButton
            key={`cancel-record-${record.id}`}
            tooltipTitle={t('crud.cancel')}
            icon={<CloseCircleOutlined />}
            onClick={() => action.onAction(record.id)}
            isHidden={action.isHidden(record.id)}
          />
        );
      default:
        return <></>;
    }
  };

  const mappedColumns = columns.map((column) => {
    const { type, title, key, className } = column;
    return {
      title,
      dataIndex: key,
      key,
      className,
      render: (text, record) => {
        if (type === COLUMN_TYPES.ACTION) {
          return (
            <Space size="middle" key={record.id}>
              {actions?.map((action) => {
                return resolveActionComponent(action, record);
              })}
            </Space>
          );
        } else {
          return renderType[type](text, record);
        }
      },
    };
  });
  return (
    <Table
      columns={mappedColumns}
      dataSource={data}
      pagination={pagination}
      loading={isLoading}
    />
  );
}

CustomTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  actions: PropTypes.array,
  pagination: PropTypes.object,
  isLoading: PropTypes.bool,
  getTagColor: PropTypes.func,
};
