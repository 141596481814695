import { CreditCardOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CardDisplay from 'src/components/generic/CardDisplay';
import ParameterSelect from 'src/components/generic/inputs/ParameterSelect';
import AdditionalAttribute from '../../generic/AdditionalAttribute';
import Theme from '../Theme/Theme';
import ParameterMultiSelect from 'src/components/generic/inputs/ParameterMultiSelect/ParameterMultiSelect';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';

const BankCard = () => {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t('displayCard.cardInfo')}>
      <div className="grid grid-cols-2 gap-4">
        <ParameterSelect
          name="debitType"
          label={t('cardBank.debitType')}
          domain="DEBIT_TYPE"
          startAdornment={<CreditCardOutlined style={{ fontSize: '25px' }} />}
          sx={{ margin: '7px' }}
        />
        <ParameterMultiSelect
          name="authorizedHolders"
          label={t('cardBank.authorizedHolders')}
          domain="AUTHORIZED_HOLDER"
          startAdornment={<UserOutlined style={{ fontSize: '25px' }} />}
          sx={{ margin: '7px' }}
        />
        <SwitchInput name="tokenizable" label={t('cardBank.tokenizable')} />
        <ParameterSelect
          name="purchaser"
          label={t('cardBank.purchaser')}
          domain="PURCHASER"
          startAdornment={<CreditCardOutlined style={{ fontSize: '25px' }} />}
          sx={{ margin: '7px' }}
        />
      </div>
      <Theme />
      <AdditionalAttribute />
    </CardDisplay>
  );
};

export default BankCard;
