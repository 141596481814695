import { showError } from 'src/redux/slices/errorSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useErrorHandler = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onError = (error) => {
    dispatch(
      showError({
        status: error?.response?.data?.status,
        message: error?.response?.data?.message ?? t('an_error_occurred'),
      }),
    );
  };
  return { onError };
};
export { useErrorHandler };
