export const mapDataToInternationalisation = (input) => {
  const result = [];

  for (const e of input?.['otherCanals'] || []) {
    for (const loc in e) {
      if (loc !== 'canal' && Object.hasOwnProperty.call(e, loc)) {
        result.push({ locale: loc, canal: e.canal, content: e[loc] || '' });
      }
    }
  }

  for (const locale in input) {
    if (locale !== 'otherCanals') {
      result.push({ locale, canal: 'ADMIN', content: input[locale] || '' });
    }
  }

  return result;
};

export const mapInternationalisationToData = (array = []) => {
  const result = { otherCanals: [] };

  const channels = {};
  for (const elem of array) {
    const { canal, locale, content } = elem;
    if (canal === 'ADMIN') {
      result[locale] = content;
    } else {
      channels[canal] = channels[canal] || [];
      channels[canal].push({ locale, content });
    }
  }

  for (const canal in channels) {
    if (Object.hasOwnProperty.call(channels, canal)) {
      result.otherCanals.push({
        canal,
        ...Object.fromEntries(
          channels[canal].map(({ locale, content }) => [locale, content]),
        ),
      });
    }
  }

  return result;
};

export const mapDataToTargets = (data) => {
  if (data.targetType === 'FAMILY') return data['families'];
  if (data.targetType === 'PRODUCT') return data['products'];
  if (data.targetType === 'GROUP') return data['groups'];
  if (data.targetType === 'OPTION')
    return data['targets'].options?.map(
      (elem) => data['targets']?.product + ':' + elem,
    );
  return [];
};

export const mapTargetsToData = (elem) => {
  if (elem.targetType === 'FAMILY')
    return {
      families: elem.targets,
    };
  if (elem.targetType === 'PRODUCT')
    return {
      products: elem.targets,
    };
  if (elem.targetType === 'GROUP')
    return {
      groups: elem.targets,
    };

  if (elem.targetType === 'OPTION')
    return {
      targets: {
        product: elem.targets?.[0]?.split(':')[0],
        options: elem.targets.map((el) => el?.slice(el.indexOf(':') + 1)),
      },
    };
};

export const mapDocumentAttachmentListToData = (documentAttachmentList) => {
  return (documentAttachmentList || []).map((elem) => ({
    functionalId: elem.functionalId,
    descriptions: mapInternationalisationToData(elem.descriptions),
    file: {
      fileMetaData: elem.fileMetaData,
      url: elem.url,
    },
  }));
};

export const mapDataToDocumentAttachmentList = (data) => {
  return (data || []).map((elem) => ({
    functionalId: elem.functionalId,
    descriptions: mapDataToInternationalisation(elem.descriptions),
    ...elem.file,
  }));
};
