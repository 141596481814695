import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { SERIALS_DEFAULT } from 'src/utils/defaultsSupplier';
import SerialForm from '../SerialForm';

export default function Serial(props) {
  const { namePrefix } = props;
  return (
    <ArrayAccordion
      name={`${namePrefix}`}
      translationPrefix="serial"
      Form={SerialForm}
      defaultValues={SERIALS_DEFAULT}
      fieldsNames={['minSerial', 'maxSerial']}
    />
  );
}

Serial.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
