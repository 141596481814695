import i18n from 'src/i18n';

export const getTargetTypes = (hasOptions) => {
  const { t } = i18n;
  return [
    {
      functionalId: 'PRODUCT',
      name: t('targetTypes.products'),
    },
    {
      functionalId: 'FAMILY',
      name: t('targetTypes.families'),
    },
    {
      functionalId: 'GROUP',
      name: t('targetTypes.groups'),
    },
    {
      functionalId: 'ALL',
      name: t('targetTypes.all'),
    },
    ...(hasOptions
      ? [
          {
            functionalId: 'OPTION',
            name: t('targetTypes.options'),
          },
        ]
      : []),
  ];
};
