import { Controller, useFormContext } from 'react-hook-form';
import { Form, Input } from 'antd';
import InputLabel from 'src/components/generic/inputs/InputLabel/InputLabel';
import React from 'react';

interface TextAreaInputProps {
  name: string;
  label: string;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  rows?: number;
  description?: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  allowClear?: boolean;
  autoSize?: boolean;
}

export function TextAreaInput({
  name,
  label,
  className,
  placeholder,
  disabled,
  rows,
  description = undefined,
  minLength,
  maxLength,
  required,
  allowClear = false,
  autoSize = false,
}: TextAreaInputProps) {
  // @ts-ignore
  const { control, formDisabled } = useFormContext();

  return (
    <Form.Item className="mb-0" help={description}>
      <InputLabel label={label} required={required} />
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Input.TextArea
            {...field}
            size="large"
            className={className}
            placeholder={placeholder}
            rows={rows}
            disabled={disabled || formDisabled}
            minLength={minLength}
            maxLength={maxLength}
            required={required}
            allowClear={allowClear}
            autoSize={autoSize}
          />
        )}
      />
    </Form.Item>
  );
}
