import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import PossessionForm from '../PossessionForm';
import NonPossessionForm from '../NonPossesionForm';

const Possession = (props) => {
  const { namePrefix, hasMaxAllowed } = props;
  const Form = hasMaxAllowed ? NonPossessionForm : PossessionForm;
  const translationPrefix = hasMaxAllowed ? 'nonPossession' : 'possession';

  return (
    <ArrayAccordion
      name={`${namePrefix}`}
      translationPrefix={translationPrefix}
      Form={Form}
      defaultValues={{}}
      fieldsNames={[]}
    />
  );
};

Possession.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  hasMaxAllowed: PropTypes.bool,
};

export default Possession;
