import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { ServiceFormulaForm } from 'src/components/product/form/service/serviceFormulaForm/ServiceFormulaForm';
import { SERVICE_OPTION_DEFAULT } from 'src/utils/defaultsSupplier';

function ServiceFormula() {
  return (
    <ArrayAccordion
      name="serviceFormulas"
      translationPrefix="formulaList"
      Form={ServiceFormulaForm}
      defaultValues={SERVICE_OPTION_DEFAULT}
      fieldsNames={[]}
    />
  );
}

export { ServiceFormula };
