import { useTranslation } from 'react-i18next';
import ConstraintWrapper from 'src/components/constraint/form/ConstraintWrapper/ConstraintWrapper';
import { ShoppingOutlined, QrcodeOutlined } from '@ant-design/icons';
import ParameterMultiSelect from 'src/components/generic/inputs/ParameterMultiSelect/ParameterMultiSelect';
import { Row, Col } from 'antd';
import TextInput from 'src/components/generic/inputs/TextInput/TextInput';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput/InternationalizationInput';

const MotifsWithConstraintsForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Row gutter={[16, 8]} className="mb-5">
        <Col span={12}>
          <TextInput
            name={`${namePrefix}.functionalId`}
            label={t('motifs.functionalId')}
            startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>

        <Col span={12}>
          <InternationalizationInput
            name={`${namePrefix}.label`}
            label={t('motifs.label')}
            startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
        <Col span={24}>
          <ParameterMultiSelect
            name={`${namePrefix}.motifList`}
            label={t('motifs.title')}
            domain="MOTIF"
            startAdornment={<ShoppingOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
      </Row>
      <div className="col-span-2">
        <h2>{t('eligibleAct.constraints')}</h2>
        <ConstraintWrapper namePrefix={namePrefix} />
      </div>
    </div>
  );
};

export default MotifsWithConstraintsForm;
