import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import InternationalizationArrayInput from 'src/components/generic/inputs/InternationalizationArrayInput';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import DamageGuaranteeOption from '../DamageGuaranteeOption';

const DamageGuaranteeForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('damageGuarantee.functionalId')}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.name`}
          label={t('damageGuarantee.name')}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.description`}
          label={t('damageGuarantee.description')}
        />
      </Col>

      <Col span={12}>
        <SwitchInput
          name={`${namePrefix}.optional`}
          label={t('damageGuarantee.optional')}
        />
      </Col>
      <Col span={24}>
        <InternationalizationArrayInput
          name={`${namePrefix}.details`}
          label={t('damageGuarantee.details')}
          translationPrefix="details"
        />
      </Col>
      <Col span={24}>
        <DamageGuaranteeOption namePrefix={`${namePrefix}`} />
      </Col>
    </Row>
  );
};

export default DamageGuaranteeForm;
