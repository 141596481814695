import { SUPPORTING_DOCUMENT_DEFAULT } from '../../../../utils/defaultsSupplier';
import ArrayAccordion from '../../../generic/ArrayAccordion';
import SupportingDocumentForm from '../SupportingDocumentForm';

export default function SupportingDocument() {
  return (
    <ArrayAccordion
      name="supportingDocuments"
      translationPrefix="supportingDocuments"
      Form={SupportingDocumentForm}
      defaultValues={SUPPORTING_DOCUMENT_DEFAULT}
      fieldsNames={['functionalId', 'label', 'sides']}
    />
  );
}
