import { useAPI } from 'src/containers/DynamicFormContainer/hooks/useAPI';
import { IMap, PropertyUISource } from 'src/types';

export function useUIOptions(source?: PropertyUISource) {
  const resolvedSource = source ?? {
    resource: '',
    metadata: {},
  };
  const { data, isLoading, isError } = useAPI(
    resolvedSource.resource,
    resolvedSource.metadata,
  );

  if (isLoading || isError || !data) {
    return { isLoading, isError, options: [] };
  }

  let labelAttribute = 'label';
  if (source?.labelAttribute) {
    labelAttribute = source.labelAttribute;
  }

  let valueAttribute = 'value';
  if (source?.valueAttribute) {
    valueAttribute = source.valueAttribute;
  }

  return {
    options: data.map((element: IMap<string, any>) => ({
      name: element[labelAttribute],
      functionalId: element[valueAttribute],
    })),
    isLoading,
    isError,
  };
}
