import { mapConstraint } from '../simulationResultMapper';
import { createNodeTitleFromSimpleConstraint } from '../utils/utils';

export const mapCompositeConstraint = (constraint) => {
  return {
    title: createNodeTitleFromSimpleConstraint(constraint),
    key: Math.random() + '',
    children: [
      mapConstraint(constraint.leftConstraintEvaluationDto),
      mapConstraint(constraint.rightConstraintEvaluationDto),
    ],
  };
};
