import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism.css';

import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const LANGUAGE_MAPPER = {
  PYTHON: 'python',
  JAVASCRIPT: 'javascript',
};

export const CodeEditor = forwardRef(
  // eslint-disable-next-line
  ({ language, value, onChange, disabled, onBlur }, _) => {
    const onCodeChange = (_code) => {
      onChange(_code);
    };

    const highlightCode = (code) => {
      const appliedLanguage =
        LANGUAGE_MAPPER[language] ?? LANGUAGE_MAPPER.PYTHON;
      const languageGrammar = languages[appliedLanguage];
      return highlight(code, languageGrammar, appliedLanguage);
    };

    return (
      <Editor
        value={value ?? ''}
        onValueChange={onCodeChange}
        highlight={highlightCode}
        padding={10}
        disabled={disabled}
        className="border rounded-md border-gray-300 min-h-[150px]"
        textareaClassName="rounded-md focus-visible:!outline-[#E86440] dir-ltr"
        preClassName="focus-visible:outline-none dir-ltr"
        onBlur={onBlur}
      />
    );
  },
);

CodeEditor.propTypes = {
  language: PropTypes.oneOf(Object.keys(LANGUAGE_MAPPER)),
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

CodeEditor.defaultProps = {
  language: 'PYTHON',
  disabled: false,
  onBlur: () => {},
};
