import { Col, Row } from 'antd';
import TextInput from '../../../../generic/inputs/TextInput';
import InternationalizationInput from '../../../../generic/inputs/InternationalizationInput';
import InternationalizationArrayInput from '../../../../generic/inputs/InternationalizationArrayInput';
import { useTranslation } from 'react-i18next';
import Beneficiary from '../Beneficiary';
import Vehicle from '../Vehicle';
import GraveConcession from '../GraveConcession';

const AssistanceOptionForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('assistanceOption.functionalId')}
        />
      </Col>

      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.label`}
          label={t('assistanceOption.label')}
        />
      </Col>
      <Col span={24}>
        <InternationalizationInput
          name={`${namePrefix}.description`}
          label={t('assistanceOption.description')}
        />
      </Col>
      <Col span={24}>
        <InternationalizationArrayInput
          name={`${namePrefix}.details`}
          label={t('assistanceOption.details')}
          translationPrefix="details"
        />
      </Col>
      <Col span={24}>
        <Beneficiary namePrefix={`${namePrefix}`} />
      </Col>
      <Col span={24}>
        <Vehicle namePrefix={`${namePrefix}`} />
      </Col>
      <Col span={24}>
        <GraveConcession namePrefix={`${namePrefix}`} />
      </Col>
    </Row>
  );
};

export default AssistanceOptionForm;
