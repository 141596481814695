import { useContext } from 'react';
import { useQuery } from 'react-query';
import { getFacts } from 'src/api';
import Loading from 'src/components/generic/Loading';
import EligibleActFactsDisplayer from '../EligibleActFactsDisplayer';
import { FactsContext } from '../../context';
import { isEmpty } from 'lodash';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

export default function FactsDisplayer() {
  const { eventChoice } = useContext(FactsContext);
  const { t } = useTranslation();

  const {
    data: eligibleActs,
    isLoading,
    isError,
  } = useQuery(
    ['simulation-facts-eligibleActs', eventChoice],
    () => getFacts(eventChoice),
    {
      enabled: !!eventChoice?.product?.product && !!eventChoice.act,
      useErrorBoundary: false,
    },
  );

  if (isLoading) return <Loading />;

  if (!eventChoice?.product?.product || !eventChoice.act)
    return (
      <Empty
        description={t('simulation.noSimulationInProgress')}
        className="mb-5"
      />
    );

  if (isEmpty(eligibleActs))
    return (
      <Empty
        description={t('simulation.noEligibleActFound')}
        className="mb-5"
      />
    );

  if (isError) return null;

  return <EligibleActFactsDisplayer eligibleActs={eligibleActs} />;
}
