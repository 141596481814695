import React from 'react';
import { FormComponentType } from 'src/pages/formSchemas/types';
import TextInput from 'src/components/generic/inputs/TextInput';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;
  return (
    <TextInput
      type={field.type}
      label={field.label as string}
      name={name}
      required={field.config?.required}
      placeholder={field.config?.placeholder}
      minLength={field.config?.min}
      maxLength={field.config?.max}
    />
  );
};
