import { DesignerDefaultFieldValuesType } from 'src/pages/formSchemas/types';
import { FIELD_TYPES } from 'src/pages/formSchemas/utils/constants/form';

export const designerDefaultFieldValues: DesignerDefaultFieldValuesType =
  () => ({
    name: '',
    label: 'Array field',
    description: '',
    type: FIELD_TYPES.array,
    config: {},
  });
