import DiscountForm from '../DiscountForm';
import { DISCOUNT_DEFAULT } from 'src/utils/defaultsSupplier';
import ArrayAccordion from '../../generic/ArrayAccordion';

export default function Discount(props) {
  const { namePrefix } = props;

  return (
    <>
      <ArrayAccordion
        name={`${namePrefix}`}
        translationPrefix="discount"
        Form={DiscountForm}
        defaultValues={DISCOUNT_DEFAULT}
        fieldsNames={['description', 'rate']}
      />
    </>
  );
}
