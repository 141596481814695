import { DesignerComponentType } from 'src/pages/formSchemas/types';
import { Form, Input } from 'antd';
import { FieldCardLabel } from 'src/pages/formSchemas/components/FieldCardLabel';
import React from 'react';

export const DesignerComponent: DesignerComponentType = ({ fieldValue }) => {
  return (
    <Form.Item className="w-full" help={fieldValue.description}>
      <FieldCardLabel fieldValue={fieldValue} />
      <Input.TextArea
        rows={3}
        value={fieldValue?.config?.defaultValue}
        placeholder={fieldValue?.config?.placeholder}
      />
    </Form.Item>
  );
};
