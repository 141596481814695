import {
  mapAdditionalAttributesWithConstraintsToData,
  mapDataToAdditionAttributesWithConstraints,
} from '../additionalAttributesWithConstraintsMapper';
import {
  mapDataToDocumentAttachmentList,
  mapDataToInternationalisation,
  mapDataToTargets,
  mapDocumentAttachmentListToData,
  mapInternationalisationToData,
  mapTargetsToData,
} from '../mapperHelpers';
import { mapReservedSchemaFields } from 'src/pages/formSchemas/utils/parser';

const mapConsentsToData = (consents) => {
  return consents.map((consent) => ({
    ...consent,
    descriptions: mapInternationalisationToData(consent.descriptions),
    constraints: consent.constraints.map((elem) => ({ content: elem })),
    documentAttachmentList: mapDocumentAttachmentListToData(
      consent.documentAttachmentList,
    ),
  }));
};

const mapSupportingDocumentsToData = (supportingDocuments) => {
  return supportingDocuments.map((supportingDocument) => ({
    ...supportingDocument,
    label: mapInternationalisationToData(supportingDocument.label),
    constraints: supportingDocument.constraints.map((elem) => ({
      content: elem,
    })),
  }));
};

function mapConstraintsToData(eligibleAct) {
  return eligibleAct.constraints.map((elem) => {
    elem.map((constraint) => {
      if (Array.isArray(constraint.errors)) {
        constraint.errors = mapInternationalisationToData(constraint.errors);
      }
    });

    return { content: elem };
  });
}

export const mapEligibleActToData = (eligibleAct) => {
  return {
    functionalId: eligibleAct.functionalId,
    descriptions: mapInternationalisationToData(eligibleAct.descriptions),
    targetType: eligibleAct.targetType,
    act: eligibleAct.act,
    tags: eligibleAct.tags,
    products: [],
    families: [],
    groups: [],
    ...mapTargetsToData(eligibleAct),
    consents: mapConsentsToData(eligibleAct.consents),
    supportingDocuments: mapSupportingDocumentsToData(
      eligibleAct.supportingDocuments,
    ),
    constraints: mapConstraintsToData(eligibleAct),
    additionalAttributeList: mapAdditionalAttributesWithConstraintsToData(
      eligibleAct.additionalAttributeList,
    ),
    motifList:
      eligibleAct.motifList?.map((motif) => ({
        ...motif,
        constraints: motif.constraints.map((elem) => ({ content: elem })),
        label: mapInternationalisationToData(motif.label),
      })) ?? [],
    messageList:
      eligibleAct.messageList?.map((message) => ({
        ...message,
        constraints: message.constraints.map((elem) => ({ content: elem })),
        content: mapInternationalisationToData(message.content),
        label: mapInternationalisationToData(message.label),
      })) ?? [],
    formSchema: eligibleAct.formSchema,
    formSchemaValues: eligibleAct.formSchemaValues,
  };
};

const mapDataToConsents = (data) => {
  return data.map((d) => ({
    ...d,
    descriptions: mapDataToInternationalisation(d.descriptions),
    constraints: d.constraints?.map((elem) => elem.content),
    documentAttachmentList: mapDataToDocumentAttachmentList(
      d.documentAttachmentList,
    ),
  }));
};

const mapDataToSupportingDocuments = (data) => {
  return data.map((d) => ({
    ...d,
    label: mapDataToInternationalisation(d.label),
    constraints: d.constraints?.map((elem) => elem.content),
  }));
};

function mapDataToConstraints(constraints) {
  return constraints.map((elem) => {
    return elem.content.map((constraint) => {
      return {
        ...constraint,
        errors: mapDataToInternationalisation(constraint.errors),
      };
    });
  });
}

export const mapDataToEligibleAct = (data) => {
  return {
    functionalId: data.functionalId,
    descriptions: mapDataToInternationalisation(data.descriptions),
    targetType: data.targetType,
    act: data.act,
    tags: data.tags,
    targets: mapDataToTargets(data),
    consents: mapDataToConsents(data.consents),
    supportingDocuments: mapDataToSupportingDocuments(data.supportingDocuments),
    constraints: mapDataToConstraints(data.constraints),
    additionalAttributeList: mapDataToAdditionAttributesWithConstraints(
      data.additionalAttributeList,
    ),
    motifList: data.motifList?.map((motif) => ({
      ...motif,
      label: mapDataToInternationalisation(motif.label),
      constraints: motif.constraints?.map((elem) => elem.content),
    })),
    messageList: data.messageList.map((elem) => ({
      functionalId: elem.functionalId,
      type: elem.type,
      content: mapDataToInternationalisation(elem.content),
      label: mapDataToInternationalisation(elem.label),
      constraints: elem.constraints?.map((elem) => elem.content),
    })),
    formSchema: data.formSchema
      ? { ...data.formSchema, __schema: undefined }
      : undefined,
    formSchemaValues: mapReservedSchemaFields(
      data.formSchemaValues,
      data.formSchema?.__schema,
    ),
  };
};
