import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { OFFER_DEFAULT } from 'src/utils/defaultsSupplier';
import OfferForm from '../OfferForm';

export default function Offer() {
  return (
    <ArrayAccordion
      name="offers"
      translationPrefix="offers"
      Form={OfferForm}
      defaultValues={OFFER_DEFAULT}
      fieldsNames={['functionalId', 'codeOfferTech', 'label']}
    />
  );
}
