import {
  FormBuilderField,
  FormComponentType,
} from 'src/pages/formSchemas/types';
import { Collapse } from 'antd';
import { getFormComponent } from 'src/pages/formSchemas/utils/form';
import React from 'react';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;

  return (
    <Collapse>
      <Collapse.Panel header={field?.label} key="default">
        {((field.config?.fields as FormBuilderField[]) ?? []).map((val, i) => {
          const key = `${val.label}-${i}`;
          const component = getFormComponent({ field: val, namePrefix: name });
          return <React.Fragment key={key}>{component}</React.Fragment>;
        })}
      </Collapse.Panel>
    </Collapse>
  );
};
