const ClearButton = ({ title, onClick }) => {
  return (
    <button
      type="button"
      className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded focus:outline-none focus:shadow-outline transition-colors duration-300 delay-100 flex-shrink-0"
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default ClearButton;
