import { Input, Skeleton } from 'antd';

export default function InputConditionalDisplayer({
  isLoading,
  isError,
  children,
  size = 'large',
}) {
  const { Input: SkeletonInput } = Skeleton;
  if (isLoading)
    return (
      <div
        className="h-full flex flex-col justify-end"
        data-testid="loading-input"
      >
        <SkeletonInput active={true} size={size} block={true} />
      </div>
    );
  if (isError)
    return (
      <Input
        data-testid="error-input"
        size={size}
        disabled={true}
        defaultValue={'Please reload the page'}
      />
    );

  return <>{children}</>;
}
