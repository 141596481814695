import { DesignerComponentType } from 'src/pages/formSchemas/types';
import { Checkbox, Form } from 'antd';
import React from 'react';

export const DesignerComponent: DesignerComponentType = ({ fieldValue }) => {
  return (
    <Form.Item className="w-full" help={fieldValue.description}>
      <Checkbox checked={fieldValue?.config?.defaultValue}>
        {fieldValue.label}
      </Checkbox>
    </Form.Item>
  );
};
