import { Input, Select, Tabs } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ParameterLister from 'src/components/parameter/listing/ParameterLister';
import { useDomainCategories, useDomains } from 'src/pages/parameters/store';
import Loading from 'src/components/generic/Loading';
import { AuthFormDisabler } from 'src/components/generic/AuthFormDisabler';
import { USER_ROLES } from 'src/utils/constants';
import { ExportSelectionContextProvider } from 'src/contexts/exportSelectionContext';

export const ParameterListingPage = () => {
  const { t } = useTranslation();
  const { data: domains, isLoading: isLoadingDomains } = useDomains();
  const { data: domainCategories, isLoading: isLoadingDomainCategories } =
    useDomainCategories();

  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState();
  const [selectedDomains, setSelectedDomains] = useState([]);
  const ref = useRef(null);

  const handleDomainChange = (newSelectedDomains) => {
    setSelectedDomains(newSelectedDomains);
  };

  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
  };

  const canShowParameter = (domainId) => {
    if (!selectedDomains.length) {
      return true;
    }
    return selectedDomains.some((item) => item === domainId);
  };

  const clearSearchQuery = () => {
    ref.current.value = '';
    ref.current.input.value = '';
    setSearchQuery('');
  };

  const clearSelectedDomains = () => {
    setSelectedDomains([]);
  };

  const groupDomainsByCategory = useMemo(
    () =>
      domains?.reduce((acc, domain) => {
        const categoryDomains = acc[domain.category];
        if (!categoryDomains) {
          acc[domain.category] = [domain];
          return acc;
        }

        categoryDomains.push(domain);
        return acc;
      }, {}),
    [domains],
  );

  const optionsCategories = useMemo(() => {
    if (!groupDomainsByCategory || !domainCategories?.length) {
      return [];
    }

    if (activeTab) {
      return groupDomainsByCategory[activeTab];
    }

    return groupDomainsByCategory[domainCategories[0].id];
  }, [activeTab, groupDomainsByCategory, domainCategories]);

  if (isLoadingDomains || isLoadingDomainCategories) {
    return <Loading />;
  }

  const tabs = domainCategories.map((category) => {
    return {
      label: category.label,
      key: category.id,
      children: (
        <>
          {groupDomainsByCategory[category.id]?.map(
            (domain) =>
              canShowParameter(domain.id) && (
                <AuthFormDisabler
                  key={domain.id}
                  componentRender={({ isAuth }) => (
                    <ExportSelectionContextProvider>
                      <ParameterLister
                        domain={domain}
                        formDisabled={!isAuth}
                        searchQuery={searchQuery}
                      />
                    </ExportSelectionContextProvider>
                  )}
                  roles={[USER_ROLES.EDIT_PARAMETERS]}
                />
              ),
          )}
        </>
      ),
    };
  });

  return (
    <>
      <div className="pt-3 flex justify-center">
        <div className="flex w-1/5">
          <Input
            ref={ref}
            role="textbox"
            size="large"
            placeholder={t('search.label')}
            value={ref.current?.value}
            onPressEnter={() => {
              setSearchQuery(ref.current?.input?.value);
            }}
          ></Input>
          <CloseCircleOutlined className="ml-2" onClick={clearSearchQuery} />
        </div>
        <div className="flex w-1/5 ml-8">
          <Select
            bordered={false}
            style={{
              width: '100%',
              borderRadius: '8px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#d9d9d9',
              backgroundColor: '#ffffff',
            }}
            size="large"
            showSearch
            mode="multiple"
            placeholder={t('search.domain')}
            optionFilterProp="name"
            showArrow
            fieldNames={{ label: 'name', value: 'id' }}
            options={optionsCategories}
            value={selectedDomains}
            onChange={handleDomainChange}
          ></Select>
          <CloseCircleOutlined
            className="ml-2"
            onClick={clearSelectedDomains}
          />
        </div>
      </div>
      <div className="p-6 pt-3 w-full h-full">
        <Tabs
          centered
          items={tabs}
          activeKey={activeTab}
          onChange={handleTabChange}
        />
      </div>
    </>
  );
};
