import { Button } from 'antd';
import modalConfirm from 'src/utils/modalConfirm';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface FieldDeleteButtonProps {
  onDelete: () => void;
}

export function FieldDeleteButton({
  onDelete,
}: Readonly<FieldDeleteButtonProps>) {
  const { t } = useTranslation();

  return (
    <Button
      data-testid="field-delete-button"
      onClick={() =>
        modalConfirm(t('form_schema.field_delete_confirm_message'), onDelete)
      }
      className="inline-flex items-center justify-center bg-white"
      size="middle"
      shape="circle"
      icon={<DeleteOutlined className="text-xs" />}
    />
  );
}
