import React from 'react';
import { FormComponentType } from 'src/pages/formSchemas/types';
import TextInput from 'src/components/generic/inputs/TextInput';
import MultiChipsInput from 'src/components/generic/inputs/MultiChipInput';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;

  if (field.config?.multiple) {
    return (
      <MultiChipsInput
        label={field.label as string}
        name={name}
        required={field.config?.required}
        placeholder={field.config?.placeholder}
      />
    );
  }

  return (
    <TextInput
      type={field.type}
      label={field.label as string}
      name={name}
      required={field.config?.required}
      placeholder={field.config?.placeholder}
      minLength={field.config?.minLength}
      maxLength={field.config?.maxLength}
    />
  );
};
