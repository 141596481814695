import React from 'react';
import Form from '../../generic/Form';
import { useNavigate } from 'react-router-dom';
import { FACT_ELEMENT_COLLECTION_DEFAULT } from '../../../utils/defaultsSupplier';
import { FACT_BASE_PATH } from '../../../routes/paths';
import { mapFactCollectionToData } from '../../../utils/mappers/factMapper';
import FactsForm from '../FactsForm';
import FactCollectionIds from '../FactCollectionIds';
import { FACT_ELEMENT_COLLECTOR_VALIDATION_SCHEMA } from '../../../utils/validationSchemas/factCollectorSchema';

const FactCollectionForm = (props) => {
  const { onSubmit, FactCollection, submitDisabled } = props;
  const navigate = useNavigate();

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        submitDisabled={submitDisabled}
        defaultValues={
          FactCollection
            ? mapFactCollectionToData(FactCollection)
            : FACT_ELEMENT_COLLECTION_DEFAULT
        }
        onCancel={() => navigate(FACT_BASE_PATH)}
        validationSchema={FACT_ELEMENT_COLLECTOR_VALIDATION_SCHEMA}
      >
        <FactCollectionIds className="grid grid-cols-4 gap-3" />
        <FactsForm />
      </Form>
    </div>
  );
};

export default FactCollectionForm;
