function findSelectedItem(items, defaultSelected) {
  if (!items?.length || !defaultSelected) {
    return '';
  }

  for (let item of items) {
    if (item.path.startsWith(defaultSelected)) {
      return item.path;
    }

    const maxSplit = 2;
    const selectedSlices = defaultSelected.split('/', maxSplit);
    const pathResource = '/' + selectedSlices[1];
    if (selectedSlices.length === maxSplit && item.path === pathResource) {
      return item.path;
    }

    const result = findSelectedItem(item?.children, defaultSelected);
    if (result !== '') {
      return item.path;
    }
  }

  return '';
}

export { findSelectedItem };
