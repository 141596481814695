import PropTypes from 'prop-types';
import SelectInput from '../SelectInput';

export default function MultiChipsInput(props) {
  const { name, label, required, placeholder } = props;

  return (
    <SelectInput
      name={name}
      label={label}
      mode="tags"
      data={[]}
      required={required}
      placeholder={placeholder}
    />
  );
}

MultiChipsInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};
