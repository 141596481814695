import { Button, Table } from 'antd';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { extractConstraintsInfo } from 'src/utils/mappers/simulationFactsNamesMapper';

export default function SimulationConstraintsFactsTable({ constraints }) {
  const { t } = useTranslation();
  const { append, fields } = useFormContext();

  const columns = [
    {
      title: t('simulation.names'),
      dataIndex: 'name',
      width: '20%',
    },
    {
      title: t('simulation.label'),
      dataIndex: 'label',
      width: '30%',
    },
    {
      title: t('simulation.description'),
      dataIndex: 'description',
      width: '30%',
    },
    {
      title: t('simulation.values'),
      dataIndex: 'values',
      width: '20%',
    },
  ];

  const memoedConstraints = useMemo(
    () => extractConstraintsInfo(constraints),
    [constraints],
  );

  const handleAddAlternativeFacts = () => {
    const fieldNames = memoedConstraints.map((e) => e.name);
    fieldNames.forEach((name) => {
      if (!fields.some((field) => field.name == name))
        append({ name, value: '' });
    });
  };

  return (
    <div>
      <Table
        className="mb-8"
        dataSource={memoedConstraints}
        columns={columns}
        pagination={false}
        bordered
      />
      <Button
        onClick={handleAddAlternativeFacts}
        className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
      >
        {t('simulation.factsAddAlternative')}
      </Button>
    </div>
  );
}
