import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import useModal from 'src/hooks/componentHooks/useModal';
import {
  BatchSchedulingCronTable,
  BatchSchedulingDateTable,
  BatchSchedulingFormSwitcher,
  BatchSchedulingHeader,
} from 'src/components/administration/technicalSetting/factTechnicalSettings';

const DATE_SCHEDULING_STATUS = {
  CANCELED: 'CANCELED',
  SCHEDULED: 'SCHEDULED',
  COMPLETED: 'COMPLETED',
};

const CRON_SCHEDULING_STATUS = {
  CANCELED: 'CANCELED',
  SCHEDULED: 'SCHEDULED',
};
const TYPE_SCHEDULING = {
  CRON: 'CRON',
  DATE: 'DATE',
};

function FactTechnicalSettingTabContainer() {
  const { t } = useTranslation();
  const [currentDateSchedulingStatus, setCurrentDateSchedulingStatus] =
    useState(DATE_SCHEDULING_STATUS.SCHEDULED);
  const [currentSchedulingCronStatus, setCurrentSchedulingCronStatus] =
    useState(CRON_SCHEDULING_STATUS.SCHEDULED);
  const [typeScheduling, setTypeScheduling] = useState(TYPE_SCHEDULING.DATE);

  const { Modal, setOpen } = useModal();
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangeDateSchedulingStatus = (formData) => {
    setCurrentPage(1);
    setCurrentDateSchedulingStatus(formData);
  };
  const handleCronSchedulingStatusChange = (formData) => {
    setCurrentPage(1);
    setCurrentSchedulingCronStatus(formData);
  };
  const handleChangeType = (formData) => {
    setCurrentPage(1);
    setTypeScheduling(formData);
    setCurrentDateSchedulingStatus(DATE_SCHEDULING_STATUS.SCHEDULED);
    setCurrentSchedulingCronStatus(CRON_SCHEDULING_STATUS.SCHEDULED);
  };

  return (
    <Card
      title={t('factTechnicalSetting.batch.resetZero.title')}
      bordered={false}
      className="bg-white"
      extra={
        <BatchSchedulingHeader
          setOpen={setOpen}
          handleChangeDateSchedulingStatus={handleChangeDateSchedulingStatus}
          defaultValueDateSchedulingStatus={currentDateSchedulingStatus}
          defaultCronSchedulingStatus={currentSchedulingCronStatus}
          defaultValueCurrentType={typeScheduling}
          dateSchedulingStatus={DATE_SCHEDULING_STATUS}
          handleChangeCronStatus={handleCronSchedulingStatusChange}
          cronStatus={CRON_SCHEDULING_STATUS}
          schedulingTypes={TYPE_SCHEDULING}
          handleChangeTypeScheduling={handleChangeType}
        />
      }
    >
      {typeScheduling === TYPE_SCHEDULING.CRON ? (
        <BatchSchedulingCronTable
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          currentStatus={currentSchedulingCronStatus}
        />
      ) : (
        <BatchSchedulingDateTable
          currentStatus={currentDateSchedulingStatus}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
      <Modal
        title={t('factTechnicalSetting.batch.resetZero.add')}
        widthFull={'60%'}
      >
        <BatchSchedulingFormSwitcher close={() => setOpen(false)} />
      </Modal>
    </Card>
  );
}

export { FactTechnicalSettingTabContainer };
