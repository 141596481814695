import { Popover } from 'antd';
import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { useSideBarContext } from 'src/components/generic/Sidebar/context';
import UserActions from 'src/components/generic/Sidebar/SideBarFooter/UserActions';
import UserAvatar from 'src/components/generic/Sidebar/SideBarFooter/UserAvatar';
import { useAuth } from 'src/contexts/authContext';

const SideBarFooter = () => {
  const { user } = useAuth();
  const { collapsed } = useSideBarContext();
  const userName = user.username?.toUpperCase() || '';

  const getFirstLetter = () => {
    const initials = userName
      .split('.')
      .map((word) => word.charAt(0))
      .join('');
    return initials.toUpperCase();
  };

  const renderCollapsedFooter = () =>
    userName ? (
      <Popover
        placement="topRight"
        content={UserActions}
        trigger="hover"
        arrow={false}
      >
        <div className="flex justify-center mb-5 cursor-pointer">
          <UserAvatar initials={getFirstLetter()} />
        </div>
      </Popover>
    ) : (
      <Popover
        placement="topRight"
        content={UserActions}
        trigger="click"
        arrow={false}
      >
        <button className="cursor-pointer text-gray-800 focus:text-primary focus:bg-bgOrange hover:text-primary hover:bg-bgOrange rounded-full p-1.5 mb-5">
          <MenuOutlined />
        </button>
      </Popover>
    );

  const renderExpandedFooter = () =>
    userName ? (
      <div className="px-4 w-full flex flex-row items-center justify-between">
        <div className="flex flex-row gap-4 items-center">
          <UserAvatar initials={getFirstLetter()} />
          <p className="font-medium text-gray-800 text-xs lg:text-sm">
            {userName?.split('@', 2)?.[0]}
          </p>
        </div>
        <Popover
          placement="topRight"
          content={UserActions}
          trigger="click"
          arrow={false}
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <button className="cursor-pointer text-gray-800 focus:text-primary focus:bg-bgOrange hover:text-primary hover:bg-bgOrange rounded-full p-1.5">
            <MenuOutlined />
          </button>
        </Popover>
      </div>
    ) : (
      <div className="px-4 w-full flex flex-row items-center justify-end">
        <Popover
          placement="topRight"
          content={UserActions}
          trigger="click"
          arrow={false}
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <button className="cursor-pointer text-gray-800 focus:text-primary focus:bg-bgOrange hover:text-primary hover:bg-bgOrange rounded-full p-1.5">
            <MenuOutlined />
          </button>
        </Popover>
      </div>
    );

  return collapsed ? renderCollapsedFooter() : renderExpandedFooter();
};

export { SideBarFooter };
