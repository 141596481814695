import ConstraintAddForm from 'src/components/constraint/form/ConstraintAddForm';
import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { EditButton } from './EditButton';
import { DeleteButton } from './DeleteButton';

export const ConstraintComponent = ({
  constraint,
  onUpdate,
  onDelete,
  readOnly,
}) => {
  // Hooks
  const { openModal, closeModal } = useModalContext();

  // Computed
  const canEdit = !readOnly;

  // Handlers
  const UpdateAndClose = (constraint) => {
    onUpdate(constraint);
    closeModal();
  };
  const handleEdit = () => {
    if (!canEdit) {
      return;
    }

    openModal({
      body: (
        <ConstraintAddForm
          constraint={constraint}
          onCancel={closeModal}
          addConstraint={UpdateAndClose}
          updateConstraint={UpdateAndClose}
        />
      ),
    });
  };

  return (
    <div className="flex items-center space-x-2" onDoubleClick={handleEdit}>
      <div className="border rounded-lg p-2 bg-gray-50">
        <div className="line-clamp-1 text-xs">{constraint.label}</div>
      </div>
      <div className="flex items-center space-x-2">
        <EditButton canEdit={canEdit} onEdit={handleEdit} />
        <DeleteButton canDelete={canEdit} onDelete={onDelete} />
      </div>
    </div>
  );
};
