import clsx from 'clsx';
import PropTypes from 'prop-types';

const VARIANT_MAP = {
  success: 'text-green-600',
  error: 'text-red-500',
};

export const DryRunResult = ({ result, variant }) => {
  return (
    <div
      data-testid={`dry-run-result-${variant}`}
      className={clsx(VARIANT_MAP[variant])}
    >
      {result}
    </div>
  );
};

DryRunResult.propTypes = {
  result: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(VARIANT_MAP)),
};

DryRunResult.defaultProps = {
  variant: 'success',
  result: '',
};
