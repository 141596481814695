export const mapDataToDotation = (data) => {
  return {
    eligibleCards: data.eligibleCards,
    type: 'dotation',
  };
};

export const mapDotationToData = (dotation) => {
  return {
    eligibleCards: dotation.eligibleCards,
  };
};
