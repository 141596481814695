import { resolveErrorMessage } from 'src/utils/errorUtils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const errorIcon = (
  <div className="inline-flex rounded-full bg-red-100 p-4">
    <div className="rounded-full stroke-red-600 bg-red-200 p-4">
      <svg
        className="w-16 h-16"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 8H6.01M6 16H6.01M6 12H18C20.2091 12 22 10.2091 22 8C22 5.79086 20.2091 4 18 4H6C3.79086 4 2 5.79086 2 8C2 10.2091 3.79086 12 6 12ZM6 12C3.79086 12 2 13.7909 2 16C2 18.2091 3.79086 20 6 20H14"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M17 16L22 21M22 16L17 21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </div>
  </div>
);

function ErrorPage({ status, message }) {
  const { t } = useTranslation();
  const displayedMessage = message || resolveErrorMessage(status);

  return (
    <section
      data-testid="error-page"
      className="h-full g-slate-100 flex flex-col gap-6 items-center justify-center p-5 bg-gray-100"
    >
      <div>{errorIcon}</div>
      {status && (
        <h1 className="text-3xl font-bold text-slate-800 lg:text-[50px]">
          {status} {t('errorMessages.error')}
        </h1>
      )}
      <p className="text-center text-slate-600 mt-5 lg:text-lg flex">
        {displayedMessage}
      </p>
    </section>
  );
}

ErrorPage.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
};

export { ErrorPage };
