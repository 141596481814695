import { FormComponentType } from 'src/pages/formSchemas/types';
import clsx from 'clsx';
import { FormFieldV2 } from 'src/types';
import { getFormComponent } from 'src/pages/formSchemas/utils/form';
import React from 'react';

const rowClassNames: { [key: string | number]: string } = {
  default: 'col-auto',
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
};

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;
  return (
    <div
      className={clsx(
        'grid gap-4',
        rowClassNames[field.config?.fields?.length] ?? rowClassNames.default,
      )}
    >
      {field.config?.fields?.map((field: FormFieldV2, i: number) => {
        const key = `${field.label}-${i}`;
        const component = getFormComponent({ field, namePrefix: name });
        return <div key={key}>{component}</div>;
      })}
    </div>
  );
};
