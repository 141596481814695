import { ChevronDown, ChevronUp } from 'src/components/icons';
import React from 'react';

function MenuItemChevron({ visible, open }) {
  if (!visible) {
    return <></>;
  }

  if (open) {
    return <ChevronUp data-testid="chevron-up" className="w-4 h-4" />;
  }

  return <ChevronDown data-testid="chevron-down" className="w-4 h-4" />;
}

export { MenuItemChevron };
