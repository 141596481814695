import PropTypes from 'prop-types';
import useParameters from 'src/hooks/normalHooks/useParameters';
import DataMultiSelect from '../DataMultiSelect';
import InputConditionalDisplayer from 'src/components/generic/inputs/InputConditionalDisplayer';

export default function ParameterMultiSelect(props) {
  const { name, label, domain, startAdornment, className, disabled } = props;

  const { data, isLoading, isError } = useParameters(domain);

  return (
    <InputConditionalDisplayer isLoading={isLoading} isError={isError}>
      <DataMultiSelect
        name={name}
        label={label}
        className={className}
        required={false}
        data={data}
        startAdornment={startAdornment}
        disabled={disabled}
      />
    </InputConditionalDisplayer>
  );
}

ParameterMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  className: PropTypes.string,
  errors: PropTypes.object,
  disabled: PropTypes.bool,
};
