import { Link } from 'react-router-dom';
import { FACT_ADD_PATH } from '../../../../routes/paths';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const Header = ({ t }) => {
  return (
    <div className="flex justify-end mt-2 mr-2">
      <Link to={FACT_ADD_PATH}>
        <Button
          type="primary"
          className="bg-orange-500 text-white flex items-center disabled:opacity-30"
        >
          <PlusOutlined sx={{ fontSize: '20px' }} /> {t('crud.add')}{' '}
          {t('subnav.FactCollection')}
        </Button>
      </Link>
    </div>
  );
};

export default Header;
