import { PageRequest, PageResult, ReusableFormSchemaDto } from 'src/types';
import HttpService from 'src/services/HttpService';
import { REUSABLE_FORM_SCHEMAS_PATH } from 'src/api/apiPaths';

const instance = HttpService.getAxiosClient('catalogue');

export const loadReusableFormSchemas = async (
  page?: PageRequest,
): Promise<PageResult<ReusableFormSchemaDto>> => {
  const res = await instance.get(`${REUSABLE_FORM_SCHEMAS_PATH}`, {
    params: {
      ...page,
    },
  });
  return res.data;
};

export const getReusableFormSchemaById = async (
  id: string,
): Promise<ReusableFormSchemaDto> => {
  const res = await instance.get(`${REUSABLE_FORM_SCHEMAS_PATH}/${id}`);
  return res.data;
};

export const createReusableFormSchema = async (
  data: ReusableFormSchemaDto,
): Promise<ReusableFormSchemaDto> => {
  const res = await instance.post(`${REUSABLE_FORM_SCHEMAS_PATH}`, data);
  return res.data;
};

export const updateReusableFormSchema = async (
  id: string,
  data: ReusableFormSchemaDto,
): Promise<ReusableFormSchemaDto> => {
  const res = await instance.put(`${REUSABLE_FORM_SCHEMAS_PATH}/${id}`, data);
  return res.data;
};

export const deleteReusableFormSchema = async (id: string): Promise<void> => {
  const res = await instance.delete(`${REUSABLE_FORM_SCHEMAS_PATH}/${id}`);
  return res.data;
};
