import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import TextInput from 'src/components/generic/inputs/TextInput';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import InternationalizationArrayInput from 'src/components/generic/inputs/InternationalizationArrayInput';

function ServiceFormulaForm(props) {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]} className="mb-2">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('serviceFormulas.functionalId')}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.label`}
          label={t('serviceFormulas.label')}
        />
      </Col>
      <Col span={24}>
        <InternationalizationInput
          name={`${namePrefix}.description`}
          label={t('serviceFormulas.description')}
        />
      </Col>
      <Col span={24}>
        <InternationalizationArrayInput
          name={`${namePrefix}.details`}
          label={t('serviceFormulas.details')}
          translationPrefix="details"
        />
      </Col>
    </Row>
  );
}

export { ServiceFormulaForm };
