import React from 'react';
import { useParams } from 'react-router-dom';
import { useFormSchemasStore } from 'src/stores/formSchemas';
import Loading from 'src/components/generic/Loading';
import { FormSchemaAdminDto } from 'src/types';

const FormSchemaContainer = React.lazy(
  () => import('src/pages/formSchemas/containers/FormSchemaContainer'),
);

export default function FormSchemaUpdate() {
  const { id } = useParams();
  const store = useFormSchemasStore();
  const query = store.getById(id ?? '');

  if (query.isLoading) {
    return <Loading />;
  }

  return (
    <React.Suspense fallback={null}>
      <FormSchemaContainer
        key={query.data?.id}
        schema={query.data as Required<FormSchemaAdminDto>}
      />
    </React.Suspense>
  );
}
