import { EvaluationDryRunResult } from 'src/components/constraint/form/ScriptConstraint/Dryrun/EvaluationDryRunResult';
import { SyntaxCheckDryRunResult } from 'src/components/constraint/form/ScriptConstraint/Dryrun/SyntaxCheckDryRunResult';

export const DRY_RUN_CONTEXT = {
  EVALUATING: 'EVALUATING',
  CHECKING_SYNTAX: 'CHECKING_SYNTAX',
};

export function buildEngineOptions(data, t) {
  return (
    data?.map(({ functionalId, name }) => ({
      functionalId,
      name: t('constraintEngine.' + name),
    })) || []
  );
}

export function getLanguageTemplateScript(languages, language) {
  if (languages == null) {
    return '';
  }

  if (languages.length === 1) {
    return languages[0].templateScript;
  }

  return (
    languages.find((l) => l.functionalId === language)?.templateScript ?? ''
  );
}

export const resolveDryRunComponent = (context) => {
  if (context === DRY_RUN_CONTEXT.EVALUATING) {
    return EvaluationDryRunResult;
  }

  if (context === DRY_RUN_CONTEXT.CHECKING_SYNTAX) {
    return SyntaxCheckDryRunResult;
  }

  return null;
};
