import UUIDClass from 'uuidjs';
import { COMPOUND_CONSTRAINT_TYPES } from 'src/constants/constraints';

export const appendIds = (constraints) => {
  if (Array.isArray(constraints)) {
    return constraints.map((constraint) => appendIds(constraint));
  }

  const id = UUIDClass.generate();

  if (
    [COMPOUND_CONSTRAINT_TYPES.OR, COMPOUND_CONSTRAINT_TYPES.AND].includes(
      constraints.type,
    )
  ) {
    return {
      id,
      ...constraints,
      constraints: appendIds(constraints.constraints),
    };
  }
  return {
    id,
    ...constraints,
  };
};

export const mapConstraints = (constraints) => {
  return constraints.map((constraint) => {
    return {
      constraints: constraint,
    };
  });
};

export const appendLabelsToConstraints = (constraints) => {
  return constraints?.map((alt) => {
    return alt.map((c) => {
      if (!c.label) {
        c.label = c.type;
      }
      return c;
    });
  });
};
