import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Form from 'src/components/generic/Form/Form';
import { useShowMessage } from 'src/hooks/normalHooks/useShowMessage';
import { TECHNICAL_SETTING_PATH } from 'src/routes/paths';
import PropTypes from 'prop-types';
import { scheduleResetToZeroDateBatch } from 'src/api/facts';
import { formatDateWithPattern } from 'src/utils/mappers/dateMapper';
import { BATCH_DATE_SCHEDULING_VALIDATION_SCHEMA } from 'src/utils/validationSchemas/factCollectorSchema';
import { useAddMutation } from 'src/hooks/normalHooks/mutation/useAddMutation';
import CustomDatePicker from 'src/components/generic/inputs/DatePicker';
import { CountFactNamesSelector } from 'src/components/administration/technicalSetting/factTechnicalSettings/countFactsNamesSelector';
import { PERIODICITY } from 'src/utils/constants';
import { batchSchedulingQueryKeys } from 'src/utils/constants/queryKeys';

export function DateSchedulingForm(props) {
  const { t } = useTranslation();
  const { close } = props;
  const { showSuccess } = useShowMessage();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [currentPeriodicity, setCurrentPeriodicity] = useState(
    PERIODICITY.DAILY,
  );
  const defaultDate = new Date();
  defaultDate.setMinutes(defaultDate.getMinutes() + 1);
  defaultDate.setSeconds(0);

  const dateSchedulingAddMutation = useAddMutation({
    add: (data) => scheduleResetToZeroDateBatch(data),
    onSuccess: async () => {
      showSuccess(
        t('factTechnicalSetting.batch.resetZero.planificationSuccess'),
      );
      navigate(TECHNICAL_SETTING_PATH);
      await queryClient.invalidateQueries({
        queryKey: [batchSchedulingQueryKeys.getDatesScheduling],
      });
      close();
    },
    basePath: TECHNICAL_SETTING_PATH,
  });
  const onSubmit = (data) => {
    data = {
      ...data,
      launchDate: formatDateWithPattern(data.date, 'YYYY-MM-DDTHH:mm:ss'),
      executeImmediately: false,
    };
    dateSchedulingAddMutation.mutate(data);
  };
  return (
    <Form
      onSubmit={onSubmit}
      onCancel={close}
      buttonsOnTop={false}
      validationSchema={BATCH_DATE_SCHEDULING_VALIDATION_SCHEMA}
      defaultValues={{ date: defaultDate }}
    >
      <div className={'flex flex-col gap-4 mb-4'}>
        <CountFactNamesSelector
          currentPeriodicity={currentPeriodicity}
          setCurrentPeriodicity={setCurrentPeriodicity}
          typeScheduling={'DATE'}
          name={'counts'}
        />
        <CustomDatePicker
          label={t('factTechnicalSetting.batch.resetZero.launchDate')}
          name="date"
          required
          disabledDate={(current) => {
            return current && current.valueOf() < Date.now();
          }}
          showTime={true}
          showSecond={false}
          showNow={false}
        />
      </div>
    </Form>
  );
}

DateSchedulingForm.propTypes = {
  close: PropTypes.func,
};
