import React from 'react';
import { OPTIONS_DEFAULT } from '../../../../../utils/defaultsSupplier';
import ArrayAccordion from '../../../../generic/ArrayAccordion';
import OptionsForm from '../../optionForm';

const Option = (props) => {
  const { namePrefix } = props;
  const name = namePrefix ? `${namePrefix}.options` : 'options';
  return (
    <ArrayAccordion
      name={name}
      translationPrefix="options"
      Form={OptionsForm}
      defaultValues={OPTIONS_DEFAULT}
      fieldsNames={['functionalId', 'label']}
    />
  );
};

export default Option;
