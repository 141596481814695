import React from 'react';
import { ErrorPageDisplayer } from 'src/components/generic/userFeedback/ErrorPageDisplayer';

export class ErrorBoundaryInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(_error, _errorInfo) {
    this.props.setHasError(true);
    this.props.onError?.(_error, _errorInfo);
  }

  render() {
    return this.state.hasError ? <ErrorPageDisplayer /> : this.props.children;
  }
}
