import { getNameWithNamePrefix } from 'src/utils';
import TextInput from '../../../generic/inputs/TextInput';
import { useTranslation } from 'react-i18next';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';

export default function ConstraintCommonFields(props) {
  const { namePrefix, hasErrorMessage = false } = props;
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <TextInput
        name={getNameWithNamePrefix('label', namePrefix)}
        label={t('constraints.label')}
        className="mb-2"
      />
      <TextInput
        name={getNameWithNamePrefix('description', namePrefix)}
        label={t('constraints.description')}
        className="mb-2"
      />{' '}
      {hasErrorMessage && (
        <div className="mb-2">
          <InternationalizationInput
            name={getNameWithNamePrefix('errors', namePrefix)}
            label={t('constraints.errorMessage')}
            placeholder={t('constraints.errorMessagePlaceholder')}
          />
        </div>
      )}
    </div>
  );
}
