import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { DAMAGE_GUARANTEE_DEFAULT } from 'src/utils/defaultsSupplier';
import DamageGuaranteeForm from '../DamageGuaranteeForm';

export default function DamageGuarantee(props) {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={`${namePrefix}.damageGuarantees`}
      translationPrefix={'damageGuarantee'}
      Form={DamageGuaranteeForm}
      defaultValues={DAMAGE_GUARANTEE_DEFAULT}
      fieldsNames={[]}
    />
  );
}

DamageGuarantee.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
