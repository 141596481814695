import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const DeleteButton = ({ canDelete, onDelete }) => {
  if (!canDelete) {
    return <></>;
  }

  return (
    <Button
      data-testid="delete-button"
      htmlType="button"
      onClick={onDelete}
      className="inline-flex items-center justify-center bg-white"
      size="middle"
      shape="circle"
      icon={<DeleteOutlined className="text-xs" />}
    />
  );
};
