import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import AdditionalAttributesWithConstraintsForm from '../AdditionalAttributesWithConstraintsForm';
import { ADDITIONAL_ATTRIBUTE_WITH_CONSTRAINTS_DEFAULT } from '../../../../../utils/defaultsSupplier';

const AdditionalAttributesWithConstraints = (props) => {
  const { namePrefix } = props;

  return (
    <ArrayAccordion
      name={
        namePrefix
          ? `${namePrefix}.additionalAttributeList`
          : 'additionalAttributeList'
      }
      translationPrefix="additionalAttributes"
      Form={AdditionalAttributesWithConstraintsForm}
      defaultValues={ADDITIONAL_ATTRIBUTE_WITH_CONSTRAINTS_DEFAULT}
      fieldsNames={['name']}
    />
  );
};

AdditionalAttributesWithConstraints.propTypes = {
  namePrefix: PropTypes.string,
};

export default AdditionalAttributesWithConstraints;
