import { familyMappers } from 'src/utils/familyMappers';
import { mapDateToData } from '../dateMapper';
import {
  mapDataToInternationalisation,
  mapDataToDocumentAttachmentList,
  mapDocumentAttachmentListToData,
  mapInternationalisationToData,
} from '../mapperHelpers';
import {
  mapAdditionalAttributesToData,
  mapDataToAdditionAttributes,
} from '../additionalAttributesMapper';

const mapDataToProductGenericField = (data) => {
  const additionalAttributes = mapDataToAdditionAttributes(
    data.additionalAttributes,
  );
  return {
    functionalId: data.functionalId,
    provider: data.provider,
    imageLink: data.imageLink,
    label: mapDataToInternationalisation(data.name),
    descriptions: mapDataToInternationalisation(data.description),
    active: !!data.active,
    salable: true,
    version: 'draft',
    family: data.family,
    groupList: data.groups,
    canals: data.canals,
    sellingModes: data.sellingModes,
    activationStartDate: data.activationStartDate,
    activationEndDate: data.activationEndDate,
    periodAmount: data.periodAmount,
    periodValidity: data.periodValidity,
    renewable: data.renewable,
    tagList: data.tagList,
    additionalAttributes,
    documentAttachmentList: mapDataToDocumentAttachmentList(
      data.documentAttachmentList,
    ),
    details: data.details?.map((elem) => mapDataToInternationalisation(elem)),
    developedDetailList: mapDataToDevelopedDetailList(data.developedDetailList),
  };
};

const mapProductGenericFieldsToData = (product) => {
  const additionalAttributes = mapAdditionalAttributesToData(
    product.additionalAttributes,
  );
  return {
    functionalId: product.functionalId,
    name: mapInternationalisationToData(product.label),
    description: mapInternationalisationToData(product.descriptions),
    family: product.family,
    provider: product.provider,
    groups: product.groupList,
    imageLink: product.imageLink,
    canals: product.canals,
    sellingModes: product.sellingModes,
    active: product.active,
    activationStartDate: mapDateToData(product.activationStartDate),
    activationEndDate: mapDateToData(product.activationEndDate),
    periodAmount: product.periodAmount,
    periodValidity: product.periodValidity,
    renewable: product.renewable,
    tagList: product.tagList,
    additionalAttributes,
    documentAttachmentList: mapDocumentAttachmentListToData(
      product.documentAttachmentList,
    ),
    details: product.details?.map((elem) =>
      mapInternationalisationToData(elem),
    ),
    developedDetailList: mapDevelopedDetailListToData(
      product.developedDetailList,
    ),
  };
};

export const mapProductToData = (product) => {
  if (!product) {
    return;
  }

  if (product.formSchema?.id) {
    return {
      ...mapProductGenericFieldsToData(product),
      ...familyMappers.CUSTOMIZED_GENERIC_PRODUCT.mapToData(product),
    };
  }

  return {
    ...mapProductGenericFieldsToData(product),
    ...(familyMappers[product.family]
      ? familyMappers[product.family].mapToData(product)
      : familyMappers['GENERIC_PRODUCT'].mapToData(product)),
  };
};

export const mapDataToProduct = (data) => {
  if (!data) {
    return;
  }

  if (data.formSchema) {
    return {
      ...mapDataToProductGenericField(data),
      ...familyMappers.CUSTOMIZED_GENERIC_PRODUCT.mapFromData(data),
    };
  }

  return {
    ...mapDataToProductGenericField(data),
    ...(familyMappers[data.family]
      ? familyMappers[data.family].mapFromData(data)
      : familyMappers['GENERIC_PRODUCT'].mapFromData(data)),
  };
};

const mapDevelopedDetailListToData = (developedDetailList) => {
  return (developedDetailList || []).map((elem) => ({
    functionalId: elem.functionalId,
    descriptions: mapInternationalisationToData(elem.descriptions),
    titles: mapInternationalisationToData(elem.titles),
    documentAttachmentList: mapDocumentAttachmentListToData(
      elem.documentAttachmentList,
    ),
    priority: elem.priority,
    details: elem.details?.map((detail) =>
      mapInternationalisationToData(detail),
    ),
  }));
};

const mapDataToDevelopedDetailList = (data) => {
  return (data || []).map((elem) => ({
    functionalId: elem.functionalId,
    descriptions: mapDataToInternationalisation(elem.descriptions),
    titles: mapDataToInternationalisation(elem.titles),
    documentAttachmentList: mapDataToDocumentAttachmentList(
      elem.documentAttachmentList,
    ),
    priority: elem.priority,
    details: elem.details?.map((detail) =>
      mapDataToInternationalisation(detail),
    ),
  }));
};
