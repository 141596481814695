import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loading from 'src/components/generic/Loading';
import {
  cancelEventPricingPublication,
  createEventPricingDraft,
  getEventPricingById,
  getEventPricingVersions,
  partiallyPublishEventPricingDraft,
  publishEventPricingDraft,
  scheduleEventPricingPublication,
  unpublishEventPricingDraft,
  updateEventPricing,
  updateEventPricingPublicationConstraints,
} from '../../../api/eventPricing';
import EventPricingForm from '../../../components/eventPricing/EventPricingForm';
import { mapDataToEventPricing } from '../../../utils/mappers/eventPricingMapper';

import { useQuery } from 'react-query';
import { Error500 } from 'src/pages/error';
import CardDisplay from 'src/components/generic/CardDisplay';
import { EVENT_PRICING_BASE_PATH } from 'src/routes/paths';
import VersioningButtons from 'src/components/generic/versioning/VersioningButtons';
import { useUpdateMutation } from '../../../hooks/normalHooks/mutation/useUpdateMutation';
import { usePublishMutation } from 'src/hooks/normalHooks/mutation/usePublishMutation';
import { RESOURCES } from 'src/utils/constants';
import { usePartiallyPublishMutation } from 'src/hooks/normalHooks/mutation/usePartiallyPublishMutation';
import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { useSchedulePublicationMutation } from 'src/hooks/normalHooks/mutation/useSchedulePublicationMutation';
import { useCancelSchedulingMutation } from 'src/hooks/normalHooks/mutation/useCancelSchedulingMutation';

export default function EventPricingUpdatePage() {
  const { eventPricingId } = useParams();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const { t } = useTranslation();
  const queryKey = ['get-event-pricing', eventPricingId];
  const { closeModal } = useModalContext();

  const {
    data: eventPricing,
    isLoading,
    isError,
  } = useQuery(queryKey, () => getEventPricingById(eventPricingId));

  const eventPricingUpdateMutation = useUpdateMutation({
    update: (eventPricing) => updateEventPricing(eventPricing, eventPricingId),
    basePath: EVENT_PRICING_BASE_PATH,
    queryKey: queryKey,
    translationPrefix: 'eventPricing',
    onSettled: () => setSubmitDisabled(false),
  });

  const eventPricingPublishMutation = usePublishMutation({
    publish: () => publishEventPricingDraft(eventPricingId),
    basePath: EVENT_PRICING_BASE_PATH,
    queryKeysToInvalidate: [queryKey, getEventPricingVersions.name],
    translationPrefix: 'eventPricing',
  });

  const eventPricingPartiallyPublishMutation = usePartiallyPublishMutation({
    partiallyPublish: ({ functionalId, publicationConstraints }) =>
      partiallyPublishEventPricingDraft(functionalId, publicationConstraints),
    basePath: EVENT_PRICING_BASE_PATH,
    queryKeysToInvalidate: [queryKey, getEventPricingVersions.name],
    translationPrefix: 'eventPricing',
  });

  const publicationConstraintsUpdateMutation = useUpdateMutation({
    update: ({ functionalId, publicationConstraints }) =>
      updateEventPricingPublicationConstraints(
        functionalId,
        publicationConstraints,
      ),
    basePath: EVENT_PRICING_BASE_PATH,
    queryKey: queryKey,
    translationPrefix: 'eventPricing.publicationConstraints',
    onSettled: () => closeModal(),
  });

  const eventPricingSchedulePublicationMutation =
    useSchedulePublicationMutation({
      schedulePublication: ({ functionalId, publicationDate }) =>
        scheduleEventPricingPublication(functionalId, publicationDate),
      basePath: EVENT_PRICING_BASE_PATH,
      queryKeysToInvalidate: [queryKey, getEventPricingVersions.name],
      translationPrefix: 'eventPricing',
    });

  const eventPricingCancelPublicationMutation = useCancelSchedulingMutation({
    cancelScheduling: (functionalId) =>
      cancelEventPricingPublication(functionalId),
    basePath: EVENT_PRICING_BASE_PATH,
    queryKeysToInvalidate: [queryKey, getEventPricingVersions.name],
    translationPrefix: 'eventPricing',
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    const mappedEventPricing = mapDataToEventPricing(data);
    eventPricingUpdateMutation.mutate(mappedEventPricing);
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error500 />;

  return (
    <div className="p-3">
      <CardDisplay title={t('eventPricing.update')} titleCentered>
        <EventPricingForm
          key={eventPricing?.id}
          submitDisabled={submitDisabled}
          onSubmit={onSubmit}
          formDisabled={eventPricing.version !== 'draft'}
          eventPricing={eventPricing}
          isUpdateForm={true}
          additionalButtons={
            <VersioningButtons
              element={eventPricing}
              createDraft={createEventPricingDraft}
              publishDraft={eventPricingPublishMutation.mutate}
              schedulePublication={
                eventPricingSchedulePublicationMutation.mutate
              }
              unpublishDraft={unpublishEventPricingDraft}
              partiallyPublishDraft={
                eventPricingPartiallyPublishMutation.mutate
              }
              updatePublicationConstraints={
                publicationConstraintsUpdateMutation.mutate
              }
              cancelSchedulingQuery={
                eventPricingCancelPublicationMutation.mutate
              }
              updateUrl="/event-pricings/update/"
              resource={RESOURCES.PRICINGS}
            />
          }
        />
      </CardDisplay>
    </div>
  );
}
