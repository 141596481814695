import { mapDataToDiscounts, mapDiscountsToData } from '../discountMapper';
import {
  mapDataToInternationalisation,
  mapDataToTargets,
  mapInternationalisationToData,
  mapTargetsToData,
} from '../mapperHelpers/mapperHelpers';
import { mapDataToPrices, mapPricesToData } from '../pricingMapper';
import {
  mapDataToPrepaidPeriod,
  mapPrepaidPeriodListToData,
} from 'src/utils/mappers/prepaidPeriodMapper';

const mapCommissionToData = (commission) => {
  return {
    functionalId: commission.functionalId,
    label: mapInternationalisationToData(commission.label),
    pricingType: commission.pricingType,
    commissionType: commission.commissionType,
    periodicity: commission.periodicity,
    tva: commission.tva,
    required: commission.required,
    standardPricing: mapPricesToData(
      commission.pricingList.filter((price) => price.standard),
    ),
    preferentialPricing: mapPricesToData(
      commission.pricingList.filter((price) => !price.standard),
    ),

    discountList: mapDiscountsToData(commission.discountList),
    commissionGroup: commission.commissionGroup,
    sens: commission.sens,
    prepaidPeriodList: mapPrepaidPeriodListToData(commission.prepaidPeriodList),
  };
};

const mapDataToCommission = (data) => {
  return {
    functionalId: data.functionalId,
    label: mapDataToInternationalisation(data.label),
    pricingType: data.pricingType,
    commissionType: data.commissionType,
    periodicity: data.periodicity,
    tva: data.tva,
    required: data.required,
    pricingList: [
      ...mapDataToPrices(data.standardPricing, true),
      ...mapDataToPrices(data.preferentialPricing, false),
    ],
    discountList: mapDataToDiscounts(data.discountList),
    commissionGroup: data.commissionGroup,
    sens: data.sens,
    prepaidPeriodList: mapDataToPrepaidPeriod(data.prepaidPeriodList),
  };
};
export const mapEventPricingToData = (eventPricing) => {
  return {
    functionalId: eventPricing.functionalId,
    targetType: eventPricing.targetType,
    products: [],
    families: [],
    groups: [],
    descriptions: mapInternationalisationToData(eventPricing.descriptions),
    ...mapTargetsToData(eventPricing),
    act: eventPricing.act,
    tags: eventPricing.tags,
    commissions: eventPricing.commissions.map(mapCommissionToData),
    preProcessors: eventPricing?.preProcessors.map((p, i) => ({
      ...p,
      order: i,
    })),
    postProcessors: eventPricing?.postProcessors.map((p, i) => ({
      ...p,
      order: i,
    })),
  };
};

export const mapDataToEventPricing = (data) => {
  return {
    functionalId: data.functionalId,
    targetType: data.targetType,
    targets: mapDataToTargets(data),
    act: data.act,
    descriptions: mapDataToInternationalisation(data.descriptions),
    tags: data.tags,
    commissions: data.commissions.map(mapDataToCommission),
    preProcessors: data?.preProcessors.map((processor, i) => ({
      ...processor,
      order: i,
    })),
    postProcessors: data?.postProcessors.map((processor, i) => ({
      ...processor,
      order: i,
    })),
  };
};
