import CardDisplay from 'src/components/generic/CardDisplay';
import { useTranslation } from 'react-i18next';
import AdditionalAttribute from '../../generic/AdditionalAttribute';
import ConventionCardFormula from '../ConventionCardFormula';

export default function ConventionCard() {
  const { t } = useTranslation();

  return (
    <CardDisplay title={t('conventionCard.info')}>
      <ConventionCardFormula />
      <AdditionalAttribute />
    </CardDisplay>
  );
}
