import CardDisplay from 'src/components/generic/CardDisplay';
import { useTranslation } from 'react-i18next';
import AdditionalAttribute from '../../generic/AdditionalAttribute';
import AssistanceOption from '../AssistanceOption';

export default function Assistance() {
  const { t } = useTranslation();

  return (
    <CardDisplay title={t('assistance.info')}>
      <AssistanceOption />
      <AdditionalAttribute />
    </CardDisplay>
  );
}
