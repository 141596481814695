import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';

export const ApiLocator = ({ routes = [] }) => {
  const location = useLocation();

  const { setCurrentApi } = useUserContext();

  useEffect(() => {
    const matchingRoute = routes.find(
      ({ path }) => !!matchPath(path, location.pathname),
    );

    if (matchingRoute && matchingRoute?.targetApi) {
      setCurrentApi(matchingRoute.targetApi);
    }
  }, [location.pathname, routes.length]);

  return null;
};
