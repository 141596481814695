import SelectProductSimulation from 'src/components/simulation/SelectProductSimulation/SelectProductSimulation';
import SimulationFacts from '../../SimulationFacts/SimulationFacts';
import { FactsProvider } from '../../context';
import PricingSimulationFactsList from '../PricingSimulationFactsList';
import PricingSimulationResult from '../PricingSimulationResult';

export default function PricingSimulationForm() {
  return (
    <div className="p-5">
      <FactsProvider>
        <SelectProductSimulation />
        <SimulationFacts FactsDisplayer={PricingSimulationFactsList} />
        <PricingSimulationResult />
      </FactsProvider>
    </div>
  );
}
