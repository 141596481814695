import { useMutation, useQueryClient } from 'react-query';
import { useShowMessage } from '../../useShowMessage';
import { useTranslation } from 'react-i18next';

export const useCancelSchedulingMutation = ({
  cancelScheduling,
  queryKeys,
  translationPrefix,
  onSettled,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showSuccess, showError } = useShowMessage();

  return useMutation(cancelScheduling, {
    onSuccess: onSuccess
      ? onSuccess
      : () => {
          queryClient.invalidateQueries(queryKeys);
          showSuccess(t(`${translationPrefix}.canceled`));
        },
    onError: (err) => {
      showError(err);
    },
    onSettled,
  });
};
