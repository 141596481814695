import { useEffect, useState } from 'react';
import UUIDClass from 'uuidjs';
import { getDownloadUrl, getImageUploadUrl, uploadFile } from '../../../../api';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const FileUpload = ({ name, valueAsUrlOnly = false }) => {
  const { setValue, clearErrors, control } = useFormContext();
  const [file, setFile] = useState(null);
  const existingFile = useWatch({
    control,
    name,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (existingFile) {
      if (valueAsUrlOnly) {
        setFile({
          uid: '-1',
          name: 'File',
          status: 'done',
          url: existingFile,
        });
      } else {
        setFile({
          uid: '-1',
          name: existingFile.fileMetaData.filename,
          status: 'done',
          url: existingFile.url,
        });
      }
    }
  }, [existingFile, valueAsUrlOnly]);

  const setFieldValue = (name, file) => {
    if (!file || !valueAsUrlOnly) {
      setValue(name, file);
      return;
    }

    setValue(name, file.url);
  };

  const props = {
    async onChange(info) {
      const { file } = info;
      const fullFileName = file.name;
      const extension = fullFileName.substring(fullFileName.lastIndexOf('.'));
      const funcId = UUIDClass.generate();
      const fileName = fullFileName.substring(0, fullFileName.lastIndexOf('.'));
      const uploadurl = await getImageUploadUrl(
        fileName + funcId + extension,
        extension,
        funcId,
      );
      await uploadFile(uploadurl.preSignedUrl, file);
      const url = await getDownloadUrl(uploadurl.metaDataId, funcId);
      const fileInfo = {
        fileMetaData: {
          filename: fullFileName,
          extension,
          type: file.type,
          size: file.size,
        },
        url,
      };
      clearErrors(name);
      setFieldValue(name, fileInfo);
    },
  };

  const handleRemove = () => {
    setFieldValue(name, null);
    setFile(null);
    return false;
  };

  return (
    <div className="mb-3">
      <Upload
        {...props}
        onRemove={handleRemove}
        beforeUpload={() => false}
        maxCount={1}
        fileList={file ? [file] : []}
      >
        <Button icon={<UploadOutlined />}>{t('fileUpload.upload')}</Button>
      </Upload>
    </div>
  );
};

export default FileUpload;
