import React from 'react';
import { FormComponentType } from 'src/pages/formSchemas/types';
import FlatInternationalizationInput from 'src/components/generic/inputs/FlatInternationalizationInput';
import FlatInternationalizationArrayInput from 'src/components/generic/inputs/FlatInternationalizationArrayInput';

export const FormComponent: FormComponentType = ({ field, namePrefix }) => {
  const name = `${namePrefix ?? ''}${field.name ?? ''}`;

  if (field.config?.multiple === true) {
    return (
      <FlatInternationalizationArrayInput
        label={field.label as string}
        name={name}
        required={field.config?.required}
      />
    );
  }

  return (
    <FlatInternationalizationInput
      label={field.label as string}
      name={name}
      required={field.config?.required}
    />
  );
};
