import { FormSchemaAdminDto, IMap } from 'src/types';
import Form from 'src/components/generic/Form';
import React, { FunctionComponent, useMemo } from 'react';
import {
  getFormComponent,
  getFormDefaultValue,
} from 'src/pages/formSchemas/utils/form';
import { OptionalObjectSchema } from 'yup/lib/object';
import { AnyObject } from 'yup/lib/types';

interface FormSchemaParserContainerProps {
  schema: FormSchemaAdminDto;
  withForm?: boolean;
  namePrefix?: string;
  onSubmit?: (data: IMap<string, any>) => void;
  validationSchema?: OptionalObjectSchema<any, AnyObject, any>;
}

export default function FormSchemaParserContainer({
  schema,
  withForm,
  namePrefix,
  onSubmit,
  validationSchema,
}: Readonly<FormSchemaParserContainerProps>) {
  const defaultValues = useMemo(() => getFormDefaultValue(schema), [schema]);

  const FormWrapper: FunctionComponent<{ children: React.ReactNode }> =
    useMemo(() => {
      if (withForm) {
        return ({ children }) => (
          <Form
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            defaultValues={defaultValues}
          >
            {children}
          </Form>
        );
      }

      return ({ children }) => <>{children}</>;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [withForm, defaultValues]);

  return (
    <FormWrapper>
      <div className="space-y-4 mb-4">
        {schema.fields.map((field, i) => {
          const component = getFormComponent({ field, namePrefix });
          return (
            <React.Fragment key={field.type + i}>{component}</React.Fragment>
          );
        })}
      </div>
    </FormWrapper>
  );
}
