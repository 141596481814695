import { useTranslation } from 'react-i18next';
import { ScriptDryRunForm } from './ScriptDryRunForm';
import { Collapse } from 'antd';

export const DryRunSection = ({ onExecute }) => {
  const { t } = useTranslation();

  return (
    <Collapse>
      <Collapse.Panel header={t('scriptConstraint.dryRun')} key="1">
        <ScriptDryRunForm onSubmit={onExecute} />
      </Collapse.Panel>
    </Collapse>
  );
};
