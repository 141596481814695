import { v4 as uuid } from 'uuid';

const factsProcessors = {
  LIST: (conditionValue) => conditionValue.join('-'),
  NOT_IN_LIST: (conditionValue) => conditionValue.join('-'),
  RANGE: ({ min, max }) => `[${min ?? ' '}, ${max ?? ' '}]`,
  RANGES: (conditionValue) =>
    conditionValue
      .filter(({ min, max }) => min !== undefined && max !== undefined)
      .map(({ min, max }) => `[${min}, ${max}]`)
      .join(', '),
  STRING: (conditionValue) => conditionValue,
};

const processValueType = (type, conditionValue) => {
  return (factsProcessors[type] || factsProcessors.STRING)(conditionValue);
};

const processConstraint = (constraint) => {
  if (constraint.type.includes('composite')) {
    return [
      ...processConstraint(constraint.left),
      ...processConstraint(constraint.right),
    ];
  }

  let factNames = constraint.factNames;
  if (!constraint.factNames?.length)
    factNames = [
      {
        name: '-',
        type: 'STRING',
        conditionValue: '-',
      },
    ];

  return factNames
    .map(({ name, type, conditionValue }) => {
      const values = processValueType(type, conditionValue);
      return values
        ? {
            key: uuid(),
            name,
            values,
            label: constraint.label,
            description: constraint.description,
          }
        : null;
    })
    .filter(Boolean);
};

export const extractConstraintsInfo = (constraints) => {
  return constraints.flatMap((constraint) => {
    return processConstraint(constraint);
  });
};
