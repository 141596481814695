import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { CatalogStatisticsTab } from 'src/pages/administration/auditStatistics/CatalogStatisticsTab';
import { PricingStatisticsTab } from 'src/pages/administration/auditStatistics/PricingStatisticsTab';
import { isAuthorized } from 'src/utils/authUtils';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { API_NAMES, USER_ROLES } from 'src/utils/constants';
import { FactsStatisticsTab } from 'src/pages/administration/auditStatistics/FactsStatisticsTab';

function AuditStatisticsBasePage() {
  const { t } = useTranslation();
  const { userContext } = useUserContext();
  const tabs = [
    {
      key: '1',
      label: t('navbar.catalog'),
      children: <CatalogStatisticsTab />,
      isVisible: isAuthorized(userContext[API_NAMES.CATALOG], [
        USER_ROLES.TECHNICAL_ADMIN,
      ]),
    },
    {
      key: '2',
      label: t('navbar.eventPricings'),
      children: <PricingStatisticsTab />,
      isVisible: isAuthorized(userContext[API_NAMES.EVENT_PRICING], [
        USER_ROLES.TECHNICAL_ADMIN,
      ]),
    },
    {
      key: '3',
      label: t('navbar.facts'),
      children: <FactsStatisticsTab />,
      isVisible: isAuthorized(userContext[API_NAMES.FACTS], [
        USER_ROLES.TECHNICAL_ADMIN,
      ]),
    },
  ];

  return (
    <>
      <Tabs
        defaultActiveKey={tabs[0].key}
        items={tabs.filter((tab) => tab.isVisible)}
      />
    </>
  );
}

export { AuditStatisticsBasePage };
