import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import { MessageForm } from 'src/components/eligibleAct/form/MessageForm';

function MessageContainer({ namePrefix }) {
  return (
    <ArrayAccordion
      name={namePrefix ? `${namePrefix}.messageList` : 'messageList'}
      translationPrefix="messageList"
      Form={MessageForm}
      defaultValues={{}}
      fieldsNames={['functionalId', 'type']}
    />
  );
}

export { MessageContainer };
