import { API_NAMES } from 'src/utils/constants/ApiNames';

export const ActionType = {
  usageProduct: 'usage/product',
  usageEligibility: 'usage/eligibility',
  usagePricing: 'usage/pricing',
  usageFacts: 'usage/facts',
};

export const AuditableAction = {
  [API_NAMES.CATALOG]: {
    [ActionType.usageProduct]: {
      GET_PRODUCTS_BY_FAMILY: 'GET_PRODUCTS_BY_FAMILY',
      GET_PRODUCT: 'GET_PRODUCT',
    },
    [ActionType.usageEligibility]: {
      IS_ELIGIBLE_BY_ACT_AND_PRODUCT: 'IS_ELIGIBLE_BY_ACT_AND_PRODUCT',
      GET_ELIGIBLE_ACTS_FOR_PRODUCT_BY_ID:
        'GET_ELIGIBLE_ACTS_FOR_PRODUCT_BY_ID',
      GET_ELIGIBLE_ACT_FOR_PRODUCT_BY_ID: 'GET_ELIGIBLE_ACT_FOR_PRODUCT_BY_ID',
    },
  },
  [API_NAMES.EVENT_PRICING]: {
    [ActionType.usagePricing]: {
      GET_PRICES: 'GET_PRICES',
    },
  },
  [API_NAMES.FACTS]: {
    [ActionType.usageFacts]: {
      GET_FACTS: 'GET_FACTS',
    },
  },
};
