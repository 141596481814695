import * as yup from 'yup';
import i18n from 'src/i18n';

const REQUIRED_STRING = yup
  .string()
  .required(i18n.t('validator.scheduleDateMustBePicked'))
  .nullable();

export const DATE_SCHEDULING_SCHEMA = yup
  .object({
    publicationDate: REQUIRED_STRING,
  })
  .required();
