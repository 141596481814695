import PropTypes from 'prop-types';
import ArrayAccordion from 'src/components/generic/ArrayAccordion';
import IncomeAndNatureForm from '../IncomeAndNatureForm';
import { INCOME_AND_CATEGORY_DEFAULT } from 'src/utils/defaultsSupplier';
export default function IncomeAndNature(props) {
  const { namePrefix } = props;
  return (
    <ArrayAccordion
      name={`${namePrefix}`}
      translationPrefix="incomeNature"
      Form={IncomeAndNatureForm}
      defaultValues={INCOME_AND_CATEGORY_DEFAULT}
      fieldsNames={[]}
    />
  );
}

IncomeAndNature.propTypes = {
  namePrefix: PropTypes.string.isRequired,
};
