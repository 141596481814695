import Bancass from 'src/components/product/form/bancass/Bancass';
import BankCard from 'src/components/product/form/bankCard/BankCard';
import BankOnline from 'src/components/product/form/bankOnline/BankOnline';
import Dotation from 'src/components/product/form/Dotation';
import Exemption from 'src/components/product/form/exemption/Exemption';
import GenericProduct from 'src/components/product/form/GenericProduct';
import Assistance from 'src/components/product/form/assistance/Assistance';
import OfferInfo from 'src/components/product/form/offer/OfferInfo/OfferInfo';
import SavingInfo from 'src/components/product/form/saving/SavingInfo';
import Damage from 'src/components/product/form/Damage/Damage';
import ServiceInfo from '../components/product/form/service/serviceInfo';
import ConventionCard from 'src/components/product/form/conventionCard/ConventionCard';

import {
  mapDataToExemption,
  mapExemptionToData,
} from 'src/utils/mappers/productMapper/exemptionMapper';
import {
  mapBancassToData,
  mapDataToBancass,
} from './mappers/productMapper/bancassMapper';
import {
  mapBankOnlineToData,
  mapDataToBankOnline,
} from './mappers/productMapper/bankOnlineMapper';
import {
  mapCardToData,
  mapDataToCard,
} from './mappers/productMapper/cardMapper';
import {
  mapDataToDotation,
  mapDotationToData,
} from './mappers/productMapper/dotationMapper';
import {
  mapDataToGenericProduct,
  mapGenericProductToData,
} from './mappers/productMapper/genericProductMapper/genericProductMapper';
import {
  mapAssistanceToData,
  mapDataToAssistance,
} from './mappers/productMapper/assistanceMapper/assistanceMapper';
import {
  mapDataToOffer,
  mapOfferToData,
} from './mappers/productMapper/offerMapper/offerMapper';
import {
  mapDataToSaving,
  mapSavingToData,
} from './mappers/productMapper/savingMapper';
import {
  mapDataToDamage,
  mapDamageToData,
} from './mappers/productMapper/damageMapper';
import {
  mapDataToService,
  mapServiceToData,
} from 'src/utils/mappers/productMapper/serviceMapper';
import {
  mapDataToCustomizedGenericProduct,
  mapCustomizedGenericProductToData,
} from './mappers/productMapper/customizedGenericProductMapper';
import {
  mapDataToConventionCard,
  mapConventionCardToData,
} from './mappers/productMapper/conventionCardMapper';

export const familyMappers = {
  MONETIQUE: {
    mapFromData: mapDataToCard,
    mapToData: mapCardToData,
    component: <BankCard />,
  },
  ASSURANCE: {
    mapFromData: mapDataToBancass,
    mapToData: mapBancassToData,
    component: <Bancass />,
  },

  DOTATION: {
    mapFromData: mapDataToDotation,
    mapToData: mapDotationToData,
    component: <Dotation />,
  },
  EPARGNE: {
    mapFromData: mapDataToSaving,
    mapToData: mapSavingToData,
    component: <SavingInfo />,
  },
  BANK_ONLINE: {
    mapFromData: mapDataToBankOnline,
    mapToData: mapBankOnlineToData,
    component: <BankOnline />,
  },
  OFFER: {
    mapFromData: mapDataToOffer,
    mapToData: mapOfferToData,
    component: <OfferInfo />,
  },
  GENERIC_PRODUCT: {
    mapFromData: mapDataToGenericProduct,
    mapToData: mapGenericProductToData,
    component: <GenericProduct />,
  },

  ASSISTANCE: {
    mapFromData: mapDataToAssistance,
    mapToData: mapAssistanceToData,
    component: <Assistance />,
  },

  EXEMPTION: {
    mapFromData: mapDataToExemption,
    mapToData: mapExemptionToData,
    component: <Exemption />,
  },
  DAMAGE: {
    mapFromData: mapDataToDamage,
    mapToData: mapDamageToData,
    component: <Damage />,
  },
  SERVICE: {
    mapFromData: mapDataToService,
    mapToData: mapServiceToData,
    component: <ServiceInfo />,
  },
  CUSTOMIZED_GENERIC_PRODUCT: {
    mapFromData: mapDataToCustomizedGenericProduct,
    mapToData: mapCustomizedGenericProductToData,
    component: <></>,
  },
  CONVENTION_CARD: {
    mapFromData: mapDataToConventionCard,
    mapToData: mapConventionCardToData,
    component: <ConventionCard />,
  },
};
