import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import React from 'react';
import { USER_ROLES } from 'src/utils/constants';

function MenuItemWrapper({ componentRender, apis, requiredRoles }) {
  const { props, type: Component } = componentRender();
  const { userContext } = useUserContext();

  const allRoles = apis?.flatMap((api) => userContext[api]?.roles);

  const featureEnabledCheck = true;

  const authorizationCheck =
    !requiredRoles ||
    requiredRoles?.length === 0 ||
    allRoles?.includes(USER_ROLES.SUPER_ADMIN) ||
    allRoles?.find((role) => requiredRoles?.includes(role));

  const isVisible = Boolean(featureEnabledCheck && authorizationCheck);

  return <Component {...props} isVisible={isVisible} />;
}

export { MenuItemWrapper };
