import { filterStringArrayByPrefix } from 'src/utils/arrayUtils';
import { useUserContext } from 'src/hooks/normalHooks/context/useUserContext';
import { useEffect } from 'react';
import { showAlert } from 'src/redux/slices/alertSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useCheckSuperAdminRole } from 'src/utils/authUtils';
import { useTranslation } from 'react-i18next';

const ALERT_MESSAGE_RESOLVER = [
  {
    handler: ({ missingRoles }) => {
      return filterStringArrayByPrefix(missingRoles, 'EDIT').length > 0;
    },
    msgKey: 'roles.edit',
  },
  {
    handler: ({ missingRoles }) => {
      return (
        filterStringArrayByPrefix(missingRoles, 'PARTIALLY_PUBLISH').length > 0
      );
    },
    msgKey: 'roles.partially_publish',
  },
  {
    handler: ({ missingRoles }) => {
      return filterStringArrayByPrefix(missingRoles, 'PUBLISH').length > 0;
    },
    msgKey: 'roles.publish',
  },
];

export const AuthorizationAlertDispatcher = ({ missingRoles }) => {
  const { userContext, currentApi, isLoading } = useUserContext();
  const dispatch = useDispatch();
  const path = useLocation().pathname;
  const { isSuperAdmin } = useCheckSuperAdminRole();
  const { t } = useTranslation();
  const getAlertMessages = (missingRoles) => {
    return ALERT_MESSAGE_RESOLVER.reduce((acc, item) => {
      const handler = item.handler({ missingRoles, userContext, currentApi });
      if (handler) {
        acc.push(t(item.msgKey));
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!isSuperAdmin && missingRoles.length > 0) {
      const alertMessages = getAlertMessages(missingRoles);
      if (alertMessages.length > 0) {
        const message =
          t('authorization.dontHaveRole') +
          ' ' +
          alertMessages.reduce((role1, role2) => role1.concat(', ' + role2)) +
          '.';
        dispatch(showAlert({ message }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missingRoles, path]);
};
