import clsx from 'clsx';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AND, COMPOUND_CONSTRAINT_TYPES, OR } from 'src/constants/constraints';
import {
  resolveBadgeColor,
  resolvedFillColor,
} from 'src/components/constraints/builder/utils';
import { InlineConstraintAddForm } from 'src/components/constraints/InlineConstraintAddForm';
import { ConstraintComponent } from './ConstraintComponent';
import { DeleteButton } from './DeleteButton';

export const ConstraintCombinerWrapper = ({
  type,
  constraint,
  namePrefix,
  readOnly,
}) => {
  const [focused, setFocused] = useState(false);

  const { t } = useTranslation();
  const { fields, append, remove, update } = useFieldArray({
    name: namePrefix,
    keyName: 'fieldId',
  });

  // Handlers
  const handleFocus = (event) => {
    event.stopPropagation();
    setFocused((prev) => !prev);
  };

  // Computed
  const isRoot =
    Array.isArray(constraint.constraints) &&
    Array.isArray(constraint.constraints[0]);
  const constraints = constraint?.constraints ?? [];
  const hasOnlyBaseComponents = constraints.every(
    (constraint) =>
      constraint.type !== COMPOUND_CONSTRAINT_TYPES.OR &&
      constraint.type !== COMPOUND_CONSTRAINT_TYPES.AND,
  );

  const fillColor = resolvedFillColor(type);
  const canAdd = !readOnly;
  const hasMoreThanOneSubConstraint = fields.length > 1;
  return (
    <div
      className={clsx('w-full p-2 shadow-xs rounded-md', {
        'border-2 border-dashed': focused,
        'border-blue-400': type === AND && focused,
        'border-orange-400': type === OR && focused,
      })}
    >
      <div className="flex relative items-center flex-1">
        {hasMoreThanOneSubConstraint && (
          <>
            <div
              className={clsx(
                `h-full w-0.5 absolute inset-0 left-5 flex`,
                fillColor,
              )}
            />
            <div
              className={clsx(
                `w-4 h-0.5 absolute inset-0 left-5 flex`,
                fillColor,
              )}
            />
            <div
              className={clsx(
                `w-4 h-0.5 absolute bottom-0 left-5 flex`,
                fillColor,
              )}
            />
            <div className="relative" onClick={handleFocus}>
              <h2
                className={clsx(
                  'px-3 py-1 rounded-full text-xs z-10 cursor-pointer',
                  resolveBadgeColor(type),
                )}
              >
                {t(`constraints-builder.${type}`)}
              </h2>
            </div>
          </>
        )}

        <div
          className={clsx('flex-1 gap-2 flex flex-col', {
            'pl-2 flex-wrap': !isRoot && hasOnlyBaseComponents,
          })}
        >
          {fields.map((constraint, index) => {
            return (
              <div
                key={constraint.fieldId}
                className={clsx('flex flex-1', {
                  'pb-2 pl-1': !isRoot && !hasOnlyBaseComponents,
                })}
              >
                <ComponentResolver
                  constraint={constraint}
                  namePrefix={`${namePrefix}.${index}`}
                  readOnly={readOnly}
                  onUpdate={(newValue) => update(index, newValue)}
                  onRemove={() => remove(index)}
                />
              </div>
            );
          })}
          {canAdd && (
            <div className="flex justify-start flex-1">
              <InlineConstraintAddForm
                name={namePrefix}
                onAddSubmit={(constraint) => append(constraint)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ConstraintsMap = {
  [COMPOUND_CONSTRAINT_TYPES.OR]: {
    props: {
      type: OR,
    },
    component: ConstraintCombinerWrapper,
  },
  [COMPOUND_CONSTRAINT_TYPES.AND]: {
    props: {
      type: AND,
    },
    component: ConstraintCombinerWrapper,
  },
};

const ComponentResolver = ({
  constraint,
  namePrefix,
  readOnly,
  onUpdate,
  onRemove,
}) => {
  const data = constraint['constraints'] ?? constraint;
  const type = constraint['type'] ?? null;

  const { t } = useTranslation();
  const { register, getValues } = useFormContext();

  if (Array.isArray(data)) {
    const constraintTypeMap = {
      [COMPOUND_CONSTRAINT_TYPES.AND]: AND,
      [COMPOUND_CONSTRAINT_TYPES.OR]: OR,
    };
    const constraintType = constraintTypeMap[type];
    const canEdit = !!constraintType && !readOnly;
    const canDelete = !readOnly;
    return (
      <div className="flex flex-col gap-2 flex-1">
        {(canEdit || canDelete) && (
          <div className="flex items-center gap-1">
            {canEdit && (
              <input
                type="text"
                className="bg-transparent border rounded-md px-1.5"
                placeholder={t('constraints-builder.placeholder')}
                {...register(`${namePrefix}.label`)}
              />
            )}
            {canDelete && (
              <DeleteButton canDelete={canDelete} onDelete={onRemove} />
            )}
          </div>
        )}
        {!canEdit && (
          <span className="text-zinc-400">
            {getValues(`${namePrefix}.label`)}
          </span>
        )}
        <ConstraintCombinerWrapper
          type={constraintType ?? AND}
          namePrefix={`${namePrefix}.constraints`}
          constraint={{
            type: type ?? COMPOUND_CONSTRAINT_TYPES.AND,
            constraints: data,
          }}
          readOnly={readOnly}
          onDelete={onRemove}
        />
      </div>
    );
  }

  const result = ConstraintsMap[constraint.type];
  if (result) {
    return <></>;
  }
  return (
    <ConstraintComponent
      constraint={constraint}
      onUpdate={onUpdate}
      onDelete={onRemove}
      readOnly={readOnly}
    />
  );
};
