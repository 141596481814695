import React, { useEffect } from 'react';
import { Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from 'src/redux/slices/messageSlice/messageSlice';
import { useLocation } from 'react-router-dom';

const alertTypes = {
  success: 'success',
  error: 'error',
  info: 'info',
};

const MessageDisplayer = () => {
  const dispatch = useDispatch();
  const { type, message, visible } = useSelector((state) => state.message);

  const path = useLocation().pathname;
  useEffect(() => {
    if (!path.includes('/new') && !path.includes('/update'))
      dispatch(hideMessage());
  }, [path, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [message]);

  const onClose = (e) => {
    e.preventDefault();
    dispatch(hideMessage());
  };

  if (!visible) {
    return null;
  }

  return (
    <Alert
      className="m-5 p-5"
      message={type.toUpperCase()}
      description={message}
      type={alertTypes[type] || 'info'}
      showIcon
      closable
      onClose={onClose}
    />
  );
};

export default MessageDisplayer;
