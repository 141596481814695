import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { APP_BASE_PATH, HOME_BASE_PATH } from '../paths';
import { APP_ROUTES, MENU } from '../routes';
import { AppLayout } from 'src/components/layouts/AppLayout';
import ErrorPage from 'src/pages/error/GenericErrorPage';
import AuthorizationWrapper from 'src/components/wrapper/authorization/authorizationWrapper';

const renderRoutes = () => {
  return APP_ROUTES.map((route, index) => {
    const Component = route.component;
    const requiredRoles = route.requiredRoles;
    const apis = route.apis;
    return (
      <Route
        key={`${route.path}-${index}`}
        path={route.path}
        element={
          <AuthorizationWrapper
            requiredRoles={requiredRoles}
            apis={apis}
            renderOnUnauthorized={() => <ErrorPage status="403" />}
          >
            <Component />
          </AuthorizationWrapper>
        }
      />
    );
  });
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={APP_BASE_PATH} element={<AppLayout menu={MENU} />}>
        <Route index element={<Navigate replace to={HOME_BASE_PATH} />} />
        {renderRoutes()}
        <Route path="*" element={<Navigate replace to={APP_BASE_PATH} />} />
      </Route>
    </Routes>
  );
};
