import { mapDateToData } from '../../../utils/mappers/dateMapper';
import dayjs from 'dayjs';

export const mapFilterToParams = (page, size, filter = {}) => {
  const params = {};
  if (filter.label) {
    params.label = filter.label;
  }
  if (filter.targetType) {
    params.targetType = filter.targetType;
  }

  if (filter.family && filter.family.length > 0) {
    params.family = filter.family.join(',');
  }

  if (filter.excludedFamilies && filter.excludedFamilies.length > 0) {
    params.excludedFamilies = filter.excludedFamilies.join(',');
  }

  if (filter.provider && filter.provider.length > 0) {
    params.provider = filter.provider.join(',');
  }

  if (filter.acts && filter.acts.length > 0) {
    params.acts = filter.acts.join(',');
  }

  if (filter.periodValidity && filter.periodValidity.length > 0) {
    params.periodValidity = filter.periodValidity.join(',');
  }

  if (filter.groups && filter.groups.length > 0) {
    params.groups = filter.groups.join(',');
  }

  if (filter.tagList && filter.tagList.length > 0) {
    params.tagList = filter.tagList.join(',');
  }

  if (filter.sellingModes && filter.sellingModes.length > 0) {
    params.sellingModes = filter.sellingModes.join(',');
  }

  if (filter.canals && filter.canals.length > 0) {
    params.canals = filter.canals.join(',');
  }

  if (filter.version) {
    params.version = filter.version;
  }
  if (filter.constraintId) {
    params.constraintId = filter.constraintId;
  }

  if (filter.roles && filter.roles.length > 0) {
    params.roles = filter.roles.join(',');
  }

  if (filter.profile && filter.profile.length > 0) {
    params.profile = filter.profile;
  }

  if (filter.status && filter.status.length > 0) {
    params.status = filter.status[0];
  }

  return { ...params, page, size };
};

export const mapAuditToParams = (page, size, filter = {}) => {
  const params = {};
  if (filter.correlationId) {
    params.correlationId = filter.correlationId;
  }
  if (filter.requestId) {
    params.requestId = filter.requestId;
  }
  if (filter.clientId) {
    params.clientId = filter.clientId;
  }
  if (filter.productId) {
    params.productId = filter.productId;
  }
  if (filter.eventTypes && filter.eventTypes.length > 0) {
    params.eventTypes = filter.eventTypes.join(',');
  }
  if (filter.canals && filter.canals.length > 0) {
    params.canals = filter.canals.join(',');
  }
  if (filter.acts && filter.acts.length > 0) {
    params.acts = filter.acts.join(',');
  }
  if (filter.status) {
    params.status = filter.status;
  }
  if (filter.userName) {
    params.userName = filter.userName;
  }
  if (filter.matricule) {
    params.matricule = filter.matricule;
  }
  if (filter.startDate) {
    params.startDate = mapDateToData(filter.startDate).format();
  }
  if (filter.endDate) {
    params.endDate = mapDateToData(filter.endDate).format();
  }
  if (filter.userIp) {
    params.userIp = filter.userIp;
  }
  if (filter.roles && filter.roles.length > 0) {
    params.roles = filter.roles.join(',');
  }
  if (filter.profile && filter.profile.length > 0) {
    params.profile = filter.profile;
  }
  return { ...params, page, size };
};

export const mapParamsToFilter = (urlParams, paramMap, allowedKeys) => {
  const filterObj = {};
  Object.keys(paramMap).forEach((paramKey) => {
    const paramValue = urlParams.get(paramKey);
    if (paramValue) {
      if (allowedKeys.includes(paramKey)) {
        filterObj[paramMap[paramKey]] = paramValue.split(',');
      } else if (paramKey.includes('Date')) {
        filterObj[paramMap[paramKey]] = dayjs(paramValue);
      } else {
        filterObj[paramMap[paramKey]] = paramValue;
      }
    }
  });
  return filterObj;
};

export const mapFilterToUrl = (filter, paramMap) => {
  const queryParams = new URLSearchParams();
  Object.keys(paramMap).forEach((key) => {
    const value = filter[paramMap[key]];
    if (value) {
      if (Array.isArray(value)) {
        queryParams.set(key, value.join(','));
      } else if (key.includes('Date')) {
        queryParams.set(key, dayjs(value).format());
      } else {
        queryParams.set(key, value);
      }
    }
  });

  return queryParams.toString();
};
