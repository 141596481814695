import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createApisForRule } from 'src/api/rule';
import CardDisplay from 'src/components/generic/CardDisplay';
import RuleForm from 'src/components/rule/form/RuleForm';
import { useAddMutation } from 'src/hooks/normalHooks/mutation/useAddMutation';
import { RULE_BASE_PATH } from 'src/routes/paths';
import { mapDataToRule } from 'src/utils/mappers/ruleMapper';
import { useParams } from 'react-router-dom';

function RuleAddPage() {
  const { type } = useParams();

  const { addRule } = createApisForRule(type);
  const { t } = useTranslation();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const ruleAddMutation = useAddMutation({
    add: addRule,
    basePath: RULE_BASE_PATH + '/' + type,
    translationPrefix: 'rule',
    onSettled: () => setSubmitDisabled(false),
  });

  const onSubmit = (data) => {
    setSubmitDisabled(true);
    const mappedRule = mapDataToRule(data);
    ruleAddMutation.mutate(mappedRule);
  };

  return (
    <div className="p-3">
      <CardDisplay titleCentered title={t('rule.add')}>
        <RuleForm submitDisabled={submitDisabled} onSubmit={onSubmit} />
      </CardDisplay>
    </div>
  );
}

export default RuleAddPage;
