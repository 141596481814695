import { EVENT_PRICINGS_PATH } from 'src/api/apiPaths';
import HttpService from '../../services/HttpService';
import { mapFilterToParams } from 'src/utils/mappers/filterMapper';
import {
  add,
  createDraft,
  getVersions,
  partiallyPublishDraft,
  publishDraft,
  unPublishDraft,
  updatePublicationConstraints,
} from 'src/api/versioning/versioningApiUtil';

const instance = HttpService.getAxiosClient('eventPricing');

export const addEventPricing = (eventPricing) =>
  add(instance, EVENT_PRICINGS_PATH, eventPricing);

export const getAllEventPricings = async (page, size) => {
  const res = await instance.get(
    `${EVENT_PRICINGS_PATH}?page=${page}&size=${size}`,
  );
  return res.data;
};
export const getEventPricingById = async (eventPricingId) => {
  const res = await instance.get(`${EVENT_PRICINGS_PATH}/${eventPricingId}`);
  return res.data;
};
export const updateEventPricing = async (eventPricing, id) => {
  const res = await instance.put(`${EVENT_PRICINGS_PATH}/${id}`, eventPricing);
  return res.data;
};

export const getEventPricingVersions = (functionalId) =>
  getVersions(instance, EVENT_PRICINGS_PATH, functionalId);

export const publishEventPricingDraft = (functionalId) =>
  publishDraft(instance, EVENT_PRICINGS_PATH, functionalId);

export const partiallyPublishEventPricingDraft = (
  functionalId,
  publicationConstraints,
) =>
  partiallyPublishDraft(
    instance,
    EVENT_PRICINGS_PATH,
    functionalId,
    publicationConstraints,
  );

export const unpublishEventPricingDraft = (functionalId) =>
  unPublishDraft(instance, EVENT_PRICINGS_PATH, functionalId);

export const createEventPricingDraft = (functionalId) =>
  createDraft(instance, EVENT_PRICINGS_PATH, functionalId);

export const getAllEventpricingsByCriteria = async (page, size, filter) => {
  const params = mapFilterToParams(page, size, filter);

  const res = await instance.get(`${EVENT_PRICINGS_PATH}`, {
    params,
  });
  return res.data;
};
export const getCommissionsByEvent = async (event) => {
  const res = await instance.get(`${EVENT_PRICINGS_PATH}/commissions`, {
    params: {
      event,
    },
  });
  return res.data;
};

export const getProcessors = async (phase) => {
  const res = await instance.get(`/pricing-processors?phase=${phase}`);
  return res.data;
};

export const evaluateScript = async (data) => {
  const res = await instance.post(`/scripts/evaluate`, data);
  return res.data;
};

export const updateEventPricingPublicationConstraints = (
  functionalId,
  publicationConstraints,
) =>
  updatePublicationConstraints(
    instance,
    EVENT_PRICINGS_PATH,
    functionalId,
    publicationConstraints,
  );

export const scheduleEventPricingPublication = async (
  functionalId,
  publicationDate,
) => {
  const params = { publicationDate: publicationDate };
  const res = await instance.patch(
    `${EVENT_PRICINGS_PATH}/${functionalId}/deferred-publication`,
    null,
    { params },
  );
  return res.data;
};

export const cancelEventPricingPublication = async (functionalId) => {
  const res = await instance.delete(
    `${EVENT_PRICINGS_PATH}/${functionalId}/deferred-publication`,
  );
  return res.data;
};
