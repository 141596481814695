import { Fn, PageResult, ReusableFormSchemaDto } from 'src/types';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import {
  createReusableFormSchema,
  deleteReusableFormSchema,
  getReusableFormSchemaById,
  loadReusableFormSchemas,
  updateReusableFormSchema,
} from 'src/api';

export interface ReusableFormSchemasStore {
  getAll: () => UseQueryResult<PageResult<ReusableFormSchemaDto>>;
  save: Fn<
    void,
    UseMutationResult<ReusableFormSchemaDto, unknown, ReusableFormSchemaDto>
  >;
  destroy: Fn<void, UseMutationResult<void, unknown, string>>;
  getById: (id: string) => UseQueryResult<ReusableFormSchemaDto>;
}

export function useReusableFormSchemasStore(): ReusableFormSchemasStore {
  const baseKey = 'reusable-form-schemas';
  const client = useQueryClient();

  const getAll = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery<PageResult<ReusableFormSchemaDto>>({
      queryKey: [baseKey],
      queryFn: () =>
        loadReusableFormSchemas({
          pageSize: 1000,
          sortBy: 'label',
          sortDir: 'asc',
        }),
    });

  const save = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation<ReusableFormSchemaDto, unknown, ReusableFormSchemaDto>({
      mutationFn: (data) => {
        if (data.id) {
          return updateReusableFormSchema(data.id, data);
        }
        return createReusableFormSchema(data);
      },
      onSuccess: () => {
        client.invalidateQueries([baseKey]);
      },
    });

  const destroy = () =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutation<void, unknown, string>({
      mutationFn: (id) => deleteReusableFormSchema(id),
      onSuccess: () => {
        client.invalidateQueries([baseKey]);
      },
    });

  const getById = (id: string) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery<ReusableFormSchemaDto>({
      queryKey: [baseKey, id],
      queryFn: () => getReusableFormSchemaById(id),
    });

  return { getAll, save, destroy, getById };
}
