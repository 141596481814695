import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogoutOutlined } from '@ant-design/icons';
import modalConfirm from 'src/utils/modalConfirm';
import LanguageSelector from 'src/components/generic/LanguageSelector/LanguageSelector';
import { useAuth } from 'src/contexts/authContext';

const UserActions = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();

  return (
    <div className="flex justify-start text-gray-800 flex-col mx-4">
      <div className="flex flex-row gap-4 items-center hover:text-primary h-fit p-2 rounded-sm">
        <div className="text-primary">
          <LogoutOutlined />
        </div>
        <button onClick={() => modalConfirm(t('navbar.logout'), logout)}>
          {t('navbar.logoutBtn')}
        </button>
      </div>
      <LanguageSelector />
    </div>
  );
};
export default UserActions;
