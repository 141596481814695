import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../generic/inputs/TextInput';
import InternationalizationInput from '../../../../generic/inputs/InternationalizationInput';
import InternationalizationArrayInput from '../../../../generic/inputs/InternationalizationArrayInput';
import { FileTextOutlined, QrcodeOutlined } from '@ant-design/icons';
import DocumentAttachment from '../DocumentAttachment';
import { useFormContext } from 'react-hook-form';

const DevelopedDetailsForm = (props) => {
  const { namePrefix } = props;
  const { setValue } = useFormContext();

  useEffect(() => {
    const index = namePrefix.match(/\[(\d+)\]/)?.[1];
    if (index !== undefined) {
      setValue(`${namePrefix}.priority`, Number(index) + 1);
    }
  }, [namePrefix, setValue]);

  const { t } = useTranslation();
  return (
    <div>
      <Row gutter={[16, 12]}>
        <Col span={12}>
          <TextInput
            label={t('developedDetails.functionalId')}
            name={`${namePrefix}.functionalId`}
            startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>
        <Col span={12}>
          <InternationalizationInput
            name={`${namePrefix}.descriptions`}
            label={t('developedDetails.descriptions')}
            startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>

        <Col span={12}>
          <TextInput
            label={t('developedDetails.priority')}
            name={`${namePrefix}.priority`}
            startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
            disabled
          />
        </Col>

        <Col span={12}>
          <InternationalizationInput
            name={`${namePrefix}.titles`}
            label={t('developedDetails.titles')}
            startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>

        <Col span={24}>
          <InternationalizationArrayInput
            name={`${namePrefix}.details`}
            label={t('developedDetails.details')}
            translationPrefix={'detailDeveloped'}
            startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
          />
        </Col>

        <Col span={24}>
          <DocumentAttachment namePrefix={namePrefix} />
        </Col>
      </Row>
    </div>
  );
};

export default DevelopedDetailsForm;
