import { ELIGIBLE_ACT_PATH } from 'src/api/apiPaths';
import HttpService from '../../services/HttpService';
import { mapFilterToParams } from 'src/utils/mappers/filterMapper';
import {
  add,
  cancelPublication,
  createDraft,
  getVersions,
  partiallyPublishDraft,
  publishDraft,
  schedulePublicationDraft,
  unPublishDraft,
  updatePublicationConstraints,
} from 'src/api/versioning/versioningApiUtil';

const instance = HttpService.getAxiosClient('catalogue');

export const addEligibleAct = (eligibleAct) =>
  add(instance, ELIGIBLE_ACT_PATH, eligibleAct);

export const getAllEligibleActs = async (page, size) => {
  const res = await instance.get(
    `${ELIGIBLE_ACT_PATH}?page=${page}&size=${size}`,
  );
  return res.data;
};

export const getEligibleActById = async (eligibleActId) => {
  const res = await instance.get(`${ELIGIBLE_ACT_PATH}/${eligibleActId}`);
  return res.data;
};

export const updateEligibleAct = async (eligibleAct, id) => {
  const res = await instance.put(`${ELIGIBLE_ACT_PATH}/${id}`, eligibleAct);
  return res.data;
};

export const getEligibleActVersions = (functionalId) =>
  getVersions(instance, ELIGIBLE_ACT_PATH, functionalId);

export const publishEligibleActDraft = (functionalId) =>
  publishDraft(instance, ELIGIBLE_ACT_PATH, functionalId);

export const partiallyPublishEligibleActDraft = (
  functionalId,
  publicationConstraints,
) =>
  partiallyPublishDraft(
    instance,
    ELIGIBLE_ACT_PATH,
    functionalId,
    publicationConstraints,
  );

export const unpublishEligibleActDraft = (functionalId) =>
  unPublishDraft(instance, ELIGIBLE_ACT_PATH, functionalId);

export const createEligibleActDraft = (functionalId) =>
  createDraft(instance, ELIGIBLE_ACT_PATH, functionalId);

export const getAllEligibleActsByCriteria = async (page, size, filter) => {
  const params = mapFilterToParams(page, size, filter);
  const res = await instance.get(`${ELIGIBLE_ACT_PATH}`, {
    params,
  });
  return res.data;
};
export const scheduleEligibilityPublication = async (
  functionalId,
  publicationDate,
) =>
  schedulePublicationDraft(
    instance,
    ELIGIBLE_ACT_PATH,
    functionalId,
    publicationDate,
  );

export const cancelEligibilityPublication = async (functionalId) =>
  cancelPublication(instance, ELIGIBLE_ACT_PATH, functionalId);
export const updateEligibleActPublicationConstraints = (
  functionalId,
  publicationConstraints,
) =>
  updatePublicationConstraints(
    instance,
    ELIGIBLE_ACT_PATH,
    functionalId,
    publicationConstraints,
  );
