import { useContext } from 'react';
import { ExportSelectionContext } from 'src/contexts/exportSelectionContext';

const useExportSelectionContext = () => {
  const context = useContext(ExportSelectionContext);
  if (!context) {
    throw new Error(
      'useExportSelectionContext must be used within a ExportContextProvider',
    );
  }
  return context;
};
export { useExportSelectionContext };
