import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useShowMessage } from '../../useShowMessage';
import { useTranslation } from 'react-i18next';

export const useDeleteMutation = ({
  deleteFunc,
  basePath,
  queryKeys,
  translationPrefix,
  onSettled,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showSuccess, showError } = useShowMessage();

  return useMutation(deleteFunc, {
    onSuccess: onSuccess
      ? onSuccess
      : () => {
          queryClient.invalidateQueries(queryKeys);
          showSuccess(t(`${translationPrefix}.deleted`));
          navigate(basePath);
        },
    onError: (err) => {
      showError(err);
    },
    onSettled,
  });
};
