import CardDisplay from 'src/components/generic/CardDisplay';
import AdditionalAttribute from '../generic/AdditionalAttribute';
import { useTranslation } from 'react-i18next';
export default function GenericProduct() {
  const { t } = useTranslation();
  return (
    <CardDisplay title={t('displayCard.title')}>
      <AdditionalAttribute />
    </CardDisplay>
  );
}
