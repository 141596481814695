import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ParameterSelect from '../../../../generic/inputs/ParameterSelect';
import LevelRange from '../LevelRange';
import { Price } from '../../Price/Price';

export default function LevelPricingForm({ namePrefix }) {
  const { t } = useTranslation();
  return (
    <>
      <ParameterSelect
        name={`${namePrefix}.currency`}
        label={t('pricing.currency')}
        domain="CURRENCY"
      />
      <ParameterSelect
        name={`${namePrefix}.amountType`}
        label={t('pricing.amountType')}
        domain="AMOUNT_TYPE"
      />
      <Price
        name={`${namePrefix}.minLevel`}
        label={t('pricing.min')}
        currencyName={`${namePrefix}.currency`}
        startAdornment={<ArrowDownOutlined style={{ fontSize: '25px' }} />}
      />
      <Price
        name={`${namePrefix}.maxLevel`}
        label={t('pricing.max')}
        currencyName={`${namePrefix}.currency`}
        startAdornment={<ArrowUpOutlined style={{ fontSize: '25px' }} />}
      />
      <div className="col-span-2">
        <LevelRange namePrefix={`${namePrefix}.levelRangeList`} />
      </div>
    </>
  );
}
