import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Collapse, Empty, Tabs } from 'antd';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { pricingFacts } from 'src/api';
import Loading from 'src/components/generic/Loading';
import { extractPricingConstraintsInfo } from 'src/utils/mappers/simulationFactsNamesPricingMapper';
import { FactsContext } from '../../context';

const { Panel } = Collapse;

const tableCellStyle = {
  border: '1px solid #f0f0f0',
  padding: '8px',
};

const tableHeaderCellStyle = {
  ...tableCellStyle,
  color: 'rgba(0, 0, 0, 0.88)',
  fontWeight: 600,
  background: '#fafafa',
  transition: 'background 0.2s ease',
};

const PricingSimulationFactsList = () => {
  const { eventChoice } = useContext(FactsContext);
  const { t } = useTranslation();
  const { append, fields } = useFormContext();
  const { data, isLoading, isError, error } = useQuery(
    ['simulation-facts-eventPricing', eventChoice],
    () => {
      if (!eventChoice?.product?.product || !eventChoice.act) return null;
      return pricingFacts({
        productId: eventChoice.product.product,
        actId: eventChoice.act,
      }).then(extractPricingConstraintsInfo);
    },
    {
      enabled: !!eventChoice,
      useErrorBoundary: false,
    },
  );

  const handleAddAlternativeFacts = (factNames) => {
    factNames.forEach((fact) => {
      if (!fields.some((field) => field.name == fact.name))
        append({ name: fact.name, value: '' });
    });
  };

  if (isError) return <Empty description={error.response.data.message} />;

  if (isLoading) return <Loading />;

  const generateTableRows = (pricing, pricingIndex) => {
    if (!pricing?.factNames || pricing.factNames.length === 0) {
      return (
        <tr key={pricingIndex}>
          <td style={tableCellStyle}>
            {t('simulationPricingResult.' + pricing.pricingType) +
              ' ' +
              (pricingIndex + 1)}
          </td>
          <td style={tableCellStyle}>
            {t('simulationPricingResult.noCondition')}
          </td>
          <td style={tableCellStyle}>
            {t('simulationPricingResult.noCondition')}
          </td>
          <td style={tableCellStyle}>
            {t('simulationPricingResult.noCondition')}
          </td>
          <td style={{ textAlign: 'center', ...tableCellStyle }}></td>
        </tr>
      );
    }

    return pricing?.factNames?.map((fact, factIndex) => (
      <tr key={factIndex}>
        {factIndex === 0 && (
          <>
            <td rowSpan={pricing.factNames.length} style={tableCellStyle}>
              {t('simulationPricingResult.' + pricing.pricingType) +
                ' ' +
                (pricingIndex + 1)}
            </td>
            <td style={tableCellStyle} rowSpan={pricing.factNames.length}>
              {fact.description}
            </td>
          </>
        )}
        <td style={tableCellStyle}>{fact.name}</td>
        <td style={tableCellStyle}>{fact.values}</td>
        {factIndex === 0 && (
          <td
            style={{ textAlign: 'center', ...tableCellStyle }}
            rowSpan={pricing.factNames.length}
          >
            <Button
              onClick={() => handleAddAlternativeFacts(pricing?.factNames)}
            >
              {t('simulationPricingResult.addFacts')}
            </Button>
          </td>
        )}
      </tr>
    ));
  };

  const generateTable = (pricings) => {
    return (
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          borderRadius: '8px 8px 0 0',
        }}
      >
        <thead>
          <tr>
            <th style={tableHeaderCellStyle}>
              {t('simulationPricingResult.typePricing')}
            </th>
            <th style={tableHeaderCellStyle}>
              {t('simulationPricingResult.descriptionPricing')}
            </th>
            <th style={tableHeaderCellStyle}>
              {t('simulationPricingResult.factNames')}
            </th>
            <th style={tableHeaderCellStyle}>
              {t('simulationPricingResult.values')}
            </th>
            <th style={tableHeaderCellStyle}>{t('table.columns.actions')}</th>
          </tr>
        </thead>
        <tbody>{pricings.map(generateTableRows)}</tbody>
      </table>
    );
  };

  const pricingTabs = (data) => {
    const uniquePricingTypes = [
      ...new Set(data.map((item) => item.pricingType)),
    ];

    return uniquePricingTypes.map((pricingType) => {
      const filteredData = data.filter(
        (item) => item.pricingType === pricingType,
      );

      return {
        key: pricingType,
        label: t('simulationPricingResult.' + pricingType + 'S'),
        children: filteredData.length > 0 && generateTable(filteredData),
      };
    });
  };

  return (
    <Collapse
      accordion
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
    >
      {data?.map((commission, index) => (
        <Panel
          key={index}
          header={
            <p>
              {t('commission.titleSingle') +
                ' ' +
                (index + 1) +
                ' : ' +
                commission.commission}
            </p>
          }
        >
          <Tabs items={pricingTabs(commission.pricings)}></Tabs>
        </Panel>
      ))}
    </Collapse>
  );
};

// PricingSimulationFactsList.propTypes = {
//   data: PropTypes.array.isRequired,
//   handleAddFacts: PropTypes.func.isRequired,
// };

export default PricingSimulationFactsList;
