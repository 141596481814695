export const mapAdditionalAttributesWithConstraintsToData = (attributes) => {
  return attributes.map((attr) => ({
    ...attr,
    value: { [attr.type]: attr.value },
    constraints: attr.constraints.map((elem) => ({ content: elem })),
  }));
};

export const mapDataToAdditionAttributesWithConstraints = (data) => {
  return data.map((elem) => ({
    ...elem,
    value: elem.value[elem.type],
    constraints: elem.constraints.map((elem) => elem.content),
  }));
};
