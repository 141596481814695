import React from 'react';

interface InputLabelProps {
  label: string;
  required?: boolean;
  className?: string;
}

export default function InputLabel({
  className,
  label,
  required,
}: Readonly<InputLabelProps>) {
  return (
    <label className={className}>
      <span className="inline-flex items-center">
        {label} {required && <span className="text-red-500 font-bold">*</span>}
      </span>
    </label>
  );
}
