import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { getConstraintTypes } from 'src/api';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import ConstraintAddForm from 'src/components/constraint/form/ConstraintAddForm';
import UUIDClass from 'uuidjs';
import { useModalContext } from 'src/hooks/normalHooks/context/useModalContext';
import { COMPOUND_CONSTRAINT_TYPES } from 'src/constants/constraints';

export const InlineConstraintAddForm = ({ onAddSubmit }) => {
  const [constraintAddFormMode, setConstraintAddFormMode] = useState('HIDDEN');
  const [selectedConstraintType, setSelectedConstraintType] = useState(null);
  const { t } = useTranslation();

  const { openModal, closeModal } = useModalContext();

  const { data: constraintTypes } = useQuery(
    'constraint-types',
    getConstraintTypes,
  );

  const handleAddItem = () => {
    setConstraintAddFormMode('SELECTING');
  };

  const handleConstraintChoiceConfirmation = () => {
    if (!selectedConstraintType) {
      return;
    }

    setConstraintAddFormMode('HIDDEN');

    if (
      [COMPOUND_CONSTRAINT_TYPES.AND, COMPOUND_CONSTRAINT_TYPES.OR].includes(
        selectedConstraintType,
      )
    ) {
      onAddSubmit({
        id: UUIDClass.generate(),
        type: selectedConstraintType,
        constraints: [],
      });

      return;
    }

    openModal({
      body: (
        <ConstraintAddForm
          constraint={{ type: selectedConstraintType }}
          onCancel={closeModal}
          addConstraint={(constraint) => {
            onAddSubmit({
              id: UUIDClass.generate(),
              ...constraint,
            });
            closeModal();
          }}
          updateConstraint={(constraint) => {
            onAddSubmit({
              id: UUIDClass.generate(),
              ...constraint,
            });
            closeModal();
          }}
        />
      ),
    });
  };
  return (
    <>
      {constraintAddFormMode === 'HIDDEN' && (
        <button
          type={'button'}
          className={'px-3 p-1 text-xs flex items-center gap-1.5 text-zinc-600'}
          onClick={handleAddItem}
        >
          <PlusOutlined /> <span>{t('constraints-builder.add')}</span>
        </button>
      )}

      {constraintAddFormMode === 'SELECTING' && (
        <div className={'flex items-center w-full gap-2'}>
          <Select
            getPopupContainer={(trigger) => trigger.parentElement}
            bordered={false}
            style={{
              width: '80%',
              borderRadius: '8px',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#d9d9d9',
              backgroundColor: '#ffffff',
            }}
            size="large"
            showSearch
            placeholder={t('search.constraint')}
            optionFilterProp="name"
            showArrow
            fieldNames={{ label: 'name', value: 'id' }}
            options={constraintTypes.map((constraintType) => ({
              name: t(`constraints.${constraintType.id}`),
              id: constraintType.id,
            }))}
            value={selectedConstraintType}
            onChange={setSelectedConstraintType}
          />
          <button
            disabled={!selectedConstraintType}
            type={'button'}
            onClick={handleConstraintChoiceConfirmation}
          >
            {t('confirmationDialogue.confirm')}
          </button>
        </div>
      )}
    </>
  );
};
