import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import TextInput from 'src/components/generic/inputs/TextInput';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import InternationalizationArrayInput from 'src/components/generic/inputs/InternationalizationArrayInput';
import ConventionIncludedCard from '../ConventionIncludedCard';

const ConventionCardFormulaForm = (props) => {
  const { namePrefix } = props;
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 12]} className="m-1 mb-5">
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('conventionCardFormula.functionalId')}
        />
      </Col>

      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.label`}
          label={t('conventionCardFormula.label')}
        />
      </Col>
      <Col span={12}>
        <TextInput
          type="number"
          name={`${namePrefix}.minNumberCards`}
          label={t('conventionCardFormula.minNumberCards')}
        />
      </Col>
      <Col span={12}>
        <TextInput
          type="number"
          name={`${namePrefix}.maxNumberCards`}
          label={t('conventionCardFormula.maxNumberCards')}
        />
      </Col>
      <Col span={24}>
        <InternationalizationArrayInput
          name={`${namePrefix}.details`}
          label={t('conventionCardFormula.details')}
          translationPrefix="details"
        />
      </Col>
      <Col span={24}>
        <ConventionIncludedCard
          namePrefix={namePrefix}
          label={t('conventionCardFormula.ConventionIncludedCardList')}
        />
      </Col>
    </Row>
  );
};

export default ConventionCardFormulaForm;
