import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, Form } from 'antd';

interface CheckboxInputProps {
  name: string;
  label: string;
  className?: string;
  disabled?: string;
  description?: string;
}

export function CheckboxInput({
  name,
  label,
  className,
  disabled,
  description,
}: CheckboxInputProps) {
  // @ts-ignore
  const { control, formDisabled } = useFormContext();

  return (
    <Form.Item help={description} className="mb-0">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox
            {...field}
            className={className}
            disabled={disabled || formDisabled}
            checked={field.value}
            onChange={(v) => field.onChange(v.target.checked)}
          >
            {label}
          </Checkbox>
        )}
      />
    </Form.Item>
  );
}
