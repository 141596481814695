import PropTypes from 'prop-types';
import EligibilitySimulationForm from 'src/components/simulation/EligibilitySimulationForm';

export const SimulationPage = () => {
  return (
    <div className="w-full min-h-screen h-full bg-gray-100">
      <div className="h-full w-full">
        <EligibilitySimulationForm />
      </div>
    </div>
  );
};

SimulationPage.propTypes = {
  children: PropTypes.any,
};
