import { PUBLICATION_STATUS } from 'src/utils/constants/publicationStatus';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';
import { Button } from 'antd';
import modalConfirm from 'src/utils/modalConfirm';
import getUserRolesByTypeAndResource from 'src/utils/authUtils';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CreateDraftButton({ element, createDraft, resource, updateUrl }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (element.status === PUBLICATION_STATUS.DRAFT) {
    return null;
  }

  return (
    <AuthComponentDisabler
      componentRender={() => (
        <Button
          data-testid="create-draft-button"
          type="primary"
          ghost
          onClick={() => {
            modalConfirm(t('versioning.overwriteDraftMessage'), () =>
              createDraft(element.id).then((res) => {
                navigate(
                  typeof updateUrl === 'function'
                    ? updateUrl(res.id)
                    : updateUrl + res.id,
                );
                window.location.reload();
              }),
            );
          }}
        >
          {t('versioning.createDraft')}
        </Button>
      )}
      roles={getUserRolesByTypeAndResource('EDIT', resource)}
    />
  );
}

export { CreateDraftButton };
