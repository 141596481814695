import FlatPricingForm from 'src/components/eventPricing/pricing/form/FlatPricingForm/FlatPricingForm';
import PercentagePricingForm from 'src/components/eventPricing/pricing/form/PercentagePricingForm/PercentagePricingForm';
import LevelPricingForm from 'src/components/eventPricing/pricing/form/LevelPricingForm/LevelPricingForm';
import FormulaPricingForm from '../components/eventPricing/pricing/form/FormulaPricingForm';
import RatePricingForm from 'src/components/eventPricing/pricing/form/RatePricingForm/RatePricingForm';

export const pricingTypes = {
  'flat-pricing': {
    component: FlatPricingForm,
  },
  'percentage-pricing': {
    component: PercentagePricingForm,
  },
  'level-pricing': {
    component: LevelPricingForm,
  },
  'formula-pricing': {
    component: FormulaPricingForm,
  },
  'rate-pricing': {
    component: RatePricingForm,
  },
};
