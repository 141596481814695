import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from '../mapperHelpers';

export const mapDataToParameter = (data, domain) => {
  return {
    functionalId: data.functionalId,
    name: data.name,
    domain: domain.id,
    active: !!data.active,
    descriptions: mapDataToInternationalisation(data.description),
    additionalFields: data.additionalFields,
  };
};

export const mapParameterToData = (parameter) => {
  return {
    functionalId: parameter.functionalId,
    name: parameter.name,
    active: parameter.active,
    description: mapInternationalisationToData(parameter.descriptions),
    additionalFields: parameter.additionalFields,
  };
};
