import Cards from 'src/components/dashboard/cards/cards';
import Charts from 'src/components/dashboard/charts/charts';

export const DashboardPage = () => {
  return (
    <div className="p-5 ">
      <Cards />
      <Charts />
    </div>
  );
};
