import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

export default function SwitchInput(props) {
  const { name, label, disabled = false } = props;

  const { control, setValue, formDisabled } = useFormContext();
  const value = useWatch({ control, name });
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage,
  );

  const handleClick = () => {
    if (formDisabled || disabled) {
      return;
    }
    setValue(name, !value);
  };

  const isArabic = selectedLang === 'ar';
  return (
    <div className="flex items-end h-full justify-between pb-2">
      <span>{label}</span>
      <Form.Item style={{ marginBottom: '0px' }}>
        <div
          onClick={handleClick}
          data-testid="switch"
          className={
            `cursor-pointer w-12 h-6 rounded-full p-1 flex justify-${
              isArabic ? 'end' : 'start'
            } transition-all duration-200 ` +
            (value ? ' bg-orange-400 ' : ' bg-gray-400 ') +
            (formDisabled || disabled
              ? 'cursor-not-allowed bg-gray-200'
              : 'cursor-pointer ')
          }
        >
          <div
            className={
              'w-4 h-4 bg-white rounded-full z-10 transition-all duration-300 ' +
              (value ? 'translate-x-6 bg-white' : '')
            }
          />
        </div>
      </Form.Item>
    </div>
  );
}

SwitchInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
