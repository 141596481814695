import {
  mapDataToInternationalisation,
  mapInternationalisationToData,
} from 'src/utils/mappers/mapperHelpers';

const mapConventionIncludedCardToData = (elem) => {
  return {
    product: elem.product,
    options: elem.productOptions,
    minNumberCards: elem.minNumberCards,
    maxNumberCards: elem.maxNumberCards,
    details: (elem.details || []).map((elem) =>
      mapInternationalisationToData(elem),
    ),
  };
};

const mapDataToConventionIncludedCard = (data) => {
  return {
    product: data.product,
    productOptions: data.options,
    minNumberCards: data.minNumberCards,
    maxNumberCards: data.maxNumberCards,
    details: (data.details || []).map((elem) =>
      mapDataToInternationalisation(elem),
    ),
  };
};

const mapConventionCardFormulaToData = (elem) => {
  return {
    functionalId: elem.functionalId,
    label: mapInternationalisationToData(elem.label),
    minNumberCards: elem.minNumberCards,
    maxNumberCards: elem.maxNumberCards,
    details: (elem.details || []).map((elem) =>
      mapInternationalisationToData(elem),
    ),
    conventionIncludedCardList: (elem.conventionIncludedCardList || []).map(
      (card) => mapConventionIncludedCardToData(card),
    ),
  };
};

const mapDataToConventionCardFormula = (data) => {
  return {
    functionalId: data.functionalId,
    label: mapDataToInternationalisation(data.label),
    minNumberCards: data.minNumberCards,
    maxNumberCards: data.maxNumberCards,
    details: (data.details || []).map((elem) =>
      mapDataToInternationalisation(elem),
    ),
    conventionIncludedCardList: (data.conventionIncludedCardList || []).map(
      (card) => mapDataToConventionIncludedCard(card),
    ),
  };
};

export const mapConventionCardToData = (elem) => {
  return {
    conventionCardFormulaList: (elem.conventionCardFormulaList || []).map(
      (formula) => mapConventionCardFormulaToData(formula),
    ),
  };
};

export const mapDataToConventionCard = (data) => {
  return {
    conventionCardFormulaList: (data.conventionCardFormulaList || []).map(
      (formula) => mapDataToConventionCardFormula(formula),
    ),

    type: 'conventionCard',
  };
};
