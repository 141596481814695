import axios from 'axios';
import { API_NAMES } from 'src/utils/constants';
import i18next from 'i18next';
import { getAuthToken } from 'src/contexts/authContext';

const BASE_URL = window.ENV.API_URL;
const BASE_URL_USAGE = window.ENV.API_USAGE_URL;
const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
};

axios.defaults.timeout = 60000;
axios.defaults.headers = { 'Content-Type': 'application/json' };

const _catalogue_axios = axios.create({
  baseURL: BASE_URL,
});

const _catalogue_usage_axios = axios.create({
  baseURL: BASE_URL_USAGE,
});

const _event_pricing_axios = axios.create({
  baseURL: window.ENV.EVENT_PRICING_API_URL,
});

const _event_pricing_usage_axios = axios.create({
  baseURL: window.ENV.EVENT_PRICING_USAGE_API_URL,
});

const _facts_axios = axios.create({
  baseURL: window.ENV.FACT_API_URL,
});

const configure = () => {
  const oauthInterceptor = (config) => {
    const accessToken = getAuthToken();
    if (accessToken !== null) {
      return {
        ...config,
        start: new Date(),
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
          Pragma: 'no-cache',
          'Accept-Language': i18next.language,
        },
      };
    }
  };
  _catalogue_axios.interceptors.request.use(oauthInterceptor);
  _catalogue_usage_axios.interceptors.request.use(oauthInterceptor);
  _event_pricing_axios.interceptors.request.use(oauthInterceptor);
  _event_pricing_usage_axios.interceptors.request.use(oauthInterceptor);
  _facts_axios.interceptors.request.use(oauthInterceptor);
};

const getAxiosClient = (type) => {
  return {
    [API_NAMES.CATALOG]: _catalogue_axios,
    catalogue_usage: _catalogue_usage_axios,
    [API_NAMES.EVENT_PRICING]: _event_pricing_axios,
    [API_NAMES.FACTS]: _facts_axios,
    [API_NAMES.EVENT_PRICING_USAGE]: _event_pricing_usage_axios,
  }[type];
};

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
};

export default HttpService;
