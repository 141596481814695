import {
  BarcodeOutlined,
  FileTextOutlined,
  QrcodeOutlined,
  TagOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import InternationalizationInput from 'src/components/generic/inputs/InternationalizationInput';
import TextInput from 'src/components/generic/inputs/TextInput';
import Module from '../Module';

export default function OfferForm(props) {
  const { namePrefix } = props;
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 12]}>
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.functionalId`}
          label={t('offers.functionalId')}
          startAdornment={<QrcodeOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <TextInput
          name={`${namePrefix}.codeOfferTech`}
          label={t('offers.codeOfferTech')}
          startAdornment={<BarcodeOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.label`}
          label={t('offers.label')}
          startAdornment={<TagOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>
      <Col span={12}>
        <InternationalizationInput
          name={`${namePrefix}.description`}
          label={t('offers.description')}
          startAdornment={<FileTextOutlined style={{ fontSize: '25px' }} />}
        />
      </Col>

      <Col span={24}>
        <Module namePrefix={`${namePrefix}.modules`} />
      </Col>
    </Row>
  );
}
