import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import modalConfirm from 'src/utils/modalConfirm';

interface FieldDuplicateButtonProps {
  onDuplicate: VoidFunction;
}

export default function FieldDuplicateButton({
  onDuplicate,
}: FieldDuplicateButtonProps) {
  const { t } = useTranslation();

  return (
    <Button
      data-testid="field-duplicate-button"
      onClick={() =>
        modalConfirm(
          t('form_schema.field_duplicate_confirm_message'),
          onDuplicate,
        )
      }
      className="inline-flex items-center justify-center bg-white"
      size="middle"
      shape="circle"
      icon={<CopyOutlined className="text-xs" />}
    />
  );
}
