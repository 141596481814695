import { FormBuilderFieldUtil } from 'src/pages/formSchemas/types';
import { resolveFormDefaultValueForPlainTypes } from 'src/pages/formSchemas/utils/common';
import { DesignerComponent } from './DesignerComponent';
import { DesignerFormComponent } from './DesignerFormComponent';
import { designerDefaultFieldValues } from './designerDefaultFieldValues';
import { FormComponent } from './FormComponent';

export const localizedTextFieldType: FormBuilderFieldUtil = {
  designerComponent: DesignerComponent,
  designerFormComponent: DesignerFormComponent,
  designerDefaultFieldValues,
  formComponent: FormComponent,
  formResolveDefaultValue: resolveFormDefaultValueForPlainTypes,
};
