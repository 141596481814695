import { Form, Input, Select, Space } from 'antd';
import useParameters from 'src/hooks/normalHooks/useParameters';
import { Controller, useFormContext } from 'react-hook-form';
import InputConditionalDisplayer from 'src/components/generic/inputs/InputConditionalDisplayer';

export default function Amount({ name, label, disabled }) {
  const { control, formDisabled } = useFormContext();
  const { data, isLoading, isError } = useParameters('CURRENCY');

  return (
    <Form.Item style={{ marginBottom: '0px' }}>
      <label>{label}</label>
      <Space.Compact size="large" block>
        <Controller
          name={`${name}.price`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="number"
              className="w-1/2"
              disabled={disabled || formDisabled}
            />
          )}
        />
        <InputConditionalDisplayer isLoading={isLoading} isError={isError}>
          <Controller
            name={`${name}.currency`}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={data}
                showSearch
                className="w-1/2"
                fieldNames={{ label: 'name', value: 'functionalId' }}
                disabled={disabled || formDisabled}
              />
            )}
          />
        </InputConditionalDisplayer>
      </Space.Compact>
    </Form.Item>
  );
}
