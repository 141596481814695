import { COMPOUND_CONSTRAINT_TYPES } from 'src/constants/constraints';
import { mapCompositeConstraint } from './CompositeConstraintSimulationResultMapper/CompositeConstraintSimulationResultMapper';
import { mapListConstraint } from './ListConstraintSimulationResultMapper/ListConstraintSimulationResultMapper';
import {
  createNodeTitle,
  createNodeTitleFromSimpleConstraint,
} from './utils/utils';

const mapSimpleConstraint = (constraint) => {
  return {
    title: createNodeTitleFromSimpleConstraint(constraint),
    key: Math.random() + '',
  };
};

export const mapConstraint = (constraint) => {
  if (constraint.constraintDto.type.includes('Composite'))
    return mapCompositeConstraint(constraint);
  if (
    Object.values(COMPOUND_CONSTRAINT_TYPES).includes(
      constraint.constraintDto.type,
    )
  )
    return mapListConstraint(constraint);
  if (constraint.constraintDto.type.includes('rule'))
    return mapRuleConstraint(constraint);
  return mapSimpleConstraint(constraint);
};

const mapAlternativeList = (alternative, altIndex, t) => {
  const evaluation = alternative.every((constraint) => constraint.evaluation);

  return {
    title: createNodeTitle(
      t('simulation.alternative', {
        number: altIndex + 1,
      }),
      evaluation,
    ),
    key: Math.random() + '',
    children: (alternative || []).map((constraint) =>
      mapConstraint(constraint),
    ),
  };
};

export const mapSimulationResult = (data, t) => {
  if (!data || !data.constraintSimulationList) {
    return [];
  }

  return data.constraintSimulationList.map((simulation) => {
    return {
      title: createNodeTitle(
        t('simulation.target.' + simulation.target),
        simulation.evaluation,
      ),
      key: Math.random() + '',
      children: (simulation?.constraintList || []).map(
        (alternative, altIndex) => {
          return mapAlternativeList(alternative, altIndex, t);
        },
      ),
    };
  });
};

export const mapRuleConstraint = (constraint) => {
  const constraints = constraint.constraintEvaluationDtoList;
  return {
    title: createNodeTitle(constraint.title, constraint.evaluation),
    key: Math.random() + '',
    children: constraints.map((alternative) => mapConstraint(alternative)),
  };
};
