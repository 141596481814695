import React from 'react';
import {
  DesignerFormComponentType,
  FormBuilderField,
} from 'src/pages/formSchemas/types';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import TextInput from 'src/components/generic/inputs/TextInput';
import { Button } from 'antd';
import SwitchInput from 'src/components/generic/inputs/SwitchInput';

export const DesignerFormComponent: DesignerFormComponentType = ({
  fieldName,
  fieldValue,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const methods = useForm<FormBuilderField>({
    defaultValues: fieldValue,
  });

  const handleSubmit = (d: FormBuilderField) => {
    return onSubmit(fieldName, d);
  };

  return (
    <FormProvider {...methods}>
      <form className="space-y-4" onSubmit={methods.handleSubmit(handleSubmit)}>
        <TextInput type="text" name="name" label={t('form.name')} required />
        <TextInput type="text" name="label" label={t('form.label')} required />
        <SwitchInput
          name="config.defaultValue"
          label={t('form.default_value')}
        />
        <Button
          type="primary"
          ghost
          htmlType="submit"
          className="border-green-600 text-green-600 hover:border-green-500 hover:text-green-500 disabled:opacity-30"
        >
          {t('form.save')}
        </Button>
      </form>
    </FormProvider>
  );
};
