import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Form, Input as AntdInput } from 'antd';
import PropTypes from 'prop-types';
import CronInput from 'src/components/generic/inputs/CronInput/CronInput';
import { DEFAULT_LOCALE_EN, FRENCH_LOCALE } from 'src/utils/cronUtils/locale';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Controller, useFormContext } from 'react-hook-form';
import { useLanguages } from 'src/hooks/componentHooks/useLanguges';

export function CronFormInput(props) {
  const { t } = useTranslation();
  const { currentPeriodicity, name } = props;
  const { control, setValue } = useFormContext();

  useEffect(() => {
    let newCronValue;
    switch (currentPeriodicity) {
      case 'YEARLY':
        newCronValue = '0 0 1 1 *';
        break;
      case 'MONTHLY':
        newCronValue = '0 0 1 * *';
        break;
      case 'WEEKLY':
        newCronValue = '0 0 * * 0';
        break;
      case 'DAILY':
        newCronValue = '0 0 * * *';
        break;
      default:
        newCronValue = '0 0 * * *';
    }

    setValue('cronExpression', newCronValue, { shouldDirty: true });
  }, [currentPeriodicity, setValue]);

  const { selectedLang } = useLanguages();

  return (
    <div>
      <label>{t('factTechnicalSetting.batch.resetZero.input.cron')}</label>
      <div className="p-4 border border-gray-300 rounded-lg">
        <Form.Item>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <CronInput
                  className={'w-full'}
                  value={value}
                  setValue={onChange}
                  allowedPeriods={['year', 'month', 'week', 'day']}
                  locale={
                    selectedLang === 'en-US' ? DEFAULT_LOCALE_EN : FRENCH_LOCALE
                  }
                  clearButton={false}
                />
                <Divider>OR</Divider>
                <AntdInput
                  value={value}
                  onChange={(event) => onChange(event.target.value)}
                />
              </>
            )}
          />
        </Form.Item>
        <InfoCircleOutlined className={'m-2'} />
        <span>{t('factTechnicalSetting.batch.resetZero.annotation')}</span>
      </div>
    </div>
  );
}

CronFormInput.propTypes = {
  name: PropTypes.string,
  currentPeriodicity: PropTypes.string,
};
