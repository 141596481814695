function If({ condition, children }) {
  if (Boolean(condition)) {
    if (typeof children === 'function') {
      return children();
    }

    return children;
  }

  return <></>;
}

export { If };
