import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { appendIds, mapConstraints } from 'src/utils/builderUtils';
import { ConstraintCombinerWrapper } from 'src/components/constraints/builder/components';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { COMPOUND_CONSTRAINT_TYPES, OR } from 'src/constants/constraints';

export const ConstraintsBuilder = ({
  constraints,
  readOnly = false,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const initialState = useMemo(() => {
    return appendIds(constraints);
  }, []);

  const ctx = mapConstraints(initialState);
  const form = useForm({
    defaultValues: {
      constraints: ctx,
    },
  });

  const handleSaveConstraints = () => {
    const data = form
      .getValues('constraints')
      .map((c) => c['constraints'] ?? [c]);
    onSubmit?.({ constraints: data });
  };

  return (
    <FormProvider {...form}>
      <div className={'flex flex-col gap-1'}>
        <div className={'flex justify-end'}>
          <Button
            htmlType={'button'}
            ghost
            className="bg-orange-500 text-white flex items-center disabled:opacity-30"
            onClick={handleSaveConstraints}
          >
            {t('form.save')}
          </Button>
        </div>
        <ConstraintCombinerWrapper
          readOnly={readOnly}
          namePrefix={'constraints'}
          type={OR}
          constraint={{ type: COMPOUND_CONSTRAINT_TYPES.OR, constraints: ctx }}
        />
      </div>
    </FormProvider>
  );
};
