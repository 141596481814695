import { PlusOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllRecommendationsByCriteria } from '../../../api/recommendation';
import { Error500 } from 'src/pages/error';
import {
  RECOMMENDATION_FILTER_FIELDS,
  filterParamMapedKeys,
  filterListTypeKeys,
} from 'src/utils/filterFields';
import { RECOMMENDATION_ADD_PATH } from 'src/routes/paths';
import CustomPagination from 'src/components/generic/CustomPagination';
import GenericFilter from 'src/components/generic/GenericFilter';
import Loading from 'src/components/generic/Loading';
import {
  mapParamsToFilter,
  mapFilterToUrl,
} from 'src/utils/mappers/filterMapper';
import { USER_ROLES } from 'src/utils/constants';
import { AuthComponentDisabler } from 'src/components/generic/AuthComponentDisabler';
import RecommendationCard from './RecommendationCard';

const ELEMENT_PER_PAGE = 20;

export const RecommendationListingPage = () => {
  const { t } = useTranslation();
  const { state, pathname, search } = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [currentFilter, setCurrentFilter] = useState({});

  // Extract query parameters from the URL
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const {
    data: recommendations,
    isLoading,
    isError,
  } = useQuery(['get-recommendations', page, currentFilter], () =>
    getAllRecommendationsByCriteria(page - 1, ELEMENT_PER_PAGE, currentFilter),
  );

  useEffect(() => {
    if (state?.toastContent) toast.success(state?.toastContent);
    const filter = mapParamsToFilter(
      queryParams,
      filterParamMapedKeys,
      filterListTypeKeys,
    );

    setCurrentFilter(filter);
    setPage(1);
  }, [state, queryParams]);

  const onFilter = (param) => {
    const paramsString = mapFilterToUrl(param || {}, filterParamMapedKeys); // Get the query string
    const newUrl = `${pathname}?${paramsString}`; // Construct the new URL
    navigate(newUrl, { replace: true });
  };

  if (isError) return <Error500 />;

  return (
    <div className="h-auto">
      <GenericFilter
        onFilter={onFilter}
        initialFilterValues={currentFilter}
        emptyFilterValues={{}}
        filterFields={RECOMMENDATION_FILTER_FIELDS}
        disableSubmit={isLoading}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <div className="w-full h-full flex flex-col p-5">
          <div className="flex items-end mb-4 mr-2 justify-between">
            <p className="mx-2">
              {recommendations?.totalElements[0]?.totalElements
                ? recommendations?.totalElements[0]?.totalElements +
                  ' ' +
                  t('search.numberRecommendationsFound')
                : t('search.noResultFound')}
            </p>
            <Link to={RECOMMENDATION_ADD_PATH}>
              <AuthComponentDisabler
                componentRender={() => (
                  <Button
                    type="primary"
                    className="bg-orange-500 text-white flex items-center disabled:opacity-30"
                  >
                    <PlusOutlined sx={{ fontSize: '20px' }} /> {t('crud.add')}{' '}
                    {t('subnav.recommendation')}
                  </Button>
                )}
                roles={[USER_ROLES.EDIT_RECOMMENDATIONS]}
              />
            </Link>
          </div>

          {recommendations?.content.length > 0 ? (
            <div className="grid grid-cols-5 gap-4 w-full mb-2">
              {recommendations?.content.map((elem) => (
                <Link key={elem.id} to={`/recommendations/update/${elem.id}`}>
                  <RecommendationCard recommendation={elem} />
                </Link>
              ))}
            </div>
          ) : (
            <div className="h-96">
              <Empty />
            </div>
          )}
          <CustomPagination
            current={page}
            total={recommendations?.totalElements[0]?.totalElements}
            pageSize={ELEMENT_PER_PAGE}
            onChange={(value) => setPage(value)}
          />
        </div>
      )}
    </div>
  );
};
export default RecommendationListingPage;
