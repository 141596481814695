import { AND, OR } from 'src/constants/constraints';

const COLOR_MAP = {
  [AND]: 'bg-blue-400',
  [OR]: 'bg-orange-400',
};

const BADGE_COLOR_MAP = {
  [AND]: 'bg-blue-200 border border-blue-500 text-blue-700',
  [OR]: 'bg-orange-200 border border-orange-500 text-orange-700',
};

export const resolvedFillColor = (type) => {
  return COLOR_MAP[type] ?? COLOR_MAP.AND;
};

export const resolveBadgeColor = (type) => {
  return BADGE_COLOR_MAP[type] ?? BADGE_COLOR_MAP.AND;
};
