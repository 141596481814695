import { Card, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ActionType, API_NAMES, AuditableAction } from 'src/utils/constants';
import { StatisticFilters } from 'src/components/administration/audit/StatisticsFilters';
import { EmptyState } from 'src/components/generic/EmptyState';
import { useGetStatisticsByApiAndDomainAndFilter } from 'src/pages/administration/auditStatistics/store';
import { ChartBar, Percentage } from 'src/components/icons';
import { StatisticsCards } from 'src/components/administration/audit/StatisticCards';
import { appendTechnicalId } from 'src/utils/arrayUtils';
import { Clock } from 'src/components/icons/Clock';

const { Option } = Select;

function getStatDataMap(
  eligibilityStatisticsData,
  productStatisticsData,
  pricingStatisticsData,
  factsStatisticsData,
) {
  return {
    [ActionType.usageEligibility]: appendTechnicalId(eligibilityStatisticsData),
    [ActionType.usageProduct]: appendTechnicalId(productStatisticsData),
    [ActionType.usagePricing]: appendTechnicalId(pricingStatisticsData),
    [ActionType.usageFacts]: appendTechnicalId(factsStatisticsData),
  };
}

function StatisticsPanel({ api }) {
  const { t } = useTranslation();
  const [domain, setDomain] = useState(Object.keys(AuditableAction[api])[0]);
  const [currentFilter, setCurrentFilter] = useState({
    auditableAction: Object.values(AuditableAction[api][domain])[0],
  });
  const handleDomainChange = (domain) => {
    setCurrentFilter({
      auditableAction: Object.values(AuditableAction[api][domain])[0],
    });
    setDomain(domain);
  };

  const {
    data: statisticsData,
    isLoading: isGetStatisticsLoading,
    isError: isGetStatisticsError,
  } = useGetStatisticsByApiAndDomainAndFilter(api, domain, currentFilter);

  const eligibilityStatisticsData = [
    {
      title:
        t('statistics.invocationNumber') +
        ' ' +
        t('statistics.usage/eligibility'),
      value: statisticsData?.numberOfCalls,
      icon: <ChartBar />,
      isLoading: isGetStatisticsLoading,
      isError: isGetStatisticsError,
    },
    {
      title: t('statistics.responseTime'),
      value: `${statisticsData?.executionTimeAverageInMilliseconds?.toFixed(
        2,
      )} ms`,
      icon: <Clock />,
      isLoading: isGetStatisticsLoading,
      isError: isGetStatisticsError,
    },
  ];
  const productStatisticsData = [
    {
      title:
        t('statistics.invocationNumber') + ' ' + t('statistics.usage/product'),
      value: statisticsData?.numberOfCalls,
      icon: <ChartBar />,
      isLoading: isGetStatisticsLoading,
      isError: isGetStatisticsError,
    },
    {
      title: t('statistics.responseTime'),
      value: `${statisticsData?.executionTimeAverageInMilliseconds?.toFixed(
        2,
      )} ms`,
      icon: <Clock />,
      isLoading: isGetStatisticsLoading,
      isError: isGetStatisticsError,
    },
  ];
  const factsStatisticsData = [
    {
      title:
        t('statistics.invocationNumber') + ' ' + t('statistics.usage/product'),
      value: statisticsData?.numberOfCalls,
      icon: <ChartBar />,
      isLoading: isGetStatisticsLoading,
      isError: isGetStatisticsError,
    },
    {
      title: t('statistics.responseTime'),
      value: `${statisticsData?.executionTimeAverageInMilliseconds?.toFixed(
        2,
      )} ms`,
      icon: <Clock />,
      isLoading: isGetStatisticsLoading,
      isError: isGetStatisticsError,
    },
  ];
  const pricingStatisticsData = [
    {
      title:
        t('statistics.invocationNumber') + ' ' + t('statistics.usage/pricing'),
      value: statisticsData?.numberOfCalls,
      icon: <ChartBar />,
      isLoading: isGetStatisticsLoading,
      isError: isGetStatisticsError,
    },
    {
      title: t('statistics.responseTime'),
      value: `${statisticsData?.executionTimeAverageInMilliseconds?.toFixed(
        2,
      )} ms`,
      icon: <Clock />,
      isLoading: isGetStatisticsLoading,
      isError: isGetStatisticsError,
    },
    {
      title: t('statistics.ratio'),
      value: `${statisticsData?.countPreferentialPriceApplied} / ${statisticsData?.countStandardPriceApplied}`,
      icon: <Percentage />,
      isLoading: isGetStatisticsLoading,
      isError: isGetStatisticsError,
    },
    {
      title: t('statistics.preferentialPricingAccumulation'),
      value: `${statisticsData?.countPreferentialPriceApplied}`,
      icon: <ChartBar />,
      isLoading: isGetStatisticsLoading,
      isError: isGetStatisticsError,
    },
  ];

  const statDataMap = getStatDataMap(
    eligibilityStatisticsData,
    productStatisticsData,
    pricingStatisticsData,
    factsStatisticsData,
  );

  const StatisticDomainSelect =
    api === API_NAMES.EVENT_PRICING ? null : (
      <Select
        className="min-w-[200px]"
        onChange={handleDomainChange}
        value={domain}
        disabled={Object.keys(AuditableAction[api])?.length === 1}
      >
        {Object.keys(AuditableAction[api])?.map((value) => (
          <Option key={value} value={value}>
            {t(`statistics.${value}`)}
          </Option>
        ))}
      </Select>
    );

  if (isGetStatisticsError) {
    return <EmptyState />;
  }
  return (
    <Card
      title={t('statistics.statistics') + ' ' + t(`statistics.${domain}`)}
      bordered={false}
      className="bg-white"
      extra={StatisticDomainSelect}
    >
      <StatisticFilters
        api={api}
        domain={domain}
        setFilter={setCurrentFilter}
      />
      <StatisticsCards statData={statDataMap[domain]} />
    </Card>
  );
}

export { StatisticsPanel };
