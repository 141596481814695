import { Typography, List } from 'antd';
const { Link } = Typography;

const PanelList = ({ items, linkPrefix }) => (
  <List
    dataSource={items}
    renderItem={(item) => (
      <List.Item>
        <Link href={`${linkPrefix}/update/${item.id}`} target="_blank">
          {item.functionalId} {' - '} {item.description}
        </Link>
      </List.Item>
    )}
  />
);
export default PanelList;
