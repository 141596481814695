import { productsSchemableType } from 'src/pages/formSchemas/schemableTypes/products';
import { SchemableTypeUtils } from 'src/pages/formSchemas/types';
import { eligibleActsSchemableType } from 'src/pages/formSchemas/schemableTypes/eligibleActs';

export const SCHEMABLE_TYPES = {
  products: 'products',
  eligibleActs: 'eligibleActs',
  // parameters: 'parameters',
} as const;

export const SCHEMABLE_UTILITY: SchemableTypeUtils = {
  [SCHEMABLE_TYPES.products]: productsSchemableType,
  [SCHEMABLE_TYPES.eligibleActs]: eligibleActsSchemableType,
};

export const SCHEMABLE_ID_KEY_DELIMITER = '|';
