import { useSelector } from 'react-redux';

const useSelectedLanguage = () => {
  const selectedLang = useSelector(
    (state) => state?.selectedLanguage?.selectedLanguage,
  );

  const renderLocalizedContent = (contentArray) => {
    const localizedContent = contentArray.find(
      (elem) => elem.locale === selectedLang && elem.canal === 'ADMIN',
    );
    return localizedContent ? localizedContent.content : '';
  };
  return { renderLocalizedContent, selectedLang };
};

export { useSelectedLanguage };
